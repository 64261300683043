import { Flex, Heading, Tag, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import { Box, Text } from 'rebass'
import styled from 'styled-components'
import {
  initiateCancelRefundInvoice,
  initiateInvoiceCharge,
  initiateReattemptInvoiceRefund,
  InvoiceStatus,
  RefundStatus
} from '../../api'
import { AdminBodyWrapper } from '../../components/AdminBodyWrapper'
import { AlertBox } from '../../components/AlertBox'
import { Button } from '../../components/Button'
import { GroupingHeaderLayout } from '../../components/GroupingHeaderLayout'
import { useAuth } from '../../context/auth-context'
import { routeStrings } from '../../routeStrings'
import { Card } from '../../styled/Card'
import { CenteredSpinner } from '../../styled/CenteredSpinner'
import { IInvoice } from '../../types'
import { useInvoice, useOrganizationInvoicePreview } from '../../utils/apiHooks'
import { useRole } from '../../utils/rbac'
import { LayoutMemberDashboard } from '../MemberDashboard/LayoutMemberDashboard'

const Title = styled(Text)({
  textAlign: 'left',
  fontSize: '18px',
  color: '#525961'
})

const PreviewTitle = styled(Text)({
  marginBottom: '12px',
  fontWeight: 'bold',
  textAlign: 'left',
  fontSize: '18px',
  color: '#525961'
})

const Value = styled.div({
  textAlign: 'left',
  fontSize: '18px',
  color: '#8B96A3',
  textTransform: 'capitalize'
})

const TitleValue: React.FC<{ title: string; value: string }> = ({
  title,
  value
}) => (
  <Box mb={3}>
    <Title mb={1}>{title}</Title>
    <Value>{value}</Value>
  </Box>
)

const InvoiceDetails: React.FC<{ invoice: IInvoice }> = ({ invoice }) => {
  return (
    <Flex style={{ padding: '16px', width: '100%' }}>
      <Box width={['100%', '50%']}>
        {invoice.id !== -1 ? (
          <TitleValue
            title="Date Issued"
            value={new Date(invoice.issued_on).toLocaleDateString(undefined, {
              day: 'numeric',
              month: 'long',
              year: 'numeric'
            })}
          />
        ) : (
          <PreviewTitle>Next months invoice</PreviewTitle>
        )}
        <TitleValue title="Status" value={invoice.status.toLocaleLowerCase()} />
        <TitleValue title="Amount" value={'$' + invoice.amount_charged} />

        {invoice.paid_on && (
          <TitleValue title="Date Paid" value={invoice.paid_on} />
        )}
      </Box>
      {invoice.refund != undefined && (
        <Box>
          <Heading mb={2} size={'sm'}>
            Refund Details
          </Heading>
          <TitleValue
            title="Date Attempted"
            value={new Date(invoice.refund.created_at).toLocaleDateString(
              undefined,
              {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
                minute: 'numeric',
                hour: 'numeric'
              }
            )}
          />

          <TitleValue
            title="Status"
            value={invoice.refund.status.toLocaleLowerCase()}
          />
          <TitleValue title="Amount" value={'$' + invoice.refund.value} />
        </Box>
      )}
    </Flex>
  )
}

export const MemberDashboardBillingInvoice: React.FC = () => {
  const history = useHistory()
  const match = useRouteMatch<{ invoiceId: string }>()
  const { isInAdminRole } = useRole()
  // const [invoice, setInvoice] = useState<Invoice>()
  const [error, setError] = useState<string>('')
  const [isProcessing, setIsProcessing] = useState(false)

  const { me, currentOrganization } = useAuth()

  const [invoice, setInvoice] = useState<IInvoice>()

  const invoiceId = match?.params.invoiceId

  const {
    data: invoiceReal,
    isValidating: realLoading,
    revalidate: revalidateRealInvoice,
    error: errorFetching
  } = useInvoice(parseInt(invoiceId ? invoiceId : ''))
  const { data: invoicePreview, isValidating: previewLoading } =
    useOrganizationInvoicePreview(currentOrganization?.id)

  let preview = false

  if (
    invoiceId === undefined ||
    isNaN(parseInt(invoiceId)) ||
    invoiceId === '-1'
  ) {
    preview = true
  }

  // Toast info module from chakra
  const toastPopup = useToast()
  useEffect(() => {
    if (preview && invoicePreview) {
      setInvoice(invoicePreview.invoice)
    } else {
      setInvoice(invoiceReal)
    }
  }, [realLoading, previewLoading])
  useEffect(() => {
    if (errorFetching) {
      toastPopup({
        description: 'Invoice unavailable',
        status: 'error',
        duration: 4000
      })
      history.push(
        isInAdminRole
          ? routeStrings.adminDashboard
          : routeStrings.memberDashboardHome
      )
    }
  }, [errorFetching])

  const handleChargeInvoice = () => {
    if (isProcessing) return
    setIsProcessing(true)
    const token = window.localStorage.getItem('Token')
    initiateInvoiceCharge(invoice!.id, { token })
      .then((res) => {
        setIsProcessing(false)
        toastPopup({
          position: 'top',
          duration: 5000,
          render: (onClose) => (
            <AlertBox onClose={onClose}>
              Invoice Charge process started
            </AlertBox>
          ),
          isClosable: true
        })
      })
      .catch((error) => {
        setIsProcessing(false)

        try {
          setError(error.response.data.message) // try display a nice error
        } catch {
          setError(error.toString()) // otherwise display default
        }
      })
  }

  const handleRefundReattempt = () => {
    if (isProcessing || !invoice || !isInAdminRole) return
    setIsProcessing(true)
    initiateReattemptInvoiceRefund(invoice.id)
      .then((res) => {
        setIsProcessing(false)
        toastPopup({
          position: 'top',
          duration: 5000,
          render: (onClose) => (
            <AlertBox onClose={onClose}>New Refund Created</AlertBox>
          ),
          isClosable: true
        })
      })
      .catch((error) => {
        setIsProcessing(false)

        try {
          setError(error.response.data.message) // try display a nice error
        } catch {
          setError(error.toString()) // otherwise display default
        }
      })
  }

  const handleCancelRefund = () => {
    if (isProcessing || !invoice || !isInAdminRole) return
    setIsProcessing(true)
    initiateCancelRefundInvoice(invoice.id)
      .then((res) => {
        setIsProcessing(false)
        revalidateRealInvoice()
        toastPopup({
          position: 'top',
          duration: 5000,
          render: (onClose) => (
            <AlertBox onClose={onClose}>Refund Process Cancelled.</AlertBox>
          ),
          isClosable: true
        })
      })
      .catch((error) => {
        setIsProcessing(false)
        revalidateRealInvoice()
        try {
          setError(error.response.data.message) // try display a nice error
        } catch {
          setError(error.toString()) // otherwise display default
        }
      })
  }

  return (
    <LayoutMemberDashboard>
      <GroupingHeaderLayout basicTitle="Invoice" />

      <AdminBodyWrapper>
        <Card>
          {invoice === undefined ? (
            <CenteredSpinner />
          ) : (
            <>
              <InvoiceDetails
                invoice={invoice ? invoice : invoicePreview!.invoice}
              />
              <Button
                mr={3}
                onClick={() =>
                  history.push(
                    routeStrings.memberDashboardBillingInvoiceView.replace(
                      ':invoiceId',
                      invoice.id + ''
                    )
                  )
                }
              >
                View Invoice
              </Button>
              {(invoice.status === InvoiceStatus.Issued ||
                invoice.status === InvoiceStatus.Declined ||
                invoice.status === InvoiceStatus.Error) && (
                <Button
                  disabled={isProcessing}
                  loadingText={'Sending'}
                  isLoading={isProcessing}
                  onClick={handleChargeInvoice}
                >
                  Pay Invoice
                </Button>
              )}
              {isInAdminRole &&
                invoice.refund &&
                invoice.refund.status === RefundStatus.Error && (
                  <Button
                    size={'xs'}
                    mr={3}
                    variant="secondary"
                    disabled={isProcessing}
                    loadingText={'Sending'}
                    isLoading={isProcessing}
                    onClick={handleCancelRefund}
                  >
                    Cancel Refund
                  </Button>
                )}
              {isInAdminRole &&
                invoice.refund &&
                invoice.refund.status === RefundStatus.Error && (
                  <Button
                    size={'xs'}
                    mr={3}
                    variant="secondary"
                    disabled={isProcessing}
                    loadingText={'Sending'}
                    isLoading={isProcessing}
                    onClick={handleRefundReattempt}
                  >
                    Reattempt Refund
                  </Button>
                )}
            </>
          )}
          {/* Password error message */}
          {error && (
            <Flex justify="center">
              <Tag color="#f50">{error}</Tag>
            </Flex>
          )}
        </Card>
      </AdminBodyWrapper>
    </LayoutMemberDashboard>
  )
}
