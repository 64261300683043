import { Input, InputProps } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'

interface Props extends InputProps {
  defaultDebounceTime?: number
  value: string | undefined
  setValue: (newValue: string) => void
}
export const DebouncedInputText: React.FC<Props> = ({
  defaultDebounceTime,
  value,
  setValue,
  ...rest
}) => {
  const [debouncedTerm, setDebouncedTerm] = useState(value)
  // const [filterTest, setFilterTest] = useState('')
  // const [isFetching, setIsFeching] = useState(false)
  const debounceTime = defaultDebounceTime ?? 600

  useEffect(() => {
    const timer = setTimeout(() => setValue(debouncedTerm ?? ''), debounceTime)
    return () => clearTimeout(timer)
  }, [debouncedTerm])
  return (
    <Input
      variant={'outline'}
      fontSize="md"
      value={debouncedTerm}
      onChange={(e) => setDebouncedTerm(e.target.value)}
      {...rest}
    />
  )
}
