import { Box, Flex, Text, useDisclosure, useToast } from '@chakra-ui/react'
import { format } from 'date-fns'
import React, { useState } from 'react'
import { useParams } from 'react-router'
import { deleteOneTimeCharge } from '../api'
import { useAuth } from '../context/auth-context'
import { GenericAlertDialog } from '../pages/AdminDashboard/Components/GenericAlertDialog'
import { CreateOneTimeChargeModal } from '../pages/AdminDashboard/Modals/CreateOneTimeChargeModal'
import { IOneTimeCharge } from '../types'
import { useOrganization, useOrgOneTimeCharges } from '../utils/apiHooks'
import { useRole } from '../utils/rbac'
import { AccountCodeCell } from './billing/AccountCodeCell'
import { ValueComponent } from './billing/ValueComponent'
import { Button } from './Button'
import { StatusBox } from './StatusBox'
import { Table, TableDataProp } from './table/Table'

export const BillingOneTimeCharge: React.FC<any> = () => {
  const { onOpen, isOpen, onClose } = useDisclosure()
  const {
    onOpen: onOpenConfirmDialog,
    isOpen: isOpenConfirmDialog,
    onClose: onCloseConfirmDialog
  } = useDisclosure()
  const toast = useToast()

  const { currentOrganization } = useAuth()
  const { isSpaceSuperAdminRole } = useRole()
  const { orgId: routeOrgId } = useParams<{ orgId?: string }>()
  const orgId =
    (routeOrgId && parseInt(routeOrgId, 10)) || currentOrganization?.id
  const { data: organization } = useOrganization(orgId)
  const { data: oneTimeCharges, revalidate } = useOrgOneTimeCharges(orgId)
  const [editOneTimeCharge, setEditOneTimeCharge] = useState<IOneTimeCharge>()
  const [isLoading, setIsLoading] = useState(false)

  const handleCloseModal = () => {
    setIsLoading(false)
    revalidate()
    onClose()
    onCloseConfirmDialog()
    setEditOneTimeCharge(undefined)
  }

  const handleDeleteOneTimeCharge = () => {
    if (!organization || !editOneTimeCharge || isLoading) return
    setIsLoading(true)
    return deleteOneTimeCharge(editOneTimeCharge.id)
      .then(() => {
        handleCloseModal()
        toast({
          description: 'One time charge removed',
          status: 'success',
          duration: 4000
        })
      })
      .catch((err) => {
        handleCloseModal()
        toast({
          description: 'An error occurred',
          status: 'error',
          duration: 4000
        })
      })
  }
  if (!organization) return null

  const oneTimeChargeActions = [
    {
      text: 'View charge',
      testIdPrefix: 'view-charge',
      action: (rowId: number) => {
        const oneTimeCharge = oneTimeCharges?.find(
          (oneTimeCharge) => oneTimeCharge.id === rowId
        )
        if (oneTimeCharge) {
          setEditOneTimeCharge(oneTimeCharge)
          onOpen()
        }
      }
    }
  ]

  const StatusRenderer = (otcPaid: boolean) => {
    const status = otcPaid ? 'paid' : 'unpaid'
    return (
      <StatusBox
        status={status}
        mapping={{
          paid: { color: 'positive', label: 'PAID' },
          unpaid: { color: 'blue', label: 'INVOICED' }
        }}
      />
    )
  }

  const tableData =
    oneTimeCharges?.map((oneTimeCharge) => {
      const actions = oneTimeChargeActions.concat([])

      if (isSpaceSuperAdminRole && !oneTimeCharge.has_been_paid) {
        actions.push({
          text: 'Delete charge',
          testIdPrefix: 'delete-charge',
          action: (rowId: number) => {
            setEditOneTimeCharge(oneTimeCharge)
            onOpenConfirmDialog()
          }
        })
      }

      const cleanedData = {
        id: TableDataProp(oneTimeCharge.id),
        description: TableDataProp(oneTimeCharge.description),
        status: TableDataProp(StatusRenderer(oneTimeCharge.has_been_paid)),
        long_description: TableDataProp(oneTimeCharge.long_description),
        value: TableDataProp(
          <ValueComponent value={`${oneTimeCharge.value}`} />
        ),
        quantity: TableDataProp(oneTimeCharge.quantity),
        charged_by: TableDataProp(oneTimeCharge.charged_by),
        xero_account_code: TableDataProp(
          <AccountCodeCell accountCode={oneTimeCharge.xero_account_code} />
        ),
        created_at: TableDataProp(
          format(Date.parse(oneTimeCharge.created_at), 'dd/MM/yyyy')
        ),
        actions: TableDataProp(actions)
      }

      return cleanedData
    }) || []

  return (
    <Box my="3" minW="900px">
      <Flex mb={4} alignItems="center" justify="space-between">
        <Text mb={0} fontWeight="semibold" color="headingPrimary" fontSize="lg">
          One Time Charges History{' '}
        </Text>
        {!organization?.is_deleted && (
          <Button
            variant="secondary"
            onClick={() => {
              setEditOneTimeCharge(undefined)
              onOpen()
            }}
          >
            Create new one time charge
          </Button>
        )}
      </Flex>
      {/* One Time Charges history */}
      <Table
        headers={[
          { header: 'id', accessor: 'id', show: false },
          { header: 'test_prefix', accessor: 'test_prefix', show: false },
          {
            header: 'Description',
            accessor: 'description',
            show: true,
            width: 3 / 12
          },
          {
            header: 'Status',
            accessor: 'status',
            show: true,
            width: 1 / 12
          },
          {
            header: 'Value',
            accessor: 'value',
            show: true,
            width: 1.5 / 12
          },
          {
            header: 'Quantity',
            accessor: 'quantity',
            show: true,
            width: 1.5 / 12
          },
          {
            header: 'Xero account code',
            accessor: 'xero_account_code',
            show: true,
            width: 2 / 12
          },
          {
            header: 'Created at',
            accessor: 'created_at',
            show: true,
            width: 1 / 12
          },
          { header: '', accessor: 'actions', show: true, width: 1 / 12 }
        ]}
        data={tableData}
        loading={!oneTimeCharges}
      />

      {organization && (
        <CreateOneTimeChargeModal
          isOpen={isOpen}
          onClose={handleCloseModal}
          oneTimeChargeToEdit={editOneTimeCharge}
          organization={organization}
        />
      )}
      <GenericAlertDialog
        onClose={onCloseConfirmDialog}
        isConfirming={isLoading}
        isOpen={isOpenConfirmDialog}
        title={'Delete one time charge'}
        confirmButtonText={'Confirm'}
        onConfirm={handleDeleteOneTimeCharge}
      ></GenericAlertDialog>
    </Box>
  )
}
