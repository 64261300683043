import {
  Box,
  ButtonGroup,
  Link,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  Textarea
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { createConnection } from '../../../api'
import { FormInput } from '../../../components/FormInput'
import { useAuth } from '../../../context/auth-context'
import { useCustomToast } from '../../../context/toast-context'
import { IOrganizationalUnitBasic } from '../../../types'
import { analyticsTrack } from '../../../utils/analytics'
import { useOrganizationalUnitsConnections } from '../../../utils/apiHooks'
import { stringSearch } from '../../../utils/stringSearch'
import { Button } from '../../../components/Button'

interface Props {
  isOpen: boolean
  selectedOu: IOrganizationalUnitBasic
  closeModal: () => void
}

export const ConnectMemberModal: React.FC<Props> = (props) => {
  const { isOpen, closeModal, selectedOu } = props
  const { data: orgUnits } = useOrganizationalUnitsConnections()
  const [userToConnect, setUserToConnect] = useState<IOrganizationalUnitBasic>()
  const { me } = useAuth()
  const [searchQuery, setSearchQuery] = useState<string>()
  const [isSubmitting, setIsSubmitting] = useState<boolean>()
  const [filteredMembers, setFilteredMembers] = useState<
    IOrganizationalUnitBasic[] | undefined
  >(orgUnits?.filter((ou) => ou.id !== selectedOu.id))
  const [connectionMessage, setConnectionMessage] = useState<string>()
  const { newToast: toast } = useCustomToast()

  useEffect(() => {
    if (searchQuery !== undefined && searchQuery !== '') {
      setFilteredMembers(
        orgUnits
          ?.filter((ou) => stringSearch(searchQuery, ou.user.name))
          .filter((ou) => ou.id !== selectedOu.id)
      )
    }
    if (searchQuery === '') {
      setFilteredMembers(orgUnits?.filter((ou) => ou.id !== selectedOu.id))
    }
  }, [searchQuery])

  async function submit() {
    if (userToConnect !== undefined && me !== undefined) {
      // if the message box was edited but is now blank, just set it to be undefined
      const message = connectionMessage !== '' ? connectionMessage : undefined
      setIsSubmitting(true)

      const connectionData = {
        memberAId: selectedOu.user.id,
        memberBId: userToConnect.user.id,
        message,
        connectedBy: me.user.id
      }

      await createConnection(connectionData)
      analyticsTrack('Create Connection', connectionData)
      toast({
        status: 'success',
        description: 'Connection created'
      })
      setIsSubmitting(false)
      closeModal()
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={closeModal} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create a connection</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={2}>
          {userToConnect === undefined && (
            <Stack spacing={3}>
              <Text>
                Connecting <b>{selectedOu.user.name}</b> with another member
              </Text>
              <Box>
                <FormInput
                  label="Search for a member"
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                <Box maxH="200px" overflowY="scroll">
                  <List>
                    {filteredMembers?.map((ou) => (
                      <ListItem
                        p={2}
                        cursor="pointer"
                        onClick={() => setUserToConnect(ou)}
                      >
                        {ou.user.name}
                      </ListItem>
                    ))}
                  </List>
                </Box>
              </Box>
            </Stack>
          )}
          {userToConnect && (
            <Stack spacing={3}>
              <Text>
                Connecting <b>{selectedOu.user.name}</b> with{' '}
                <b>{userToConnect.user.name}</b>{' '}
                <Link as="button" onClick={() => setUserToConnect(undefined)}>
                  (Change)
                </Link>
              </Text>
              <Box>
                <Text fontWeight="bold" mb={1}>
                  Enter a message to send the members:
                </Text>
                <Textarea
                  placeholder="Connection message (optional)"
                  onChange={(e: any) => setConnectionMessage(e.target.value)}
                />
              </Box>
            </Stack>
          )}
        </ModalBody>

        <ModalFooter>
          <ButtonGroup>
            <Button
              onClick={submit}

              disabled={userToConnect === undefined}
            >
              Connect
            </Button>
            <Button variant="secondary" onClick={closeModal}>Cancel</Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
