import React from 'react'
import Timeline from 'react-calendar-timeline'
// make sure you include the timeline stylesheet or the timeline will not be styled
import 'react-calendar-timeline/lib/Timeline.css'
import { addHours } from 'date-fns'

interface Props {
  mapGroup: any[]
  mapItems: any[]
  date: Date
}

export const MeetingRoomSchedule: React.FC<Props> = ({
  mapGroup,
  mapItems,
  date
}) => {
  const visibleTimeEnd = addHours(date, 24).getTime()
  date.setHours(0, 0, 0)
  const visibleTimeStart = date.getTime()
  return (
    //@ts-ignore
    <Timeline
      groups={mapGroup}
      items={mapItems}
      visibleTimeStart={visibleTimeStart}
      visibleTimeEnd={visibleTimeEnd}
      sidebarWidth={200}
      itemHeightRatio={0.9}
      canMove={false}
    />
  )
}
