import { Box, Image, useTheme, Text, Heading } from '@chakra-ui/react'
import { format } from 'date-fns'
import React from 'react'
import { useHistory } from 'react-router'
import { updateOrganizationName, updateUserDetails } from '../../api'

import { Button } from '../../components/Button'
import { OnboardingWrapper } from '../../components/OnboardingWrapper'
import { useAuth } from '../../context/auth-context'
import { useFeatureFlags } from '../../context/feature-flag-context'
import { routeStrings } from '../../routeStrings'
import { useUserDetails } from '../../context/user-onboard-context'
import { Roles, useRole } from '../../utils/rbac'

export const OnBoardingIframe = ({ locationName }) => {
  if (locationName === 'flux') {
    return (
      <>
        <iframe
          title="Onboarding Scheduling"
          src="https://meetings.hubspot.com/shanille/flux-onboarding-link?embed=true"
          width="100%"
          height="600"
          frameBorder="0"
        />
        <script
          type="text/javascript"
          src="https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js"
        />
      </>
    )
  } else if (locationName === 'riff') {
    return (
      <>
        <iframe
          title="Onboarding Scheduling"
          src="https://meetings.hubspot.com/amandine10/riff-onboarding-?embed=true"
          width="100%"
          height="600"
          frameBorder="0"
        />
        <script
          type="text/javascript"
          src="https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js"
        />
      </>
    )
  } else if (locationName === 'fern') {
    return (
      <>
        <iframe
          title="Onboarding Scheduling"
          src="https://meet.spacecubed.com/meetings/chris1602?embed=true"
          width="100%"
          height="600"
          frameBorder="0"
        />
        <script
          type="text/javascript"
          src="https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js"
        />
      </>
    )
  }
  return <></>
}

export const CompleteBookOnboarding: React.FC = ({}: // setUserDetails
any) => {
  const { acuityEnabled } = useFeatureFlags()
  const { userDetails } = useUserDetails()
  const { role, isExternalMember } = useRole()
  const history = useHistory()
  const auth = useAuth()
  const theme: any = useTheme()
  const allendaleTenant =
    role === Roles.FernAllendaleTenantTeamAdmin ||
    role === Roles.FernAllendaleTenantActiveMember

  const isIlumaUser =
    role === Roles.IlumaTenantTeamAdmin ||
    role === Roles.IlumaTenantActiveMember

  return (
    <OnboardingWrapper>
      <Box p={3}>
        <Image
          src={theme.logos.logo_long}
          width="128px"
          className="location-logo"
        />
        <Box mt={6}>
          <Box my={3}>
            <svg
              className="teams-complete"
              width="31"
              height="31"
              viewBox="0 0 31 31"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.5 16.5L12.5 20.5L22.5 10.5"
                stroke="#24A148"
                stroke-miterlimit="10"
                stroke-linecap="square"
              />
              <path
                d="M15.5 30.5C23.7843 30.5 30.5 23.7843 30.5 15.5C30.5 7.21573 23.7843 0.5 15.5 0.5C7.21573 0.5 0.5 7.21573 0.5 15.5C0.5 23.7843 7.21573 30.5 15.5 30.5Z"
                stroke="#24A148"
                stroke-miterlimit="10"
                stroke-linecap="square"
              />
            </svg>
          </Box>
          <Text>
            <Text>
              <Heading className="teams-complete" fontSize="xl">
                {auth &&
                  auth.currentOrgUnit &&
                  `Welcome to ${auth.currentOrgUnit?.organization.parent_platform_tenant_name}, ${auth.me?.user.name}!`}
              </Heading>
            </Text>
            <br />
          </Text>
          {allendaleTenant || isIlumaUser || isExternalMember ? null : (
            <>
              {' '}
              <br />
              <Text>
                You will start your membership on{' '}
                <strong>
                  {userDetails.startDate &&
                    format(userDetails.startDate, 'do MMMM yyyy')}
                </strong>
              </Text>
              <Text>
                {acuityEnabled
                  ? 'You can now book an onboarding session, and access your account.'
                  : 'You can now access your account.'}
              </Text>
            </>
          )}
          {isExternalMember && (
            <>
              {' '}
              <br />
              <Text>
                You will be able to book meeting rooms, offices, and workspaces
                at different locations across &nbsp;
                <strong>the Spacecubed Network.</strong>
              </Text>
              <br />
              <Text>You can now access your account.</Text>
              <br />
            </>
          )}
        </Box>
        {allendaleTenant || isIlumaUser || isExternalMember ? null : (
          <>
            <br />
            {acuityEnabled && (
              <OnBoardingIframe
                locationName={userDetails.locationName?.toLowerCase()}
              />
            )}

            <br />
            <br />
          </>
        )}
        <Button
          data-testid="complete-next"
          onClick={() => {
            const token = window.localStorage.getItem('Token')
            if (userDetails!.company) {
              updateOrganizationName(
                auth.currentOrganization!.id,
                userDetails!.company!
              )
            }
            return updateUserDetails(
              {
                about_you: userDetails!.aboutYou,
                job_title: userDetails!.jobTitle,
                personal_email: userDetails!.personalEmail,
                interests: userDetails!.interests,
                skills: userDetails!.skills
              },
              { token }
            ).then(() => {
              auth.getAndSetMe!(token).then(() => {
                if (allendaleTenant || isIlumaUser) {
                  history.push(routeStrings.memberDashboardHome)
                } else if (isExternalMember) {
                  history.push(routeStrings.memberDashboardMeetingRooms)
                } else {
                  history.push(routeStrings.memberDashboardBillingUpdate)
                }
              })
            })
          }}
        >
          {allendaleTenant
            ? 'Start booking meetings'
            : isIlumaUser
            ? 'Start booking workspaces'
            : 'Finish'}
        </Button>
      </Box>
    </OnboardingWrapper>
  )
}
