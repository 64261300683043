import { MinusIcon, PlusSquareIcon } from '@chakra-ui/icons'
import {
  Box,
  Center,
  Flex,
  IconButton,
  Spacer,
  Spinner,
  Text,
  useToast
} from '@chakra-ui/react'
import { format, isBefore, roundToNearestMinutes } from 'date-fns'
import el from 'date-fns/esm/locale/el/index.js'
import React, { useEffect, useState } from 'react'
import ReactDatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { updateDayBusinessHours } from '../../../../api'
import { ILocationBusinessHours, Location } from '../../../../types'
import { BusinessHoursProps } from './BusinessHoursConfig'

interface Props {
  location: Location
  hours: BusinessHoursProps
}

const DAY_NAMES = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday'
]

interface OpeningTimes {
  open: Date
  close: Date
}

export const BusinessHoursDaySetting: React.FC<Props> = ({
  hours,
  location
}) => {
  const toast = useToast()
  const [debouncedTimes, setDebouncedTimes] = useState<
    OpeningTimes | undefined
  >()
  const [lastUpdated, setLastUpdated] = useState<OpeningTimes | undefined>()

  const [isMounted, setIsMounted] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isClosed, setIsClosed] = useState(!hours.open)
  const [startTime, setStartTime] = useState<Date | null>(hours.open)
  const [endTime, setEndTime] = useState<Date | null>(hours.close)

  const early = new Date()
  early.setHours(8)
  early.setMinutes(0)
  const late = new Date()
  late.setHours(17)
  late.setMinutes(0)

  useEffect(() => {
    if (debouncedTimes != lastUpdated) {
      const timer = setTimeout(() => updateHours(debouncedTimes), 600)
      return () => clearTimeout(timer)
    }
  }, [debouncedTimes])

  const updateHours = (openingTimes?: OpeningTimes) => {
    if (openingTimes && (!openingTimes.open || !openingTimes.close)) return
    if (isSubmitting) return
    setIsSubmitting(true)
    let hoursSelected: ILocationBusinessHours = {
      day: hours.day
    }
    if (openingTimes) {
      if (isBefore(openingTimes.close, openingTimes.open)) {
        setIsSubmitting(false)
        toast({ title: 'Invalid interval', duration: 1500, status: 'info' })

        return
      } else {
        hoursSelected.open = format(openingTimes.open, 'kk:mm')
        hoursSelected.close = format(openingTimes.close, 'kk:mm')
      }
    }
    updateDayBusinessHours(location.id, hoursSelected)
      .then((res) => {
        toast({ title: 'Hours updated', duration: 1000, status: 'success' })
        setIsSubmitting(false)
        setLastUpdated(openingTimes)
      })
      .catch((err) => {
        setIsSubmitting(false)
        alert('Error')
      })
  }

  return (
    <Flex
      p={1}
      data-testid={`business-hours-settings-day-${hours.day}`}
      className={isClosed ? 'business-day-is-closed' : ''}
    >
      <Text fontSize={'sm'} pl={3}>
        {DAY_NAMES.length > hours.day ? DAY_NAMES[hours.day] : 'Undefined'}
      </Text>
      <Spacer />
      {isClosed && (
        <Center w={'md'}>
          <Text
            fontSize={'sm'}
            textStyle="italic"
            textAlign={'center'}
            data-testid="closed-label"
          >
            Closed
          </Text>
        </Center>
      )}
      <Box mx={1}>
        {!isClosed && (
          <ReactDatePicker
            selected={startTime}
            onChange={(date) => {
              if (date) {
                setStartTime(roundToNearestMinutes(date, { nearestTo: 15 }))
              }

              if (date && endTime) {
                setDebouncedTimes({ open: date, close: endTime })
              }
            }}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={30}
            timeCaption="Time"
            dateFormat="h:mm aa"
            disabled={isSubmitting}
            data-testid="datepicker-open"
          />
        )}
      </Box>
      <Box mx={1}>
        {!isClosed && (
          <ReactDatePicker
            selected={endTime}
            onChange={(date) => {
              if (date) {
                setEndTime(roundToNearestMinutes(date, { nearestTo: 15 }))
              }

              if (date && startTime) {
                setDebouncedTimes({ open: startTime, close: date })
              }
            }}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={30}
            timeCaption="Time"
            dateFormat="h:mm aa"
            disabled={isSubmitting}
            data-testid="datepicker-close"
          />
        )}
      </Box>
      <Box ml={2}>
        {startTime ? (
          <IconButton
            isRound
            borderRadius="50%"
            size="xs"
            backgroundColor="brandPrimary"
            aria-label="Remove Times"
            isDisabled={isSubmitting}
            icon={<MinusIcon />}
            onClick={() => {
              setStartTime(null)
              setEndTime(null)
              setIsClosed(true)
              updateHours()
            }}
            data-testid="button-close-day"
          />
        ) : (
          <IconButton
            isRound
            borderRadius="50%"
            size="xs"
            backgroundColor="#eaeaea"
            aria-label="Add times"
            isDisabled={isSubmitting}
            icon={<PlusSquareIcon />}
            onClick={() => {
              let open = roundToNearestMinutes(early, { nearestTo: 30 })
              setStartTime(open)
              let close = roundToNearestMinutes(late, { nearestTo: 30 })
              setEndTime(close)
              setIsClosed(false)
              setDebouncedTimes({ open, close })
            }}
            data-testid="button-open-day"
          />
        )}
      </Box>
    </Flex>
  )
}
