import React from 'react'
import { FilterableMultiSelect } from '../../components/FilterableMultiSelect'
import { useInterests } from '../../utils/apiHooks'

interface Props {
  value?: Array<number>
  initialValues: Array<number> | undefined
  onSelectionChanges: (interests: Array<number>) => void
}

export const FilterableInterestTags: React.FC<Props> = (props) => {
  const { initialValues, value, onSelectionChanges } = props
  const { data: interests } = useInterests()

  if (!interests) return null

  return (
    <FilterableMultiSelect
      value={value}
      initialValues={initialValues}
      isClearable={true}
      closeMenuOnSelect={true}
      items={interests?.map((interest) => ({
        value: interest.id,
        label: interest.name
      }))}
      onSelect={(ids) => {
        onSelectionChanges(ids)
      }}
    />
  )
}
