import { differenceInSeconds } from 'date-fns/esm'
import React, { useContext, useEffect, useState } from 'react'
import {
  IBasicDirectoryMemberResult,
  Interest,
  IOrganizationalUnitDirectoryPreferences,
  OrganizationIndustry,
  Skill
} from '../types'
import {
  analyticsTrack,
  ANALYTICS_MODULE_MEMBERS_DIRECTORY
} from '../utils/analytics'

import {
  useLatestDirectoryConnections,
  useIndustries,
  useInterests,
  useOrgUnitDirectoryPreferences,
  useSkills
} from '../utils/apiHooks'
import { useAuth } from './auth-context'
import { createCtx } from './createCtx'
import { useFeatureFlags } from './feature-flag-context'

import { usePlatformTenant } from './platform-tenant-context'

type ClickSource = 'HOME' | 'SIDEBAR'
type SearchType = 'Organization' | 'Person'
type ViewDetail = 'Website' | 'LinkedIn' | 'Modal'

export interface MembersDirectoryContext {
  skills: Array<Skill> | undefined
  interests: Array<Interest> | undefined
  industries: Array<OrganizationIndustry> | undefined

  preferences?: IOrganizationalUnitDirectoryPreferences
  latestConnections?: IBasicDirectoryMemberResult[]
  revalidatePreferences: () => void

  // Analytics
  logOpenConnections: (source: ClickSource) => void
  logActionSearchInDirectory: () => void
  logOpenDirectory: (source: ClickSource) => void
  logRemoveContact: (created_at: Date) => void
  logBrowseDirectory: (type: SearchType, skills: boolean) => void
  logAddContact: (messageLenght: number) => void
  logViewConnectionMessage: () => void
  logViewMemberExtraDetail: (orgUnitId: number, detail: ViewDetail) => void
  logViewCompanyExtraDetail: (orgId: number, detail: ViewDetail) => void
}

const [useMembersDirectoryContext, MembersDirectoryContextProvider] =
  createCtx<MembersDirectoryContext>()

const MembersDirectoryProvider = ({ children }) => {
  const { platformTenant } = usePlatformTenant()
  const { currentOrgUnit, me } = useAuth()
  const { data: industries } = useIndustries()
  const { data: interests } = useInterests()
  const { data: skills } = useSkills()

  const { membersDirectoryEnabled } = useFeatureFlags()

  const { data: preferences, revalidate: revalidatePreferences } =
    useOrgUnitDirectoryPreferences(currentOrgUnit?.id)

  const { data: latestConnections } = useLatestDirectoryConnections(
    membersDirectoryEnabled ? currentOrgUnit?.id : undefined
  )

  const logOpenConnections = (source: ClickSource) => {
    if (!me || !me.user || !platformTenant) return
    analyticsTrack('Open my connections', {
      module: ANALYTICS_MODULE_MEMBERS_DIRECTORY,
      userId: me?.user.id,
      tenant: platformTenant?.id,
      source
    })
  }

  const logActionSearchInDirectory = () => {
    if (!me || !me.user || !platformTenant) return
    analyticsTrack('Search in Directory', {
      module: ANALYTICS_MODULE_MEMBERS_DIRECTORY,
      userId: me?.user.id,
      tenant: platformTenant?.id
    })
  }

  const logOpenDirectory = (source: ClickSource) => {
    if (!me || !me.user || !platformTenant) return
    analyticsTrack('Open Members Directory', {
      module: ANALYTICS_MODULE_MEMBERS_DIRECTORY,
      userId: me?.user.id,
      tenant: platformTenant?.id,
      source
    })
  }

  const logRemoveContact = (created_at: Date) => {
    if (!me || !me.user || !platformTenant) return
    analyticsTrack('Remove Contact in Directory', {
      module: ANALYTICS_MODULE_MEMBERS_DIRECTORY,
      userId: me?.user.id,
      tenant: platformTenant?.id,
      seconds: differenceInSeconds(new Date(), created_at)
    })
  }

  const logBrowseDirectory = (type: SearchType, skills: boolean) => {
    if (!me || !me.user || !platformTenant) return
    analyticsTrack('Search in Directory', {
      module: ANALYTICS_MODULE_MEMBERS_DIRECTORY,
      userId: me?.user.id,
      tenant: platformTenant?.id,
      type,
      includesSkills: skills
    })
  }

  const logAddContact = (messageLenght: number) => {
    if (!me || !me.user || !platformTenant) return
    analyticsTrack('Create Connection in Directory', {
      module: ANALYTICS_MODULE_MEMBERS_DIRECTORY,
      userId: me?.user.id,
      tenant: platformTenant?.id,
      messageLenght
    })
  }
  const logViewConnectionMessage = () => {
    if (!me || !me.user || !platformTenant) return
    analyticsTrack('View Connection Message', {
      module: ANALYTICS_MODULE_MEMBERS_DIRECTORY,
      userId: me?.user.id,
      tenant: platformTenant?.id
    })
  }

  const logViewMemberExtraDetail = (orgUnitId: number, detail: ViewDetail) => {
    if (!me || !me.user || !platformTenant) return
    analyticsTrack('View Connection Message', {
      module: ANALYTICS_MODULE_MEMBERS_DIRECTORY,
      userId: me?.user.id,
      organizationUnitId: orgUnitId,
      tenant: platformTenant?.id,
      detail
    })
  }
  const logViewCompanyExtraDetail = (orgId: number, detail: ViewDetail) => {
    if (!me || !me.user || !platformTenant) return
    analyticsTrack('View Connection Message', {
      module: ANALYTICS_MODULE_MEMBERS_DIRECTORY,
      userId: me?.user.id,
      organizationId: orgId,
      tenant: platformTenant?.id,
      detail
    })
  }

  return (
    <MembersDirectoryContextProvider
      value={{
        preferences,
        industries,
        interests,
        skills,
        latestConnections,
        revalidatePreferences,
        logOpenConnections,
        logActionSearchInDirectory,
        logOpenDirectory,
        logRemoveContact,
        logBrowseDirectory,
        logAddContact,
        logViewConnectionMessage,
        logViewMemberExtraDetail,
        logViewCompanyExtraDetail
      }}
    >
      {children}
    </MembersDirectoryContextProvider>
  )
}

export { useMembersDirectoryContext, MembersDirectoryProvider }
