import { CheckIcon, CloseIcon } from '@chakra-ui/icons'
import {
  Flex,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Switch,
  Text,
  Wrap,
  WrapItem,
  Button,
  useDisclosure,
  Spinner,
  Input,
  IconButton,
  Tooltip,
  Spacer
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { createPlanCategory, updatePlanCategory } from '../../../api'

import XeroAccountDropdown from '../../../components/inputs/XeroAccountDropdown'
import { usePlatformTenant } from '../../../context/platform-tenant-context'
import { PlanCategoryDetailed } from '../../../types'
import { usePlanCategories } from '../../../utils/apiHooks'

interface IModalInput {
  isOpen: boolean
  onClose: () => void
}
export const EditPlanCategoriesModal: React.FC<IModalInput> = ({
  isOpen,
  onClose
}) => {
  const { isOpen: editsEnabled, onToggle } = useDisclosure({
    defaultIsOpen: false
  })

  const { isOpen: showNewItemRow, onToggle: toggleNewItemRow } = useDisclosure({
    defaultIsOpen: false
  })

  const [nameInput, setNameInput] = useState('')
  const [accountCodeInput, setAccountCodeInput] = useState('')

  const handleNewPlanCreate = () => {
    platformTenant?.id &&
      createPlanCategory(platformTenant.id, {
        xero_account_code: accountCodeInput,
        name: nameInput
      })
        .then((res) => {
          planCategoriesRevalidate()
          toggleNewItemRow()
        })
        .catch((err) => console.log('something went wrong'))
  }

  const { platformTenant } = usePlatformTenant()

  const {
    data: planCategories,
    isValidating: planCategoriesLoading,
    revalidate: planCategoriesRevalidate
  } = usePlanCategories(platformTenant?.id)

  const [tableizedData, setTableizedData] = useState<
    (PlanCategoryDetailed & { xeroString: string })[]
  >([])

  useEffect(() => {
    /**
     * This is a somewhat convoluted way of making our `xero_account_code` prop equal a nice templated string,
     * This is required since table data must be passed in 'flat' to the Table component, can't have object nesting
     *
     * @NOTE  if the commented out  <Table/> implementation below cannot be corrected, we can delete this
     */
    if (planCategories) {
      const unNestedData = [...planCategories].map((pc) => ({
        ...pc,
        xeroString: pc?.xero_account_code
          ? `${pc.xero_account_code.title} (${pc.xero_account_code.code})`
          : 'Not set'
      }))
      setTableizedData(unNestedData)
    }
    return () => {}
  }, [planCategories])

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent minW={['450px', '600px']} borderRadius="6px">
        <ModalHeader pb={5}>Plan Categories & Account Codes </ModalHeader>
        <ModalCloseButton />

        <ModalBody zIndex={999}>
          <FormControl display="flex" alignItems="center" mb={5}>
            <FormLabel htmlFor="edits" mb="0">
              Make Changes?
            </FormLabel>
            <Switch
              id="edits"
              onChange={onToggle}
              isChecked={editsEnabled}
              colorScheme="red"
            />
          </FormControl>
          {planCategoriesLoading && (
            <Spinner size="sm" pos="absolute" right="60px" top="70px" />
          )}
          <Wrap flexDir="column">
            {planCategories?.map((td) => (
              <WrapItem key={td.id} w="100%">
                <Flex
                  bg="gray.50"
                  p={2}
                  px={4}
                  alignItems="center"
                  w="100%"
                  rounded="lg"
                  boxShadow="sm"
                >
                  <Text mr="auto">{td.name}</Text>
                  <XeroAccountDropdown
                    w="290px"
                    // The defaultValue prop seems to glitch, so we've opted for
                    // a fully controlled dropdown, meaning theirs some latency when revalidating
                    // a spinner shows in top right to account for this
                    value={td?.xero_account_code?.id.toString() ?? ''}
                    isDisabled={!editsEnabled}
                    onChange={(e) => {
                      platformTenant?.id &&
                        updatePlanCategory(platformTenant.id, {
                          ...td,
                          xero_account_code: e.target.value
                        }).then((res) => {
                          planCategoriesRevalidate()
                        })
                    }}
                  />
                </Flex>
              </WrapItem>
            ))}
            {showNewItemRow && editsEnabled ? (
              <WrapItem w="100%">
                <Flex py={2} alignItems="center" w="100%" rounded="lg">
                  <Input
                    type="text"
                    placeholder="Name"
                    mr={3}
                    autoFocus={true}
                    value={nameInput}
                    onChange={(e) => {
                      setNameInput(e.target.value)
                    }}
                  />
                  <XeroAccountDropdown
                    w="290px"
                    value={accountCodeInput}
                    onChange={(e) => {
                      setAccountCodeInput(e.target.value)
                    }}
                  />
                </Flex>
              </WrapItem>
            ) : (
              <Spacer height="55px" />
            )}
          </Wrap>
          {/* 
              This table implementation is sadly broken 
              
              Logs show the desired row and cell data, but its just not rendering in the UI. Tried changing colour, zIndex etc.
          */}
          {/* <Table
            data={tableizedData}
            loading={!tableizedData}
            headers={
              [
                { header: 'id', accessor: 'id', show: false },
                {
                  header: 'Name',
                  accessor: 'name',
                  show: true,
                  width: 1 / 3
                }
                // {
                //   header: 'Xero Account Code',
                //   // accessor: 'xeroString',
                //   show: true,
                //   width: 1 / 3
                // }

                // This TableHeaders type will accept an input of PlanCategory,
                // Thus granting typings to our `accessor` prop in the above header array
              ] as TableHeaders<keyof PlanCategoryDetailed>
            }
          /> */}
        </ModalBody>

        <ModalFooter justifyContent="flex-start" pt={0}>
          <Flex alignItems="center" flexDir="row">
            {showNewItemRow && editsEnabled ? (
              <>
                <Tooltip label="Add plan category">
                  <IconButton
                    colorScheme="whatsapp"
                    variant="solid"
                    aria-label="Save"
                    size="sm"
                    icon={<CheckIcon />}
                    onClick={handleNewPlanCreate}
                  />
                </Tooltip>
                <Tooltip label="Discard">
                  <IconButton
                    colorScheme="red"
                    variant="ghost"
                    aria-label="Cancel"
                    size="sm"
                    icon={<CloseIcon />}
                    onClick={toggleNewItemRow}
                  />
                </Tooltip>
              </>
            ) : (
              <Button
                size="sm"
                bg="primary"
                colorScheme="red"
                color="white"
                rounded="full"
                disabled={!editsEnabled || showNewItemRow}
                onClick={toggleNewItemRow}
              >
                + Create a new Plan Category
              </Button>
            )}
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
