import {
  Box,
  Flex,
  Heading,
  Link,
  Spacer,
  Stack,
  Text,
  useTheme
} from '@chakra-ui/react'
import { faHome } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Button } from '../../../../components/Button'
import { UserDisplay } from '../../../../components/UserDisplay'
import { useMembersDirectoryContext } from '../../../../context/members-directory-context'
import { DirectoryOrganizationalUnitResult } from '../../../../types'

interface Props {
  member: DirectoryOrganizationalUnitResult
  handleConnect: (orgUnitID: number) => void
  displayOrganizationProfile?: () => void
}

export const DirectoryResultOrganizationalUnitProfile: React.FC<Props> = (
  props
) => {
  const { member, handleConnect, displayOrganizationProfile } = props
  const { preferences } = useMembersDirectoryContext()
  const { profile } = member
  const theme: any = useTheme()

  const linkStyle = {
    fontSize: '11px',
    color: theme.colors.primary ?? '#777',
    marginTop: '0px',
    fontWeight: 'bold' as 'bold'
  }

  const disabledLink = {
    fontSize: '11px',
    color: '#777',
    cursor: 'not-allowed',
    opacity: '0.5',
    textDecoration: 'none'
  }

  return (
    <Box background={'white'} borderRadius="lg" p={4} my={3} boxShadow="sm">
      <Flex>
        <Box w={['100%', '70%']}>
          <Flex>
            <Box mr={1}>
              <UserDisplay
                my="5px"
                size="sm"
                name={member.user?.name}
                src={profile?.profile_picture_url}
              />
            </Box>
            <Box>
              {/* COntact Data */}
              <Stack px={2}>
                <Heading my={0} pt={2} lineHeight={'1'} size={'sm'}>
                  {member.user?.name}
                </Heading>

                {profile?.job_title && (
                  <Text color={'#444'} fontSize="xs">
                    {profile?.job_title}
                  </Text>
                )}
                <Text
                  py={2}
                  fontWeight="medium"
                  fontSize="xs"
                  color={theme.colors.primary}
                  _hover={{
                    fontWeight: 'bold',
                    textDecoration: 'underline'
                  }}
                  lineHeight={'1'}
                  cursor={'pointer'}
                  onClick={() => {
                    displayOrganizationProfile && displayOrganizationProfile()
                  }}
                >
                  {member.organization.name}
                </Text>

                <Stack spacing={0}>
                  {profile?.linkedin_url && (
                    <Link
                      href={profile.linkedin_url}
                      style={linkStyle}
                      isExternal
                    >
                      LinkedIn profile
                    </Link>
                  )}
                </Stack>
              </Stack>
            </Box>
          </Flex>
          <Box mt={1}>
            {/* ABOUT */}

            {/* {profile.about_you && profile.about_you != '' && (
              <>
                <Heading size={'xs'} color="#444">
                  About
                </Heading>
                <Text fontSize={'xs'} color={'#777'}>
                  {profile.about_you}
                </Text>
              </>
            )} */}
            <Flex flexWrap={'wrap'}>
              {profile?.skills &&
                profile.skills.map((tag, i) => (
                  <Text
                    key={i}
                    tag={tag}
                    mb={2}
                    fontSize="xs"
                    color={'#999'}
                    mr={1}
                    lineHeight={'5px'}
                  >
                    {tag.name}
                    {profile.skills && i === profile.skills.length - 1
                      ? '.'
                      : ','}
                  </Text>
                ))}
            </Flex>
          </Box>
        </Box>
        <Box w={['100%', '30%']}>
          <Box>
            {/* Home */}
            {member.organization.home_location_name && (
              <Text fontWeight={'medium'} color="#666" lineHeight={'1'}>
                <FontAwesomeIcon icon={faHome} />{' '}
                {member.organization.home_location_name}
                <Text as="span" color={'#777'} fontSize="xx-small">
                  {', ' + member.organization.home_location_address}
                </Text>
              </Text>
            )}
          </Box>
          <Flex mt={3} justifyContent="right">
            <Spacer />
            {preferences?.appear_in_directory && (
              <Button
                onClick={() => {
                  handleConnect(member.id)
                }}
                variant="primary"
                size={'xs'}
              >
                Connect
              </Button>
            )}
          </Flex>
        </Box>
      </Flex>
    </Box>
  )
}
