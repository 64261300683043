import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  Text,
  useDisclosure,
  useTheme
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { initializeTeam } from '../../../api'
import { useCustomToast } from '../../../context/toast-context'
import { IOffice, Location, Plan } from '../../../types'
import { useOffices, usePlans } from '../../../utils/apiHooks'
import { Button } from '../../../components/Button'
import { StyledInput } from '../../../components/StyledInputs'
import { StyledSelect } from '../../../components/StyledSelect'
import { usePlatformTenant } from '../../../context/platform-tenant-context'

import { MinusIcon, AddIcon } from '@chakra-ui/icons'
interface IStackedSelectInput {
  header: string
  options: Array<{
    id: number
    title: string
    location?: string | Location
    disabled?: boolean
  }>
  autoAddEnabled?: boolean
  addSelectedOption: (id: number) => void
}

export const StackedSelect: React.FC<IStackedSelectInput> = (props) => {
  const { header, options, addSelectedOption, autoAddEnabled, children } = props

  const [selectedOption, setSelectedOption] = useState<number>()

  return (
    <>
      <FormControl>
        <FormLabel>{header}</FormLabel>

        <Stack spacing={3} isInline>
          <StyledSelect
            aria-labelledby="Select Entry"
            placeholder="-"
            onChange={(event) => {
              setSelectedOption(parseInt(event.target.value))
              if (autoAddEnabled) {
                addSelectedOption(parseInt(event.target.value))
              }
            }}
          >
            {options.map((option) => {
              if (option.location === null) {
                return (
                  <option
                    key={option.id}
                    value={option.id}
                    disabled={option.disabled}
                  >
                    {option.title}
                  </option>
                )
              } else {
                return (
                  <option
                    key={option.id}
                    value={option.id}
                    disabled={option.disabled}
                  >
                    {option.location} - {option.title}
                  </option>
                )
              }
            })}
          </StyledSelect>
          <IconButton
            isRound
            size="sm"
            backgroundColor="brandPrimary"
            color="#ffffff"
            _hover={{
              transform: 'scale(1.038)'
            }}
            aria-label="Add Entry"
            icon={<AddIcon />}
            isDisabled={!!!selectedOption}
            onClick={() => addSelectedOption(selectedOption!)}
          />
        </Stack>
      </FormControl>

      <Stack spacing={2} pt={4} pb={5}>
        {children}
      </Stack>
    </>
  )
}

interface IModalInput {
  isOpen: boolean
  closeModal: () => void
}

export const InitializeTeamModal: React.FC<IModalInput> = (input) => {
  const { isOpen, closeModal } = input
  const theme = useTheme()

  const { onClose } = useDisclosure()
  const { newToast: toast } = useCustomToast()

  const [teamName, setTeamName] = useState('')
  const [admins, setAdmins] = useState<string[]>([])
  const [selectedPlans, setSelectedPlans] = useState<
    Array<{ plan: Plan; value: number; overrideReason?: string }>
  >([])
  const [selectedOffices, setSelectedOffices] = useState<Array<IOffice>>([])
  const [
    isFirstSubscriptionNonChargeable,
    setisFirstSubscriptionNonChargeable
  ] = useState(false)
  const [isEFTPermitted, setIsEFTPermitted] = useState(false)

  const [locationId, setLocationId] = useState<number>()
  const [adminInput, setAdminInput] = useState('')
  const { platformTenant } = usePlatformTenant()
  const { plans } = usePlans(platformTenant?.id)
  const { data: offices, revalidate: revalidateOffices } = useOffices(
    platformTenant?.id,
    true,
    { refreshInterval: 0, revalidateOnFocus: false }
  )

  const [errors, setErrors] = useState<string[]>([])
  const [isSubmitting, setIsSubmitting] = useState<boolean>()

  useEffect(() => {
    if (isOpen) {
      revalidateOffices()
    }
  }, [isOpen])

  function resetModal() {
    setTeamName('')
    setAdmins([])
    setSelectedPlans([])
    setSelectedOffices([])
    setAdminInput('')
    setErrors([])
    setisFirstSubscriptionNonChargeable(false)
    onClose()
    closeModal()
  }

  function validateForm() {
    let caughtErrors: string[] = []
    setErrors([])

    if (!!!teamName) {
      caughtErrors.push('Team name cannot be blank.')
    }

    if (admins.length === 0) {
      caughtErrors.push('At least one team admin email must be provided')
    }
    if (locationId === undefined || isNaN(locationId)) {
      caughtErrors.push('You must select a location.')
    }
    if (
      selectedPlans.find((selectedPlan) => {
        return (
          selectedPlan.plan.rate.value !== selectedPlan.value &&
          (selectedPlan.overrideReason === undefined ||
            (selectedPlan.overrideReason !== undefined &&
              selectedPlan.overrideReason.length === 0))
        )
      }) !== undefined
    ) {
      caughtErrors.push('You must give a reason for override')
    }
    if (caughtErrors.length > 0) {
      setErrors(caughtErrors)
      return false
    }

    return true
  }

  function submit() {
    setIsSubmitting(true)
    if (validateForm()) {
      const seats = selectedPlans.map((selectedPlan) => ({
        plan_id: selectedPlan.plan.id,
        value: selectedPlan.value,
        override_reason: selectedPlan.overrideReason
      }))
      if (locationId === undefined) {
        setErrors(['You must select a location.'])
        setIsSubmitting(false)
      } else {
        initializeTeam(
          teamName,
          admins,
          seats,
          selectedOffices.map((office) => office.id),
          isFirstSubscriptionNonChargeable,
          isEFTPermitted,
          locationId
        )
          .then(() => {
            toast({
              status: 'success',
              description: 'Team Created'
            })
            resetModal()
            // revalidateOrg()
          })
          .catch((error) => {
            setErrors(['Could not create team.'])
          })
          .finally(() => {
            setIsSubmitting(false)
          })
      }
    } else {
      setIsSubmitting(false)
    }
  }

  function validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(email)
  }

  function addAdminEmail() {
    if (
      validateEmail(adminInput) &&
      admins.filter((email) => email === adminInput).length === 0
    ) {
      setAdmins([...admins, adminInput])
    }
  }

  function removeAdminEmail(adminIndex) {
    const temp = [...admins]
    temp.splice(adminIndex, 1)
    setAdmins(temp)
  }

  function addSelectedPlan(id: number) {
    if (id) {
      const selectedPlan = plans.find((plan) => plan.id === id)

      if (selectedPlan) {
        setSelectedPlans([
          ...selectedPlans,
          {
            plan: selectedPlan,
            value: selectedPlan.rate.value
          }
        ])
      }
    }
  }

  function removePlan(planIndex) {
    const temp = [...selectedPlans]
    temp.splice(planIndex, 1)
    setSelectedPlans(temp)
  }

  function setPlanValue(event, planIndex) {
    const value = event.target.value
    // restrict to only numbers >=0
    if (value && !!!isNaN(value) && value >= 0) {
      const temp = [...selectedPlans]
      temp[planIndex].value = event.target.value
      setSelectedPlans(temp)
    }
  }

  function setPlanValueOverrideReason(event, planIndex) {
    const value = event.target.value
    // restrict to only numbers >=0
    const temp = [...selectedPlans]
    temp[planIndex].overrideReason = event.target.value
    setSelectedPlans(temp)
  }

  function addSelectedOffice(id: number) {
    if (offices && id) {
      const selectedOffice = offices.find((office) => office.id === id)

      // could find the office and it is not yet in the list
      if (
        selectedOffice &&
        selectedOffices.filter((office) => office.id === selectedOffice.id)
          .length === 0
      ) {
        setSelectedOffices([...selectedOffices, selectedOffice])
      }
    }
  }

  function removeOffice(officeIndex) {
    const temp = [...selectedOffices]
    temp.splice(officeIndex, 1)
    setSelectedOffices(temp)
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={resetModal} size="lg">
        <ModalOverlay />
        <ModalContent minW={['450px', '600px']} borderRadius="6px">
          <ModalHeader>Initialise Team</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={4}>
            <FormControl pt={2} pb={5}>
              <FormLabel>Team Name</FormLabel>
              <StyledInput
                w="92%"
                onChange={(event) => setTeamName(event.target.value)}
                value={teamName}
              />
            </FormControl>

            {/* Admins input */}
            <FormControl pt={2}>
              <FormLabel htmlFor="email">Team Admin Emails</FormLabel>
              <Stack spacing={3} isInline>
                <StyledInput
                  w="100%"
                  value={adminInput}
                  type="email"
                  onChange={(event) => setAdminInput(event.target.value)}
                  onKeyDown={(event) => {
                    if (event.keyCode === 13) {
                      addAdminEmail()
                    }
                  }}
                />
                <IconButton
                  isRound
                  size="sm"
                  backgroundColor="brandPrimary"
                  color="#ffffff"
                  aria-label="Add Admin"
                  icon={<AddIcon />}
                  _hover={{
                    transform: 'scale(1.038)'
                  }}
                  isDisabled={!!!validateEmail(adminInput)}
                  onClick={() => {
                    addAdminEmail()
                  }}
                />
              </Stack>
            </FormControl>

            <Stack spacing={2} ml={5} pt={3} pb={1}>
              {admins.map((admin, i) => {
                return (
                  <Alert
                    status="info"
                    background={'#f5f5f5'}
                    borderRadius="full"
                    key={i}
                  >
                    <Box>
                      <AlertTitle>{admin}</AlertTitle>
                    </Box>
                    <Spacer />
                    <IconButton
                      isRound
                      size="xs"
                      backgroundColor="brandPrimary"
                      aria-label="Remove Admin"
                      icon={<MinusIcon />}
                      onClick={() => removeAdminEmail(i)}
                    />
                  </Alert>
                )
              })}
            </Stack>
            <FormControl pt={2} pb={6}>
              <FormLabel htmlFor="email">Location</FormLabel>
              <StyledSelect
                w="92%"
                aria-labelledby="Select Entry"
                placeholder="-"
                onChange={(event) =>
                  setLocationId(parseInt(event.target.value))
                }
              >
                {platformTenant?.locations.map((option) => {
                  return (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  )
                })}
              </StyledSelect>
            </FormControl>

            {/* Plans input */}
            <StackedSelect
              header="Plans"
              autoAddEnabled={true}
              options={
                plans &&
                plans
                  .filter(
                    (p) => p.location?.id === locationId || p.location === null
                  )
                  .map((plan) => {
                    return {
                      id: plan.id,
                      title: plan.name
                    }
                  })
              }
              addSelectedOption={addSelectedPlan}
            >
              {selectedPlans.map((plan, i) => {
                return (
                  <Stack key={i} background={'#f5f5f5'} p={2}>
                    <Flex>
                      <Text width="80%" fontSize="md" fontWeight={'bold'}>
                        {plan.plan.name}
                      </Text>
                      <Spacer />
                      <IconButton
                        isRound
                        borderRadius="50%"
                        size="xs"
                        backgroundColor="brandPrimary"
                        aria-label="Remove Plan"
                        icon={<MinusIcon />}
                        onClick={() => removePlan(i)}
                      />
                    </Flex>
                    <Stack
                      key={i}
                      ml={5}
                      spacing={2}
                      isInline
                      background={'#f5f5f5'}
                      borderRadius="full"
                    >
                      <Text width="60%" fontSize="sm">
                        Value
                      </Text>
                      <InputGroup size="sm">
                        <InputLeftAddon children="$" />
                        <Input
                          width={20}
                          onChange={(event) => setPlanValue(event, i)}
                          value={plan.value}
                        />
                      </InputGroup>
                    </Stack>
                    <Text fontSize="sm">Override Reason</Text>
                    <Input
                      placeholder="A reason for override is required for price changes"
                      width="100%"
                      size={'sm'}
                      background="#fff"
                      onChange={(event) => setPlanValueOverrideReason(event, i)}
                      value={plan.overrideReason}
                      marginBottom="16px"
                    />
                  </Stack>
                )
              })}
            </StackedSelect>
            {/* Offices input */}
            <StackedSelect
              header="Offices"
              options={(offices ? offices : [])
                .filter((o) => o.location_id === locationId)
                .map((office) => {
                  return {
                    id: office.id,
                    title: `${office.name} - $${office.rate}`,
                    disabled: office.organization != undefined
                  }
                })}
              autoAddEnabled={true}
              addSelectedOption={addSelectedOffice}
            >
              <Stack>
                <Stack flexWrap="wrap" isInline ml={5} spacing={3}>
                  {selectedOffices.map((office, i) => {
                    return (
                      <Flex
                        size="md"
                        key={office.name}
                        color="#000000"
                        rounded="full"
                        variant="solid"
                        backgroundColor="#ffffff"
                        border="2px solid"
                        borderColor={'brandPrimary'}
                        p={2}
                      >
                        <Text pl="8px" pr="10px">
                          {office.name} - ${office.rate}
                        </Text>

                        <IconButton
                          isRound
                          borderRadius="full"
                          size="xs"
                          backgroundColor="brandPrimary"
                          aria-label="Remove Office"
                          icon={<MinusIcon />}
                          onClick={() => removeOffice(i)}
                        />
                      </Flex>
                    )
                  })}
                </Stack>
              </Stack>
            </StackedSelect>

            <Checkbox
              isChecked={isFirstSubscriptionNonChargeable}
              onChange={(e) =>
                setisFirstSubscriptionNonChargeable(e.target.checked)
              }
            >
              Should the first subscription be maked as non billable?
            </Checkbox>
            <Checkbox
              isChecked={isEFTPermitted}
              onChange={(e) => setIsEFTPermitted(e.target.checked)}
            >
              Enable EFT for this team
            </Checkbox>
            <Stack mt={2} spacing={3}>
              {errors.map((error, index) => {
                return (
                  <Alert status="error" key={index}>
                    <AlertIcon />
                    {error}
                  </Alert>
                )
              })}
            </Stack>
          </ModalBody>

          <ModalFooter justifyContent="flex-start">
            <Button disabled={isSubmitting} onClick={submit} mr={3}>
              Create Team
            </Button>
            <Button onClick={resetModal} variant="secondary">
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
