import { ChevronLeftIcon } from '@chakra-ui/icons'
import { Box, Divider, Flex, Spacer, Stack, Text, Wrap } from '@chakra-ui/react'
import React from 'react'
import { useHistory } from 'react-router'
import comment from '../../assets/comment.svg'
import { UserDisplay } from '../../components/UserDisplay'
import { useContentExplorer } from '../../context/content-explorer-context'
import { routeStrings } from '../../routeStrings'
import { IDailyFiveContent } from '../../types'
import { TagItem } from '../MemberDashboard/Explorer/TagItem'
import { ContentComments } from './ContentComments'
import { ContentDetailBar } from './ContentDetailBar'
import { ContentMenu } from './ContentMenu'
import { SaveButton } from './SaveButton'

interface Props {
  content: IDailyFiveContent | undefined
  isPreview: boolean
}

export const ContentDisplay: React.FC<Props> = ({ content, isPreview }) => {
  const history = useHistory()
  const { selectTag: selectInterest, selectedTags } = useContentExplorer()
  return (
    <Flex justifyContent="center" w="100%" h="100%" flexShrink={0} px={4}>
      <Flex
        maxWidth={['100%', '800px']}
        paddingBottom="50px"
        width={'100%'}
        p={5}
        justifyContent="left"
        flexShrink={0}
      >
        <Stack w="100%">
          {isPreview ? (
            <Flex mb={2}>
              <Text fontSize="md" color="#4F4F4F">
                Post preview
              </Text>
            </Flex>
          ) : (
            <Flex
              align="center"
              as="button"
              p={3}
              onClick={() => history && history.goBack()}
              flexShrink={0}
            >
              <ChevronLeftIcon size="20px" color="brandPrimary" />
              <Text fontSize="s" color="#616161" fontWeight="bold">
                Back
              </Text>
            </Flex>
          )}
          <Flex
            paddingBottom="16px"
            w="100%"
            h={isPreview ? '150px' : '375px'}
            justify="center"
            background={content?.image ? `url("${content?.image}")` : '#dadada'}
            backgroundSize="cover"
            backgroundPosition="center"
            flexShrink={0}
            alignItems="flex-end"
            position={'relative'}
          >
            <Flex
              position={'absolute'}
              bottom={0}
              right={0}
              p={0}
              background={'white'}
              opacity={0.8}
              w={'100%'}
            >
              <Text fontSize="md" color="gray.900" fontWeight="bold" p={2}>
                {content?.content_type}
              </Text>
              <Spacer />
              {!isPreview && (
                <Flex>
                  <Flex
                    flexShrink={0}
                    h="40px"
                    w="40px"
                    borderRadius="20px"
                    background="#FFFFFF"
                    justify="center"
                    align="center"
                    mr={2}
                  >
                    <SaveButton content={content} />
                  </Flex>
                  <Flex
                    flexShrink={0}
                    h="40px"
                    w="40px"
                    borderRadius="20px"
                    background="#FFFFFF"
                    justify="center"
                    align="center"
                  >
                    <Box
                      cursor="pointer"
                      onClick={() => {
                        document.getElementById('comments')?.focus()
                      }}
                    >
                      <img src={comment} alt="comment" />
                    </Box>
                  </Flex>
                </Flex>
              )}
            </Flex>
          </Flex>
          {/* <Stack height="15px" background="red">
            {selectedTags ? (
              <PromptAddInterest interest={selectedTags[0]} />
            ) : null}
          </Stack> */}
          <Stack marginTop={2} flexShrink={0}>
            <Flex>
              <Wrap cursor={'pointer'} maxWidth={'inherit'}>
                {content &&
                  content.interests &&
                  content.interests.map((interest, i) => (
                    <TagItem
                      key={i}
                      tag={interest}
                      selectTag={() => {
                        selectInterest(interest)
                        history.push(
                          routeStrings.memberDashboardHomeContentExplorer
                        )
                      }}
                      size={'sm'}
                    />
                  ))}
              </Wrap>
              <Spacer />
              <ContentMenu content={content} size={'md'} />
            </Flex>
            <Text fontSize="lg" color="black" fontWeight="bold">
              {content?.title}
            </Text>
            {/* <Text fontSize="xs" color="#000000" fontWeight="bold">
                Posted By
              </Text> */}
            <Flex align="center">
              <UserDisplay
                name={content?.author}
                src={content?.user_profile_image}
                size="xs"
                marginRight="8px"
              />
              <Text fontSize="xs" color="#4F4F4F" fontWeight="bold">
                {content?.author}
              </Text>
            </Flex>
            <ContentDetailBar content={content} />
          </Stack>
          <Flex w="100%" flexShrink={0}>
            {content?.summary && (
              <Text
                wordBreak="break-word"
                whiteSpace="pre-line"
                dangerouslySetInnerHTML={{ __html: content.summary }}
              ></Text>
            )}
          </Flex>
          <Divider />
          {isPreview ? null : <ContentComments content={content} />}
        </Stack>
      </Flex>
    </Flex>
  )
}
