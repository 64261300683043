import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  InputGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  SimpleGrid,
  Text,
  useToast
} from '@chakra-ui/react'
import { format } from 'date-fns'
import { Field, Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import ReactDatePicker from 'react-datepicker'
import { updateOpeningHours } from '../../../api'
import { Button } from '../../../components/Button'
import { usePlatformTenant } from '../../../context/platform-tenant-context'
import { Location, ILocationOpeningHours } from '../../../types'
import { useAccountCodes, useBasicWorkspaces } from '../../../utils/apiHooks'

interface IModalInput {
  isOpen: boolean
  onClose: (status?: boolean) => void
  location: Location
}

export const LocationOpeningHoursModal: React.FC<IModalInput> = ({
  isOpen,
  onClose,
  location
}) => {
  const toast = useToast()
  const { platformTenant } = usePlatformTenant()
  const { revalidate } = useBasicWorkspaces(platformTenant?.id)
  const [hours, setHours] = useState(location.opening_hours)

  const castHoursToDate = (hours: string | undefined) => {
    if (!hours) return null
    try {
      let hoursArray = hours.split(':')
      let d = new Date()
      d.setHours(parseInt(hoursArray[0]))
      d.setMinutes(parseInt(hoursArray[1]))
      return d
    } catch (error) {
      console.error(error)
      return new Date()
    }
  }
  const [fullDayFrom, setfullDayFrom] = useState<Date | null>(
    castHoursToDate(hours?.full_day_from)
  )
  const [fullDayTo, setfullDayTo] = useState<Date | null>(
    castHoursToDate(hours?.full_day_to)
  )
  const [firstHalfFrom, setFirstHalfFrom] = useState<Date | null>(
    castHoursToDate(hours?.first_half_from)
  )
  const [firstHalfTo, setFirstHalfTo] = useState<Date | null>(
    castHoursToDate(hours?.first_half_to)
  )
  const [secondHalfFrom, setSecondHalfFrom] = useState<Date | null>(
    castHoursToDate(hours?.second_half_from)
  )
  const [secondHalfTo, setSecondHalfTo] = useState<Date | null>(
    castHoursToDate(hours?.second_half_to)
  )

  const invalidTimeframes = () => {
    if (fullDayFrom && fullDayTo && fullDayFrom > fullDayTo) {
      return 'Invalid time frame for the day'
    }
    if (firstHalfFrom && firstHalfTo && firstHalfFrom > firstHalfTo) {
      return 'Invalid time frame for the first part of the day'
    }
    if (secondHalfFrom && secondHalfTo && secondHalfFrom > secondHalfTo) {
      return 'Invalid time frame for the second part of the day'
    }
    if (secondHalfFrom && firstHalfTo && firstHalfTo > secondHalfFrom) {
      return 'Time frames are overlapping'
    }
    return ''
  }

  const getHoursField = (
    fieldName: string,
    label: string,
    date: Date | null,
    setDate
  ) => {
    return (
      <Field
        name={fieldName}
        render={({ field, form }) => (
          <FormControl
            isInvalid={form.errors[fieldName] && form.touched[fieldName]}
          >
            <FormLabel htmlFor={fieldName}>{label}</FormLabel>
            <InputGroup>
              <ReactDatePicker
                selected={date}
                onChange={(date) => setDate(date)}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={30}
                timeCaption="Time"
                dateFormat="h:mm aa"
              />
            </InputGroup>

            <FormErrorMessage>{form.errors[fieldName]}</FormErrorMessage>
          </FormControl>
        )}
      />
    )
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent minW={['450px', '600px']} borderRadius="6px">
        <ModalHeader pb={5}>{location.name} Opening Hours </ModalHeader>
        <ModalCloseButton />
        <Formik
          initialValues={{
            location: location.id,
            full_day_from: hours?.full_day_from ?? '',
            full_day_to: hours?.full_day_to ?? '',
            first_half_from: hours?.first_half_from ?? '',
            first_half_to: hours?.first_half_to ?? '',
            second_half_from: hours?.second_half_from ?? '',
            second_half_to: hours?.second_half_to ?? '',
            hours_half_day: hours?.hours_half_day ?? 0
          }}
          validate={(values) => {
            const errors: any = {}

            if ((fullDayFrom && !fullDayTo) || (fullDayTo && !fullDayFrom)) {
              errors.full_day_from = 'Start time required'
              errors.full_day_to = 'End time required'
            }
            if (
              (firstHalfFrom && !firstHalfTo) ||
              (firstHalfTo && !firstHalfFrom)
            ) {
              errors.first_half_from = 'Start time required'
              errors.first_half_to = 'End time required'
            }
            if (
              (secondHalfFrom && !secondHalfTo) ||
              (secondHalfTo && !secondHalfFrom)
            ) {
              errors.second_half_from = 'Start time required'
              errors.second_half_to = 'End time required'
            }
            if (!values.hours_half_day || values.hours_half_day <= 0) {
              errors.hours_half_day = 'Select a valid option'
            }
            return errors
          }}
          onSubmit={(values, { setSubmitting }) => {
            const openingHoursObj: ILocationOpeningHours = {
              location: location.id,
              full_day_from: fullDayFrom
                ? format(fullDayFrom, 'HH:mm:ss')
                : undefined,
              full_day_to: fullDayTo
                ? format(fullDayTo, 'HH:mm:ss')
                : undefined,
              first_half_from: firstHalfFrom
                ? format(firstHalfFrom, 'HH:mm:ss')
                : undefined,
              first_half_to: firstHalfTo
                ? format(firstHalfTo, 'HH:mm:ss')
                : undefined,
              second_half_from: secondHalfFrom
                ? format(secondHalfFrom, 'HH:mm:ss')
                : undefined,
              second_half_to: secondHalfTo
                ? format(secondHalfTo, 'HH:mm:ss')
                : undefined,
              hours_half_day: values.hours_half_day
            }
            let invalidMessage = invalidTimeframes()
            if (invalidMessage) {
              setSubmitting(false)
              toast({
                status: 'error',
                description: invalidMessage
              })
              return
            }
            updateOpeningHours(openingHoursObj)
              .then((res) => {
                toast({
                  status: 'success',
                  description: 'Opening hours updated!'
                })
                onClose(true)
              })
              .catch((e) => {
                toast({
                  status: 'error',
                  description: e.response.data
                })
                console.log(e)
                setSubmitting(false)
              })
          }}
          render={({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            setFieldValue,
            handleSubmit,
            isSubmitting
            /* and other goodies */
          }) => (
            <Form>
              <ModalBody>
                <SimpleGrid mt="10px" columns={[1, 2]} spacing={6}>
                  {getHoursField(
                    'full_day_from',
                    'Day starts at',
                    fullDayFrom,
                    setfullDayFrom
                  )}
                  {getHoursField(
                    'full_day_to',
                    'Day ends at',
                    fullDayTo,
                    setfullDayTo
                  )}

                  {/* {getHoursField(
                    'first_half_from',
                    'Morning starts at',
                    firstHalfFrom,
                    setFirstHalfFrom
                  )}
                  {getHoursField(
                    'first_half_to',
                    'Morning ends at',
                    firstHalfTo,
                    setFirstHalfTo
                  )}
                  {getHoursField(
                    'second_half_from',
                    'Evening starts at',
                    secondHalfFrom,
                    setSecondHalfFrom
                  )}
                  {getHoursField(
                    'second_half_to',
                    'Evening ends at',
                    secondHalfTo,
                    setSecondHalfTo
                  )} */}
                  <Text
                    fontSize="md"
                    pt={4}
                    fontWeight="700"
                    textAlign={['left', 'right']}
                  >
                    Hours for half a day
                  </Text>
                  <Field
                    name="hours_half_day"
                    render={({ field, form }) => (
                      <FormControl
                        isInvalid={
                          form.errors.hours_half_day &&
                          form.touched.hours_half_day
                        }
                      >
                        <InputGroup>
                          <Select
                            m={3}
                            maxWidth="250px"
                            placeholder="Select a value"
                            color="#8b96a3"
                            onChange={(e) => {
                              setFieldValue('hours_half_day', e.target.value)
                            }}
                            value={values.hours_half_day}
                          >
                            {[
                              1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15,
                              16, 17, 18
                            ].map((value, i) => (
                              <option key={i} value={value}>
                                {value + ' ' + (value === 1 ? 'hour' : 'hours')}
                              </option>
                            ))}
                          </Select>
                        </InputGroup>

                        <FormErrorMessage>
                          {form.errors.hours_half_day}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  />
                </SimpleGrid>
              </ModalBody>

              <ModalFooter justifyContent="flex-end">
                <Button variant="secondary" onClick={() => onClose()} mr={5}>
                  Cancel
                </Button>
                <Button
                  disabled={isSubmitting}
                  type="submit"
                  onClick={() => handleSubmit()}
                >
                  Save
                </Button>
              </ModalFooter>
            </Form>
          )}
        />
      </ModalContent>
    </Modal>
  )
}
