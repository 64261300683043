import { Box, Flex, Spacer, Text } from '@chakra-ui/react'
import React from 'react'

interface SettingsItem {
  id?: string | number
  title: string
  description?: string
  children?: React.ReactNode
}

export const SettingsItem: React.FC<SettingsItem> = (
  settings: SettingsItem
) => {
  return (
    <Flex key={settings.id} flexDir={['column', 'column', 'row']}>
      <Box w={['100%', '100%', '30%']} py={settings.description ? 0 : 2}>
        <Text fontSize={{ base: 'md' }} textAlign={'left'}>
          {settings.title}
        </Text>
        {settings.description && (
          <Text fontSize={{ base: 'xs' }}>{settings.description}</Text>
        )}
      </Box>

      <Box p="2" ml="30px" w={['100%', '100%', '40%']}>
        {settings.children}
      </Box>
    </Flex>
  )
}
