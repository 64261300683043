import React, { useEffect } from 'react'
import { StringParam, useQueryParams } from 'use-query-params'
import { AdminBodyWrapper } from '../../components/AdminBodyWrapper'
import { GroupingHeaderLayout } from '../../components/GroupingHeaderLayout'
import { LayoutAdminDashboard } from './LayoutAdminDashboard'
import { PlanChangesReport } from './Reports/PlanChangesReport'
import { AdminDashboardInvoicesReports } from './Reports/Invoices'
import { useAppTourContextProvider } from '../../context/app-tour-context'

const reportTypes = [
  {
    text: 'Invoices',
    id: 'invoices'
  },
  {
    text: 'Plan Changes',
    id: 'plan-changes'
  },
  {
    text: 'Member Cancellations',
    id: 'plan-cancellations'
  }
]

/**
 * This component uses a `type` query parameter to determine which report type to render.
 */
export const AdminDashboardReports: React.FC = () => {
  const { adminTourActive, setRun, run } = useAppTourContextProvider()
  useEffect(() => {
    if (adminTourActive) {
      setRun(true)
    }
  }, [run])
  const [reportType, setReportType] = useQueryParams({
    type: StringParam
  })

  useEffect(() => {
    if (!reportType || !reportType.type) {
      setReportType({
        type: reportTypes[0].id
      })
    }
  }, [reportType])
  return (
    <LayoutAdminDashboard>
      <GroupingHeaderLayout
        basicTitle="Reports"
        tabs={reportTypes.map((report) => {
          return {
            text: report.text,
            active: reportType.type == report.id,
            selectAction: () => {
              setReportType({ type: report.id })
            }
          }
        })}
      />

      {/* Tables */}
      <AdminBodyWrapper>
        {reportType.type == 'invoices' && <AdminDashboardInvoicesReports />}
        {reportType.type == 'plan-changes' && (
          <PlanChangesReport type="change" />
        )}
        {reportType.type == 'plan-cancellations' && (
          <PlanChangesReport type="cancellation" />
        )}
      </AdminBodyWrapper>
    </LayoutAdminDashboard>
  )
}
