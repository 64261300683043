import { Flex, Text, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import styled from 'styled-components'
import { getInvoicePDF } from '../../api'
import { AdminBodyWrapper } from '../../components/AdminBodyWrapper'
import { Button } from '../../components/Button'
import { GroupingHeaderLayout } from '../../components/GroupingHeaderLayout'
import { useAuth } from '../../context/auth-context'
import { useMedia } from '../../hooks/useMedia'
import { routeStrings } from '../../routeStrings'
import { CenteredSpinner } from '../../styled/CenteredSpinner'
import {
  useInvoiceHTML,
  useOrganizationInvoicePreviewRendered
} from '../../utils/apiHooks'
import { useRole } from '../../utils/rbac'
import { LayoutMemberDashboard } from '../MemberDashboard/LayoutMemberDashboard'

const Invoice = styled.div`
  width: 100%;

  > .invoice-box {
    background: white;
    max-width: 80%;
  }
`

export const MemberDashboardBillingInvoiceView: React.FC = () => {
  const history = useHistory()
  const { me, currentOrganization } = useAuth()
  const { isInAdminRole } = useRole()
  const toast = useToast()
  const match = useRouteMatch<{ invoiceId: string }>()
  const invoiceId = match?.params.invoiceId

  const [invoiceHTML, setInvoiceHTML] = useState<string>()
  const [isDownloading, setIsDownloading] = useState<boolean>(false)

  const { data: previewHTML, error: errorFetching } =
    useOrganizationInvoicePreviewRendered(currentOrganization?.id)
  const { data: realHTML } = useInvoiceHTML(
    parseInt(invoiceId ? invoiceId : ''),
    currentOrganization ? currentOrganization.id : undefined
  )

  let preview = false

  const hideInvoiceOnMobile = useMedia(['(min-width: 400px)'], [false], true)

  if (
    invoiceId === undefined ||
    isNaN(parseInt(invoiceId)) ||
    invoiceId === '-1'
  ) {
    preview = true
  }

  useEffect(() => {
    if (preview) {
      setInvoiceHTML(previewHTML)
    } else {
      setInvoiceHTML(realHTML)
    }
  }, [realHTML, previewHTML])
  useEffect(() => {
    if (errorFetching) {
      toast({
        description: 'Invoice unavailable',
        status: 'error',
        duration: 4000
      })
      history.push(
        isInAdminRole
          ? routeStrings.adminDashboard
          : routeStrings.memberDashboardHome
      )
    }
  }, [errorFetching])

  const downloadButtonPressed = () => {
    if (invoiceId && currentOrganization) {
      setIsDownloading(true)
      getInvoicePDF({ invoiceId, orgId: currentOrganization.id }).finally(() =>
        setIsDownloading(false)
      )
    }
  }

  return (
    <LayoutMemberDashboard>
      <GroupingHeaderLayout basicTitle="Invoice" />

      <AdminBodyWrapper>
        <Flex
          justifyContent={'center'}
          flex={1}
          flexDirection="column"
          style={{ width: '100%' }}
          alignItems="center"
          pt={5}
          pb={5}
        >
          {invoiceHTML == null ? (
            <CenteredSpinner />
          ) : (
            <>
              {hideInvoiceOnMobile ? (
                <Text>Your invoice is viewable as a PDF.</Text>
              ) : (
                <Invoice dangerouslySetInnerHTML={{ __html: invoiceHTML }} />
              )}
              <br />
              <Button disabled={isDownloading} onClick={downloadButtonPressed}>
                Download invoice as PDF
              </Button>
            </>
          )}
        </Flex>
      </AdminBodyWrapper>
    </LayoutMemberDashboard>
  )
}
