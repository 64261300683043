import {
  Box,
  Container,
  Flex,
  SimpleGrid,
  Image,
  Text,
  Stack,
  StackDivider,
  ContainerProps,
  Spacer,
  useDisclosure,
  useToast
} from '@chakra-ui/react'
import {
  faClock,
  faInfoCircle,
  faTv,
  faUserFriends,
  faVideo
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { ReactElement, useState } from 'react'
import { useEffect } from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import { Button } from '../../../../components/Button'
import { useAuth } from '../../../../context/auth-context'
import { useAuthDrawer } from '../../../../context/auth-drawer-context'
import { useBookingContext } from '../../../../context/BookingContext'
import { useFeatureFlags } from '../../../../context/feature-flag-context'
import { usePlatformTenant } from '../../../../context/platform-tenant-context'
import { routeStrings } from '../../../../routeStrings'
import { CenteredSpinner } from '../../../../styled/CenteredSpinner'
import { analyticsTrack } from '../../../../utils/analytics'
import { useMeetingRoomSlug } from '../../../../utils/apiHooks'
import { useRole } from '../../../../utils/rbac'

import { LocationDetailsContainer } from './LocationDetailsContainer'
import { PublicBookingDrawer } from './PublicBookingDrawer'

export const PublicMeetingRoom: React.FC = () => {
  const { me } = useAuth()

  const history = useHistory()
  const { isExternalMember } = useRole()
  const { locations: bookableLocations } = useBookingContext()
  const { publicBookingsEnabled } = useFeatureFlags()
  const match = useRouteMatch<{
    room_slug: string
  }>()
  const {
    onClose: onCloseDrawer,
    isOpen: isOpenDrawer,
    onOpen: onOpenDrawer,
    onToggle: onToggleDrawer
  } = useDisclosure()
  const { handleOpenSignInDrawer } = useAuthDrawer()

  const [room_slug, setRoomSlug] = useState('')
  const {
    data: meetingRoom,
    revalidate,
    error
  } = useMeetingRoomSlug(room_slug, {
    shouldRetryOnError: false
  })
  const { setThemeTenantLocation } = usePlatformTenant()

  const isExternalBooking =
    isExternalMember ||
    !bookableLocations.find((l) => l.id === meetingRoom?.location)
  const canBeBooked =
    !isExternalBooking || (isExternalBooking && meetingRoom?.public_booking)

  const roomAnalyticsData = {
    room: meetingRoom?.name,
    slug: meetingRoom?.room_slug,
    tenant_name: meetingRoom?.platformTenant.business_name,
    tenant_id: meetingRoom?.platformTenant.id
  }
  useEffect(() => {
    if (match) {
      setRoomSlug(match.params.room_slug)
    }
  }, [match])

  useEffect(() => {
    if (error && error.response) {
      if (error.response.status === 404) {
        history.push(routeStrings.notFound)
      }
    }
  }, [error])

  useEffect(() => {
    if (meetingRoom) {
      analyticsTrack('PublicBookings Room Loaded', roomAnalyticsData)

      setThemeTenantLocation(meetingRoom.platformTenant.id)
    }
  }, [meetingRoom])

  const boxStyles: ContainerProps = {
    py: 8,
    px: [2, 5, 10],
    bg: 'white',
    shadow: 'lg',
    rounded: 'lg'
  }

  if (!meetingRoom || !publicBookingsEnabled) return <CenteredSpinner />

  return (
    <>
      <Container
        maxW={'6xl'}
        {...boxStyles}
        mb={4}
        borderTopColor={isExternalBooking ? 'gray.400' : undefined}
        borderTopWidth={isExternalBooking ? 3 : 0}
      >
        <Flex flexDirection={['column-reverse', 'column-reverse', 'row']}>
          <Box mb={2}>
            <Text fontSize={['md', 'lg', '3xl']} fontWeight="bold">
              {meetingRoom?.name} at {meetingRoom?.roomLocation.name}
            </Text>
            <Text
              color={'gray.500'}
              fontSize={['sm', 'lg']}
              fontWeight={'normal'}
            >
              {meetingRoom?.roomLocation.address},{' '}
              <Text color={'gray.500'} fontWeight={'bold'} as="span">
                {meetingRoom?.roomLocation.city.name}.
              </Text>
            </Text>
          </Box>
          <Spacer />
          <Box>
            <Image
              src={meetingRoom?.platformTenant.logo_long}
              height={['5', '8', '10']}
            />
          </Box>
        </Flex>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
          <Flex>
            <Image
              rounded={'md'}
              alt={'meeting room image'}
              src={meetingRoom?.display_url}
              objectFit={'cover'}
              maxH={'container.xl'}
            />
          </Flex>
          <Stack spacing={4}>
            {/* <Text
              textTransform={'uppercase'}
              color={'gray.100'}
              fontWeight={600}
              fontSize={'sm'}
              bg={meetingRoom?.platformTenant.brand_primary_color}
              p={2}
              alignSelf={'flex-start'}
              rounded={'md'}
              opacity={0.6}
            >
              Meeting Room
            </Text> */}
            <Text color={'gray.500'} fontSize={'lg'}>
              {meetingRoom?.about}
            </Text>
            <Stack
              spacing={4}
              divider={<StackDivider borderColor={'gray.200'} />}
            >
              <FeatureComponent
                icon={<FontAwesomeIcon icon={faUserFriends} size={'1x'} />}
                iconBg={'yellow.100'}
                text={`Up to ${meetingRoom?.capacity} attendees`}
              />
              {isExternalBooking && canBeBooked && (
                <FeatureComponent
                  icon={<FontAwesomeIcon icon={faClock} size={'1x'} />}
                  iconBg={'green.100'}
                  text={`$${meetingRoom.non_member_price_per_hour} per hour`}
                />
              )}
              {!isExternalBooking && (
                <FeatureComponent
                  icon={<FontAwesomeIcon icon={faClock} size={'1x'} />}
                  iconBg={'green.100'}
                  text={`$${meetingRoom.member_price_per_hour} per hour`}
                />
              )}

              {meetingRoom.has_videoconference && (
                <FeatureComponent
                  icon={<FontAwesomeIcon icon={faVideo} size={'1x'} />}
                  iconBg={'gray.100'}
                  text={`Suitable for video conferencing`}
                />
              )}
              {meetingRoom.has_screen_mirroring && (
                <FeatureComponent
                  icon={<FontAwesomeIcon icon={faTv} size={'1x'} />}
                  iconBg={'purple.100'}
                  text={`Screen mirroring available`}
                />
              )}

              {/*  <FeatureComponent
                icon={<FontAwesomeIcon icon={faBook} size={'1x'} />}
                iconBg={'purple.100'}
                text={`Non member rate   $${meetingRoom.non_member_price_per_hour} per hour`}
              /> */}
            </Stack>
          </Stack>
        </SimpleGrid>
        {!canBeBooked && (
          <Flex p={4}>
            <Spacer />
            <FeatureComponent
              icon={<FontAwesomeIcon icon={faInfoCircle} size={'1x'} />}
              text="Bookings haven been disabled for this room"
              iconBg="#e6f7ff"
            />
          </Flex>
        )}
      </Container>
      <LocationDetailsContainer meetingRoom={meetingRoom}>
        {canBeBooked && (
          <Flex p={4} justifyContent={'center'}>
            {me ? (
              <Button
                w={'90%'}
                shadow={'xl'}
                onClick={() => {
                  analyticsTrack(
                    'PublicBookings authenticated Book room clicked',
                    roomAnalyticsData
                  )
                  onToggleDrawer()
                }}
              >
                Book Room
              </Button>
            ) : (
              <Button
                w={'90%'}
                shadow={'xl'}
                onClick={() => {
                  analyticsTrack(
                    'PublicBookings anonymous Book room clicked',
                    roomAnalyticsData
                  )
                  handleOpenSignInDrawer()
                }}
              >
                Book Room
              </Button>
            )}
          </Flex>
        )}
      </LocationDetailsContainer>
      <PublicBookingDrawer
        meetingRoom={meetingRoom}
        onClose={onCloseDrawer}
        isOpen={isOpenDrawer}
        onOpen={onOpenDrawer}
        onToggle={onToggleDrawer}
      />
    </>
  )
}

interface FeatureProps {
  text: string
  iconBg: string
  icon?: ReactElement
}

export const FeatureComponent = ({ text, icon, iconBg }: FeatureProps) => {
  return (
    <Stack direction={'row'} align={'center'}>
      <Flex
        w={8}
        h={8}
        align={'center'}
        justify={'center'}
        rounded={'full'}
        bg={iconBg}
      >
        {icon}
      </Flex>
      <Text fontWeight={[100, 100, 600]}>{text}</Text>
    </Stack>
  )
}
