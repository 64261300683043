import {
  Box,
  Button,
  Divider,
  Flex,
  Image,
  Stack,
  Text,
  useTheme,
  BoxProps,
  Tooltip,
  HStack,
  VStack
} from '@chakra-ui/react'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import React, { useEffect, useState, useContext } from 'react'
import { useLocation, useParams } from 'react-router'
import { NavLink, useHistory } from 'react-router-dom'

import { useAuth } from '../context/auth-context'
import { routeStrings, getTeamMemberProfileUrl } from '../routeStrings'
import { USER_SUPPORT_LINK } from '../utils/constants'
import { Actions, Roles, useRBAC, useRole } from '../utils/rbac'
import { RouterNavLink } from './Link'
import { UserDisplay } from './UserDisplay'

import { useFeatureFlags } from '../context/feature-flag-context'
import { useOrganization } from '../utils/apiHooks'
import { usePlatformTenant } from '../context/platform-tenant-context'

import {
  BillingIcon,
  LogoutIcon,
  SupportIcon,
  TeamMembersIcon,
  DashboardIcon,
  MeetingRoomsIcon,
  CalendarCustomIcon
} from './icons/icons'
import { DragHandleIcon, EditIcon, PlusSquareIcon } from '@chakra-ui/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAddressCard, faCog } from '@fortawesome/free-solid-svg-icons'
import { useMembersDirectoryContext } from '../context/members-directory-context'

import { tourTargetStrings } from '../tourStepsArrays'
interface Props {
  defaultCollapsed?: boolean
}

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height
  }
}

const CollapsedContext = React.createContext<boolean>(true)

export const TeamsSidebar: React.FC<Props> = ({ defaultCollapsed }) => {
  const { supportUrl } = usePlatformTenant()
  const { allowedRoles, assumeRole, isInAdminRole } = useRole()
  const history = useHistory()
  const canViewTeam = useRBAC(Actions.ViewTeam)
  const canViewCalendar = useRBAC(Actions.ViewCalendar)
  const canBookMeetingRooms = useRBAC(Actions.BookMeetingRooms)
  const canBookWorkspaces = useRBAC(Actions.BookWorkspaces)
  const canViewBilling = useRBAC(Actions.ViewBillingForOwnTeam)
  const canVisitSupport = useRBAC(Actions.VisitSupportLink)
  const canViewMembersDirectory = useRBAC(Actions.ViewMembersDirectory)
  const canViewMemberDashboard = useRBAC(Actions.ViewMemberDashboard)
  const canViewPersonalProfile = useRBAC(Actions.ViewPersonalProfile)
  const canViewTeamSettings = useRBAC(Actions.ViewTeamSettings)
  const canCreateNewPost = useRBAC(Actions.CreateNewPost)
  const { me, currentOrganization, currentOrgUnit, setOrganizationalUnit } =
    useAuth()
  let location = useLocation()
  const {
    dailyFiveEnabled,
    coworkingSettingsEnabled,
    workspaceBookingEnabled,
    membersDirectoryEnabled
  } = useFeatureFlags()
  const [collapsed, setCollapsed] = useState(defaultCollapsed || false)
  const { orgId: routeOrgId } = useParams<{ orgId?: string }>()
  const { ouId: routeouId } = useParams<{ ouId?: string }>()
  const orgId =
    (routeOrgId && parseInt(routeOrgId, 10)) || currentOrganization?.id
  const ouId = (routeouId && parseInt(routeouId, 10)) || currentOrgUnit?.id
  useEffect(() => setCollapsed(defaultCollapsed || false), [defaultCollapsed])
  const { data: organization } = useOrganization(orgId)

  const { logOpenDirectory } = useMembersDirectoryContext()

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  )

  const [delayHandler, setDelayHandler] = useState<number>()
  const [hovered, sethovered] = useState(false)

  const handleMouseEnter = (event) => {
    sethovered(true)
  }

  const handleMouseLeave = () => {
    clearTimeout(delayHandler)
    if (!collapsed) setCollapsed(true)
    sethovered(false)
  }

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    let timer = 0
    if (!hovered) {
      setCollapsed(true)
      if (delayHandler) {
        clearTimeout(delayHandler)
      }
    } else {
      timer =
        hovered &&
        setTimeout(() => {
          setCollapsed(false)
        }, 1500)
      setDelayHandler(timer)
    }
    return () => {
      timer && clearTimeout(timer)
    }
  }, [hovered])

  function displayBillingButton() {
    if (organization && ouId) {
      let isAdmin = false
      if (me?.user.is_space_admin) {
        isAdmin = true
      }
      return (
        location.pathname !==
          getTeamMemberProfileUrl(organization.id, ouId, isAdmin) &&
        !organization.is_space_admin &&
        organization.billing_account !== undefined
      )
    }
    return false
  }

  const screenHeightGT770 = windowDimensions.height > 770
  const theme: any = useTheme()
  const StyledLink = styled(NavLink)`
    color: ${theme.colors.eastBay06};
    transition: all 0.18s ease;
    font-weight: ${theme.fontWeights.semibold};
    font-size: 14px;
    margin-bottom: 10px;
    padding-left: 20px;
    line-height: 35px;
    height: 44px;

    &:hover {
      color: ${theme.colors.headingPrimary};
      cursor: pointer;
    }

    &.current {
      font-weight: bold;
      color: ${theme.colors.headingPrimary} !important;
      span.link-text {
      }
      box-shadow: inset 4px 0 0 ${theme.colors.brandPrimary};
    }
  `

  const StyledA = styled.a`
    color: ${theme.colors.eastBay06};
    transition: all 0.18s ease;
    font-weight: ${theme.fontWeights.semibold};
    font-size: 14px;
    margin-bottom: 16px;
    padding-left: 20px;
    height: 44px;

    &:hover {
      color: ${theme.colors.headingPrimary};
      cursor: pointer;
    }

    &.collapse {
      transition: 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) all;
      svg {
        transform: ${collapsed ? `rotate(-90deg)` : `rotate(90deg)`};
      }
    }
  `

  const menuStyles = css`
    text-align: left;
    transition: all 0.2s ease;

    display: flex;
    flex: 1;
    flex-direction: column;

    background: white;
    overflow-y: scroll;
    overflow-x: hidden;
    .example::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .example {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  `

  return (
    <CollapsedContext.Provider value={collapsed}>
      <Flex
        w={collapsed ? '50px' : '200px'}
        minWidth={collapsed ? '50px' : '200px'}
        className={'side-bar scrollable-list tour-sidebar'}
        borderRight="1px solid #D8DDE4"
        boxShadow="0px 1px 0px rgba(0, 0, 0, 0.05)"
        zIndex={1}
        css={menuStyles}
        transition="width 0.2s ease"
        pt={canViewMemberDashboard ? (screenHeightGT770 ? 16 : 4) : '39px'}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={() => {
          handleMouseLeave()
        }}
      >
        <Stack spacing={collapsed ? 0 : 1}>
          {/* s3 logo */}
          {canViewMemberDashboard && (
            <Box mb={screenHeightGT770 ? 10 : 1} p={3}>
              {collapsed ? (
                <NavLink to={routeStrings.memberDashboardHome}>
                  <Image
                    src={theme.logos.logo_square}
                    width="50px"
                    alignSelf="center"
                  />
                </NavLink>
              ) : (
                <NavLink to={routeStrings.memberDashboardHome}>
                  <Image
                    src={theme.logos.logo_long}
                    width="100%"
                    alignSelf="center"
                  />
                </NavLink>
              )}
            </Box>
          )}
          {/* main links */}
          {canViewMemberDashboard && (
            <StyledLink
              activeClassName="current"
              to={routeStrings.memberDashboardHome}
            >
              <CollapseIconComponent
                label="Dashboard"
                icon={<DashboardIcon mr="1" h="16px" w="16px" />}
              />
              <Label fontSize="xs">Dashboard</Label>
            </StyledLink>
          )}
          {canViewTeam && (
            <StyledLink
              activeClassName="current"
              className={tourTargetStrings.sbTeam}
              to={routeStrings.teamDashboardHome}
            >
              <CollapseIconComponent
                label="Team Members"
                icon={<TeamMembersIcon mr="1" h="16px" w="16px" />}
              />
              <Label data-testid="team-members-nav-link" fontSize="xs">
                Team
              </Label>
            </StyledLink>
          )}
          {membersDirectoryEnabled && canViewMembersDirectory && (
            <StyledLink
              activeClassName="current"
              className={tourTargetStrings.sbDirectory}
              to={routeStrings.memberDashboardMembersDirectory}
              onClick={() => {
                logOpenDirectory('SIDEBAR')
              }}
            >
              <Flex>
                <CollapseIconComponent
                  label="Members Directory"
                  icon={
                    <div>
                      <FontAwesomeIcon
                        icon={faAddressCard}
                        size={'1x'}
                        style={{ marginRight: '3' }}
                      />
                    </div>
                  }
                />
                <Label data-testid="members-directory-nav-link" fontSize="xs">
                  Directory
                </Label>
              </Flex>
            </StyledLink>
          )}
          {canViewCalendar && dailyFiveEnabled && (
            <StyledLink
              activeClassName="current"
              className={tourTargetStrings.sbCalendar}
              to={routeStrings.memberDashboardCalendar}
            >
              <CollapseIconComponent
                label="Community Calendar"
                icon={<CalendarCustomIcon mr="1" h="16px" w="16px" />}
              />
              <Label data-testid="community-calendar-nav-lik" fontSize={'xs'}>
                Community Calendar
              </Label>
            </StyledLink>
          )}

          {canBookMeetingRooms && (
            <StyledLink
              to={routeStrings.memberDashboardMeetingRooms}
              isActive={() => {
                if (location.pathname === routeStrings.memberDashboardBookings)
                  return true
                if (
                  location.pathname.includes(
                    routeStrings.memberDashboardMeetingRooms
                  )
                )
                  return true
                return false
              }}
              className={tourTargetStrings.sbMeeting}
              activeClassName="current"
            >
              <CollapseIconComponent
                label="Meeting Rooms"
                icon={<MeetingRoomsIcon mr="1" h="16px" w="16px" />}
              />
              <Label fontSize="xs">Meeting rooms</Label>
            </StyledLink>
          )}
          {canBookWorkspaces && workspaceBookingEnabled && (
            <StyledLink
              to={routeStrings.memberDashboardWorkspaces}
              className={tourTargetStrings.sbWorkspaces}
              isActive={() => {
                if (
                  location.pathname === routeStrings.memberDashboardWorkspaces
                )
                  return true
                if (
                  location.pathname.includes(
                    routeStrings.memberDashboardWorkspaceBookings
                  )
                )
                  return true
                return false
              }}
              activeClassName="current"
            >
              <CollapseIconComponent
                label="Workspaces"
                icon={<DragHandleIcon mr="1" h="16px" w="16px" />}
              />
              <Label fontSize="xs">Workspaces</Label>
            </StyledLink>
          )}

          {canViewTeamSettings &&
            organization &&
            !organization?.is_space_admin &&
            !organization?.is_deleted && (
              <StyledLink
                activeClassName="current"
                to={routeStrings.adminDashboardTeamSettings(organization.id)}
              >
                <Flex>
                  <CollapseIconComponent
                    label="Team Settings"
                    icon={
                      <div>
                        <FontAwesomeIcon
                          icon={faCog}
                          size={'1x'}
                          style={{ marginRight: '3' }}
                        />
                      </div>
                    }
                  />
                  <Label fontSize="xs">Team Settings</Label>
                </Flex>
              </StyledLink>
            )}
          {canViewBilling && displayBillingButton() && (
            <StyledLink
              activeClassName="current"
              className={tourTargetStrings.sbBilling}
              to={
                orgId && me?.user.is_space_admin
                  ? routeStrings.adminDashboardBilling(orgId)
                  : routeStrings.teamAdminDashboardBilling
              }
            >
              <CollapseIconComponent
                label="Billing"
                icon={<BillingIcon mr="1" h="16px" w="16px" />}
              />
              <Label fontSize="xs">Billing</Label>
            </StyledLink>
          )}
          {canCreateNewPost && dailyFiveEnabled && (
            <StyledLink
              activeClassName="current"
              className={tourTargetStrings.sbCreate}
              to={routeStrings.memberDashboardCreatePost}
            >
              <CollapseIconComponent
                label="Create Post"
                icon={<PlusSquareIcon mr="1" h="16px" w="16px" />}
              />
              <Label fontSize="xs">Create Post</Label>
            </StyledLink>
          )}

          {canVisitSupport && (
            <StyledA
              href={coworkingSettingsEnabled ? supportUrl : USER_SUPPORT_LINK}
              target="_blank"
              rel="noopener noreferrer"
            >
              <CollapseIconComponent
                label="Support"
                icon={<SupportIcon mr="1" h="16px" w="16px" />}
              />
              <Label fontSize="xs">Support</Label>
            </StyledA>
          )}

          {allowedRoles.includes(Roles.SpaceAdmin) && !isInAdminRole && (
            <Button
              px={collapsed ? 4 : 3}
              justifyContent="flex-start"
              background={theme.colors.brandPrimary}
              borderRadius={'xl'}
              onClick={() => {
                // It sets first the SpaceAdmin role, and attempts to set the SuperAdmin, if it is denied, the sole assumed will be SpaceAdmin
                assumeRole(Roles.SpaceAdmin)
                assumeRole(Roles.SpaceSuperAdmin)
                history.push(routeStrings.adminDashboard)
              }}
            >
              <CollapseIconComponent
                label="Space Admin"
                icon={<EditIcon mr="1" h="16px" w="16px" color={'#fff'} />}
              />
              <Label fontSize="xs" color={'#fff'}>
                Manage Space
              </Label>
            </Button>
          )}
        </Stack>
        <Box mb={collapsed && screenHeightGT770 ? 2 : 0} />
        {me && canViewPersonalProfile && (
          <Stack p={collapsed ? 0 : 2} justify="center">
            <Text textAlign="center" color="gray.500" fontSize="xs">
              Profile
            </Text>

            <Tooltip
              label="User Profile"
              aria-label="User Profile"
              placement="right"
            >
              <RouterNavLink
                ml={1}
                textDecoration="none"
                className={tourTargetStrings.sbProfile}
                _hover={{ textDecor: 'none' }}
                to={routeStrings.memberDashboardProfile}
                activeClassName="current"
              >
                <Stack
                  isInline
                  spacing={1}
                  alignItems="center"
                  justify={collapsed ? 'center' : 'flex-start'}
                >
                  <UserDisplay
                    mr={collapsed ? '0px' : '8px'}
                    name={me.user.name}
                    src={me.user.user_profile.profile_picture?.image}
                  />
                  <Label fontSize="xs">{me.user.name}</Label>
                </Stack>
              </RouterNavLink>
            </Tooltip>
          </Stack>
        )}
        {me && canViewPersonalProfile && (
          <VStack justify="center">
            <Divider my={3} />
            <Text
              textAlign="center"
              color="gray.500"
              fontSize="xs"
              className={tourTargetStrings.sbTeams}
            >
              Teams
              {me.organizational_units.length > 1
                ? ` (${me.organizational_units.length})`
                : ''}
            </Text>
            {me.organizational_units.map((ou, i) => (
              <HStack
                key={i}
                py={1}
                px={collapsed ? 0 : 3}
                borderRadius="xl"
                background={
                  currentOrgUnit?.id === ou.id ? 'gray.100' : undefined
                }
                _hover={{
                  background:
                    currentOrgUnit?.id === ou.id ? 'gray.100' : undefined
                }}
                cursor="pointer"
                justify={collapsed ? 'center' : 'left'}
                onClick={() => {
                  me && setOrganizationalUnit(me, ou)
                }}
                w={'100%'}
              >
                <UserDisplay
                  size={collapsed ? 'sm' : 'xs'}
                  name={ou.organization.name}
                />
                {!collapsed && (
                  <>
                    <VStack justify="left" align="left" spacing="0px">
                      <Box maxW="100px">
                        <Text fontSize="sm" isTruncated>
                          {ou.organization.name}
                        </Text>
                      </Box>
                      <Text fontWeight="bold" fontSize="xs">
                        {ou.organization.parent_platform_tenant_name}
                      </Text>
                    </VStack>
                  </>
                )}
              </HStack>
            ))}
          </VStack>
        )}
        {canViewMemberDashboard && (
          <Box marginTop="auto" marginBottom={2}>
            <Text mt="auto" textAlign="center">
              <StyledLink
                activeClassName="current"
                to={routeStrings.logout}
                style={{ paddingLeft: '0' }}
              >
                <CollapseIconComponent
                  label="Logout"
                  icon={<LogoutIcon mr="1" h="16px" w="16px" />}
                />
                <Label fontSize="xs">Logout</Label>
              </StyledLink>
            </Text>
          </Box>
        )}
      </Flex>
    </CollapsedContext.Provider>
  )
}

const Label: React.FC<BoxProps> = ({ children, ...rest }) => {
  const collapsed = useContext(CollapsedContext)

  return (
    <Box
      as="span"
      display={collapsed ? 'none' : 'contents'}
      className="link-text"
      {...rest}
    >
      {children}
    </Box>
  )
}

interface CollapseIconComponentProps {
  label: string
  icon: React.ReactElement
}

const CollapseIconComponent: React.FC<CollapseIconComponentProps> = ({
  label,
  icon
}) => {
  const collapsed = useContext(CollapsedContext)

  return icon
}
