import { stringify } from 'query-string'
import { useEffect, useState } from 'react'
import { Tag } from 'react-tag-autocomplete'
import useSWR, { ConfigInterface } from 'swr'
import { DateParam, encodeQueryParams } from 'use-query-params'
import { getInterests, getMyInvoices, getSkills, GetUserResponse } from '../api'
import { UserProfile } from '../interfaces'
import {
  DirectoryOrganizationResult,
  IAddonPlanChangeRequest,
  IBasicDirectoryMemberResult,
  IBillingAccountDetail,
  IBlogPost,
  IBooking,
  IBookingAdmin,
  ICity,
  IComment,
  ICommunityProfile,
  IConnection,
  IDailyFiveContent,
  IDailyFiveNotification,
  IFeatureFlag,
  IIndividualFeatureFlag,
  IInvoice,
  IInvoicePreview,
  ILocationAdmin,
  IMeetingCreditQuota,
  IMeetingRoom,
  IMeetingRoomSuperAdmin,
  INewMemberInvitationRecord,
  INewTeamInvitationRecord,
  INote,
  IOffice,
  IOfficeDetail,
  IOfficeListDetail,
  IOneTimeCharge,
  IOrganization,
  IOrganizationalUnitBasic,
  IOrganizationalUnitDetail,
  IOrganizationalUnitDirectoryPreferences,
  IOrganizationalUnitRequest,
  IOrganizationDetail,
  IOrganizationProfile,
  IOrganizationSeat,
  IOrganizationSeatDetail,
  IPaginatedInvoices,
  IPaginatedQuery,
  IPaginatedResults,
  IPlanChangeRequest,
  IPlatformTenant,
  IPlatformTenantTheme,
  IPublicMeetingRoom,
  ISimpleBooking,
  IWorkspace,
  IWorkspaceSuperAdmin,
  Location,
  OrganizationIndustry,
  Plan,
  Skill,
  _InvoiceSearchFilterProps
} from '../types'
import {
  IAccountCode,
  Interest,
  IPlatformIntegrationSettings,
  IXeroAccountCode,
  IXeroIntegrationSettings,
  PlanCategoryDetailed,
  TenantEftAccount
} from './../types'
import { invoiceMapping } from './invoiceMapping'
import {
  filterDateQueryParams,
  filterInvoicesQueryParams,
  filterMeetingRoomTimeline,
  filterMemberInvitationsQueryParams,
  filterOrgUnitBookingsQueryParams
} from './queryParams'

export function useTotalUnpaidInvoices(
  organization: number | undefined
): number | undefined {
  const [totalUnpaidInvoices, setTotalUnpaidInvoices] = useState<number>()

  useEffect(() => {
    if (organization) {
      getMyInvoices(organization).then(({ data: invoices }) => {
        const unpaidInvoices = invoices.filter((i) =>
          invoiceMapping.unpaid.includes(i.status)
        )
        const totalUnpaidAmount = unpaidInvoices.reduce<number>(
          (sum, invoice) => sum + parseInt(invoice.amount_charged, 10),
          0
        )
        setTotalUnpaidInvoices(totalUnpaidAmount)
      })
    }
  }, [organization])

  return totalUnpaidInvoices
}

export function useInvoicesForLocation(data: _InvoiceSearchFilterProps) {
  // (The django view will handle null locId/page)
  // spacecubed_backend/billing/views.py
  const params = encodeQueryParams(filterInvoicesQueryParams, {
    page: data.page,
    invoiceId: data.invoiceId,
    date: data.date,
    organization_filter: data.teamName,
    tenant: data.tenant,
    location: data.locId,
    status: data.status
  })
  return useSWR<IPaginatedInvoices>(
    params.tenant ? `/api/v1/invoices/?${stringify(params)}` : null,
    {
      revalidateOnFocus: false
    }
  )
}

export function useOrgInvoices(orgId?: number, tenant?: number, page?: number) {
  return useSWR<IPaginatedQuery<IInvoice>>(
    orgId && tenant
      ? `/api/v1/organizations/${orgId}/invoices/?tenant=${tenant}&page=${
          page ?? 1
        }`
      : null
  )
}
export const useOrgBillingAccounts = (organizationId?: number, options?) =>
  useSWR<IBillingAccountDetail[]>(
    organizationId
      ? `/api/v1/organizations/${organizationId}/get_billing_accounts/`
      : null,
    options
  )

export function useOrgOneTimeCharges(orgId?: number) {
  return useSWR<IOneTimeCharge[]>(
    orgId ? `/api/v1/one-time-charges/?organization=${orgId}` : null
  )
}

export function useInvoice(invoiceId?: number) {
  return useSWR<IInvoice>(
    invoiceId && invoiceId !== -1 ? `/api/v1/invoices/${invoiceId}/` : null
  )
}

export function useInvoiceHTML(invoiceId?: number, organizationId?: number) {
  return useSWR<string>(
    invoiceId && invoiceId !== -1
      ? `/api/v1/invoices/${invoiceId}/view/?organizationId=${organizationId}`
      : null
  )
}

export function useOrganizationInvoicePreview(orgId: number | undefined) {
  return useSWR<IInvoicePreview>(
    orgId ? `/api/v1/organizations/${orgId}/preview_invoice/` : null
  )
}

export function useOrganizationInvoicePreviewRendered(
  orgId: number | undefined
) {
  return useSWR<string>(
    orgId ? `/api/v1/organizations/${orgId}/preview_invoice/?render` : null,
    {
      refreshInterval: 20000,
      revalidateOnFocus: false
    }
  )
}

export function useOrganizationBookingCredits(orgId: number | undefined) {
  return useSWR<IMeetingCreditQuota>(
    orgId ? `/api/v1/organizations/${orgId}/remaining_quota/` : null
  )
}

export function useMemberNotes(memberId: number | undefined) {
  return useSWR<INote[]>(memberId ? `/api/v1/notes/?user=${memberId}` : null)
}

export function useUser(memberId: number | undefined) {
  return useSWR<GetUserResponse>(memberId ? `/api/v1/users/${memberId}/` : null)
}

export function useUserProfile() {
  const { data: rawData, revalidate } =
    useSWR<UserProfile[]>(`/api/v1/profile/`)
  const data = rawData && rawData[0]
  return { data, revalidate }
}

export function useCurrentOrganizationProfile(orgProfileId?: number) {
  return useSWR<IOrganizationProfile>(
    orgProfileId ? `/api/v1/organization-profile/${orgProfileId}/` : null
  )
}

export const useMyUserProfile = (profileId: number | undefined) => {
  return useSWR<UserProfile>(profileId ? `/api/v1/profile/${profileId}/` : null)
}

export function useBasicMeetingRooms(
  tenant?: number,
  options?: ConfigInterface<IMeetingRoomSuperAdmin[], any>
) {
  const { data, revalidate } = useSWR<IMeetingRoomSuperAdmin[]>(
    tenant ? `/api/v1/meeting-rooms/?tenant=${tenant}` : null,
    {
      refreshInterval: 0,
      revalidateOnFocus: false,
      ...options
    }
  )
  return { data, revalidate }
}
export const useMeetingRoomSlug = (room_slug?: string, options?: any) =>
  useSWR<IPublicMeetingRoom>(
    room_slug ? `/api/v1/meeting-rooms/${room_slug}/fetch_by_slug/` : null,
    {
      refreshInterval: 0,
      revalidateOnFocus: false,
      ...options
    }
  )

export function useBasicWorkspaces(
  tenantId,
  options?: ConfigInterface<IWorkspaceSuperAdmin[], any>
) {
  const { data, revalidate } = useSWR<IWorkspaceSuperAdmin[]>(
    tenantId ? `/api/v1/workspaces/?tenant=${tenantId}` : [],
    {
      refreshInterval: 0,
      revalidateOnFocus: false,
      ...options
    }
  )
  return { data: data ?? [], revalidate }
}

export function useMeetingRoom(meetingRoomId: number | undefined, options?) {
  return useSWR<IMeetingRoom>(
    meetingRoomId ? `/api/v1/meeting-rooms/${meetingRoomId}/` : null,
    options
  )
}

export function useMeetingRoomBookings(
  meetingRoomId: number | undefined,
  from?: Date,
  to?: Date,
  options?
) {
  return useSWR<ISimpleBooking[]>(
    meetingRoomId
      ? `/api/v1/meeting-rooms/${meetingRoomId}/bookings/?` +
          (from ? `from=${from.getTime()}` : '') +
          (to ? `&to=${to.getTime()}` : '')
      : null,
    options
  )
}

export function useOrganizationalUnitBookings(
  filter?: string,
  orgUnitId?: number,
  options?
) {
  const params = encodeQueryParams(filterOrgUnitBookingsQueryParams, {
    filter
  })

  return useSWR<IBooking[]>(
    orgUnitId
      ? `/api/v1/organizational-units/${orgUnitId}/bookings/?${stringify(
          params
        )}`
      : null,
    options
  )
}

export function useBookings<T extends IBookingAdmin[] | IBooking[]>(
  from?: Date,
  to?: Date,
  excludeCancelled?: boolean,
  fetchMeetingRooms?: boolean,
  fetchWorkspaces?: boolean,
  config?: ConfigInterface<T, any>
) {
  const params = encodeQueryParams(filterDateQueryParams, {
    from,
    to,
    excludeCancelled,
    fetchMeetingRooms,
    fetchWorkspaces
  })

  return useSWR<T>(`api/v1/bookings/?${stringify(params)}`, config)
}

//Used to fetch all bookings but only teh start and end times
export function usePublicBookings(
  from?: Date,
  to?: Date,
  config?: ConfigInterface<ISimpleBooking[], any>
) {
  const params = encodeQueryParams(filterDateQueryParams, {
    from,
    to
  })

  return useSWR<ISimpleBooking[]>(
    `api/v1/bookings/public_bookings/?${stringify(params)}`,
    config
  )
}

export const useListMeetingRoomsTimeline = (
  date: Date,
  location: number,
  organization: number,
  config?
) => {
  const params = encodeQueryParams(filterMeetingRoomTimeline, {
    date,
    location,
    organization
  })

  return useSWR<IMeetingRoom[]>(
    location && organization
      ? `api/v1/meeting-rooms-bookings/?${stringify(params)}`
      : null,
    config
  )
}

export const useListWorkspacesTimeline = (
  date: Date,
  location: number,
  config?
) => {
  const params = encodeQueryParams(filterMeetingRoomTimeline, {
    date,
    location
  })

  return useSWR<IWorkspace[]>(
    location ? `api/v1/workspaces-bookings/?${stringify(params)}` : null,
    config
  )
}

export function useWorkspaceTimeline(
  workspaceId: number | undefined,
  date: Date,
  options?
) {
  const params = encodeQueryParams({ date: DateParam }, { date })
  return useSWR<IWorkspace>(
    workspaceId && workspaceId > 0
      ? `/api/v1/workspaces-bookings/${workspaceId}/get_workspace_timeline?${stringify(
          params
        )}`
      : null,
    options
  )
}
export function useMeetingRoomTimeline(
  meetingRoomId: number | undefined,
  date: Date,
  options?
) {
  const params = encodeQueryParams({ date: DateParam }, { date })
  return useSWR<IMeetingRoom>(
    meetingRoomId && meetingRoomId > 0
      ? `/api/v1/meeting-rooms-bookings/${meetingRoomId}/get_meetingroom_timeline?${stringify(
          params
        )}`
      : null,
    options
  )
}

export function useBooking(bookingId?: string, options?) {
  return useSWR<IBooking>(
    bookingId ? `/api/v1/bookings/${bookingId}/` : null,
    options
  )
}

export function useOrganization(id: number | undefined) {
  return useSWR<IOrganizationDetail>(id ? `/api/v1/organizations/${id}/` : null)
}

export function useOrganizations(
  config?: ConfigInterface<IOrganization[], any>,
  tenantId?: number
) {
  return useSWR<IOrganization[]>(
    tenantId
      ? `/api/v1/organizations/?tenant=${tenantId}`
      : '/api/v1/organizations/',
    config
  )
}

export function useOrganizationalSeat(id: number | undefined) {
  return useSWR<IOrganizationSeatDetail>(
    id ? `/api/v1/organization-seats/${id}` : null
  )
}

export function useOrganizationalSeats(options?) {
  return useSWR<IOrganizationSeat[]>('/api/v1/organization-seats/', options)
}

export function useOrganizationalUnit(id: string | undefined) {
  return useSWR<IOrganizationalUnitDetail>(
    id ? `/api/v1/organizational-units/${id}/` : null
  )
}

export function useOrganizationalUnitsConnections(options?, tenantId?: number) {
  return useSWR<IOrganizationalUnitBasic[]>(
    tenantId
      ? `/api/v1/organizational-units/?detail=connections&tenant=${tenantId}`
      : '/api/v1/organizational-units/?detail=connections',
    options
  )
}

export function useAddonPlanChangeRequests(
  status?: string | undefined,
  tenantId?: number,
  options?
) {
  return useSWR<IAddonPlanChangeRequest[]>(
    tenantId
      ? 'api/v1/change-requests/list_addon_plan_requests/?type=addonplanrequest' +
          (status ? '&status=' + status : '') +
          `&tenant=${tenantId}`
      : null,
    options
  )
}

export function usePaginatedFilteredAddonPlanChangeRequests(
  tenantId?: number,
  data?: {
    organizationId?: number
    page?: number
  },
  options?
) {
  return useSWR<IPaginatedResults<IAddonPlanChangeRequest>>(
    tenantId
      ? 'api/v1/change-requests/list_addon_plan_requests/?type=addonplanrequest' +
          `&tenant=${tenantId}` +
          (data?.organizationId && data.organizationId > 0
            ? `&organization=${data.organizationId}`
            : '') +
          (data?.page ? `&page=${data.page}` : '')
      : null,
    options
  )
}
export function usePlanChangeRequests(
  status?: string | undefined,
  tenantId?: number,
  options?
) {
  return useSWR<IPlanChangeRequest[]>(
    tenantId
      ? 'api/v1/change-requests/list_plan_requests/?type=planrequest' +
          (status ? '&status=' + status : '') +
          `&tenant=${tenantId}`
      : null,
    options
  )
}

export function usePaginatedFilteredPlanChangeRequests(
  tenantId?: number,
  data?: {
    organizationId?: number
    page?: number
  },
  options?
) {
  return useSWR<IPaginatedResults<IPlanChangeRequest>>(
    tenantId
      ? 'api/v1/change-requests/list_plan_requests/?type=planrequest' +
          `&tenant=${tenantId}` +
          (data?.organizationId && data.organizationId > 0
            ? `&organization=${data.organizationId}`
            : '') +
          (data?.page ? `&page=${data.page}` : '')
      : null,
    options
  )
}

export const useMemberInvitations = (
  tenantId?: number,
  date_from?: Date,
  options?
) => {
  const params = encodeQueryParams(filterMemberInvitationsQueryParams, {
    tenant: tenantId,
    date_from: date_from
  })
  return useSWR<INewMemberInvitationRecord[]>(
    tenantId
      ? `/api/v1/invitations/fetch_members_invitations/?type=new_members&${stringify(
          params
        )}`
      : null
  )
}
export const useNewTeamMemberInvitations = (
  tenantId?: number,
  date_from?: Date,
  options?
) => {
  const params = encodeQueryParams(filterMemberInvitationsQueryParams, {
    tenant: tenantId,
    date_from: date_from
  })
  return useSWR<INewTeamInvitationRecord[]>(
    tenantId
      ? `/api/v1/invitations/fetch_members_invitations/?type=new_team_invitations&${stringify(
          params
        )}`
      : null
  )
}

export const usePlanCategories = (tenantId?: number) =>
  useSWR<PlanCategoryDetailed[]>(
    tenantId ? `/api/v1/platform-tenants/${tenantId}/plan_categories/` : null
  )

export function useNewOrgUnitRequests(
  status?: string | undefined,
  tenantId?: number,
  options?
) {
  return useSWR<IOrganizationalUnitRequest[]>(
    tenantId
      ? '/api/v1/change-requests/list_organization_units_requests/?type=organizationalunitrequest' +
          (status ? '&status=' + status : '') +
          `&tenant=${tenantId}`
      : null,
    options
  )
}

export function usePaginatedFilteredOrgUnitChangeRequests(
  tenantId?: number,
  data?: {
    organizationId?: number
    page?: number
  },
  options?
) {
  return useSWR<IPaginatedResults<IOrganizationalUnitRequest>>(
    tenantId
      ? '/api/v1/change-requests/list_organization_units_requests/?type=organizationalunitrequest' +
          `&tenant=${tenantId}` +
          (data?.organizationId && data.organizationId > 0
            ? `&organization=${data.organizationId}`
            : '') +
          (data?.page ? `&page=${data.page}` : '')
      : null,
    options
  )
}
export const useSkills = () =>
  useSWR<Skill[]>('/api/v1/skills/', {
    refreshInterval: 0,
    revalidateOnFocus: false
  })
export const useInterests = () =>
  useSWR<Interest[]>('/api/v1/interests/', {
    refreshInterval: 0,
    revalidateOnFocus: false
  })
export const useIndustries = () =>
  useSWR<OrganizationIndustry[]>('/api/v1/industries/', {
    refreshInterval: 0,
    revalidateOnFocus: false
  })

export function useSkillsAndInterests() {
  const [interests, setInterests] = useState<Tag[]>([])
  const [skills, setSkills] = useState<Tag[]>([])
  useEffect(() => {
    Promise.all([getSkills(), getInterests()]).then(
      ([{ data: skillData }, { data: interestData }]) => {
        setSkills(skillData)
        setInterests(interestData)
      }
    )
  }, [])

  return { skills, interests, setSkills, setInterests }
}

export function usePlans(tenantId?: number | undefined, isBasic?: boolean) {
  const { data, revalidate } = useSWR<Plan[]>(
    tenantId
      ? `/api/v1/plans/?detail=${isBasic ? 'basic' : ''}&tenant=${tenantId}`
      : null,
    {
      refreshInterval: 0,
      revalidateOnFocus: false
    }
  )
  return { plans: data ?? [], revalidate }
}

export function usePlansByLocation(locationId?: number) {
  return useSWR<Plan[]>(
    locationId ? `/api/v1/plans/?location=${locationId}` : null,
    {
      refreshInterval: 0,
      revalidateOnFocus: false
    }
  )
}

export function useLocations(): Location[] {
  const { data: locations } = useSWR<Location[]>('/api/v1/locations/', {
    refreshInterval: 0,
    revalidateOnFocus: false
  })

  return locations ? locations : []
}
export const useCitiesList = () =>
  useSWR<ICity[]>('/api/v1/cities/', {
    refreshInterval: 0,
    revalidateOnFocus: false
  })

export function useTenantLocations(tenantId: number | undefined) {
  const { data, revalidate } = useSWR<Location[]>(
    tenantId
      ? `/api/v1/locations/platform_tenant_locations/?tenantId=${tenantId}`
      : null,
    {
      refreshInterval: 0,
      revalidateOnFocus: false
    }
  )

  return { data: data ?? [], revalidate }
}

export function useSelfServeSignupLocations(tenant_id?: number) {
  return useSWR<Location[]>(
    tenant_id
      ? `/api/v1/locations/signup_locations/?tenant=${tenant_id}`
      : null,
    {
      refreshInterval: 0,
      revalidateOnFocus: false
    }
  )
}

export function useLocationAdmins(
  tenant_id?: number,
  location_id?: number,
  options?
) {
  return useSWR<ILocationAdmin[]>(
    tenant_id && location_id
      ? `/api/v1/locations-admins/?tenant_id=${tenant_id}&location_id=${location_id}`
      : null,
    options
  )
}

export function useFeatureFlagsAPI() {
  return useSWR<IFeatureFlag[]>('/api/v1/feature-flags/')
}

export function useIndividualFeatureFlagsAPI() {
  return useSWR<IIndividualFeatureFlag[]>('/api/v1/individual-feature-flags/')
}

export function useSpacecubedBlogPosts() {
  return useSWR<IBlogPost[]>('/api/v1/blog-posts/')
}

// QUICKFIX

// This approach is not intended to be scalable, we are using this method of querying blog posts for the core onboarding
// future scopes of work should build out the daily five/blog posts fully and this will need to change
export function useCoreBlogPosts() {
  return useSWR<IBlogPost[]>('/api/v1/blog-posts/core_blog/')
}

export function useOffices(
  tenant: number | undefined,
  available?: boolean,
  options?
) {
  // pass available = True to only retrieve available offices
  return useSWR<IOffice[]>(
    tenant
      ? `/api/v1/offices/?tenant=${tenant}` +
          (available ? '&available=True' : '')
      : null,
    options
  )
}

export function useOfficesDetailed(
  tenant: number | undefined,
  location: number | undefined,
  options?
) {
  // pass available = True to only retrieve available offices
  return useSWR<IOfficeListDetail[]>(
    tenant && location
      ? `/api/v1/offices/?tenant=${tenant}&location=${location}`
      : null,
    options
  )
}

export function useOffice(officeId: number, options?) {
  return useSWR<IOfficeDetail>(`/api/v1/offices/${officeId}/`, options)
}

export function useLatestConnection() {
  return useSWR<IConnection>(`/api/v1/daily-five/connections/latest/`)
}

export function useNewConnection() {
  return useSWR<IConnection | ''>(`/api/v1/daily-five/connections/new/`)
}

export function useLatestDailyFive(tenantId?: number) {
  return useSWR<IDailyFiveNotification>(
    tenantId
      ? `/api/v1/daily-five/notifications/latest/?tenant=${tenantId}`
      : null,
    { refreshInterval: 0, revalidateOnFocus: false }
  )
}
export function useLastSevenDailyFive(tenantId?: number) {
  return useSWR<IDailyFiveNotification[]>(
    tenantId
      ? `/api/v1/daily-five/notifications/last_seven/?tenant=${tenantId}`
      : null,
    { refreshInterval: 0, revalidateOnFocus: false }
  )
}
export function useCommunityProfile() {
  return useSWR<ICommunityProfile[]>('/api/v1/community-profile/')
}

export function useOrgUnitDirectoryPreferences(orgUnitId?: number) {
  return useSWR<IOrganizationalUnitDirectoryPreferences>(
    orgUnitId
      ? `/api/v1/organizational-units/${orgUnitId}/directory_preferences/`
      : null
  )
}

export function useOrganizationProfile(orgId?: number) {
  return useSWR<DirectoryOrganizationResult>(
    orgId
      ? `/api/v1/members-directory/${orgId}/get_organization_profile/`
      : null,
    { refreshInterval: 0, revalidateOnFocus: false }
  )
}

export const useLatestDirectoryConnections = (orgUnit?: number) => {
  return useSWR<IBasicDirectoryMemberResult[]>(
    orgUnit
      ? `/api/v1/members-directory/connections/get_latest_connections/?orgUnit=${orgUnit}`
      : null,
    { refreshInterval: 0, revalidateOnFocus: false }
  )
}

export function useScheduledTenantContent(tenantId: number | undefined) {
  return useSWR<IDailyFiveContent[]>(
    tenantId
      ? `/api/v1/daily-five/content/?scheduled=true&platform_tenant=${tenantId}`
      : null
  )
}
export function useDailyFiveContentContent(
  tenantId: number | undefined,
  active: boolean,
  approvalStatus: 'APPROVED' | 'NONE'
) {
  return useSWR<IDailyFiveContent[]>(
    `/api/v1/daily-five/content/?approval_status=${approvalStatus}&${
      tenantId ? 'platform_tenant=' + tenantId : 'platform_tenant__isnull=True'
    }&active_to_show_in_future_daily_five=${active}`,
    { refreshInterval: 0, revalidateOnFocus: false }
  )
}

export const useComments = (contentId?: number | undefined) => {
  return useSWR<IComment[]>(
    contentId ? `/api/v1/daily-five/content/${contentId}/comments/` : null
  )
}

export const useApiPlatformTenant = (tenantId?: number | undefined) => {
  return useSWR<IPlatformTenant>(
    tenantId ? `/api/v1/platform-tenants/${tenantId}/` : null,
    { refreshInterval: 0, revalidateOnFocus: false }
  )
}

export const useApiAdminPlatformTenantSettings = (
  tenantId?: number | undefined
) => {
  return useSWR<IPlatformIntegrationSettings>(
    tenantId
      ? `/api/v1/platform-tenants/${tenantId}/integration_details/`
      : null,
    { refreshInterval: 0, revalidateOnFocus: false }
  )
}

export const usePlatformTenantTheme = (
  tenantId?: number | undefined,
  locationId?: number
) => {
  return useSWR<IPlatformTenantTheme>(
    tenantId || locationId
      ? `/api/v1/platform-tenants/get_platform_tenant_theme/?platform_tenant_id=${
          tenantId ?? ''
        }${locationId ? '&location_id=' + locationId : ''}`
      : null,
    { refreshInterval: 0, revalidateOnFocus: false }
  )
}
export const useTenantEFTAccount = (tenantId: number | undefined) => {
  return useSWR<TenantEftAccount>(
    tenantId ? `/api/v1/platform-tenants/${tenantId}/eft_account/` : null,
    { refreshInterval: 0, revalidateOnFocus: false }
  )
}

export const useAccountCodesFromXero = (
  // use ALL to get everything except bank accounts
  accountType: 'BANK' | 'REVENUE' | 'ALL',
  tenantId?: number | undefined
) => {
  return useSWR<IXeroAccountCode[]>(
    tenantId
      ? `/api/v1/platform-tenants/${tenantId}/account_codes_from_xero/?type=${accountType}`
      : null,
    {
      refreshInterval: 0,
      revalidateOnFocus: false
    }
  )
}
export const useAccountCodes = (tenantId?: number | undefined) => {
  return useSWR<IAccountCode[]>(
    tenantId ? `/api/v1/platform-tenants/${tenantId}/account_codes/` : null,
    {
      refreshInterval: 0,
      revalidateOnFocus: false
    }
  )
}

export const useXeroIntegrationSettings = (tenantId?: number | undefined) => {
  return useSWR<IXeroIntegrationSettings>(
    tenantId
      ? `/api/v1/platform-tenants/${tenantId}/xero_integration_settings/`
      : null,
    {
      refreshInterval: 0,
      revalidateOnFocus: false
    }
  )
}
