import React, { useEffect, useState } from 'react'
import 'react-quill/dist/quill.snow.css'
import { useHistory, useLocation } from 'react-router'
import { AdminBodyWrapper } from '../../components/AdminBodyWrapper'
import { GroupingHeaderLayout } from '../../components/GroupingHeaderLayout'
import { useAppTourContextProvider } from '../../context/app-tour-context'
import { useAuth } from '../../context/auth-context'
import { useFeatureFlags } from '../../context/feature-flag-context'
import { usePlatformTenant } from '../../context/platform-tenant-context'
import { routeStrings } from '../../routeStrings'
import { CenteredSpinner } from '../../styled/CenteredSpinner'
import { tourTargetStrings } from '../../tourStepsArrays'
import { Roles, useRole } from '../../utils/rbac'
import { AdminDashboardPlatformTenantEmailSettings } from './AdminDashboardPlatformTenantEmailSettings'
import { AdminDashboardPlatformTenanIntegrationSettings } from './AdminDashboardPlatformTenantIntegrationSettings'
import { AdminDashboardPlatformTenantInvoiceSettings } from './AdminDashboardPlatformTenantInvoiceSettings'
import { AdminDashboardPlatformTenantPlatformSettings } from './AdminDashboardPlatformTenantPlatformSettings'
import { AdminDashboardPlatformTenantSignupSettings } from './AdminDashboardPlatformTenantSignupSettings'
import { LayoutAdminDashboard } from './LayoutAdminDashboard'

export const AdminDashboardPlatformTenantSettings: React.FC = () => {
  const { adminTourActive, setRun } = useAppTourContextProvider()
  useEffect(() => {
    if (adminTourActive) {
      setRun(true)
    }
  }, [])

  const { platformTenant } = usePlatformTenant()
  const [settingsType, setSettingsType] = useState('platform')
  const auth = useAuth()
  const { allowedRoles } = useRole()
  const history = useHistory()
  const { search } = useLocation()

  useEffect(() => {
    if (!allowedRoles.includes(Roles.SpaceSuperAdmin)) {
      history.push(routeStrings.adminDashboardMembers)
    }
  }, [allowedRoles])

  const settingsTypes = [
    {
      text: 'Platform Settings',
      id: 'platform',
      active: true,
      className: tourTargetStrings.adminheaderSettingsPlatform
    },
    {
      text: 'Sign-up Settings',
      id: 'signup',
      active: true,
      className: tourTargetStrings.adminheaderSettingsSignup
    },
    {
      text: 'Invoices Settings',
      id: 'invoices',
      active: true,
      className: tourTargetStrings.adminheaderSettingsInvoices
    },
    {
      text: 'Email Settings',
      id: 'email',
      active: true,
      className: tourTargetStrings.adminheaderSettingsEmail
    },
    {
      text: 'Integration Settings',
      id: 'integration',
      active: auth.currentOrgUnit?.is_space_super_admin,
      className: tourTargetStrings.adminheaderSettingsIntegration
    }
  ]

  useEffect(() => {
    if (search) {
      const params = new URLSearchParams(search)
      let tab = params.get('tab')
      let tabIndex = settingsTypes.findIndex((element) => element.id === tab)
      setSettingsType(settingsTypes[tabIndex < 0 ? 0 : tabIndex].id)
    } else {
      setSettingsType(settingsTypes[0].id)
    }
  }, [search])

  if (!platformTenant) {
    return <CenteredSpinner />
  }

  return (
    <LayoutAdminDashboard>
      <GroupingHeaderLayout
        basicTitle="Settings"
        tabs={settingsTypes
          .filter((setting) => setting.active)
          .map((report) => {
            return {
              text: report.text,
              active: settingsType === report.id,
              className: report.className,
              selectAction: () => {
                setSettingsType(report.id)
              }
            }
          })}
      />
      <AdminBodyWrapper>
        {settingsType === 'invoices' && (
          <AdminDashboardPlatformTenantInvoiceSettings />
        )}

        {settingsType === 'signup' && (
          <AdminDashboardPlatformTenantSignupSettings />
        )}

        {settingsType === 'email' && (
          <AdminDashboardPlatformTenantEmailSettings />
        )}

        {settingsType === 'platform' && (
          <AdminDashboardPlatformTenantPlatformSettings />
        )}

        {settingsType === 'integration' && (
          <AdminDashboardPlatformTenanIntegrationSettings />
        )}
      </AdminBodyWrapper>
    </LayoutAdminDashboard>
  )
}
