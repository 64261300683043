import { Box, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { IBillingAccountDetail } from '../types'
import { TableRow } from './TableRow'

export enum paymentMethods {
  CREDIT = 'CREDIT',
  DIRECT_DEBIT = 'DIRECT_DEBIT',
  EFT = 'EFT',
  INACTIVE = 'INACTIVE'
}

interface ICreditState {
  payment_type: string
  brand: string
  exp_month: string
  exp_year: string
  last4: string
  display_text: string
}
interface Props {
  billingAccount: IBillingAccountDetail
}

export const ExternalPaymentDetails: React.FC<Props> = (props) => {
  const { billingAccount } = props

  const [billingState, setBillingState] = useState<paymentMethods | null>(null)

  const [creditState, setCreditState] = useState<ICreditState | null>(null)

  useEffect(() => {
    if (billingAccount) {
      if (
        !billingAccount.payment_gateway ||
        !billingAccount.payment_gateway.active
      ) {
        setBillingState(paymentMethods.INACTIVE)
      } else {
        const currentPaymentMethod =
          billingAccount.payment_gateway.payment_method
        if (!currentPaymentMethod) return
        setBillingState(paymentMethods.CREDIT)
        setCreditState({
          brand: currentPaymentMethod.brand!,
          payment_type: currentPaymentMethod.payment_type!,
          exp_month: currentPaymentMethod.exp_month!,
          exp_year: currentPaymentMethod.exp_year!,
          last4: currentPaymentMethod.last4!,
          display_text: currentPaymentMethod.display_text
        })
      }
    }
  }, [billingAccount])

  return (
    <Box my="3" maxWidth="650px">
      <Box d="flex" mb={3} alignItems="center">
        <Text mb={0} fontWeight="semibold" color="headingPrimary" fontSize="lg">
          Payment details
        </Text>
      </Box>
      {/* Payment detail states */}
      {billingState === paymentMethods.CREDIT && creditState && (
        <>
          <TableRow py={2}>
            <Text
              color="textPrimary"
              fontWeight="bold"
              fontSize="sm"
              width={'33%'}
            >
              Credit Card
            </Text>
            <Text color="textPrimary" fontSize="sm" mr="auto">
              **** **** **** {creditState.last4}
            </Text>
            <Text color="textPrimary" fontSize="sm" pr={8}>
              {creditState.brand}
            </Text>
          </TableRow>
          <TableRow justifyContent="flex-start" py={2}>
            <Text
              color="textPrimary"
              fontWeight="bold"
              fontSize="sm"
              width={'33%'}
            >
              Expiry Date
            </Text>
            <Text color="textPrimary" fontSize="sm">
              {creditState.exp_month}/{creditState.exp_year}
            </Text>
            <Box />
          </TableRow>
        </>
      )}

      {billingState === paymentMethods.INACTIVE && (
        <>
          <TableRow justifyContent="space-between" py={2}>
            <Text
              color="textPrimary"
              fontWeight="bold"
              fontSize="sm"
              width={'33%'}
              mr="auto"
              mb={0}
            >
              Payment Method:
            </Text>
            <Text color="textPrimary" fontSize="sm" mb={0}>
              Not Configured
            </Text>
            <Box />
          </TableRow>
        </>
      )}
    </Box>
  )
}
