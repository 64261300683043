import { DragHandleIcon } from '@chakra-ui/icons'
import { Box, Flex, Stack, StackProps, Text } from '@chakra-ui/react'
import { format } from 'date-fns'
import React from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { MeetingRoomsIcon } from '../../../../components/icons/icons'
import { RouterLink } from '../../../../components/Link'
import { useAuth } from '../../../../context/auth-context'
import { useFeatureFlags } from '../../../../context/feature-flag-context'
import { routeStrings } from '../../../../routeStrings'
import { tourTargetStrings } from '../../../../tourStepsArrays'
import { IBooking } from '../../../../types'
import { useRBAC, Actions } from '../../../../utils/rbac'
import { OverviewBox } from './OverviewBoxBase'

interface Props extends StackProps {
  meetingsToday: IBooking[]
  creditsRemaining?: number
  daysUntilCreditsExpire?: number
  loading?: boolean
}

const formatToTimeString = (time: Date): string => format(time, 'h:mm a')

export const MeetingRoomOverview: React.FC<Props> = ({
  meetingsToday,
  creditsRemaining,
  daysUntilCreditsExpire,
  loading,
  ...rest
}) => {
  const { currentOrganization, me } = useAuth()
  const canBookMeetings = useRBAC(Actions.BookMeetingRooms)
  const canBookWorkspaces = useRBAC(Actions.BookWorkspaces)
  const canViewBilling = useRBAC(Actions.ViewBillingForOwnTeam)
  const { workspaceBookingEnabled } = useFeatureFlags()

  const mustUpdateBilling =
    !currentOrganization?.is_active_default_payment_method &&
    canViewBilling &&
    !me?.user.is_space_admin

  const meetingRoomMeetings = meetingsToday
    ? meetingsToday.reduce((value, booking) => {
        return value + (booking.meeting_room !== undefined ? 1 : 0)
      }, 0)
    : 0

  const workspaceMeetings = meetingsToday
    ? meetingsToday.reduce((value, booking) => {
        return value + (booking.workspace !== undefined ? 1 : 0)
      }, 0)
    : 0

  return (
    <SkeletonTheme color="#ffc17a" highlightColor="#e28f31">
      <OverviewBox
        bg="#FEF0E0"
        className={tourTargetStrings.dashboardBookings}
        header="Bookings"
        headerIcon={<MeetingRoomsIcon color="#e28f31" />}
        manageLink={
          canBookMeetings ? routeStrings.memberDashboardBookings : undefined
        }
        {...rest}
      >
        {canBookMeetings && (
          <Text>
            {loading !== true ? (
              `You have ${
                meetingRoomMeetings > 0 ? meetingsToday.length : 'no'
              } meeting${meetingRoomMeetings !== 1 ? 's' : ''} booked today`
            ) : (
              <Skeleton />
            )}
          </Text>
        )}
        {canBookWorkspaces && (
          <Text>
            {loading !== true ? (
              `You have ${
                workspaceMeetings > 0 ? workspaceMeetings : 'no'
              } workspace${workspaceMeetings !== 1 ? 's' : ''} booked today`
            ) : (
              <Skeleton />
            )}
          </Text>
        )}
        <Stack>
          {meetingsToday.map((meeting) => (
            <Flex
              key={meeting.id}
              alignContent="center"
              justifyContent="space-between"
              fontSize="sm"
            >
              <Flex flexDir="column" justifyContent="center">
                <Text fontWeight="bold">
                  {formatToTimeString(
                    new Date(Date.parse(meeting.start_time.toString()))
                  )}{' '}
                  -{' '}
                  {formatToTimeString(
                    new Date(Date.parse(meeting.end_time.toString()))
                  )}
                </Text>
                <Text>
                  {meeting.meeting_room_name || meeting.workspace_name}
                </Text>
              </Flex>
              <Box
                flexShrink={0}
                backgroundImage={`url(${meeting.meeting_room_display_url})`}
                w="48px"
                h="48px"
                backgroundSize="cover"
                justify="center"
                backgroundPosition="center"
                alignItems="flex-end"
              />
            </Flex>
          ))}
        </Stack>
        {canBookMeetings && (
          <RouterLink
            fontSize="sm"
            color="#255CE1"
            to={routeStrings.memberDashboardMeetingRooms}
          >
            <MeetingRoomsIcon mr="2" h="12px" w="12px" />
            Book a room
          </RouterLink>
        )}
        {workspaceBookingEnabled && canBookWorkspaces && !mustUpdateBilling && (
          <RouterLink
            fontSize="sm"
            color="#255CE1"
            to={routeStrings.memberDashboardWorkspaces}
          >
            <DragHandleIcon mr="2" h="12px" w="12px" />
            Book a workspace
          </RouterLink>
        )}
        {mustUpdateBilling && (
          <RouterLink
            fontSize="sm"
            color="#255CE1"
            to={routeStrings.memberDashboardBilling}
          >
            Update Payment Info
          </RouterLink>
        )}

        {creditsRemaining && (
          <Flex alignContent="center" justifyContent="space-between">
            <Flex flexDir="column" justifyContent="center">
              <Text fontSize="sm">Credits</Text>
              {daysUntilCreditsExpire && (
                <Text fontSize="xs" color="#757575">
                  {daysUntilCreditsExpire > 0
                    ? `Expire in ${daysUntilCreditsExpire} days`
                    : 'Expire today'}
                </Text>
              )}
            </Flex>
            <Text>{creditsRemaining}</Text>
          </Flex>
        )}
      </OverviewBox>
    </SkeletonTheme>
  )
}
