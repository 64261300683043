import React from 'react'
import { Button as BaseButton, ButtonProps } from 'rebass'
// import { css } from '@styled-system/css'
import { css } from '@emotion/core'
import { useTheme } from '@chakra-ui/react'
import { useHistory } from 'react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

export const BackButton: React.FC<ButtonProps> = (props) => {
  const { ...rest } = props

  const theme: any = useTheme()

  const history: any = useHistory()

  const BackButtonStyles = css`
    background: none;
    color: ${theme.colors.eastBay04};
    transition: all 0.2s ease;
    display: inline;
    &:hover {
      cursor: pointer;
      transform: scale(1.008);
      color: ${theme.colors.primary};
      & > svg {
        opacity: 1;
      }
    }
  ` as any

  return (
    <BaseButton
      {...rest}
      css={BackButtonStyles}
      onClick={() => history && history.goBack()}
    >
      <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: '5' }} />
      {props.children}
    </BaseButton>
  )
}
