import { Box, Divider, Flex, Stack, Text, useToast } from '@chakra-ui/react'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import React, { useState } from 'react'
import { isValidPhoneNumber } from 'react-phone-number-input'
import ReactTags from 'react-tag-autocomplete'
import {
  deleteProfilePicture,
  updateOrgUnitJobTitle,
  updateProfilePicture,
  updateUserDetails
} from '../../../api'
import { PrimarySpinner } from '../../../components/chakra-v2/PrimarySpinner'
import { FormErrorMessage } from '../../../components/FormErrorMessage'
import { FormInput } from '../../../components/FormInput'
import { ProfilePictureUpdateModal } from '../../../components/modals/ProfilePictureUpdateModal'
import { TextAreaInput } from '../../../components/TextAreaInput'
import { useAuth } from '../../../context/auth-context'
import { OnboardingUser } from '../../../interfaces'
import { InputWarning } from '../../../styled/InputWarning'
import { Phone } from '../../../styled/Phone'
import {
  useMyUserProfile,
  useSkillsAndInterests
} from '../../../utils/apiHooks'

import { UserDisplay } from '../../../components/UserDisplay'
import { Button } from '../../../components/Button'
import { IS_VALID_HTTP_URL } from '../../../utils/formUtils'
import { SettingsItem } from '../../../components/SettingsItems'
import { EditableInputText } from '../../../components/EditableInputText'
import { EMAIL_REGEX } from '../../../utils/constants'

export const EditProfile: React.FC = () => {
  const { me, currentOrgUnit } = useAuth()
  const { data: userProfile, revalidate } = useMyUserProfile(
    me?.user.user_profile.id
  )
  const toast = useToast()
  const { skills, interests } = useSkillsAndInterests()

  const [
    updateProfilePictureModalIsVisible,
    setUpdateProfilePictureModalIsVisible
  ] = useState<boolean>(false)

  if (!userProfile || !me || !currentOrgUnit) {
    return <PrimarySpinner />
  }

  return (
    <Box my="3" maxWidth="650px">
      <Flex mb={3} flexDir="column">
        <Text mb={3} fontWeight="semibold" color="headingPrimary" fontSize="lg">
          Your details
        </Text>
        <UserDisplay
          mb={3}
          cursor="pointer"
          onClick={() => {
            setUpdateProfilePictureModalIsVisible(true)
          }}
          size="2xl"
          src={userProfile.profile_picture?.image}
          name={me?.user.name}
        />
        <Text mb={3}>Update profile picture</Text>

        <Flex
          bg="white"
          w="100%"
          boxShadow="0px 1px 0px rgba(0, 0, 0, 0.05)"
          rounded="md"
          px={8}
          py={5}
          position="relative"
          color="eastBay09"
          align="center"
          justifyContent="space-between"
        >
          <Formik
            initialValues={{
              skills: userProfile?.skills || [],
              interests: userProfile?.interests || [],
              aboutYou: userProfile?.about_you,
              mobileNumber: userProfile?.mobile_number,
              personalEmail: userProfile?.personal_email || '',
              linkedin_url: userProfile?.linkedin_url || '',
              postcode: userProfile?.postcode?.toString()
            }}
            validate={(values): OnboardingUser => {
              const errors: any = {}

              if (values.skills.length === 0) {
                errors.skills = 'Please add at least one Skill to your profile'
              }
              if (values.interests.length === 0) {
                errors.postCode =
                  'Please add at least one Interest to your profile'
              }
              // if (!values.aboutYou) {
              //   errors.aboutYou = 'Required'
              // }
              if (!values.mobileNumber) {
                errors.mobileNumber = 'Mobile Number Required'
              } else {
                if (!isValidPhoneNumber(values.mobileNumber)) {
                  errors.mobileNumber = 'Invalid phone number'
                }
              }
              if (
                values.linkedin_url &&
                !IS_VALID_HTTP_URL(values.linkedin_url)
              ) {
                errors.linkedin_url = 'Invalid URL'
              }

              if (!values.personalEmail) {
                errors.personalEmail = 'Personal Email Required'
              } else if (!EMAIL_REGEX.test(values.personalEmail)) {
                errors.personalEmail = 'Invalid email address'
              }
              if (!values.postcode) {
                errors.postcode = 'Postcode Required'
              }

              return errors
            }}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true)
              const token = window.localStorage.getItem('Token')
              return updateUserDetails(
                {
                  about_you: values!.aboutYou,
                  mobile_number: values!.mobileNumber,
                  postcode: values!.postcode,
                  personal_email: values!.personalEmail.toLowerCase(),
                  interests: values!.interests,
                  linkedin_url: values!.linkedin_url,
                  skills: values!.skills
                },
                { token }
              )
                .then(() => {
                  setSubmitting(false)
                  toast({ description: 'Profile updated', status: 'success' })
                  revalidate()
                })
                .catch(({ data }) => {
                  setSubmitting(false)
                  toast({
                    description: 'Failed to update profile',
                    status: 'error'
                  })
                })
            }}
          >
            {({ values, setFieldValue, isSubmitting, touched, errors }) => (
              <Form>
                <Text fontWeight={'bold'} fontSize="md" mb={3}>
                  {me?.user.name}
                </Text>
                <Divider mb={3} />
                <SettingsItem
                  title="Job Title"
                  description={'Your current role'}
                >
                  <EditableInputText
                    maxLength={50}
                    defaultValue={currentOrgUnit.job_title ?? ''}
                    onSubmitValue={(newValue: string) => {
                      updateOrgUnitJobTitle(currentOrgUnit.id, newValue).then(
                        (res) => {}
                      )
                    }}
                    isUpdating={false}
                  />
                </SettingsItem>
                <Divider mb={3} />
                <Stack spacing={8}>
                  <Box mb={5}>
                    <Field
                      name="mobileNumber"
                      label="Mobile Number"
                      component={Phone}
                      isInvalid={
                        touched.mobileNumber &&
                        errors.mobileNumber !== undefined
                      }
                    />
                    <ErrorMessage
                      name="mobileNumber"
                      component={FormErrorMessage}
                    />
                  </Box>
                  <Box mb={5}>
                    <Field
                      name="linkedin_url"
                      label="LinkedIn Profile"
                      component={FormInput}
                      maxLength={200}
                      isInvalid={
                        touched.linkedin_url &&
                        errors.linkedin_url !== undefined
                      }
                    />
                    <ErrorMessage
                      name="linkedin_url"
                      component={FormErrorMessage}
                    />
                  </Box>
                  <Box mb={5}>
                    <Field
                      name="personalEmail"
                      label="Personal/Recovery Email"
                      component={FormInput}
                      isInvalid={
                        touched.mobileNumber &&
                        errors.mobileNumber !== undefined
                      }
                    />
                    <ErrorMessage
                      name="personalEmail"
                      component={FormErrorMessage}
                    />
                  </Box>
                  <Box mb={5}>
                    <Field
                      name="postcode"
                      label="Postcode"
                      component={FormInput}
                      maxLength={6}
                      isInvalid={
                        touched.postcode && errors.postcode !== undefined
                      }
                    />
                    <ErrorMessage
                      name="postcode"
                      component={FormErrorMessage}
                    />
                  </Box>

                  <Box mb={5}>
                    <Text fontWeight="bold" fontSize="sm" mb={1}>
                      Skills
                    </Text>
                    <ReactTags
                      tags={values.skills}
                      suggestions={skills}
                      minQueryLength={0}
                      maxSuggestionsLength={999}
                      autofocus={false}
                      placeholder="Choose some skills"
                      handleDelete={(interest: any) => {
                        const tags = values.skills.slice(0)
                        tags.splice(interest, 1)
                        setFieldValue('skills', tags)
                      }}
                      handleAddition={(skill: any) => {
                        setFieldValue('skills', [...values.skills, skill])
                      }}
                    />
                    <ErrorMessage name="skills" component={InputWarning} />
                  </Box>
                  <Box mb={5}>
                    <Text fontWeight="bold" fontSize="sm" mb={1}>
                      Interests
                    </Text>
                    <ReactTags
                      tags={values.interests || []}
                      suggestions={interests}
                      minQueryLength={0}
                      maxSuggestionsLength={999}
                      autofocus={false}
                      handleDelete={(interest: any) => {
                        const tags = values.interests.slice(0)
                        tags.splice(interest, 1)
                        setFieldValue('interests', tags)
                      }}
                      handleAddition={(interest: any) => {
                        setFieldValue('interests', [
                          ...values.interests,
                          interest
                        ])
                      }}
                    />
                    <ErrorMessage name="interests" component={InputWarning} />
                  </Box>
                  <Box mb={5}>
                    <Field
                      name="aboutYou"
                      label="About You"
                      component={TextAreaInput}
                      isInvalid={
                        touched.aboutYou && errors.aboutYou !== undefined
                      }
                    />
                    <ErrorMessage name="aboutYou" component={InputWarning} />
                  </Box>
                  <Box>
                    <Button type="submit" disabled={isSubmitting}>
                      Update details
                    </Button>
                  </Box>
                </Stack>
              </Form>
            )}
          </Formik>
        </Flex>
      </Flex>

      {/* TODO add user profile revalidate to modal props */}
      {updateProfilePictureModalIsVisible && me?.user.user_profile.id && (
        <ProfilePictureUpdateModal
          initialURL={userProfile.profile_picture?.image ?? ''}
          revalidate={revalidate}
          handleSave={(image) => {
            return updateProfilePicture({ image }, userProfile.id)
              .then((res) => {
                revalidate()
                setUpdateProfilePictureModalIsVisible(false)
                return 'Image succesfully updated'
              })
              .catch((error) => {
                return 'Something went wrong, failed to update'
              })
          }}
          handleDelete={() => {
            const handleDelete = () => {
              deleteProfilePicture(userProfile.id)
              revalidate()
            }
          }}
          visibility={{
            modalIsVisible: updateProfilePictureModalIsVisible,
            setModalIsVisible: setUpdateProfilePictureModalIsVisible
          }}
        />
      )}
    </Box>
  )
}
