import {
  Box,
  Flex,
  Link,
  Stack,
  Tag,
  Text,
  toast,
  useToast
} from '@chakra-ui/react'
import { format } from 'date-fns'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  createNewUser,
  getTermsAndConditions,
  sendEmailForApproval
} from '../../api'
import { PrimaryButton } from '../../components/chakra-v2/PrimaryButton'
import { FormErrorMessage } from '../../components/FormErrorMessage'
import { FormInput } from '../../components/FormInput'
import { routeStrings } from '../../routeStrings'
import {
  SinglePageSignupComponent,
  useUserDetails
} from '../../context/user-onboard-context'
import { analyticsIdentify, analyticsTrack } from '../../utils/analytics'
import { OnboardingHeading } from './OnboardingComponents'
import {
  FERN_ALLENDALE_PLAN_UUID,
  ILUMA_PLAN_UUID
} from '../../utils/constants'
import { usePlatformTenant } from '../../context/platform-tenant-context'

export const OnboardingAcceptTC: React.FC<SinglePageSignupComponent> = (
  props
) => {
  const { onCompleteCallback, isComplete, isActive, setIsActive } = props

  const history = useHistory()
  const { userDetails } = useUserDetails()
  const { platformTheme } = usePlatformTenant()
  const [tcId, setTcId] = useState(0)
  const [formErrorMessage, setFormErrorMessage] = useState({})
  const toast = useToast()
  useEffect(() => {
    getTermsAndConditions().then(({ data }: any) => setTcId(data[0].id))
  }, [])

  const isAllendalePlan = FERN_ALLENDALE_PLAN_UUID === userDetails.plan
  const isIlumaPlan = ILUMA_PLAN_UUID === userDetails.plan
  const tenantName = platformTheme ? platformTheme.business_name : 'Spacecubed'
  return (
    <Stack
      align="center"
      spacing={8}
      ml="auto"
      mr="auto"
      w="450px"
      onClick={setIsActive}
    >
      <OnboardingHeading size={'md'} color={isActive ? 'gray.800' : 'gray.400'}>
        Set your password
      </OnboardingHeading>
      <Formik
        initialValues={{ password: '' }}
        validate={(values) => {
          const errors: any = {}
          if (!values.password) {
            errors.password = 'Required'
            // setPasswordErrorMessage(errors.password)
          }
          if (/^\d+$/.test(values.password)) {
            errors.password = "Your password can't be entirely numeric."
            // setPasswordErrorMessage(errors.password)
          }
          if (values.password.length > 0 && values.password.length < 8) {
            errors.password =
              'This password is too short. It must contain at least 8 characters.'
            // setPasswordErrorMessage(errors.password)
          } else {
            // setPasswordErrorMessage('')
          }
          return errors
        }}
        onSubmit={(values, { setSubmitting }) => {
          if (!isComplete) {
            toast({
              title: 'Error',
              description: 'Please, confirm your personal details',
              status: 'error',
              isClosable: true
            })
            setSubmitting(false)
            return
          }

          if (!userDetails.plan) {
            toast({
              title: 'Error',
              description:
                'Please, go back and select a valid plan / membership.',
              status: 'error',
              isClosable: true
            })
            setSubmitting(false)
            return
          }

          setSubmitting(true)
          const plan = userDetails.plan
          const startDate = format(userDetails!.startDate!, 'yyyy-MM-dd')

          // gather all required details
          createNewUser({
            email: userDetails.email,
            username: userDetails.email,
            password1: values.password,
            password2: values.password,
            first_name: userDetails!.firstName,
            last_name: userDetails!.lastName,
            postcode: userDetails!.postcode,
            mobile_number: userDetails!.mobileNumber,
            start_date: startDate,
            agreed_tc: tcId,
            location_id: userDetails.location_id,
            plan
          })
            .then(({ data: { key, userId } }) => {
              window.localStorage.setItem('Token', key)

              analyticsIdentify(String(userId), {
                email: userDetails!.email,
                name: userDetails!.firstName + userDetails!.lastName
              })
              analyticsTrack('Signed Up', { plan })

              sendEmailForApproval({ token: key })
            })
            .then(() => {
              setSubmitting(false)
              history.push(routeStrings.thankyou)
            })
            .catch((e) => {
              setSubmitting(false)
              setFormErrorMessage(e.response.data)
            })
        }}
      >
        {({ isSubmitting, errors, touched }) => (
          <Form>
            <Flex direction="column" align="center">
              <Box mb={5} w={'350px'}>
                <Field
                  type="password"
                  name="password"
                  isInvalid={
                    isActive &&
                    touched.password &&
                    errors.password !== undefined
                  }
                  data-testid="onboarding-password-field"
                  placeholder="Password"
                  component={FormInput}
                />
                <Text fontSize={10} my={1}>
                  It must have at least 8 characters and must include letters
                  and numbers.
                </Text>
                <ErrorMessage name="password" component={FormErrorMessage} />
              </Box>

              {/* Form error message */}
              {Object.keys(formErrorMessage).length > 0 && (
                <Text>Please check the following fields</Text>
              )}

              {Object.entries(formErrorMessage).map((error) => {
                let errorFields = ''

                try {
                  errorFields += 'Invalid ' + error[0] + ': ' + error[1]
                } catch (err) {}
                return (
                  <Text color="red.500" fontWeight="medium" mb={2}>
                    {errorFields.slice(0, -1)}
                  </Text>
                )
              })}
              <PrimaryButton
                type="submit"
                px={6}
                flexGrow={0}
                loadingText="Signing up"
                isLoading={isSubmitting}
                data-testid="onboarding-accept-tcs-next"
                // Button is disabled if form is submitting or error message exists
                isDisabled={
                  (!isComplete && isSubmitting) || errors.password !== undefined
                }
              >
                {isAllendalePlan || isIlumaPlan
                  ? 'Sign up'
                  : 'Start your membership'}
              </PrimaryButton>
              <Text mt={4} maxW="350px" fontSize="xs" textAlign="center">
                By pressing{' '}
                {isAllendalePlan || isIlumaPlan
                  ? 'Sign up'
                  : 'Start your membership'}
                , you are agreeing to the{' '}
                <Link
                  color="blue"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={routeStrings.termsAndConditions}
                >
                  Terms and Conditions{' '}
                </Link>
                {isAllendalePlan || isIlumaPlan
                  ? '.'
                  : `and, upon approval from ${tenantName}, your membership will be charged from your selected start date.`}
              </Text>
            </Flex>
          </Form>
        )}
      </Formik>
    </Stack>
  )
}
