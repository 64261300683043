import { BoxProps, Center, Heading } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import {
  IMeetingRoom,
  IInterval,
  IBooking,
  ISimpleBooking,
  ILocationBusinessHours
} from '../types'
import TimeRange from 'react-timeline-range-slider'
import {
  set,
  isToday,
  isAfter,
  areIntervalsOverlapping,
  roundToNearestMinutes,
  format,
  subSeconds,
  addSeconds,
  isEqual,
  isWithinInterval
} from 'date-fns'
import isBefore from 'date-fns/isBefore'
import { castHoursToDate } from '../pages/MemberDashboard/MeetingRooms/meetingRoomUtils'
import { utcToZonedTime } from 'date-fns-tz'

interface Props extends BoxProps {
  meetingRoom: IMeetingRoom
  chosenDate: Date
  setTimeSlot: (timeSlot: IInterval) => void
  setError: (error: boolean) => void
  existingBooking: IBooking | undefined
  unavailable?: boolean
  businessHours?: ILocationBusinessHours
  isExternal?: boolean
}

export const MeetingRoomTimeline: React.FC<Props> = ({
  meetingRoom,
  chosenDate,
  unavailable,
  existingBooking,
  setTimeSlot,
  businessHours,
  isExternal,
  setError,
  ...rest
}) => {
  const getTodayAtSpecificHour = (hour = 12) =>
    set(chosenDate, { hours: hour, minutes: 0, seconds: 0, milliseconds: 0 })
  const [isError, setIsError] = useState<boolean>(false)

  const [selectedInterval, setSelectedInterval] = useState<[Date, Date]>([
    chosenDate,
    chosenDate
  ])
  const startTime =
    isExternal && businessHours
      ? castHoursToDate(businessHours.open, chosenDate)
      : getTodayAtSpecificHour(7)
  const endTime =
    isExternal && businessHours
      ? castHoursToDate(businessHours.close, chosenDate)
      : getTodayAtSpecificHour(23)

  const [disabledIntervals, setDisabledIntervals] = useState<IInterval[]>()

  // Calculates Disabled intervals
  useEffect(() => {
    let interval: IInterval[] = []
    if (isExternal && !businessHours) {
      interval.push({
        start: set(chosenDate, {
          hours: 7,
          minutes: 0,
          seconds: 0,
          milliseconds: 0
        }),
        end: set(chosenDate, {
          hours: 24,
          minutes: 0,
          seconds: 0,
          milliseconds: 0
        })
      })
    }

    if (isToday(chosenDate)) {
      let earlyToday = set(chosenDate, { hours: 0, minutes: 0 })
      interval.push({
        start: earlyToday,
        end: roundToNearestMinutes(
          utcToZonedTime(new Date(), meetingRoom.timezone),
          { nearestTo: 15 }
        )
      })
    }
    // If it is a rescheduling, ignores booking from the invalid intervals
    // Displays originally selected booking times
    if (existingBooking) {
      meetingRoom.bookings = meetingRoom.bookings.filter(
        (b) => b.id != existingBooking.id
      )
      setSelectedInterval([
        utcToZonedTime(
          new Date(Date.parse(existingBooking.start_time)),
          meetingRoom.timezone
        ),
        utcToZonedTime(
          new Date(Date.parse(existingBooking.end_time)),
          meetingRoom.timezone
        )
      ])
    }
    let bookings: IInterval[] = meetingRoom.bookings.map((b) => {
      return {
        start: utcToZonedTime(new Date(b.start_time), meetingRoom.timezone),
        end: utcToZonedTime(new Date(b.end_time), meetingRoom.timezone)
      } as IInterval
    })

    try {
      bookings = mergeIntervals(0, bookings, interval)
    } catch (error) {
      console.log(error)
    }
    bookings = bookings.map((b) => {
      b.start = addSeconds(b.start, 1)
      b.end = subSeconds(b.end, 1)
      return b
    })
    if (isToday(chosenDate)) {
      bookings = bookings.filter((b) => isAfter(b.start, chosenDate))
    }
    interval = interval.concat(bookings)
    setDisabledIntervals(interval)
  }, [chosenDate, meetingRoom])

  const mergeIntervals = (
    index: number,
    intervals: IInterval[],
    result: IInterval[]
  ) => {
    if (intervals.length === index) {
      return result
    }
    if (result.length === 0) {
      result.push(intervals[index])
      index += 1
      return mergeIntervals(index, intervals, result)
    }
    let i1 = result[result.length - 1]
    let i2 = intervals[index]

    if (isEqual(i1.end, i2.start)) {
      result[result.length - 1] = getInterval(i1.start, i2.end)
    } else if (isAfter(i1.end, i2.start) && isBefore(i1.end, i2.end)) {
      result[result.length - 1] = getInterval(i1.start, i2.end)
    } else if (isWithinInterval(i2.end, i1)) {
      // Skip
    } else {
      result.push(i2)
    }
    index += 1
    return mergeIntervals(index, intervals, result)
  }

  const getInterval = (start: Date, end: Date) => {
    try {
      return { start, end } as IInterval
    } catch (error) {}
    return { start: chosenDate, end: chosenDate } as IInterval
  }

  const onChangeCallback = (selectedInterval) => {
    if (isError) return
    if (isEqual(selectedInterval[0], selectedInterval[1])) return

    if (
      isNaN(selectedInterval[0].getTime()) ||
      isNaN(selectedInterval[1].getTime())
    )
      return

    let interval = getInterval(
      selectedInterval[0],
      roundToNearestMinutes(selectedInterval[1], { nearestTo: 15 })
    )
    if (disabledIntervals) {
      disabledIntervals.forEach((i) => {
        try {
          if (areIntervalsOverlapping(getInterval(i.start, i.end), interval)) {
            return
          }
        } catch (e) {
          console.log(e)
          return
        }
      })
    }

    setTimeSlot(interval)
    setSelectedInterval(selectedInterval)
  }
  const onUpdateCallback = (value) => {
    // console.log(value)
    setIsError(value.error)
    setError(value.error)
  }

  if (isExternal && (!businessHours || !businessHours.open)) {
    return (
      <Center borderWidth={1} borderColor="gray.200" borderRadius={'lg'} py={3}>
        <Heading color="#ccc">Closed on this day </Heading>
      </Center>
    )
  }

  return (
    <TimeRange
      error={isError}
      containerClassName={'TimeRange'}
      ticksNumber={15}
      formatTick={(ms) => format(ms, 'h aaa')}
      selectedInterval={selectedInterval}
      timelineInterval={[startTime, endTime]}
      onUpdateCallback={onUpdateCallback}
      onChangeCallback={onChangeCallback}
      disabledIntervals={disabledIntervals}
      mode={1}
      step={900000}
    />
  )
}
