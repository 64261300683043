import {
  Box,
  BoxProps,
  Text,
  Spacer,
  VStack,
  Divider,
  Button as ChakraButton,
  Flex,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  useTheme
} from '@chakra-ui/react'
import { differenceInSeconds, format, isEqual } from 'date-fns'
import { stringify } from 'query-string'
import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { encodeQueryParams } from 'use-query-params'
import {
  bookMeetingRoom,
  previewBookingCost,
  previewRescheduleBookingCost,
  rescheduleMeetingRoom
} from '../api'
import { useAuth } from '../context/auth-context'
import { useBookingContext } from '../context/BookingContext'
import { useCustomToast } from '../context/toast-context'
import {
  isSameInterval,
  secondsToTimeString
} from '../pages/MemberDashboard/Workspaces/meetingRoomUtils'
import { CenteredSpinner } from '../styled/CenteredSpinner'
import {
  IWorkspace,
  IInterval,
  IBooking,
  ILocationOpeningHours,
  ISimpleBooking
} from '../types'
import { viewBookingQueryParams } from '../utils/queryParams'
import { Button } from './Button'
import { WorkspaceSmallCard } from './WorkspaceSmallCard'
import { WorkspaceTimeline } from './WorkspaceTimeline'

interface Props extends BoxProps {
  workspace: IWorkspace
  chosenDate: Date
  openingHours: ILocationOpeningHours | undefined
  booking?: IBooking
  unavailable?: boolean
  otherBookings?: ISimpleBooking[] | undefined
  bookingConfirmed: (success: boolean, data: any) => void
}

export const WorkspaceTimeLineCard: React.FC<Props> = ({
  workspace,
  chosenDate,
  unavailable,
  booking,
  openingHours,
  otherBookings,
  bookingConfirmed,
  ...rest
}) => {
  const me = useAuth()
  const history = useHistory()
  const theme: any = useTheme()
  const { newToast: toast } = useCustomToast()
  const { isBooking, setIsBooking, revalOrgUnitBookings } = useBookingContext()

  const [isCalculating, setIsCalculating] = useState<boolean>(false)
  const [isError, setIsError] = useState<boolean>(false)

  const isEdition = booking != undefined

  const [bookingCost, setBookingCost] = useState<number>()

  const [bookingTimes, setBookingTimes] = useState<IInterval | undefined>()

  const selectTimes = (timeSlot: IInterval | undefined, session: string) => {
    if (isCalculating) return
    setBookingTimes(timeSlot)
    if (!timeSlot || !me.currentOrgUnit) return
    if (me && me.me && me.me.user.is_space_admin) return

    setIsCalculating(true)
    let promise =
      booking != undefined
        ? previewRescheduleBookingCost(booking.id, {
            workspace: workspace.id,
            session,
            organizational_unit: me?.currentOrgUnit.id,
            start_time: timeSlot.start.toISOString(),
            end_time: timeSlot.end.toISOString()
          })
        : previewBookingCost({
            workspace: workspace.id,
            session,
            organizational_unit: me?.currentOrgUnit.id,
            start_time: timeSlot.start.toISOString(),
            end_time: timeSlot.end.toISOString()
          })
    promise
      .then((cost) => {
        if (cost.data) {
          let charges = cost.data.charges
          setBookingCost(parseInt(charges ?? '0'))
          setIsCalculating(false)
          revalOrgUnitBookings()
        }
      })
      .catch((err) => {
        setIsCalculating(false)
      })
  }

  const makeBooking = async () => {
    if (!bookingTimes || !me || !me.currentOrgUnit) return
    if (booking != undefined) {
      if (isSameInterval(bookingTimes, booking)) {
        toast({
          description: 'Values are the same',
          status: 'info'
        })
        return
      }
    }

    setIsBooking(true)
    try {
      let promise =
        booking != undefined
          ? await rescheduleMeetingRoom(booking.id, {
              workspace: workspace.id,
              organizational_unit: me?.currentOrgUnit.id,
              start_time: bookingTimes.start.toISOString(),
              end_time: bookingTimes.end.toISOString()
            })
          : await bookMeetingRoom({
              workspace: workspace.id,
              organizational_unit: me?.currentOrgUnit.id,
              start_time: bookingTimes.start.toISOString(),
              end_time: bookingTimes.end.toISOString()
            })
      let bookingResponse = promise.data as IBooking
      if (!isEdition) {
        const params = encodeQueryParams(viewBookingQueryParams, {
          newBooking: true
        })

        const url = `/dashboard/booking/${bookingResponse.id}?${stringify(
          params
        )}`
        history.push(url)
      } else {
        bookingConfirmed(true, bookingResponse)
        toast({
          description: 'Booking rescheduled',
          status: 'success'
        })
      }
    } catch (error) {
      let description = 'Something went wrong, failed to make booking'
      toast({
        description,
        status: 'error'
      })
    }
    setIsBooking(false)
  }
  const getBookingCost = () => {
    if (!bookingTimes) return null
    return (
      <Box textAlign="right" mt={2}>
        <Text>
          <strong>
            Booking on {chosenDate ? format(chosenDate, 'dd MMMM yyyy') : ''}
          </strong>
        </Text>
        <Divider my={2} borderColor="gray.200" />
        {bookingTimes && (
          <Text>
            {`${format(bookingTimes.start, 'h:mm a')} - ${format(
              bookingTimes.end,
              'h:mm a'
            )}`}
          </Text>
        )}
        <VStack spacing={2} align="stretch">
          <Text>
            {bookingTimes &&
              secondsToTimeString(
                differenceInSeconds(bookingTimes.end, bookingTimes.start)
              )}{' '}
          </Text>
          <Text>
            <strong> {bookingCost ? '$' + bookingCost : ''}</strong>
          </Text>
        </VStack>
      </Box>
    )
  }
  interface Props {
    display: boolean
    workspace: IWorkspace
    hourLimit: Number
  }

  const ViewPricesButton: React.FC<Props> = ({
    display,
    workspace,
    hourLimit,
    ...rest
  }) => {
    if (!display) return null
    return (
      <Popover>
        <PopoverTrigger>
          <ChakraButton
            variant="ghost"
            size="xs"
            color={theme.colors.brandPrimary}
          >
            View Prices
          </ChakraButton>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverHeader>Price list for {workspace.name}</PopoverHeader>
          <PopoverBody>
            <Flex textAlign="right">
              <Text fontWeight="bold">
                Up to {hourLimit} {hourLimit > 1 ? 'hours' : 'hour'}:{' '}
              </Text>
              <Spacer />
              <Text>${workspace.member_price_half_day_price} + GST</Text>
            </Flex>
            <Flex>
              <Text fontWeight="bold">
                More than {hourLimit} {hourLimit > 1 ? 'hours' : 'hour'}:{' '}
              </Text>
              <Spacer />
              <Text>${workspace.member_price_full_day_price} + GST</Text>
            </Flex>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    )
  }

  return (
    <Box
      w="100%"
      bg="#fff"
      boxShadow="0px 8px 16px rgba(0, 0, 0, 0.1)"
      onClick={() => {
        // handleSelectWorkspace()
      }}
      mb={6}
    >
      <VStack spacing={4} align="stretch" px={4} py={3}>
        <Box mb="10px">
          {!isEdition && <WorkspaceSmallCard workspace={workspace} mb={2} />}
          <Flex pb={3}>
            <Text color="gray.500" size="sm">
              {isEdition
                ? 'Reschedule your booking'
                : 'Select a time for your booking'}
            </Text>
            <Spacer />
            {openingHours && (
              <ViewPricesButton
                display={true}
                workspace={workspace}
                hourLimit={openingHours.hours_half_day}
              />
            )}
          </Flex>
          {openingHours && (
            <WorkspaceTimeline
              workspace={workspace}
              openingHours={openingHours}
              chosenDate={chosenDate}
              setTimeSlot={selectTimes}
              setError={setIsError}
              existingBooking={booking}
              otherBookings={otherBookings}
              py={15}
            />
          )}
        </Box>
        <Spacer />
        {bookingTimes && (
          <Box mt={3} textAlign="right" w={'100%'}>
            <Box px={[3, 0]} mb={2}>
              {getBookingCost()}
            </Box>

            <Button
              disabled={isError || isBooking || !bookingTimes || isCalculating}
              size="sm"
              onClick={makeBooking}
              isLoading={isCalculating}
            >
              {isBooking ? (
                <CenteredSpinner />
              ) : isEdition ? (
                'Confirm Reschedule'
              ) : (
                'Make Booking'
              )}
            </Button>
          </Box>
        )}
        <Box h={bookingTimes ? '10px' : '45px'}></Box>
      </VStack>
    </Box>
  )
}
