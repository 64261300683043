import React from 'react'
import { Flex, Text } from '@chakra-ui/react'

export const LayoutAdminDashboard: React.FC = ({ children }) => {
  return (
    <div className="Admin-App">
      <Flex
        flexGrow={1}
        flexDir="column"
        w="100%"
        overflow="hidden"
        pl={4}
        position="relative"
      >
        {children}
        <Flex
          w="100%"
          justify="center"
          bottom="0px"
          position="absolute"
          pb={1}
          mt="10px"
        >
          <Text as="span">
            Powered by{' '}
            <Text as="span" color="#EB4969">
              Spacecubed
            </Text>
          </Text>
        </Flex>
      </Flex>
    </div>
  )
}
