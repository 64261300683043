import { Stack, Box, Link, Text, Center, Heading } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import { routeStrings } from '../../routeStrings'
import { Plan } from '../../types'
import { useUserDetails } from '../../context/user-onboard-context'
import { PlanCard } from './components/PlanCard'
import { ILUMA_PLAN_UUID } from '../../utils/constants'
import { useState } from 'react'
import { getPlanByUUID } from '../../api'
import { CenteredSpinner } from '../../styled/CenteredSpinner'
import { usePlatformTenant } from '../../context/platform-tenant-context'
import { useFeatureFlags } from '../../context/feature-flag-context'

export const OnboardingChoosePlanIluma: React.FC = () => {
  const history = useHistory()
  const { setUserDetails } = useUserDetails()
  const { setThemeTenantLocation, platformTheme } = usePlatformTenant()
  const { workzoneSignupEnabled } = useFeatureFlags()

  const [ilumaPlan, setIlumaPlan] = useState<Plan>()
  const handleSelectPlan = (uuid: string) => {
    if (!ilumaPlan) return

    setUserDetails((currentValues: any) => ({
      ...currentValues,
      ...{
        plan: uuid,
        plan_name: ilumaPlan?.name,
        plan_rate: ilumaPlan?.rate_string,
        plan_inclusions_url: ilumaPlan?.inclusions_document_url
      }
    }))
    history.push(routeStrings.singlePageSignup)
  }

  useEffect(() => {
    getPlanByUUID(ILUMA_PLAN_UUID).then((res) => {
      if (res.status === 200) {
        setIlumaPlan(res.data)
        setThemeTenantLocation(undefined, res.data.location?.id)
        return
      }
      history.push(routeStrings.location)
    })
  }, [])

  return (
    <Stack spacing={8} flexGrow={1} align="center" w="100%" p={4}>
      {workzoneSignupEnabled ? (
        ilumaPlan ? (
          <>
            <Box textAlign="center" marginBottom="1rem">
              <Text fontSize="lg">
                Sign up below for access to book at{' '}
                {platformTheme?.business_name}
                .
                <br />
              </Text>
            </Box>

            <Box>
              <PlanCard
                key={ilumaPlan.uuid}
                {...{
                  title: ilumaPlan.category.name,
                  description: ilumaPlan.description,
                  planOptions: [
                    {
                      uuid: ilumaPlan.uuid,
                      name: ilumaPlan.name,
                      rate: ilumaPlan.rate.value + '',
                      inclusions_url: ''
                    }
                  ]
                }}
                // minH={'509px'}
                w={['300px', '500px']}
                flex="0 0 280px"
                onSelectPlan={handleSelectPlan}
              />
            </Box>
          </>
        ) : (
          <CenteredSpinner />
        )
      ) : (
        <Center textTransform="uppercase">
          <Heading color="gray.600" pt={20} textAlign="center">
            Work Zone is coming soon
          </Heading>
        </Center>
      )}
    </Stack>
  )
}
