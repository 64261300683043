import { Stack, Box, Text } from '@chakra-ui/react'
import React from 'react'
import { useHistory } from 'react-router'
import { routeStrings } from '../../routeStrings'
import { useUserDetails } from '../../context/user-onboard-context'
import { PlanCard } from './components/PlanCard'
import { FERN_ALLENDALE_PLAN_UUID } from '../../utils/constants'

export const OnboardingChoosePlanAllendaleFern: React.FC = () => {
  const history = useHistory()
  const { setUserDetails } = useUserDetails()

  const handleSelectPlan = (uuid: string) => {
    setUserDetails((currentValues: any) => ({
      ...currentValues,
      ...{
        plan: uuid,
        plan_name: 'Allendale Square Business Lounge',
        plan_rate: '$0',
        plan_inclusions_url: ''
      }
    }))
    history.push(routeStrings.singlePageSignup)
  }
  const plansToDisplay = [
    {
      title: 'Allendale Square Business Lounge',
      description:
        'Flexible hotdesk ranging from one day a week to 24/7 access',
      planOptions: [
        {
          uuid: FERN_ALLENDALE_PLAN_UUID,
          name: 'Fern Allendale Tenant Business Lounge Plan',
          rate: '$0',
          inclusions_url: ''
        }
      ]
    }
  ]
  return (
    <Stack spacing={8} flexGrow={1} align="center">
      <Box textAlign="center" marginBottom="1rem">
        <Text fontSize="lg">
          Signup below for access to book the Allendale Square Business Lounge.
          <br></br> Please use your work email to be verified as a tenant of
          Allendale
        </Text>
      </Box>
      {plansToDisplay.map((plan) => (
        <Box display="inline-block">
          <PlanCard
            key={plan.title}
            {...plan}
            // minH={'509px'}
            flex="0 0 280px"
            onSelectPlan={handleSelectPlan}
          />
        </Box>
      ))}
    </Stack>
  )
}
