import { StackProps, Text, Stack } from '@chakra-ui/react'
import React from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { OverviewBox } from './OverviewBoxBase'
import { WifiPasswordDisplay } from '../../../../components/WifiPasswordDisplay'
import { RouterLink } from '../../../../components/Link'
import { useFeatureFlags } from '../../../../context/feature-flag-context'
import { InfoOutlineIcon } from '@chakra-ui/icons'
import { tourTargetStrings } from '../../../../tourStepsArrays'
interface Props extends StackProps {
  wifiPassword?: string
  loading?: boolean
}

export const WifiOverview: React.FC<Props> = ({
  wifiPassword,
  loading,
  ...rest
}) => {
  const { editWifiEnabled } = useFeatureFlags()

  return (
    <SkeletonTheme color="#c7ce94" highlightColor="#a0a76f">
      <OverviewBox
        bg="#EAEFC6"
        header="WiFi"
        className={tourTargetStrings.dashboardWifi}
        headerIcon={<InfoOutlineIcon color="#848960" pt="3px" />}
        {...rest}
      >
        {loading ? (
          <Text>
            <Skeleton />
          </Text>
        ) : (
          wifiPassword && (
            <Stack spacing={5} shouldWrapChildren>
              <Text>
                Your Spacecubed WiFi account is active. Your password is shown
                below.
              </Text>
              <WifiPasswordDisplay password={wifiPassword} />
              {editWifiEnabled && (
                <RouterLink
                  fontSize="sm"
                  color="#255CE1"
                  to="/dashboard/profile?tab=wifi"
                >
                  Edit password
                </RouterLink>
              )}
            </Stack>
          )
        )}
      </OverviewBox>
    </SkeletonTheme>
  )
}
