// import { InputWarning } from '../styled/InputWarning'
// import css from '@styled-system/css'

import PopperJS from 'popper.js'
import React, { useEffect, useState } from 'react'
import { filterOffices } from '../api'
import { usePlatformTenant } from '../context/platform-tenant-context'
import { IBasicOffice } from '../types'
import { FilterableSelect } from './FilterableSelect'
export const FilterableOfficesSelect = ({
  name,
  onSelect,
  dataTestId,
  initialValue
}: {
  name: string
  initialValue?: number
  onSelect: (n: number, value: IBasicOffice) => any
  additionalActions?:
    | Array<{ label: string | JSX.Element; itemAction: (arg: any) => void }>
    | undefined
  popOverPlacement?: PopperJS.Placement // Based off PopperJS.Placement from popper-js
  dataTestId?: string
}) => {
  const { platformTenant } = usePlatformTenant()

  const [value, setValue] = useState<string | undefined>()
  const [debouncedTerm, setDebouncedTerm] = useState('')

  const page_size = 10

  const [items, setItems] = useState<IBasicOffice[]>([])

  const [page, setPage] = useState(1)
  const [hasMoreResults, setHasMoreResults] = useState(true)
  const [isFetching, setIsFeching] = useState(false)
  const fetchValues = (input_page: number, value: string) => {
    if (isFetching) return
    if (platformTenant) {
      setIsFeching(true)
      return filterOffices(platformTenant.id, value, input_page, page_size)
        .then((res) => {
          if (res && res.data) {
            let results = res.data.results ?? []
            results = input_page > 1 ? items.concat(results) : results
            // let resOrgUnits = input_page > 1 ? orgUnits.concat(res.data?.results ?? []) : res.data?.results ?? []
            setHasMoreResults(res.data.next != null)
            setPage(input_page + 1)
            setIsFeching(false)
            setItems(results)
            // setOrgUnits(resOrgUnits)
          }
        })
        .catch((error) => {
          setIsFeching(false)
        })
    }
  }

  useEffect(() => {
    if (platformTenant && value != undefined) {
      fetchValues(1, value)
    }
  }, [value])

  useEffect(() => {
    const timer = setTimeout(() => setValue(debouncedTerm), 600)
    return () => clearTimeout(timer)
  }, [debouncedTerm])

  return (
    <FilterableSelect
      isLoading={isFetching}
      onInputChange={(newValue: string) => {
        setDebouncedTerm(newValue)
      }}
      dataTestId={dataTestId}
      isClearable={false}
      items={
        items
          ? items.map((office) => {
              return {
                label: `${office.name} @ ${office?.location_name}`,
                value: office.id
              }
            })
          : []
      }
      name={name}
      initialValue={initialValue}
      onSelect={(n) => {
        let item = items.find((o) => o.id === n)
        if (item) {
          onSelect(n, item)
        }
      }}
      onMenuScrollToBottom={() => {
        if (hasMoreResults) fetchValues(page, value ?? '')
      }}
    />
  )
}
