import {
  Alert,
  AlertIcon,
  Box,
  Divider,
  Flex,
  Image,
  Link,
  Stack,
  Tag,
  Text,
  useTheme
} from '@chakra-ui/react'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { PasswordResetConfirmation } from '../api'

import { PrimaryButton } from '../components/chakra-v2/PrimaryButton'
import { FormErrorMessage } from '../components/FormErrorMessage'
import { FormInput } from '../components/FormInput'
import { OnboardingWrapper } from '../components/OnboardingWrapper'
import { routeStrings } from '../routeStrings'

export const PasswordResetConfirm: React.FC = () => {
  const match = useRouteMatch<{
    uid: string
    user: string
    token: string
  }>()
  const theme: any = useTheme()
  const [] = useState('')
  const [formErrorMessage] = useState({})

  const [response, setResponse] = useState<String>('')
  const [error, setError] = useState<String>('')

  const [uid, setUid] = useState('')
  const [, setUser] = useState('')
  const [resetToken, setResetToken] = useState('')

  useEffect(() => {
    if (match) {
      setUid(match.params.uid)
      setUser(match.params.user)
      setResetToken(match.params.token)
    }
  }, [match])

  return (
    <OnboardingWrapper>
      <Stack
        align="center"
        spacing={8}
        mt={['100px', '20vh']}
        ml="auto"
        mr="auto"
        maxW="450px"
      >
        <Image src={theme.logos.logo_long} w={'14rem'} alt="Spacecubed Logo" />
        {response ? (
          <>
            {response && (
              <Alert status="success">
                <AlertIcon />
                {response}
              </Alert>
            )}
            <Divider />
            <Link href={routeStrings.login}>Return to home</Link>
          </>
        ) : (
          <Formik
            initialValues={{ password: '', password2: '' }}
            validate={(values) => {
              const errors: any = {}
              if (!values.password || !values.password2) {
                errors.password = 'Required'
                // setPasswordErrorMessage(errors.password)
              }
              if (
                /^\d+$/.test(values.password) ||
                /^\d+$/.test(values.password)
              ) {
                errors.password = "Your password can't be entirely numeric."
                // setPasswordErrorMessage(errors.password)
              }
              if (values.password.length < 8 || values.password2.length < 8) {
                errors.password =
                  'This password is too short. It must contain at least 8 characters.'
                // setPasswordErrorMessage(errors.password)
              }
              if (
                values.password !== values.password2 &&
                values.password.length > 1 &&
                values.password2.length > 0
              ) {
                errors.password = "The two password fields didn't match."
              } else {
                // setPasswordErrorMessage('')
              }
              return errors
            }}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmitting(true)
              if (match) {
                try {
                  const response = await PasswordResetConfirmation({
                    uid,
                    resetToken,
                    new_password1: values.password,
                    new_password2: values.password2
                  })
                  setResponse(response.data.detail)
                } catch (error) {
                  if (
                    (error.response &&
                      error.response.status === 400 &&
                      error.response.data.new_password2 !== undefined) ||
                    error.response.data.new_password1 !== undefined
                  ) {
                    setError(
                      error.response.data.new_password2[0]
                        ? error.response.data.new_password2[0]
                        : error.response.data.new_password1[0]
                    )
                  }
                  if (
                    error.response &&
                    error.response.status === 400 &&
                    error.response.data.token
                  ) {
                    setError(
                      'Invalid token, please regenerate a new password reset link'
                    )
                  } else {
                    console.log(error.response)
                  }
                }
              }
            }}
          >
            {({ errors, touched }) => (
              <Form>
                <Flex direction="column" align="center">
                  <Box mb={8} w={'350px'}>
                    <Field
                      type="password"
                      name="password"
                      autoFocus={true}
                      isInvalid={
                        touched.password && errors.password !== undefined
                      }
                      data-testid="onboarding-password-field"
                      label="New password"
                      placeholder="Type your new password here"
                      component={FormInput}
                    />
                    <br />
                    <Field
                      type="password"
                      name="password2"
                      autoFocus={false}
                      isInvalid={
                        touched.password && errors.password !== undefined
                      }
                      data-testid="onboarding-password-field"
                      label="Confirm password"
                      placeholder="Re-enter your new password here"
                      component={FormInput}
                    />
                    <ErrorMessage
                      name="password"
                      component={FormErrorMessage}
                    />
                    {error && <Tag color="#f50">{error}</Tag>}
                  </Box>

                  {/* Form error message */}
                  {Object.keys(formErrorMessage).length > 0 && (
                    <Text>Please check the following fields</Text>
                  )}

                  {Object.entries(formErrorMessage).map((error) => {
                    let errorFields = ''
                    error &&
                      error.map((unit) => {
                        errorFields += ' ' + unit + ','
                      })
                    return <Tag mb={2}>{errorFields.slice(0, -1)}</Tag>
                  })}
                  <PrimaryButton
                    type="submit"
                    px={6}
                    flexGrow={0}
                    data-testid="change-password-btn"
                  // Button is disabled if form is submitting or error message exists
                  //isDisabled={isSubmitting}
                  >
                    Confirm
                  </PrimaryButton>
                  <br />
                  <Link href={routeStrings.login}>Return to home</Link>
                </Flex>
              </Form>
            )}
          </Formik>
        )}
      </Stack>
    </OnboardingWrapper>
  )
}
