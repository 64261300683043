import { Link, StackProps, Text, useDisclosure, Box } from '@chakra-ui/react'
import React from 'react'
import { SkeletonTheme } from 'react-loading-skeleton'
import { routeStrings } from '../../../../routeStrings'
import { ConnectionModal } from '../ConnectionModal'
import { OverviewBox } from './OverviewBoxBase'
import { IConnectionDisplay } from './types'
import { analyticsTrack } from '../../../../utils/analytics'
import { EmailIcon } from '@chakra-ui/icons'
interface Props extends StackProps {
  connection: IConnectionDisplay
}

export const ConnectionOverview: React.FC<Props> = ({
  connection,
  ...rest
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { otherUserEmail, otherUserName, message } = connection

  const openModal = () => {
    analyticsTrack('Open Connection Modal', {
      from: 'Small member dashboard connection widget'
    })
    onOpen()
  }

  return (
    <>
      <SkeletonTheme color="#ffc17a" highlightColor="#e28f31">
        <OverviewBox
          bg="#FEF0E0"
          header="Connection"
          headerIcon={<EmailIcon />}
          {...rest}
        >
          <Box>
            <Text mb={5}>Your latest member connection is available.</Text>
            <Link as="button" fontSize="sm" color="#255CE1" onClick={openModal}>
              View
            </Link>
          </Box>
        </OverviewBox>
      </SkeletonTheme>
      <ConnectionModal
        otherUserEmail={otherUserEmail}
        otherUserName={otherUserName}
        message={message}
        isOpen={isOpen}
        onClose={onClose}
      />
    </>
  )
}
