import { Box, useDisclosure } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { AdminBodyWrapper } from '../../components/AdminBodyWrapper'
import { GroupingHeaderLayout } from '../../components/GroupingHeaderLayout'
import { LocationAdminSelector } from '../../components/LocationAdminSelector'
import { useAppTourContextProvider } from '../../context/app-tour-context'
import { useAuth } from '../../context/auth-context'
import { usePlatformTenant } from '../../context/platform-tenant-context'
import { tourTargetStrings } from '../../tourStepsArrays'
import { Plan } from '../../types'
import { usePlans } from '../../utils/apiHooks'
import { Actions, useRBAC } from '../../utils/rbac'
import { LayoutAdminDashboard } from './LayoutAdminDashboard'
import { CreatePlanModal } from './Modals/CreatePlanModal'
import { EditPlanCategoriesModal } from './Modals/EditPlanCategoriesModal'
import { Plans } from './Tables/Plans'

export const AdminDashboardPlans: React.FC = () => {
  const { adminTourActive, setRun } = useAppTourContextProvider()
  useEffect(() => {
    if (adminTourActive) {
      setRun(true)
    }
  }, [])
  const [locationFilter, setLocationFilter] = useState<number>()
  const { currentOrgUnit } = useAuth()
  const [filteredPlans, setFilteredPlans] = useState<Plan[]>([])
  const { plans } = usePlans(
    currentOrgUnit?.organization.parent_platform_tenant
  )
  const canAdministerPlans = useRBAC(Actions.AdministerPlans)
  const [editPlan, setEditPlan] = useState<Plan>()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { isOpen: isOpenPlanCategories, onToggle: onTogglePlanCategories } =
    useDisclosure()
  const { platformTenant } = usePlatformTenant()
  const rowActions = {
    editPlan: {
      text: 'Edit plan',
      testIdPrefix: 'edit-plan',
      action: (rowId: number) => {
        setEditPlan(plans.find((plan) => plan.id === rowId))
        onOpen()
      }
    }
  }
  /**
   * This useEffect sets the intial locationFilter, thus triggering filtering above
   */
  useEffect(() => {
    if (
      filteredPlans.length === 0 &&
      !locationFilter &&
      platformTenant &&
      platformTenant?.locations.length > 0
    ) {
      setLocationFilter(platformTenant?.locations[0].id)
    }
  }, [platformTenant])

  useEffect(() => {
    if (plans.length > 0 && locationFilter) {
      setFilteredPlans(
        plans.filter(
          (plan) => plan.location?.id === locationFilter || !plan.location
        )
      )
    }
  }, [locationFilter, plans])

  return (
    <LayoutAdminDashboard>
      <GroupingHeaderLayout
        basicTitle="Plans"
        action={
          canAdministerPlans
            ? [
                {
                  text: 'Create plan',
                  className: tourTargetStrings.adminheaderPlansCreate,
                  variant: 'primary',
                  onClick: () => {
                    setEditPlan(undefined)
                    onOpen()
                  }
                },
                {
                  text: 'Modify Plan Categories',
                  className: tourTargetStrings.adminheaderPlansCategory,
                  variant: 'primary',
                  onClick: () => {
                    onTogglePlanCategories()
                  }
                }
              ]
            : undefined
        }
      />

      {/* Tables */}
      <AdminBodyWrapper>
        <Box
          p={4}
          display={{ base: 'flex' }}
          flexWrap="wrap"
          data-testid="locations-box"
        >
          {platformTenant?.locations.map((location) => (
            <LocationAdminSelector
              key={location.id.toString()}
              location={location}
              selected={locationFilter === location.id}
              onClick={() => setLocationFilter(location.id)}
            />
          ))}
        </Box>
        <Plans
          plans={filteredPlans}
          loading={!filteredPlans}
          editPlanAction={rowActions.editPlan}
        />
      </AdminBodyWrapper>

      {/* Modals */}
      <CreatePlanModal
        isOpen={isOpen}
        onClose={onClose}
        planToEdit={editPlan}
      />
      <EditPlanCategoriesModal
        isOpen={isOpenPlanCategories}
        onClose={onTogglePlanCategories}
      />
    </LayoutAdminDashboard>
  )
}
