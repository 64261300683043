import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { getMe, login } from '../api'
import { routeStrings } from '../routeStrings'
import {
  IOrganization,
  IOrganizationalUnit,
  IOrganizationProfile,
  Me
} from '../types'
import { analyticsIdentify } from '../utils/analytics'
import { useCurrentOrganizationProfile } from '../utils/apiHooks'
import { getMembershipState } from '../utils/redirects'
import { createCtx } from './createCtx'

export interface AuthContextProps {
  isAuthenticated?: boolean
  loading: boolean
  me?: Me
  token?: string
  currentOrganization?: IOrganization
  currentOrgUnit?: IOrganizationalUnit
  currentOrganizationProfile?: IOrganizationProfile
  revalidateOrganizationProfile: () => void
  isTeamAdmin?: boolean
  loginUser?: (email: string, password: string) => Promise<void>
  logoutUser?: (...p: any[]) => void
  getAndSetMe?: (token) => Promise<Me | void>
  adminChangeOrganization: (org: IOrganization) => void
  setOrganizationalUnit: (me: Me, orgUnit: IOrganizationalUnit) => void
}

const allowedStartRoutes = [
  '/invite',
  '/start',
  '/accounts',
  '/reset',
  '/kiosk',
  '/external'
]

const includesSubstringInArray = (substring: string, arr: string[]) => {
  let match = false
  arr.map((item) => {
    substring.includes(item) || match ? (match = true) : (match = false)
  })
  return match
}

const urlIsStartRoute = (url: string) =>
  includesSubstringInArray(url, allowedStartRoutes)

const [useAuth, AuthContextProvider] = createCtx<AuthContextProps>()

/**
 *  Checks if the user is staff or member and sends them to the correct page
 */
export const redirectAfterLoginUrl = (loggedInUser: Me) => {
  const params = new URLSearchParams(document.location.search.substring(1))
  const next = params.get('next')
  if (next) {
    return next
  } else {
    return loggedInUser.user.is_space_admin
      ? routeStrings.adminDashboard
      : routeStrings.memberDashboardHome
  }
}

const AuthProvider = ({ children }) => {
  const history = useHistory()
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>()
  const [me, setMe] = useState<Me>()
  const [currentOrganization, setCurrentOrganization] =
    useState<IOrganization>()
  const [currentOrgUnit, setCurrentOrgUnit] = useState<IOrganizationalUnit>()
  const [isTeamAdmin, setIsTeamAdmin] = useState<boolean>()
  const [token, setToken] = useState<string>()
  const [loading, setLoading] = useState<boolean>(true)

  const {
    data: currentOrganizationProfile,
    revalidate: revalidateOrganizationProfile
  } = useCurrentOrganizationProfile(
    currentOrgUnit?.organization.organization_profile?.id
  )
  const getAndSetMe = async (localToken: string) => {
    setToken(localToken)
    return getMe({ token: localToken })
      .then(({ data }) => {
        const me = {
          ...data,
          membershipState: getMembershipState(data),
          startDate: new Date(Date.parse(data.user.user_profile.start_date))
        }

        analyticsIdentify(data.user.id.toString(), {
          name: data.user.name,
          email: data.user.email
        })
        if (data.organizational_units.length > 0) {
          let orgUnit: IOrganizationalUnit | undefined =
            data.organizational_units[0]
          //sets the user to their first team
          let orgUnitId = window.localStorage.getItem('CurrentOrgUnitId')
          let orgUnitFound = false
          if (orgUnitId) {
            try {
              let foundOrgUnit = data.organizational_units.find(
                (ou) => ou.id === parseInt(orgUnitId + '')
              )
              if (foundOrgUnit) {
                setOrganizationalUnit(me, foundOrgUnit)
                orgUnitFound = true
              }
            } catch (error) {
              console.error(error)
            }
          }
          if (!orgUnitFound && orgUnit) {
            setOrganizationalUnit(me, orgUnit)
            window.localStorage.setItem('CurrentOrgUnitId', orgUnit.id + '')
          }
          // Redefined MembershipState for the selected Orgunit
          me.membershipState = getMembershipState(data, orgUnit)
        }
        setMe(me)
        setIsAuthenticated(true)
        setLoading(false)
        return me
      })
      .catch((e) => {
        // analyticsTrack("Login Failed", { exception: e });
        logoutUser()
      })
  }

  // on first mount, pickup a token if there is one in localStorage
  useEffect(() => {
    setLoading(true)
    const savedLocalToken = window.localStorage.getItem('Token')
    if (savedLocalToken) {
      setToken(savedLocalToken)
      getAndSetMe(savedLocalToken)
    } else if (window.location.pathname !== routeStrings.login) {
      const nextUrl = window.location.pathname
      if (!urlIsStartRoute(nextUrl)) {
        const loginUrl = nextUrl
          ? routeStrings.login + '/?next=' + nextUrl
          : routeStrings.login
        history.replace(loginUrl)
      }
      setLoading(false)
    } else {
      setLoading(false)
    }
  }, [])

  const setOrganizationalUnit = (me: Me, orgUnit: IOrganizationalUnit) => {
    const isAdmin = me.admin_of
      .map((org) => org.id)
      .includes(orgUnit.organization.id)
    setCurrentOrgUnit(orgUnit)
    setIsTeamAdmin(isAdmin)
    setCurrentOrganization(orgUnit.organization)
    window.localStorage.setItem('CurrentOrgUnitId', orgUnit.id + '')
  }

  const adminChangeOrganization = async (org: IOrganization) => {
    setCurrentOrganization(org)
  }

  const loginUser = async (email: string, password: string) => {
    const resp = await login({ email, password })
    const newToken = resp.data.key
    setToken(newToken)
    window.localStorage.setItem('Token', newToken)
    getAndSetMe(newToken)
  }

  const logoutUser = () => {
    window.localStorage.setItem('Token', '')
    setToken(undefined)
    setMe(undefined)
    setIsAuthenticated(undefined)
    setCurrentOrgUnit(undefined)
    setCurrentOrganization(undefined)
    history.push(routeStrings.login)
  }

  return (
    <AuthContextProvider
      value={{
        isAuthenticated,
        me: me,
        loading,
        token,
        currentOrganization,
        currentOrgUnit,
        isTeamAdmin,
        currentOrganizationProfile,
        revalidateOrganizationProfile,
        loginUser,
        logoutUser,
        getAndSetMe,
        adminChangeOrganization,
        setOrganizationalUnit
      }}
    >
      {children}
    </AuthContextProvider>
  )
}

export { useAuth, AuthProvider }
