import { Box, BoxProps, Flex, Icon, Link, Stack, Text } from '@chakra-ui/react'
import { format } from 'date-fns'
import React from 'react'
import { IDailyFiveContent } from '../../types'

import { ExternalLinkIcon, LinkIcon } from '@chakra-ui/icons'
import { faMapMarked } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
interface DetailProps extends BoxProps {
  content: IDailyFiveContent | undefined
}
export const ContentDetailBar: React.SFC<DetailProps> = ({ content }) => {
  const showDate = content?.date || content?.start_time || content?.finish_time

  return (
    <Flex flexDirection={['column']}>
      <Flex flexDirection={['column', 'row']} mb={['20px']}>
        {showDate && (
          <Flex alignContent="top" mr={[0, '50px']}>
            <Stack spacing="4px">
              <Text color="#333333" fontSize="md" fontWeight="bold">
                {content?.date
                  ? format(Date.parse(content.date!), 'PP')
                  : 'Time'}
              </Text>
              <Text color="#333333" fontSize="xs">
                {`${
                  content?.start_time
                    ? format(
                        Date.parse(`2020-04-23T${content?.start_time}`),
                        'h:mm aa'
                      )
                    : ''
                }${
                  content?.finish_time
                    ? ` - ${format(
                        Date.parse(`2020-04-23T${content?.finish_time}`),
                        'h:mm	aa'
                      )}`
                    : ''
                }`}
              </Text>
            </Stack>
          </Flex>
        )}

        {(content?.location || content?.location_venue) && (
          <Flex>
            <FontAwesomeIcon
              icon={faMapMarked}
              style={{ marginRight: '5px', marginTop: '2px' }}
            />
            <Box>
              {content.location_venue && (
                <Text color="#333333" fontSize="sm" fontWeight="bold">
                  {content.location_venue.name},{' '}
                  {content.location_venue.address},{' '}
                  {content.location_venue.city.name}
                </Text>
              )}
              <Text color="#333333" fontSize="sm">
                {content.location}
              </Text>
            </Box>
          </Flex>
        )}
      </Flex>
      <Flex flexDirection={['column', 'row']} mb={['20px']}>
        {content?.zoom_meeting_url && (
          <Flex alignContent="top" mr={[0, '50px']}>
            <ExternalLinkIcon marginRight="20px" />
            <Stack spacing="4px">
              <Link
                href={content.zoom_meeting_url}
                color="#2F80ED"
                fontSize="sm"
                fontWeight="bold"
              >
                Join Zoom Meeting
              </Link>
              <Text color="#333333" fontSize="sm">
                Password: {content.zoom_meeting_password}
              </Text>
            </Stack>
          </Flex>
        )}
        {content?.url && (
          <Flex alignContent="top" mr={[0, '50px']}>
            <LinkIcon marginRight="20px" />
            <Link
              isExternal
              href={
                content.url.startsWith('http') ||
                content.url.startsWith('mailto:')
                  ? content.url
                  : '//' + content.url
              }
              color="#2F80ED"
              fontSize="sm"
              fontWeight="bold"
            >
              Visit link
            </Link>
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}
