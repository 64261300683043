import React from 'react'

import { Table, TableDataProp } from '../../../components/table/Table'
import { IInvitationRecord, INewTeamInvitationRecord } from '../../../types'

import { Box, Text } from '@chakra-ui/react'
import { TeamLink } from './TeamLink'
import { CenteredSpinner } from '../../../styled/CenteredSpinner'

import { format } from 'date-fns'

interface IInputParams {
  invitations: INewTeamInvitationRecord[] | undefined
  isLoading: boolean
  revalidateInvitations: () => void
  resendInvitation: (invitation: IInvitationRecord) => void
  cancelInvitation: (invitation: IInvitationRecord) => void
}

export const TeamMemberInvitations: React.FC<IInputParams> = (input) => {
  const { invitations, isLoading, cancelInvitation, resendInvitation } = input

  const invitationActions = (invitation: IInvitationRecord) => [
    {
      text: 'Re send Invitation Email ',
      testIdPrefix: 'cancel-invitation',
      action: () => {
        resendInvitation(invitation)
      }
    },
    {
      text: 'Cancel Invitation',
      testIdPrefix: 'cancel-invitation',
      action: () => {
        cancelInvitation(invitation)
      }
    }
  ]
  if (isLoading) return <CenteredSpinner />

  const tableData = invitations
    ? invitations.map((invitation) => {
        const cleanedData = {
          plan: TableDataProp(
            invitation.seats
              ? invitation.seats.length > 0
                ? invitation.seats.map((s) => s.active_plan?.name + ', ')
                : ''
              : ''
          ),
          start_date: TableDataProp(
            format(Date.parse(invitation.created_at), 'dd/MM/yyyy')
          ),
          organization: TableDataProp(
            TeamLink({
              organizationId: invitation.organization.id,
              organizationName: invitation.organization.name
            })
          ),
          invitation_email: TableDataProp(invitation.invitation_email),
          created_at: TableDataProp(
            format(new Date(invitation.created_at), 'dd/MM/yyyy HH:mm')
          ),
          updated_at: TableDataProp(
            format(new Date(invitation.updated_at), 'dd/MM/yyyy HH:mm')
          ),
          actions: TableDataProp(invitationActions(invitation))
        }

        return cleanedData
      })
    : []

  return (
    <div style={{ width: '100%', padding: '16px' }}>
      <Box my="3" w="100%">
        <Box d="flex" mb={3} alignItems="baseline">
          <Text
            mb={0}
            mr={3}
            color="headingPrimary"
            fontSize="lg"
            textTransform="capitalize"
          >
            New Teams
          </Text>
          <Text fontSize="xs" color="grey">
            {invitations?.length} Total
          </Text>
        </Box>
        <Table
          headers={[
            { header: 'id', accessor: 'id', show: false },
            { header: 'Created at', accessor: 'created_at', show: true },
            { header: 'Organization', accessor: 'organization', show: true },
            {
              header: 'invitation_email',
              accessor: 'invitation_email',
              show: true
            },
            { header: 'plans', accessor: 'plan', show: true },
            { header: 'Start date', accessor: 'start_date', show: true },
            { header: 'Last sent', accessor: 'updated_at', show: true },
            { header: '', accessor: 'actions', show: true }
          ]}
          data={tableData}
          loading={isLoading}
        />
      </Box>
    </div>
  )
}
