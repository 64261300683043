// import { InputWarning } from '../styled/InputWarning'
// import css from '@styled-system/css'
import { Box, Skeleton, Spinner, Text, useTheme } from '@chakra-ui/react'
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import styled from '@emotion/styled'
import PopperJS from 'popper.js'
import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { GroupedOption, SelectOption } from '../types'

interface Props {
  items: Array<SelectOption> | Array<GroupedOption> | undefined
  name?: string
  initialValue?: number
  onSelect: (n: number) => any
  dataTestId?: string
  isLoading?: boolean
  isClearable?: boolean
  isOptionDisabled?: (option) => boolean
  onInputChange?: (value: string) => void
  onMenuScrollToBottom?: () => void
}
export const FilterableSelect: React.FC<Props> = ({
  items,
  name,
  onSelect,
  initialValue,
  dataTestId,
  isClearable,
  isLoading,
  isOptionDisabled,
  onInputChange,
  onMenuScrollToBottom
}) => {
  const theme: any = useTheme()

  const [defaultInputValue, setDefaultInputValue] = useState<SelectOption>()
  const [isReady, setIsReady] = useState(!!!initialValue || items?.length === 0)
  const [hasSearched, setHasSearched] = useState(isReady)

  useEffect(() => {
    if (!isReady && initialValue && initialValue >= 0 && items) {
      items.forEach((item: SelectOption | GroupedOption, index: number) => {
        if (item['options'] != undefined) {
          let group = item as GroupedOption

          group.options.forEach((sub_item) => {
            if (sub_item.value === initialValue) {
              setDefaultInputValue(sub_item)
              return
            }
          })
        } else {
          if (index === initialValue) {
            let option = item as SelectOption
            setDefaultInputValue(option)
            return
          }
        }
      })
      setHasSearched(true)
    }
  }, [])

  useEffect(() => {
    if (defaultInputValue) {
      setIsReady(true)
    } else {
      if (hasSearched) setIsReady(true)
    }
  }, [defaultInputValue, hasSearched])

  const labelStyles = css`
    font-family: Open Sans;
    color: #000;
    font-weight: bold;
    margin-bottom: 4px;
  `

  const customStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: '#ffffff',
      padding: '3px',
      border: '1px solid #e2e8f0',
      color: '#4f4f4f'
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? theme.colors.brandPrimary
          : isFocused
          ? '#4cfff90'
          : undefined,
        cursor: 'cursor',
        ':active': {
          borderColor: '#888888'
        },

        ':hover': {
          ...styles[':hover'],
          backgroundColor: isSelected
            ? theme.colors.brandPrimary
            : !isDisabled
            ? '#4cfff90a'
            : undefined
        }
      }
    },
    dropdownIndicator: () => ({
      color: '#888888'
    })
  }

  if (!isReady)
    return (
      <Box mt={6}>
        <Skeleton h={10} />
      </Box>
    )

  return (
    <Box data-testid={dataTestId}>
      <Text css={labelStyles}>{name}</Text>
      <Select<SelectOption, false, GroupedOption>
        isLoading={isLoading}
        onInputChange={(newValue, actionMeta) => {
          if (onInputChange != undefined) onInputChange(newValue ?? '')
        }}
        styles={customStyles}
        // css={inputStyles}
        className="basic-single"
        classNamePrefix="select"
        placeholder="Select an option"
        isClearable={isClearable}
        defaultValue={defaultInputValue}
        isSearchable={true}
        name="color"
        noOptionsMessage={() => 'No results found'}
        options={items}
        onChange={(selected, type) => {
          if (selected || isClearable) {
            try {
              onSelect(selected ? selected.value : -1)
            } catch (error) {}
          }
        }}
        isOptionDisabled={isOptionDisabled}
        onMenuScrollToBottom={(event) => {
          let element = event.target as HTMLElement
          if (
            onMenuScrollToBottom != undefined &&
            element.scrollHeight - element.scrollTop <
              element.clientHeight * 1.2
          ) {
            onMenuScrollToBottom()
          }
        }}
      />
    </Box>
  )
}
