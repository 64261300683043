import { Table, Tag } from 'antd'
import Column from 'antd/lib/table/Column'
import React from 'react'
import { InvoiceStatus } from '../api'

import { Link } from 'react-router-dom'
import { IInvoice } from '../types'
import { TableProps } from 'antd/lib/table/interface'
import { routeStrings } from '../routeStrings'
import { TeamLink } from '../pages/AdminDashboard/Tables/TeamLink'
export const getInvoiceStatusColor = (status: InvoiceStatus) => {
  if (status === InvoiceStatus.Paid) {
    return 'green'
  }
  if (status === InvoiceStatus.Issued) {
    return 'blue'
  }
  if (status === InvoiceStatus.Pending) {
    return 'yellow'
  }
  return 'red'
}
export const AdminInvoiceTable: React.FC<
  {
    invoices: IInvoice[]
  } & TableProps<{}>
> = ({ invoices, ...rest }) => {
  return (
    <Table
      dataSource={invoices?.map((i, index) => {
        return { ...i, key: i.id, rowKey: index }
      })}
      {...rest}
    >
      <Column
        title="Invoice"
        dataIndex="id"
        key="id"
        render={(id: string) => (
          <Link
            style={{ color: '#255CE1' }}
            to={routeStrings.adminDashboardInvoiceView.replace(
              ':invoiceId',
              id + ''
            )}
          >
            {id}
          </Link>
        )}
      />
      <Column
        title="Team name"
        dataIndex="organization_name"
        key="organization_name"
        render={(text, invoice: IInvoice) => (
          <TeamLink
            organizationId={invoice.organization}
            organizationName={text}
          />
        )}
      />
      <Column
        title="Amount"
        dataIndex="amount_charged"
        key="amount_charged"
        render={(text: string) => (text ? '$' + text : '-')}
      />
      <Column
        title="Status"
        dataIndex="status"
        key="status"
        render={(status: InvoiceStatus) => (
          <Tag color={getInvoiceStatusColor(status)}>{status}</Tag>
        )}
      />
      <Column
        title="Date Issued"
        dataIndex="issued_on"
        key="issued_on"
        render={(issuedOn: string) => new Date(issuedOn).toLocaleDateString()}
      />
    </Table>
  )
}
