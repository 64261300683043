import React, { useEffect, useState } from 'react'
import { useDisclosure, useToast } from '@chakra-ui/react'
import { useParams } from 'react-router'
import {
  clearSeatAssignment,
  inviteTeamMember,
  removeMember,
  requestNewSeat
} from '../../api'
import { AdminBodyWrapper } from '../../components/AdminBodyWrapper'
import {
  GroupingHeaderLayout,
  IActionButton
} from '../../components/GroupingHeaderLayout'
import { useAuth } from '../../context/auth-context'
import {
  IOrganizationalUnitBasic,
  IOrganizationSeat,
  IOrganizationSeatBasic,
  IAddonSubscription,
  DjangoErrorResponse
} from '../../types'
import { useOrganization } from '../../utils/apiHooks'
import { LayoutMemberDashboard } from './LayoutMemberDashboard'
import { InviteMemberModal } from './Modals/InviteMemberModal'
import { ExtraSubscriptionModal } from './Modals/ExtraSubscriptionModal'
import { ManageMembershipModal } from './Modals/ManageMembership/ManageMembershipModal'
import { ManageAddonPlanModal } from './Modals/ManageMembership/ManageAddonPlanModal'
import { RemoveMemberModal } from './Modals/RemoveMemberModal'
import { RequestSeatModal } from './Modals/RequestSeat'
import { TeamMembers } from './Tables/TeamMembers'
import { TeamSubscriptions } from './Tables/TeamSubscriptions'
import { Office } from './Tables/TeamOffice'
import { TeamSeats } from './Tables/TeamSeats'
import { Actions, Roles, useRBAC, useRole } from '../../utils/rbac'
import { useHistory } from 'react-router'
import { routeStrings } from '../../routeStrings'
import { RemovedOrganizationAlert } from '../../components/RemovedOrganizationAlert'
import { showToastAxiosError } from '../../utils/toast_utils'

export const TeamDashboardHome: React.FC = () => {
  const auth = useAuth()
  const { allowedRoles } = useRole()
  const history = useHistory()
  const canRequestNewSeat = useRBAC(Actions.RequestNewSeat)
  const { orgId: routeOrgId } = useParams<{ orgId?: string }>()
  const toast = useToast()
  const [tableFilter, setTableFilter] = useState<string>('team_members')

  // in the admin portal, a path param is used for the org id
  // in the member portal, the auth.currentOrganization context variable is used
  const orgId =
    (routeOrgId && parseInt(routeOrgId, 10)) || auth.currentOrganization?.id

  // @TODO: this is a bit of a hack - in future, the paths for member / team admin / space admin
  // should be thought out so that the same components can be reused between them
  useEffect(() => {
    if (routeOrgId && auth?.adminChangeOrganization) {
      const org = auth.me?.admin_of.find(
        (o) => o.id === parseInt(routeOrgId, 10)
      )
      if (org) {
        auth.adminChangeOrganization(org)
      }
    }
  }, [routeOrgId])

  const {
    data: organization,
    revalidate: refreshOrganization,
    error
  } = useOrganization(orgId)
  const loading = organization === undefined

  const allMembers = organization?.members || []

  const allSeats: IOrganizationSeatBasic[] =
    organization?.seats.filter(
      (seat) => seat.active_plan || seat.ongoing_plan
    ) || []
  const coworkerSeats =
    organization?.seats?.filter((seat) => seat.active_plan) || []
  const futureSeats =
    organization?.seats?.filter(
      (seat) => !seat.active_plan && seat.ongoing_plan
    ) || []
  const offices = organization?.offices || []
  offices.forEach((office) =>
    office.desks.forEach((desk) => {
      if (desk.organization_seat) allSeats.push(desk.organization_seat)
    })
  )

  const [membersWithoutPlans, setMembersWithoutPlan] = useState<
    IOrganizationalUnitBasic[]
  >([])

  useEffect(() => {
    if (organization && organization.id.toString() === routeOrgId) {
      auth.adminChangeOrganization(organization)
    }
  }, [organization])

  useEffect(() => {
    if (error) {
      toast({
        title: "You don't have enough permissions",
        status: 'error'
      })
      history.push(routeStrings.adminDashboard)
    }
  }, [error])

  useEffect(() => {
    if (!allSeats || !offices) return
    let officesSeatsaken =
      offices.map((office) =>
        office.desks
          .filter((desk) => desk.organization_seat?.organizational_unit)
          .map((d) => d.organization_seat)
      ) || []

    let mwp = allMembers.filter((member) => {
      // find members not assigned a seat
      return (
        allSeats
          .filter((seat) => {
            return (
              seat.organizational_unit?.id === member.id ||
              officesSeatsaken.filter((office) =>
                office.find(
                  (desk) =>
                    desk?.organizational_unit?.id ==
                    seat.organizational_unit?.id
                )
              ).length > 0
            )
          })
          // note - there might be a typescript problem here as sometimes there are runtime errors if there is no ? operator on `seat`
          .map((seat) => seat?.organizational_unit?.id)
          .indexOf(member.id) === -1
      )
    })
    setMembersWithoutPlan(mwp)
  }, [organization])

  const [seatToManage, setSeatToManage] = useState<
    IOrganizationSeat | undefined
  >(undefined)
  const [subscriptionManagerToManage, setSubscriptionManagerToManage] =
    useState<IAddonSubscription>()
  const [memberToRemove, setMemberToRemove] = useState<
    IOrganizationalUnitBasic | undefined
  >(undefined)
  const [requestSeatModalIsVisible, setRequestSeatModalIsVisible] =
    useState(false)
  const [inviteModalIsVisible, setInviteModalIsVisible] = useState(false)
  const {
    onOpen: onOpenAddonModal,
    onClose: onCloseAddonModal,
    isOpen: isOpenAddonModal
  } = useDisclosure()
  const [inviteSeat, setInviteSeat] = useState<IOrganizationSeatBasic>()
  const [isRequestingSeat, setIsRequestingSeat] = useState(false)

  // Sets up the modal with the correct seat
  const handleRequestSeatAction = () => {
    setRequestSeatModalIsVisible(true)
  }

  const handleRemoveMember = (action: string, assignee?: number) => {
    if (memberToRemove) {
      removeMember(memberToRemove.id, action, assignee)
        .then(() => {
          toast({
            description: `${memberToRemove.user.name} removed`,
            status: 'success'
          })
          refreshOrganization()
        })
        .catch(() => {
          toast({
            description: 'Failed to remove team member',
            status: 'error'
          })
        })
        .finally(() => {
          setMemberToRemove(undefined)
        })
    }
  }

  // Sets up the modal with the correct seat
  const handleInviteAction = (seatId?: number) => {
    if (seatId) {
      const seat = allSeats.find((searchSeat) => searchSeat.id === seatId)
      setInviteSeat(seat)
    } else {
      setInviteSeat(undefined)
    }

    setInviteModalIsVisible(true)
  }

  const handleSubscriptionAction = () => {
    if (headerGroups[2].active === true) onOpenAddonModal()
  }

  // Sets up the modal with the correct seat
  const manageMembershipTrigger = (seatId?: number) => {
    if (seatId) {
      const seat = coworkerSeats.find((searchSeat) => searchSeat.id === seatId)
      setSeatToManage(seat)
    } else {
      setSeatToManage(undefined)
    }
  }
  // Sets up the modal with the correct seat
  const manageFutureMembershipTrigger = (seatId?: number) => {
    if (seatId) {
      const seat = futureSeats.find((searchSeat) => searchSeat.id === seatId)
      setSeatToManage(seat)
    } else {
      setSeatToManage(undefined)
    }
  }

  let headerAction: IActionButton[] | undefined = []
  let iconActions: React.ReactNode[] = []

  const headerGroups = [
    {
      text: 'Team Members',
      active: tableFilter === 'team_members',
      selectAction: () => setTableFilter('team_members')
    }
  ]

  // Options that admins have access to
  //space admins can requests seats on non space admin orgs
  if (
    !organization?.is_space_admin &&
    (auth.isTeamAdmin ||
      auth.me?.organizational_units[0].organization.is_space_admin)
  ) {
    // additional headers for admin

    headerGroups.push({
      text: 'Plans',
      active: tableFilter === 'membership',
      selectAction: () => setTableFilter('membership')
    })

    // header action for admin
    if (canRequestNewSeat && !organization?.is_deleted) {
      headerAction.push({
        text: 'Request New Membership',
        testIdPrefix: 'request-seat',
        variant: 'primary',
        onClick: handleRequestSeatAction
      })
    }
  }

  // only space admins can see the tab, cant add addons to space admin orgs
  if (
    allowedRoles.includes(Roles.SpaceAdmin) &&
    !organization?.is_space_admin &&
    !organization?.is_deleted
  ) {
    headerGroups.push({
      text: 'Extra Subscriptions',
      active: tableFilter === 'extra_subscriptions',
      selectAction: () => setTableFilter('extra_subscriptions')
    })
  }

  // Handles requesting a new seat. Called from the modal
  const requestSeat = (locationId: number, planId: number, startDate) => {
    if (organization) {
      setIsRequestingSeat(true)
      requestNewSeat(auth.token, organization.id, locationId, planId, startDate)
        .then(() => {
          setIsRequestingSeat(false)
          toast({ description: 'Request sent', status: 'success' })
          refreshOrganization()
        })
        .catch((e: DjangoErrorResponse) => {
          setIsRequestingSeat(false)
          showToastAxiosError(toast, e, 'Failed to create request')
        })
        .finally(() => {
          setRequestSeatModalIsVisible(false)
        })
    }
  }

  // Handles sending the invite. Called from the modal
  const sendInvite = (email: string) => {
    const seatId = inviteSeat ? inviteSeat.id : undefined
    if (organization) {
      inviteTeamMember(auth.token, organization.id, email, seatId)
        .then(() => {
          toast({ description: 'Invite successfully sent', status: 'success' })
          refreshOrganization()
        })
        .catch(() => {
          toast({ description: 'Failed to send invite', status: 'error' })
        })
        .finally(() => {
          setInviteSeat(undefined)
          setInviteModalIsVisible(false)
        })
    }
  }

  function clearSeatAssignmentAction(seatId: number) {
    clearSeatAssignment(seatId)
      .then(() => {
        toast({ description: 'Seat assignment cleared', status: 'success' })
        refreshOrganization()
      })
      .catch((e) => {
        toast({
          description: 'Failed to create request',
          status: 'error'
        })
      })
  }

  let orgDisplayName = organization ? organization.name : ''

  if (organization?.is_space_admin) {
    orgDisplayName = orgDisplayName + ' (Space Admin)'
  }

  return (
    <LayoutMemberDashboard>
      <RemovedOrganizationAlert removed={organization?.is_deleted} />
      {/* Header */}
      <GroupingHeaderLayout
        basicTitle={orgDisplayName}
        action={headerAction}
        tabs={headerGroups}
        iconAction={iconActions}
      />

      {/* Tables */}
      <AdminBodyWrapper>
        {tableFilter === 'team_members' && (
          <TeamMembers
            orgId={orgId}
            title="All Members"
            members={allMembers}
            loading={loading}
            isSpaceAdmin={organization?.is_space_admin === true}
            handleActionInvite={handleInviteAction}
            triggerRemoveMember={(member) => {
              setMemberToRemove(member)
            }}
          />
        )}

        {tableFilter === 'membership' && (
          <>
            <TeamMembers
              orgId={orgId}
              title="Members without plans"
              members={membersWithoutPlans}
              loading={loading}
              isSpaceAdmin={organization?.is_space_admin === true}
              handleActionInvite={handleInviteAction}
              triggerRemoveMember={(member) => {
                setMemberToRemove(member)
              }}
            />
            {offices.map((office, index) => (
              <Office
                key={index}
                title={office.name}
                office={office}
                loading={loading}
                handleActionInvite={handleInviteAction}
                clearSeatAssignment={clearSeatAssignmentAction}
              />
            ))}
            {organization && (
              <TeamSeats
                organizationId={organization.id}
                title="Coworking membership"
                seats={coworkerSeats}
                loading={loading}
                handleActionInvite={handleInviteAction}
                clearSeatAssignment={clearSeatAssignmentAction}
                manageSeatMembership={manageMembershipTrigger}
              />
            )}
            {organization && futureSeats && futureSeats.length > 0 && (
              <TeamSeats
                organizationId={organization.id}
                isFutureSeat={true}
                title="Upcoming Memberships"
                seats={futureSeats}
                loading={loading}
                handleActionInvite={handleInviteAction}
                clearSeatAssignment={clearSeatAssignmentAction}
                manageSeatMembership={manageFutureMembershipTrigger}
              />
            )}
          </>
        )}

        {tableFilter === 'extra_subscriptions' && (
          <>
            <TeamSubscriptions
              orgId={orgId}
              title="Extra Subscriptions"
              loading={loading}
              handleActionAddSubscription={handleSubscriptionAction}
              handleActionCancelSubscription={setSubscriptionManagerToManage}
            />
          </>
        )}
      </AdminBodyWrapper>

      {/* MODALS */}
      <InviteMemberModal
        visable={inviteModalIsVisible}
        seat={inviteSeat}
        membersWithoutPlans={membersWithoutPlans}
        handleCancel={() => setInviteModalIsVisible(false)}
        handleSendInvite={sendInvite}
      />
      <RequestSeatModal
        isSubmitting={isRequestingSeat}
        visable={requestSeatModalIsVisible}
        handleCancel={() => setRequestSeatModalIsVisible(false)}
        handleSendRequest={requestSeat}
      />
      <RemoveMemberModal
        allMembers={allMembers}
        member={memberToRemove}
        closeModalCallback={() => setMemberToRemove(undefined)}
        handleRemoveMember={handleRemoveMember}
      />
      <ManageMembershipModal
        seat={seatToManage}
        closeModalCallback={() => setSeatToManage(undefined)}
      />

      <ManageAddonPlanModal
        subscription_manager={subscriptionManagerToManage}
        closeModalCallback={() => setSubscriptionManagerToManage(undefined)}
      />
      {orgId !== undefined && (
        <ExtraSubscriptionModal
          orgId={orgId}
          isOpen={isOpenAddonModal}
          onClose={onCloseAddonModal}
        />
      )}
    </LayoutMemberDashboard>
  )
}
