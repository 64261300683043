import { ChakraProvider } from '@chakra-ui/react'
import React, { useContext, useEffect, useState } from 'react'
import logoLongWhite from '../assets/logo-long-white.png'
import logoLong from '../assets/logo-long.png'
import logoSquare from '../assets/logo-square.png'
import { routeStrings } from '../routeStrings'
import theme, { MyTheme } from '../theme/theme'
import { IPlatformTenant, IPlatformTenantTheme } from '../types'
import { useAuth } from './auth-context'
import { usePlatformTenant } from './platform-tenant-context'
import { useLocation } from 'react-router'

interface IEditableTheme {
  logos: {
    logo_long_white: string
    logo_long: string
    logo_square: string
  }

  colors: {
    brandPrimary: string
  }

  buttons: {
    primary: {
      height: string
      color: string
      bg: string
      border: string
      borderColor: string
      fontWeight: string
      borderRadius: string
      transition: string
      ':hover': {
        cursor: string
        transform: string
        boxShadow: string
      }
    }
    secondary: {
      height: string
      color: string
      bg: string
      fontWeight: string
      border: string
      borderColor: string
      borderRadius: string
      transition: string
      ':hover': {
        cursor: string
        transform: string
        boxShadow: string
        bg: string
      }
    }
  }
}

type EditableTheme = IEditableTheme & MyTheme

export interface EditableThemeContextProps extends EditableTheme {}

const defaultProps: EditableThemeContextProps = {
  ...theme,
  logos: {
    logo_long_white: logoLongWhite,
    logo_long: logoLong,
    logo_square: logoSquare
  },

  colors: {
    ...theme.colors,
    brandPrimary: '#EB4969'
  },
  buttons: {
    primary: {
      height: 'fit-content',
      color: 'white',
      bg: '#EB4969',
      border: '1px solid',
      borderColor: '#EB4969',
      fontWeight: '500',
      borderRadius: '50px',
      transition: 'all ease .2s',
      ':hover': {
        cursor: 'pointer',
        transform: 'scale(1.008)',
        boxShadow: '0px 22px 22px rgba(0, 0, 0, 0.04)'
      }
    },
    secondary: {
      height: 'fit-content',
      color: '#EB4969',
      bg: 'white',
      border: '1px solid',
      borderColor: '#EB4969',
      borderRadius: '50px',
      transition: 'all ease .2s',
      fontWeight: 'bold',
      ':hover': {
        cursor: 'pointer',
        transform: 'scale(1.008)',
        boxShadow: '0px 22px 22px rgba(0, 0, 0, 0.04)',
        bg: '#F4F5F5' //'rgb(235,73,105, 0.2)'
      }
    }
  }
}

export const EditableThemeContext =
  React.createContext<EditableThemeContextProps>(defaultProps)
const customUnauthRoutes = [
  routeStrings.location,
  routeStrings.plan,
  routeStrings.startAllendaleFernPlan,
  routeStrings.startIlumaPlan,
  routeStrings.singlePageSignup,
  routeStrings.details,
  routeStrings.termsAndConditions,
  routeStrings.acceptTermsAndConditions,
  routeStrings.thankyou
]
export const EditableThemeProvider = ({ children }) => {
  // get location profile details, render the theme
  const { platformTenant, platformTheme } = usePlatformTenant()
  const auth = useAuth()
  const location = useLocation()

  const [editableTheme, setEditableTheme] =
    useState<EditableTheme>(defaultProps)

  useEffect(() => {
    let route = window.location.pathname
    route =
      route.endsWith('/') && route.length > 1
        ? route.substring(0, route.length - 1)
        : route

    const isRouteThemeCustomizable = customUnauthRoutes.includes(route)
    const customTheme: IPlatformTenantTheme | IPlatformTenant | undefined =
      isRouteThemeCustomizable
        ? platformTheme ?? platformTenant
        : platformTenant
    setEditableTheme({
      ...theme,
      logos: {
        logo_long_white: customTheme?.logo_long_white || logoLongWhite,
        logo_long: customTheme?.logo_long || logoLong,
        logo_square: customTheme?.logo_square || logoSquare
      },
      colors: {
        ...theme.colors,
        brandPrimary: customTheme?.brand_primary_color || '#EB4969'
      },
      buttons: {
        primary: {
          height: 'fit-content',
          color: 'white',
          border: '1px solid',
          borderColor: customTheme?.brand_primary_color || '#EB4969',
          bg: customTheme?.brand_primary_color || '#EB4969',

          fontWeight: '500',
          borderRadius: '50px',
          transition: 'all ease .2s',
          ':hover': {
            cursor: 'pointer',
            transform: 'scale(1.008)',
            boxShadow: '0px 22px 22px rgba(0, 0, 0, 0.04)'
          }
        },

        secondary: {
          height: 'fit-content',

          color: customTheme?.brand_primary_color || '#EB4969',
          bg: 'white',
          border: '1px solid',
          borderColor: customTheme?.brand_primary_color || '#EB4969',

          borderRadius: '50px',
          transition: 'all ease .2s',
          fontWeight: 'bold',
          ':hover': {
            cursor: 'pointer',
            transform: 'scale(1.008)',
            boxShadow: '0px 22px 22px rgba(0, 0, 0, 0.04)',

            bg: '#F4F5F5'
          }
        }
      }
    })
  }, [platformTheme, platformTenant, location])

  return (
    <EditableThemeContext.Provider value={{ ...editableTheme }}>
      <ChakraProvider theme={editableTheme}>{children}</ChakraProvider>
    </EditableThemeContext.Provider>
  )
}

export const useEditableTheme = () => {
  const context = useContext(EditableThemeContext)
  if (context === undefined) {
    throw new Error(
      'useEditableTheme must be used within an EditableThemeProvider'
    )
  }
  return context
}
