import { useTheme } from '@chakra-ui/react'
import React, { useState } from 'react'
import Joyride, { ACTIONS, CallBackProps, StoreHelpers } from 'react-joyride'
import { useHistory } from 'react-router'
import { useAppTourContextProvider } from '../../context/app-tour-context'
import { useAuth } from '../../context/auth-context'
import { routeStrings } from '../../routeStrings'
import { tourStepsArrays } from '../../tourStepsArrays'

interface Props {}

export const MemberTourJoyride: React.FC<Props> = (props) => {
  const { run, setRun, setShouldShowMemberTour, setTourActive, stepIndex } =
    useAppTourContextProvider()

  const { generalMemberSteps: steps } = tourStepsArrays
  const { me, currentOrgUnit } = useAuth()
  const history = useHistory()
  const theme = useTheme()
  const [helpers, setHelpers] = useState<StoreHelpers>()

  const callbackHandler = (value: CallBackProps) => {
    const { action } = value
    if (window.location.pathname != routeStrings.memberDashboardHome) {
      history.push(routeStrings.memberDashboardHome)
    }
    if (action === ACTIONS.RESET || action === ACTIONS.CLOSE) {
      if (helpers) {
        setTourActive(false)
        setRun(false)
        helpers.go(0)
        if (me && currentOrgUnit) {
          window.localStorage.setItem(`member-tour-${me?.user.id}`, 'done')
          setShouldShowMemberTour(false)
        }
      }
    }
  }

  return (
    <Joyride
      run={run}
      callback={callbackHandler}
      styles={{
        options: {
          arrowColor: '#fff',
          backgroundColor: '#fff',
          primaryColor: theme.colors.brandPrimary,
          textColor: theme.black
        },
        tooltipTitle: { color: theme.colors.brandPrimary, fontWeight: 'bold' }
      }}
      getHelpers={(_helpers) => {
        if (!helpers) {
          setHelpers(_helpers)
        }
      }}
      steps={steps}
      showSkipButton={true}
      continuous
      disableOverlayClose
    />
  )
}
