import {
  Box,
  Flex,
  FlexProps,
  Stack,
  StackProps,
  Text,
  Wrap
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { fetchMyPosts } from '../../../api'
import { Button } from '../../../components/Button'
import { useAuth } from '../../../context/auth-context'
import { useContentExplorer } from '../../../context/content-explorer-context'
import { usePlatformTenant } from '../../../context/platform-tenant-context'
import { routeStrings } from '../../../routeStrings'
import { CenteredSpinner } from '../../../styled/CenteredSpinner'
import { IDailyFiveContent, Interest } from '../../../types'
import { useMyUserProfile } from '../../../utils/apiHooks'
import { BigDailyFive } from '../../DailyFive/BigDailyFive'
import { SmallDailyFive } from '../../DailyFive/SmallDailyFive'

interface Props extends FlexProps {}

export const MyPostsDisplay: React.FC<Props> = ({ ...rest }) => {
  const { me } = useAuth()
  const { data: userProfile, revalidate } = useMyUserProfile(
    me?.user?.user_profile.id
  )

  const { platformTenant } = usePlatformTenant()
  const { activeTab, postRemoved } = useContentExplorer()
  const [page, setPage] = useState<number>(1)
  const [nextExists, setNextExists] = useState<boolean>(true)

  const [content, setContent] = useState<IDailyFiveContent[]>([])
  const [loadingPosts, setLoadingPosts] = useState<boolean>(false)

  useEffect(() => {
    fetchContent(true)
  }, [platformTenant])

  useEffect(() => {
    if (activeTab === 'MYPOSTS') {
      fetchContent(true)
    }
  }, [activeTab])

  const fetchContent = (refresh?: boolean) => {
    const selectedPage = refresh ? 1 : page
    if (platformTenant) {
      fetchMyPosts(platformTenant?.id, selectedPage).then((res) => {
        if (res.status) {
          setNextExists(res.data.next != null)
          let newContent: IDailyFiveContent[] = res.data.results
            ? res.data.results
            : []
          setContent(
            selectedPage === 1 ? newContent : content.concat(newContent)
          )
          setPage(nextExists ? selectedPage + 1 : 1)
        }
      })
    }
  }

  useEffect(() => {
    if (postRemoved) {
      setContent(content.filter((post) => post.id != postRemoved))
    }
  }, [postRemoved])

  return (
    <Box
      flexDirection="column"
      // h="100%"
      w="100%"
      align="stretch"
      {...rest}
    >
      {' '}
      <Wrap spacing={6} paddingBottom="32px" align="bottom">
        {content &&
          content.map((c, i) => (
            <BigDailyFive
              w={['100%', '100%', '100%', '45%']}
              key={i}
              content={c}
              imageHeight="280px"
            />
          ))}
        {!loadingPosts && content?.length === 0 ? NoPostsFound() : null}
      </Wrap>
      {nextExists && (
        <Box textAlign="center">
          <Button
            w="50%"
            mb="20px"
            variant="secondary"
            onClick={() => fetchContent()}
            disabled={loadingPosts}
          >
            {' '}
            {!loadingPosts && nextExists ? 'Load more Posts' : ''}
            {loadingPosts ? <CenteredSpinner /> : null}{' '}
          </Button>
        </Box>
      )}
    </Box>
  )
}

const NoPostsFound = () => {
  return (
    <Box p="20px" textAlign="center">
      <Text fontSize={'16px'} color="grey">
        It looks like you have not posted anything yet. Try adding a{' '}
        <Link to={routeStrings.memberDashboardCreatePost}>New Post</Link>
      </Text>
    </Box>
  )
}
