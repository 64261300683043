import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast
} from '@chakra-ui/react'
import { Field, Formik } from 'formik'
import React from 'react'
import { updateEFTAccount } from '../../../api'
import { Button } from '../../../components/Button'
import { StyledInput } from '../../../components/StyledInputs'
import { usePlatformTenant } from '../../../context/platform-tenant-context'
import { Plan } from '../../../types'
import { useTenantEFTAccount } from '../../../utils/apiHooks'

interface IModalInput {
  isOpen: boolean
  onClose: () => void
  planToEdit?: Plan
}
export const EditEFTAccountModal: React.FC<IModalInput> = ({
  isOpen,
  onClose
}) => {
  const { platformTenant } = usePlatformTenant()

  const account = useTenantEFTAccount(platformTenant?.id)

  if (!(platformTenant && account.data)) {
    return null
  }
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent minW={['450px', '600px']} borderRadius="6px">
        <ModalHeader pb={5}>Update EFT Account</ModalHeader>
        <ModalCloseButton />

        <Formik
          initialValues={{
            bsb_number: account.data?.bsb_number ?? '',
            account_number: account.data?.account_number ?? '',
            bank: account.data?.bank ?? '',
            account_name: account.data?.account_name ?? ''
          }}
          onSubmit={(values, actions) => {
            updateEFTAccount(platformTenant.id, values).then((res) => {
              account.revalidate()
              onClose()
            })
          }}
          render={(props) => (
            <form onSubmit={props.handleSubmit}>
              <ModalBody>
                <Field
                  name="bsb_number"
                  render={({ field, form }) => (
                    <FormControl
                      isRequired
                      isInvalid={
                        form.errors.bsb_number && form.touched.bsb_number
                      }
                      pt={2}
                    >
                      <FormLabel htmlFor="bsb_number">BSB Number</FormLabel>
                      <StyledInput {...field} id="bsb_number" w="100%" />

                      <FormErrorMessage>
                        {form.errors.bsb_number}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />
                <Field
                  name="account_number"
                  render={({ field, form }) => (
                    <FormControl
                      isRequired
                      isInvalid={
                        form.errors.account_number &&
                        form.touched.account_number
                      }
                      pt={2}
                    >
                      <FormLabel htmlFor="account_number">
                        Account Number
                      </FormLabel>
                      <StyledInput {...field} id="account_number" w="100%" />

                      <FormErrorMessage>
                        {form.errors.account_number}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />
                <Field
                  name="bank"
                  render={({ field, form }) => (
                    <FormControl
                      isRequired
                      isInvalid={form.errors.bank && form.touched.bank}
                      pt={2}
                    >
                      <FormLabel htmlFor="bank">Bank</FormLabel>
                      <StyledInput {...field} id="bank" w="100%" />

                      <FormErrorMessage>{form.errors.bank}</FormErrorMessage>
                    </FormControl>
                  )}
                />
                <Field
                  name="account_name"
                  render={({ field, form }) => (
                    <FormControl
                      isRequired
                      isInvalid={
                        form.errors.account_name && form.touched.account_name
                      }
                      pt={2}
                    >
                      <FormLabel htmlFor="account_name">Account Name</FormLabel>
                      <StyledInput {...field} id="account_name" w="100%" />

                      <FormErrorMessage>
                        {form.errors.account_name}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />
              </ModalBody>

              <ModalFooter justifyContent="flex-start">
                <Button disabled={props.isSubmitting} type="submit" mr={3}>
                  Save
                </Button>
                <Button variant="secondary" onClick={onClose}>
                  Cancel
                </Button>
              </ModalFooter>
            </form>
          )}
        />
      </ModalContent>
    </Modal>
  )
}
