import {
  Box,
  Flex,
  useTheme,
  useDisclosure,
  Progress,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  Text,
  Heading,
  Portal
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { AdminBodyWrapper } from '../../../components/AdminBodyWrapper'
import { GroupingHeaderLayout } from '../../../components/GroupingHeaderLayout'
import { LayoutMemberDashboard } from '../LayoutMemberDashboard'
import { EventContentArg } from '@fullcalendar/core'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import { usePlatformTenant } from '../../../context/platform-tenant-context'
import { getMonthlyEvents } from '../../../api'
import {
  ApprovalStatusChoices,
  IDailyFiveContent,
  IEventsFilter,
  IInterval
} from '../../../types'
import { useCustomToast } from '../../../context/toast-context'
import { ViewEventModal } from '../../AdminDashboard/Modals/ViewEventModal'
import { Actions, useRBAC } from '../../../utils/rbac'
import { useFeatureFlags } from '../../../context/feature-flag-context'
import { CreatePostModal } from '../../AdminDashboard/Modals/CreatePostModal'
import { setHours } from 'date-fns/esm'
import { format, isSameDay } from 'date-fns'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import { analyticsTrack } from '../../../utils/analytics'
import { useAuth } from '../../../context/auth-context'
import { EventsCalendarFilters } from '../../../components/EventsCalendarFilters'

interface MontlyEvents {
  month: string
  events: IDailyFiveContent[]
}

interface CalendarEvent {
  title: string
  date: Date
  start?: Date | string
  end?: Date | string
  id?: string

  extendedProps?: any
  startStr?: any
  endStr?: any
  backgroundColor?: any
  textColor?: any
  allDay?: boolean
  display?: string
}
const defaultEventsBackground = '#2c3e50'

export const EventsCalendarView: React.FC = () => {
  const { me, currentOrganization } = useAuth()
  const { platformTenant } = usePlatformTenant()
  const theme = useTheme()
  const history = useHistory()
  const { newToast: toast } = useCustomToast()
  const canCreateNewPost = useRBAC(Actions.CreateNewPost)
  const { dailyFiveEnabled } = useFeatureFlags()

  const [selectedDates, setSelectedDates] = useState<IInterval>()
  const [selectedContent, setSelectedContent] = useState<
    IDailyFiveContent | undefined
  >()
  const [monthlyEvents, setMonthlyEvents] = useState<MontlyEvents[]>()
  const [isFetching, setIsFecthing] = useState(false)

  const { isOpen, onClose, onOpen } = useDisclosure()
  const {
    isOpen: isOpenCreatePost,
    onClose: onCloseCreatePost,
    onOpen: onOpenCreatePost
  } = useDisclosure()

  const [events, setEvents] = useState<CalendarEvent[]>([])
  const [eventsBackground, setEventsBackground] = useState<string>()
  const [eventsFilter, setEventsFilter] = useState<IEventsFilter>({})

  const handleEventClick = (eventClick: EventContentArg) => {
    let content = eventClick.event.extendedProps as IDailyFiveContent
    setSelectedContent(content)
    onOpen()
  }

  const handleOnClose = () => {
    onClose()
    setSelectedContent(undefined)
  }

  const handleAddToCalendar = () => {
    onClose()
    if (selectedContent) {
      alert('Add to Calendar' + selectedContent.title)
    }
  }
  const headerAction = {
    text: 'Create Event',
    variant: 'primary',
    onClick: () => {
      onOpenCreatePost()
      const trackingData = {
        userId: me?.user.id,
        organization: currentOrganization?.id,
        tenant: platformTenant?.id
      }
      analyticsTrack('Calendar view create post', trackingData)
    }
  }

  const fetchEvents = (filterData: IEventsFilter) => {
    if (!selectedDates || !platformTenant) return null
    setIsFecthing(true)
    getMonthlyEvents(
      platformTenant?.id,
      selectedDates.start,
      selectedDates.end,
      filterData
    )
      .then((res) => {
        if (res && res.data) {
          let showMoreDates: CalendarEvent[] = []
          let eventList: CalendarEvent[] = []
          let currentDate: Date
          let counter = 0
          res.data.forEach((e) => {
            try {
              if (!e.date) return

              let eventDate = new Date(Date.parse(e.date))
              if (
                currentDate === undefined ||
                !isSameDay(eventDate, currentDate)
              ) {
                if (counter > 3)
                  showMoreDates.push({
                    title: `${counter - 3} More`,
                    date: setHours(currentDate, 22),
                    allDay: true,
                    backgroundColor: 'transparent',
                    textColor: defaultEventsBackground,
                    extendedProps: {
                      events: [...eventList],
                      extraItems: counter
                    }
                  })
                currentDate = eventDate
                counter = 1
                eventList = [
                  mapDailyFiveToEventType(e, platformTenant.brand_primary_color)
                ]
                return
              }
              if (isSameDay(currentDate, eventDate)) {
                counter++
                eventList.push(
                  mapDailyFiveToEventType(e, platformTenant.brand_primary_color)
                )
              }

              if (counter > 3) {
                e.calendar_hide_event = true
                eventList.push(
                  mapDailyFiveToEventType(e, platformTenant.brand_primary_color)
                )
              }
            } catch (error) {
              console.log(error)
            }
          })
          let events = res.data
            .filter((content) => content.date != undefined)
            .map((content) => {
              return mapDailyFiveToEventType(
                content,
                platformTenant.brand_primary_color
              )
            })

          setEvents([...events, ...showMoreDates])
        }
        setIsFecthing(false)
      })
      .catch((error) => {
        setIsFecthing(false)
        console.log(error)
        toast({ description: 'An error occured', status: 'error' })
      })
  }

  useEffect(() => {
    const trackingData = {
      userId: me?.user.id,
      organization: currentOrganization?.id,
      tenant: platformTenant?.id
    }
    analyticsTrack('Calendar view', trackingData)
  }, [])

  useEffect(() => {
    if (platformTenant) {
      setEvents([])
      setEventsBackground(
        platformTenant.brand_primary_color || theme.colors.brandPrimary
      )
      fetchEvents({})
    }
  }, [platformTenant, selectedDates])

  const handleFilterChange = (filterData: IEventsFilter) => {
    fetchEvents(filterData)
    setEventsFilter(filterData)
  }
  const trackEventOpen = async (content: IDailyFiveContent) => {
    const trackingData = {
      userId: me?.user.id,
      contentId: content.id,
      title: content.title,
      tenant: platformTenant?.id
    }
    analyticsTrack('Calendar event open', trackingData)
  }

  return (
    <LayoutMemberDashboard>
      <GroupingHeaderLayout
        basicTitle="Community Calendar"
        action={canCreateNewPost && dailyFiveEnabled ? headerAction : undefined}
        compact={true}
      />
      <AdminBodyWrapper mt={3}>
        <EventsCalendarFilters
          loading={isFetching}
          eventsFilter={eventsFilter}
          onFilterChange={handleFilterChange}
        />

        <Box h="5px">
          {isFetching && (
            <Progress size="xs" colorScheme="gray" isIndeterminate />
          )}
        </Box>
        <Box
          bg="white"
          w="100%"
          boxShadow="0px 1px 0px rgba(0, 0, 0, 0.05)"
          rounded="md"
          p={3}
          mb={2}
          position="relative"
          color="eastBay09"
          align="left"
        >
          {theme && (
            <FullCalendar
              plugins={[dayGridPlugin]}
              events={events}
              datesSet={(dateSet) => {
                setSelectedDates({ start: dateSet.start, end: dateSet.end })
              }}
              headerToolbar={{
                right: 'prev,next',
                center: 'title',
                left: 'today'
              }}
              eventTimeFormat={{
                hour: 'numeric',
                minute: '2-digit',
                omitZeroMinute: true,
                meridiem: 'short'
              }}
              buttonText={{
                today: 'Today'
              }}
              eventBorderColor={'#aaa'}
              eventBackgroundColor={platformTenant?.brand_primary_color}
              eventOrder={['']}
              dayHeaderClassNames={['events-cal-day-header']}
              nowIndicatorClassNames={['events-cal-now-indicator']}
              dayCellClassNames={['events-cal-day-cell']}
              eventClassNames={'display-none'}
              eventContent={(arg) => {
                return !arg.event.extendedProps ||
                  !arg.event.extendedProps.extraItems ? (
                  calendarEventElement(
                    arg.event.title,
                    arg.event.allDay
                      ? undefined
                      : arg.event.extendedProps.startStr
                      ? new Date(arg.event.extendedProps.startStr)
                      : undefined,
                    'transparent',
                    () => {
                      let content = arg.event.extendedProps as IDailyFiveContent
                      setSelectedContent(content)
                      trackEventOpen(content)
                      onOpen()
                    }
                  )
                ) : (
                  <Popover
                    onOpen={() => {
                      if (
                        !arg.event.extendedProps ||
                        !arg.event.extendedProps.extraItems
                      )
                        handleEventClick(arg)
                    }}
                  >
                    <PopoverTrigger>
                      {calendarEventElement(
                        arg.event.title,
                        arg.event.allDay
                          ? undefined
                          : arg.event.start
                          ? arg.event.start
                          : undefined,
                        arg.event.backgroundColor
                      )}
                    </PopoverTrigger>
                    <Portal>
                      <Box zIndex={'popover'} w="full" h="full">
                        <PopoverContent>
                          <PopoverArrow />
                          <PopoverCloseButton />
                          <PopoverHeader>More events</PopoverHeader>
                          <PopoverBody color={defaultEventsBackground} px={4}>
                            <Heading
                              color={defaultEventsBackground}
                              fontSize="md"
                              textAlign="center"
                            >
                              {format(arg.event.start || new Date(), 'd')}
                            </Heading>
                            {arg.event.extendedProps &&
                            arg.event.extendedProps.extraItems &&
                            arg.event.extendedProps.events
                              ? arg.event.extendedProps.events.map(
                                  (e: CalendarEvent) => {
                                    return calendarEventElement(
                                      e.title,
                                      e.date,
                                      'transparent',
                                      () => {
                                        let content =
                                          e.extendedProps as IDailyFiveContent
                                        setSelectedContent(content)
                                        onOpen()
                                      }
                                    )
                                  }
                                )
                              : null}
                          </PopoverBody>
                        </PopoverContent>
                      </Box>
                    </Portal>
                  </Popover>
                )
              }}
            />
          )}
        </Box>
      </AdminBodyWrapper>
      <ViewEventModal
        isOpen={isOpen}
        onClose={handleOnClose}
        content={selectedContent}
        onAddToCalendar={handleAddToCalendar}
      />
      <CreatePostModal
        onClose={onCloseCreatePost}
        isOpen={isOpenCreatePost}
        onEventCreated={(event) => {
          if (
            event &&
            event.content_type === 'EVENT' &&
            event.approval_status === ApprovalStatusChoices.APPROVED
          ) {
            setEvents((e) => [
              ...e,
              mapDailyFiveToEventType(event, eventsBackground)
            ])
            onCloseCreatePost()
          }
        }}
      />
    </LayoutMemberDashboard>
  )
}
function calendarEventElement(
  title: string,
  eventStart?: Date,
  backgroundColor?: string,
  onClick?: () => void
) {
  return (
    <Flex
      overflowX="hidden"
      onClick={() => {
        if (onClick) {
          onClick()
        }
      }}
      py={'0.5'}
      pl={1}
      bg={eventStart ? '' : backgroundColor}
    >
      {eventStart && (
        <Text fontSize="xs" mr={1}>
          <FontAwesomeIcon icon={faCircle} size="xs" />{' '}
          {format(eventStart, 'h:mmaaa').replace(':00', '').toLowerCase()}
        </Text>
      )}
      <Text fontWeight="800" _hover={{ color: { backgroundColor } }}>
        {title}
      </Text>
    </Flex>
  )
}

function mapDailyFiveToEventType(
  content: IDailyFiveContent,
  eventsBackground,
  isShowMore?: boolean,
  extendedProps?: CalendarEvent[]
): CalendarEvent {
  let isPublic = content.platform_tenant === undefined
  let eventDate = content.date ? new Date(Date.parse(content.date)) : new Date()
  return {
    id: content.id + '',
    title: content.title,
    date: eventDate,
    extendedProps: extendedProps ?? content,
    startStr: convertTimes(eventDate, content.start_time),
    endStr: convertTimes(eventDate, content.finish_time),
    backgroundColor: isPublic ? eventsBackground : defaultEventsBackground,
    allDay: !content.start_time,
    display: content.calendar_hide_event ? 'none' : 'auto'
  }
}
const convertTimes = (
  chosenDate: Date | undefined,
  chosenTime: string | undefined
) => {
  if (!chosenDate || !chosenTime) return undefined
  try {
    return new Date(
      format(new Date(chosenDate), 'yyyy-MM-dd') + 'T' + chosenTime
    ).toISOString()
  } catch (e) {
    console.error('Could not cast ' + chosenTime)
    console.error(e)
    return undefined
  }
}
