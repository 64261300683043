import React, { useState } from 'react'
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Flex } from '@chakra-ui/react'
import { CrossCustomIcon } from './icons/icons'

export const AlertBox = (props) => {
  const [hide, setHide] = useState(false)

  if (hide) {
    return null
  }
  return (
    <Flex
      justify="center"
      position="relative"
      background="#ffffff"
      boxShadow="0px 8px 16px rgba(0, 0, 0, 0.1)"
      borderRadius="4px"
      padding="15px"
      margin="5px"
    >
      <CrossCustomIcon
        position="absolute"
        color="gray.300"
        top="-1px"
        right="-1px"
        _hover={{ cursor: 'pointer' }}
        size="18px"
        onClick={() => {
          setHide(true)
          // props && props.onClose()
        }}
      />

      {props.children}
    </Flex>
  )
}
