import { Box, HStack, Text, useDisclosure } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { AdminBodyWrapper } from '../../components/AdminBodyWrapper'
import { LocationAdminSelector } from '../../components/LocationAdminSelector'
import { IAction } from '../../components/table/ActionsDropdown'
import { useAppTourContextProvider } from '../../context/app-tour-context'
import { useBookingContext } from '../../context/BookingContext'
import { usePlatformTenant } from '../../context/platform-tenant-context'
import { IMeetingRoomSuperAdmin } from '../../types'
import { Actions, useRBAC } from '../../utils/rbac'
import { CreateMeetingRoomModal } from './Modals/CreateMeetingRoomModal'
import { MeetingRoomsTable } from './Tables/MeetingRooms'

export const AdminDashboardMeetingRoomsAdmin: React.FC = () => {
  const { adminTourActive, setRun } = useAppTourContextProvider()
  useEffect(() => {
    if (adminTourActive) {
      setRun(true)
    }
  }, [])
  const [locationFilter, setLocationFilter] = useState<number>()
  const [filteredRooms, setFilteredRooms] = useState<IMeetingRoomSuperAdmin[]>(
    []
  )

  const { isOpen, onClose, onOpen } = useDisclosure()
  const [selectedRoom, setSelectedRoom] = useState<IMeetingRoomSuperAdmin>()
  const { platformTenant } = usePlatformTenant()
  const { tenantRooms } = useBookingContext()
  const canAdministerMeetingRooms = useRBAC(Actions.AdministerMeetingRooms)
  const rowActions: IAction[] = []
  canAdministerMeetingRooms &&
    rowActions.push({
      text: 'Edit meeting room',
      testIdPrefix: 'edit-meeting-room',
      action: (rowId: number) => {
        setSelectedRoom(
          tenantRooms.find((meetingRoom) => meetingRoom.id === rowId)
        )
        onOpen()
      }
    })

  /**
   * This useEffect sets the intial locationFilter, thus triggering filtering above
   */
  useEffect(() => {
    if (
      filteredRooms.length === 0 &&
      !locationFilter &&
      platformTenant &&
      platformTenant?.locations.length > 0
    ) {
      setLocationFilter(platformTenant?.locations[0].id)
    }
  }, [platformTenant])

  useEffect(() => {
    if (tenantRooms.length > 0 && locationFilter) {
      setFilteredRooms(
        tenantRooms.filter(
          (room) => room.location === locationFilter || !room.location
        )
      )
    }
  }, [locationFilter, tenantRooms])
  return (
    <AdminBodyWrapper>
      <Box
        p={4}
        display={{ base: 'flex' }}
        flexWrap="wrap"
        data-testid="locations-box"
      >
        {platformTenant?.locations.map((location) => (
          <LocationAdminSelector
            key={location.id.toString()}
            location={location}
            selected={locationFilter === location.id}
            onClick={() => setLocationFilter(location.id)}
            data-testid={`location-selector-${location.id}`}
          />
        ))}
      </Box>
      <Text mr={3} fontSize="xl" mb={1}>
        Meeting Room List
      </Text>
      <MeetingRoomsTable
        meetingRooms={filteredRooms}
        loading={!tenantRooms}
        editMeetingRoomAction={rowActions}
      />
      {isOpen && (
        <CreateMeetingRoomModal
          onClose={onClose}
          isOpen={isOpen}
          meetingRoomToEdit={selectedRoom}
        />
      )}
    </AdminBodyWrapper>
  )
}
