import { Flex } from '@chakra-ui/react'
import { css } from '@emotion/core'
import React from 'react'
import { useAdminBodyWidth } from './AdminBodyWrapper'

export const AdminHeaderLayout: React.FC<any> = (props) => {
  const { disabled, ...rest } = props

  const buttonHoverStyle = css`
    display: 'block';
  ` as any

  const width = useAdminBodyWidth()

  return (
    <Flex
      h={['70px', '132px']}
      width="100%"
      justify="center"
      background="white"
    >
      <Flex
        width={width}
        justify="space-between"
        direction="row"
        css={buttonHoverStyle}
        alignSelf="bottom"
        alignItems="bottom"
        mt="auto"
        mx={3}
        {...rest}
      >
        {props.children}
      </Flex>
    </Flex>
  )
}
