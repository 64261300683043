import { Box, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { useAuth } from '../context/auth-context'
import { CenteredSpinner } from '../styled/CenteredSpinner'
import { analyticsTrack } from '../utils/analytics'
import { useOrganization } from '../utils/apiHooks'
import { CheckCustomIcon } from './icons/icons'
import { TableRow } from './TableRow'

export enum paymentMethods {
  CREDIT = 'CREDIT',
  DIRECT_DEBIT = 'DIRECT_DEBIT',
  EFT = 'EFT',
  INACTIVE = 'INACTIVE'
}

// Interfaces based off Stripe responses
interface IDebitState {
  payment_type: string
  bsb_number: string
  last4: string
  //deprecated in migration to Stripe paymentMethods API
  // status: string
  mandate_status: string
  mandate_url: string
  display_text: string
}

interface ICreditState {
  payment_type: string
  brand: string
  exp_month: string
  exp_year: string
  last4: string
  display_text: string
}

export const PaymentDetails: React.FC = () => {
  const [billingState, setBillingState] = useState<paymentMethods | null>(null)
  const [debitState, setDebitState] = useState<IDebitState | null>(null)
  const [creditState, setCreditState] = useState<ICreditState | null>(null)

  const auth = useAuth()
  const { orgId: routeOrgId } = useParams<{ orgId?: string }>()

  const orgId =
    (routeOrgId && parseInt(routeOrgId, 10)) || auth.currentOrganization?.id

  const { data: organization, isValidating } = useOrganization(orgId)

  useEffect(() => {
    if (organization && organization.default_payment_method) {
      let paymentMethod = organization?.default_payment_method?.payment_method

      if (!organization?.default_payment_method || !paymentMethod) {
        setBillingState(paymentMethods.INACTIVE)
      } else {
        const type = paymentMethod?.payment_type
        // EFT
        if (type === 'EFT') setBillingState(paymentMethods.EFT)
        // Card
        else if (type === 'card') {
          setBillingState(paymentMethods.CREDIT)
          setCreditState({
            brand: paymentMethod.brand!,
            payment_type: paymentMethod.payment_type!,
            exp_month: paymentMethod.exp_month!,
            exp_year: paymentMethod.exp_year!,
            last4: paymentMethod.last4!,
            display_text: paymentMethod.display_text
          })
        }
        // Direct Debit  au_becs_debit
        else if (type === 'au_becs_debit') {
          setBillingState(paymentMethods.DIRECT_DEBIT)
          setDebitState({
            bsb_number: paymentMethod.bsb_number!,
            payment_type: paymentMethod.payment_type!,
            last4: paymentMethod.last4!,
            // status: currentPaymentMethod.status!,
            mandate_status: paymentMethod.mandate_status!,
            mandate_url: paymentMethod.mandate_url!,
            display_text: paymentMethod.display_text
          })
        }
        analyticsTrack('Team admin dashboard billing info screen', {
          currentPaymentMethod: paymentMethod.display_text
        })
      }
    }
  }, [organization])

  if (isValidating) return <CenteredSpinner />

  return (
    <Box my="3" maxWidth="650px">
      <Box d="flex" mb={3} alignItems="center">
        <Text mb={0} fontWeight="semibold" color="headingPrimary" fontSize="lg">
          Payment details
        </Text>
      </Box>
      {/* Payment detail states */}
      {billingState === paymentMethods.CREDIT && creditState && (
        <>
          <TableRow py={2}>
            <Text
              color="textPrimary"
              fontWeight="bold"
              fontSize="sm"
              width={'33%'}
            >
              Credit Card
            </Text>
            <Text color="textPrimary" fontSize="sm" mr="auto">
              **** **** **** {creditState.last4}
            </Text>
            <Text color="textPrimary" fontSize="sm" pr={8}>
              {creditState.brand}
            </Text>
          </TableRow>
          <TableRow justifyContent="flex-start" py={2}>
            <Text
              color="textPrimary"
              fontWeight="bold"
              fontSize="sm"
              width={'33%'}
            >
              Expiry Date
            </Text>
            <Text color="textPrimary" fontSize="sm">
              {creditState.exp_month}/{creditState.exp_year}
            </Text>
            <Box />
          </TableRow>
        </>
      )}
      {billingState === paymentMethods.DIRECT_DEBIT && debitState && (
        <>
          <TableRow py={2}>
            <Text
              color="textPrimary"
              fontWeight="bold"
              fontSize="sm"
              width="30%"
            >
              {debitState.display_text}
            </Text>
            <Text color="textPrimary" fontSize="sm" mr="auto">
              {/* **** **** **** {debitState.last4} */}
            </Text>
            <Text color="textPrimary" fontSize="sm">
              {/* {debitState.brand} */}
            </Text>
          </TableRow>
          <TableRow justifyContent="flex-start" py={2}>
            <Text
              color="textPrimary"
              fontWeight="bold"
              fontSize="sm"
              width={'33%'}
            >
              Mandate Status
            </Text>
            <Text color="textPrimary" fontSize="sm">
              {/* Show mandate status with a check or display in plain text */}
              {debitState.mandate_status === 'accepted' ? (
                <a href={debitState.mandate_url}>
                  Accepted
                  <CheckCustomIcon color="alertSuccess" ml={2} />
                </a>
              ) : (
                debitState.mandate_status
              )}
              {/* {debitState.exp_month}/{debitState.exp_year} */}
            </Text>
            <Box />
          </TableRow>
        </>
      )}
      {billingState === paymentMethods.EFT && (
        <>
          <TableRow justifyContent="space-between" py={2}>
            <Text
              color="textPrimary"
              fontWeight="bold"
              fontSize="sm"
              width={'33%'}
              mr="auto"
              mb={0}
            >
              Payment Method:
            </Text>
            <Text color="textPrimary" fontSize="sm" mb={0}>
              EFT
            </Text>
            <Box />
          </TableRow>
        </>
      )}
      {billingState === paymentMethods.INACTIVE && (
        <>
          <TableRow justifyContent="space-between" py={2}>
            <Text
              color="textPrimary"
              fontWeight="bold"
              fontSize="sm"
              width={'33%'}
              mr="auto"
              mb={0}
            >
              Payment Method:
            </Text>
            <Text color="textPrimary" fontSize="sm" mb={0}>
              Not Configured
            </Text>
            <Box />
          </TableRow>
        </>
      )}
    </Box>
  )
}
