import { Icon, Stack, useTheme } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Box, Image } from '@chakra-ui/react'
import { validateInvite } from '../../api'

import { useUserDetails } from '../../context/user-onboard-context'
import { IInvitation } from '../../types'
import { routeStrings } from '../../routeStrings'
import { CenteredSpinner } from '../../styled/CenteredSpinner'
import { CheckCustomIcon, CrossCustomIcon } from '../../components/icons/icons'
import { usePlatformTenant } from '../../context/platform-tenant-context'

interface IinviteState {
  type: 'ERROR' | 'VALIDATING' | 'VALID'
  headline?: string
  body?: string
}

export const Onboarding0Invite: React.FC = () => {
  const { setUserDetails } = useUserDetails()
  const [tenantId, setTenantId] = useState<number>()
  const { setThemeTenantLocation, platformTheme } = usePlatformTenant()
  const theme: any = useTheme()
  const history = useHistory()
  let { token } = useParams<{ token: any }>()
  const [inviteState, setInviteState] = useState<IinviteState>({
    type: 'VALIDATING',
    headline: 'Validating your token',
    body: 'You have been invited to join a team at Spacecubed'
  })

  useEffect(() => {
    token &&
      validateInvite(token)
        .then(({ data }: { data: IInvitation }) => {
          if (data?.tenant_id) {
            setThemeTenantLocation(data.tenant_id)
          }
          setInviteState({
            type: 'VALID',
            headline: 'You invite has been validated',
            body: 'Redirecting you to onboarding'
          })
          setUserDetails((currentValues) => ({
            ...currentValues,
            location_id: data.location,
            locationName: data.location_name,
            inviteToken: token,
            email: data.invitation_email,
            //if for some reason the is_team_admin flag is not present, set it to true
            //this flag is used to toggle showing the company name option on the form
            is_team_admin: data.is_team_admin ?? true
          }))
          setTimeout(() => {
            history.push(routeStrings.onboarding1Email)
          }, 750)
        })
        .catch((error) => {
          console.log('error', error)
          setInviteState({
            type: 'ERROR',
            headline: 'An error occured',
            body: 'Request your team admin for a new invite request, or contact support'
          })
        })
  }, [])

  return (
    <Box p={3}>
      <Image
        src={theme.logos.logo_long}
        width="128px"
        className="location-logo"
      />
      <Box mt={6}>
        <Stack isInline>
          <h1>{inviteState.headline}</h1>
          {inviteState.type === 'VALIDATING' && <CenteredSpinner />}
          {inviteState.type === 'ERROR' && (
            <CrossCustomIcon
              color="buttonPrimary"
              height="1.5rem"
              width="1.5rem"
              ml={2}
              pb="2px"
            />
          )}
          {inviteState.type === 'VALID' && (
            <CheckCustomIcon
              color="alertSuccess"
              height="1.5rem"
              width="1.5rem"
              ml={2}
            />
          )}
        </Stack>
        <h3>{inviteState.body}</h3>
        <br />
        <h4>{token}</h4>
        <br />
      </Box>
    </Box>
  )
}
