import {
  Box,
  Text,
  useDisclosure,
  Flex,
  Alert,
  AlertIcon
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { getOrgBonds } from '../api'
import { useAuth } from '../context/auth-context'
import { IBond } from '../types'
import { useOrganization } from '../utils/apiHooks'
import { format } from 'date-fns'
import { CreateBondModal } from '../pages/AdminDashboard/Modals/CreateBondModal'
import { TableDataProp, Table } from './table/Table'
import { Button } from './Button'

interface Props {
  removed?: boolean
}
export const RemovedOrganizationAlert: React.FC<Props> = ({ removed }) => {
  if (!removed) return null
  return (
    <Box my={2}>
      <Alert status="error">
        <AlertIcon />
        This company has been deactivated
      </Alert>
    </Box>
  )
}
