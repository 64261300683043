import { Box, Text, Link, Select, Stack } from '@chakra-ui/react'

import React, { useEffect, useState } from 'react'
import { RouterLink } from '../../../components/Link'
import { StatusBox } from '../../../components/StatusBox'
import { Table, TableDataProp } from '../../../components/table/Table'
import { UserDisplay } from '../../../components/UserDisplay'
import { useAuth } from '../../../context/auth-context'
import { getTeamMemberProfileUrl, routeStrings } from '../../../routeStrings'
import { IOrganization, IOrganizationalUnit, User } from '../../../types'

import { Roles, useRole } from '../../../utils/rbac'
import { useHistory } from 'react-router'
import { usePlatformTenant } from '../../../context/platform-tenant-context'

import 'antd/dist/antd.css'

interface Props {
  status?: string
  handleOnboarding: (ouId: number) => void
  handleCreateConnection: (ou: IOrganizationalUnit) => void
  orgUnits: IOrganizationalUnit[] | undefined
}

export const Members: React.FC<Props> = (props) => {
  const { orgUnits } = props
  const history = useHistory()
  const auth = useAuth()
  const { role, isInAdminRole } = useRole()
  const { platformTenant } = usePlatformTenant()
  const [loading, setLoading] = useState<boolean>(true)

  const [tableData, setTableData] = useState<any[]>([])
  const [filterStatus, setFilterStatus] = useState<string>('')
  const [filterPlans, setFilterPlans] = useState<string>('')
  const [filterLocations, setFilterLocations] = useState<string>('')
  const [OUstatuses, setOUStatuses] = useState<Set<string>>(new Set<string>())
  const [OUplans, setOUPlans] = useState<Set<string>>(new Set<string>())

  const getSignupStatus = (user: User) => {
    return (
      user.tenant_profiles.find(
        (tp) =>
          tp.platform_tenant ===
          auth.currentOrgUnit?.organization.parent_platform_tenant
      )?.signup_status ?? ''
    )
  }

  const Plan_Office_Item: React.FC<{ org_unit?: IOrganizationalUnit }> = ({
    org_unit
  }) => {
    if (!org_unit) return null
    if (!org_unit.seats || org_unit.seats.length == 0) {
      return null
    }
    const seat = org_unit.seats[0]
    const hasDesk = seat.office_desk != undefined

    return (
      <Box
        overflowWrap={'break-word'}
        flexWrap="wrap"
        maxW="inherit"
        fontSize="xs"
      >
        {!hasDesk && seat.ongoing_plan && (
          <Text overflowWrap={'break-word'} flexWrap="wrap">
            {seat.ongoing_plan.name}
          </Text>
        )}
        {hasDesk && <Text>Office {seat.office_information?.name}</Text>}
      </Box>
    )
  }

  useEffect(() => {
    if (orgUnits) {
      setLoading(false)
      const tempValidOus = orgUnits
        .filter((ou) => ou.user)
        .filter((member) => {
          return filterStatus
            ? getSignupStatus(member.user) === filterStatus
            : true
        })
        .filter((member) => {
          return filterPlans
            ? member.seats
              ? member.seats[0]?.ongoing_plan?.name === filterPlans
              : false
            : true
        })
        .filter((member) => {
          return filterLocations
            ? member.organization.home_location.name === filterLocations
            : true
        })

      const status = new Set<string>()
      const plans = new Set<string>()
      status.add('user_inactive')
      tempValidOus?.forEach((member) => {
        status.add(getSignupStatus(member.user))
        if (member.seats) plans.add(member.seats[0]?.ongoing_plan?.name)
      })
      setOUStatuses(status)
      setOUPlans(plans)

      setTableData(
        tempValidOus.map((ou) => {
          return {
            id: TableDataProp(ou.id),
            test_prefix: TableDataProp(ou.user.email),
            // rowData: TableDataProp(request),
            team: TableDataProp(OrgLink(ou.organization), ou.organization.name),
            profilePic: TableDataProp(
              <UserDisplay
                name={ou.user.name}
                src={ou.user.user_profile.profile_picture?.image}
              />
            ),
            name: [
              TableDataProp(MemberLink(ou), ou.user.name),
              TableDataProp(ou.user.email),
              TableDataProp(ou.user?.user_profile?.mobile_number)
            ],
            plan: TableDataProp(<Plan_Office_Item org_unit={ou} />),
            location: TableDataProp(
              ou?.organization?.home_location?.name,
              ou?.organization?.home_location?.name
            ),

            start_date: TableDataProp(ou.user.user_profile?.start_date),
            status: [
              TableDataProp(
                StatusRenderFilter(getMemberStatus(ou)),
                getMemberStatus(ou)
              )
            ],
            actions: TableDataProp(memberActions(ou))
          }
        })
      )
    } else {
      setTableData([])
      setLoading(true)
    }
  }, [orgUnits, filterPlans, filterStatus, filterLocations])

  const memberActions = (rowData?: any) => [
    {
      text: 'Onboarding Done',

      testIdPrefix: rowData?.user?.email
        ? 'onboard-' + rowData.user.email
        : 'onboard',
      action: (rowId: number) => {
        const ouObj = orgUnits?.find((obj) => obj.id === rowId)
        if (ouObj) {
          props.handleOnboarding(ouObj.user.id)
        }
      }
    },
    {
      text: 'Create Connection',

      testIdPrefix: rowData?.user?.email
        ? 'connect-' + rowData.user.email
        : 'connect',
      action: (rowId: number) => {
        const ouObj = orgUnits?.find((obj) => obj.id === rowId)
        if (ouObj) {
          props.handleCreateConnection(ouObj)
        }
      }
    }
  ]

  function MemberLink(member: IOrganizationalUnit) {
    return (
      <RouterLink
        color="#255CE1"
        mb={0}
        to={getTeamMemberProfileUrl(
          member.organization.id,
          member.id,
          isInAdminRole
        )}
      >
        {member.user.name}
      </RouterLink>
    )
  }

  function OrgLink(org: IOrganization) {
    if (org && auth) {
      return (
        <Link
          color="#255CE1"
          mb={0}
          onClick={() => {
            auth.adminChangeOrganization(org)
            history.push(routeStrings.adminTeamDetailView(org.id))
          }}
        >
          {org.name}
        </Link>
      )
    }
  }

  const getMemberStatus = (ou: IOrganizationalUnit) => {
    if (
      ![
        'awaiting_onboarding',
        'awaiting_approval',
        'user_inactive',
        'cancelled_plan'
      ].includes(getSignupStatus(ou.user))
    ) {
      const activeDeskorPlan = ou.seats?.find(
        (seat) => seat.office_desk !== undefined || seat.active_plan
      )
      if (activeDeskorPlan === undefined) {
        return 'user_inactive'
      }
    }
    // return other status
    return getSignupStatus(ou.user)
  }
  function StatusRenderFilter(status: string) {
    return (
      <StatusBox
        status={status}
        mapping={{
          awaiting_approval: { color: 'yellow', label: 'Awaiting Approval' },
          awaiting_onboarding: {
            color: 'yellow',
            label: 'Awaiting Onboarding'
          },
          approved_and_onboarded: { color: 'positive', label: 'Approved' },
          user_inactive: { color: 'blue', label: 'User Inactive' }
        }}
      />
    )
  }

  return (
    <div style={{ width: '100%', padding: '16px' }}>
      <Box my="3" w="100%">
        <Box d="flex" mb={3} alignItems="baseline">
          <Text
            mb={0}
            mr={3}
            color="headingPrimary"
            fontSize="lg"
            textTransform="capitalize"
          >
            {props.status ? props.status.toLowerCase() : 'All'} members
          </Text>
          <Text fontSize="xs" color="grey">
            {tableData.length} Total
          </Text>
        </Box>
        {/* <Stack isInline ml="53px" mt={6} spacing={3} mb={4}>
          <Select
            background="white"
            maxWidth="200px"
            id="memberStatus"
            placeholder="Status"
            color="#8b96a3"
            value={filterStatus}
            onChange={(ev) => setFilterStatus(ev.target.value)}
          >
            {Array.from(OUstatuses).map((status) => {
              return (
                <option key={status} value={status}>
                  {status}
                </option>
              )
            })}
          </Select>
          <Select
            background="white"
            maxWidth="200px"
            id="memberPlans"
            placeholder="Plan"
            color="#8b96a3"
            value={filterPlans}
            onChange={(ev) => setFilterPlans(ev.target.value)}
          >
            {Array.from(OUplans).map((name) => {
              return (
                <option key={name} value={name}>
                  {name}
                </option>
              )
            })}
          </Select>
          <Select
            background="white"
            maxWidth="200px"
            id="memberLocation"
            placeholder="Location"
            color="#8b96a3"
            value={filterLocations}
            onChange={(ev) => setFilterLocations(ev.target.value)}
          >
            {platformTenant?.locations.map((loc) => {
              return (
                <option key={loc.name} value={loc.name}>
                  {loc.name}
                </option>
              )
            })}
          </Select>
        </Stack> */}

        <Table
          pageSize={900}
          headers={[
            { header: 'id', accessor: 'id', show: false },
            { header: 'test_prefix', accessor: 'test_prefix', show: false },
            {
              header: '',
              accessor: 'profilePic',
              show: true,
              width: 1 / 24,
              minWidth: '45px'
            },
            {
              header: 'Name',
              accessor: 'name',
              show: true,
              width: 3.5 / 12
            },
            {
              header: 'Team',
              accessor: 'team',
              show: true,
              width: 2 / 12,
              enableFilter: false
            },
            {
              header: 'Status',
              accessor: 'status',
              show: true,
              width: 2 / 12
            },
            {
              header: 'Plan / Office',
              accessor: 'plan',
              show: true,
              width: 2 / 12
            },
            {
              header: 'Location',
              accessor: 'location',
              show: true,
              width: 2 / 12
            },
            {
              header: 'Start Date',
              accessor: 'start_date',
              show: true,
              width: 1 / 12,
              minWidth: '74px'
            },
            { header: '', accessor: 'actions', show: true, width: 0.5 / 12 }
            // { header: '', accessor: 'spacer', show: true, width: 3 / 12 }
          ]}
          data={tableData}
          loading={loading}
        />
      </Box>
    </div>
  )
}
