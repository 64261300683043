import {
  Box,
  Flex,
  FlexProps,
  Stack,
  Text,
  StackProps,
  Wrap,
  Spacer
} from '@chakra-ui/react'
import React, { useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useHistory } from 'react-router'
import comment from '../../assets/comment.svg'
import { useCommunity } from '../../context/community-context'
import { useContentExplorer } from '../../context/content-explorer-context'
import { routeStrings } from '../../routeStrings'
import { IDailyFiveContent } from '../../types'
import { TagItem } from '../MemberDashboard/Explorer/TagItem'
import { ContentMenu } from './ContentMenu'
import { SaveButton } from './SaveButton'
interface Props extends StackProps {
  content?: IDailyFiveContent
  imageHeight?: string
}

export const BigDailyFive: React.FC<Props> = ({
  content,
  imageHeight,
  ...rest
}) => {
  const { goToContent } = useCommunity()
  const { selectTag, selectedTags } = useContentExplorer()
  const history = useHistory()
  const [isShown, setIsShown] = useState(false)
  return (
    <Stack w="100%" align="space-between" {...rest}>
      <Box
        cursor="pointer"
        onClick={() => goToContent(content)}
        w="100%"
        h={imageHeight ? imageHeight : '70%'}
        background={
          content && content.image ? `url("${content?.image}")` : '#dadada'
        }
        backgroundSize="cover"
        backgroundPosition="center"
      ></Box>

      <Box w="100%">
        <Stack
          h="100%"
          alignItems="space-between"
          onMouseEnter={() => setIsShown(true)}
          onMouseLeave={() => setIsShown(false)}
        >
          <Stack>
            <Flex>
              <Text
                fontSize="xs"
                color="#255CE1"
                fontWeight="bold"
                margin="auto"
              >
                {content ? content.content_type : <Skeleton width={30} />}
              </Text>
              <Spacer />
              {isShown && <ContentMenu content={content} size={'xs'} />}
            </Flex>
            <Text
              fontSize="s"
              color="#000000"
              fontWeight="bold"
              cursor="pointer"
              onClick={() => goToContent(content)}
            >
              {content ? content.title : <Skeleton width={150} />}
            </Text>
          </Stack>
          <Wrap cursor={'pointer'} maxWidth={'inherit'}>
            {content &&
              content.interests.map((interest, i) => (
                <TagItem
                  key={i}
                  // isSelected={selectedTags.indexOf(interest) >= 0}
                  tag={interest}
                  selectTag={() => {
                    selectTag(interest)
                    history.push(
                      routeStrings.memberDashboardHomeContentExplorer
                    )
                  }}
                  size={'sm'}
                />
              ))}
          </Wrap>
          <Flex cursor="pointer">
            <img
              src={comment}
              onClick={() => goToContent(content)}
              alt="comment"
              style={{ marginRight: '17px' }}
            />
            <SaveButton content={content} />
          </Flex>
        </Stack>
      </Box>
    </Stack>
  )
}
