import React from 'react'
import { Flex, FlexProps, useTheme } from '@chakra-ui/react'
import { useMedia } from '../hooks/useMedia'

export const useAdminBodyWidth = () =>
  useMedia(['(min-width: 1346px)'], ['1140px'], '100%')

interface Props extends FlexProps {
  onBottomReached: () => void
}

export const ScrollableFlex: React.FC<Props> = (props) => {
  const { onBottomReached, ...rest } = props

  const width = useAdminBodyWidth()

  return (
    <Flex
      overflowY="scroll"
      {...rest}
      onScroll={(event) => {
        let element = event.target as HTMLElement
        if (
          element.scrollHeight - element.scrollTop <
          element.clientHeight * 1.2
        ) {
          onBottomReached()
        }
      }}
    >
      {props.children}
    </Flex>
  )
}
