import {
  Box,
  Divider,
  Flex,
  Image,
  Stack,
  Text,
  useTheme
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router'
import styled from 'styled-components'
import { saveBillingDetails, saveBillingDetailsConnect } from '../../api'
import { Button } from '../../components/Button'
import { OnboardingWrapper } from '../../components/OnboardingWrapper'
import { useAuth } from '../../context/auth-context'
import { useFeatureFlags } from '../../context/feature-flag-context'
import { routeStrings } from '../../routeStrings'
import { Card } from '../../styled/Card'
import { analyticsTrack } from '../../utils/analytics'
import { useTotalUnpaidInvoices } from '../../utils/apiHooks'
import { CenteredSpinner } from '../../styled/CenteredSpinner'
import { StripeConnectDirectDebitForm } from '../StripeConnectDirectDebitForm'
import StripeConnectCardForm from '../CompleteProfile/StripeConnectCardForm'
import { usePlatformTenant } from '../../context/platform-tenant-context'
import { useRole } from '../../utils/rbac'
export const MemberDashboardBillingUpdate: React.FC = () => {
  const { me, currentOrgUnit } = useAuth()

  const theme: any = useTheme()
  const history = useHistory()
  return (
    <OnboardingWrapper>
      <Box p={3}>
        <Flex justifyContent={'space-between'} mb={3}>
          <Image
            src={theme.logos.logo_long}
            maxW={'44'}
            className="location-logo"
          />
          <Box>
            <Text fontSize={['xs', 'sm']}>{me?.user.name}</Text>
            <Text color={'gray.500'} fontSize={['xs', 'xs']}>
              {currentOrgUnit?.organization.name}
            </Text>
          </Box>
        </Flex>
        <BillingUpdateForm
          showHeader={true}
          showOutstandingCharges={true}
          showPlanPreview={true}
          postSubmitCallback={() =>
            history.push(routeStrings.memberDashboardBillingUpdateSuccess)
          }
        />
      </Box>
    </OnboardingWrapper>
  )
}

const Title = styled.div`
  text-align: left;
  margin-top: 8px;
  margin-bottom: 8px;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  color: #ca599c;
`

interface UpdateFormProps {
  showHeader?: boolean
  showOutstandingCharges?: boolean
  showPlanPreview?: boolean
  postSubmitCallback?: () => void
  actionButtonText?: string
}

export const BillingUpdateForm: React.FC<UpdateFormProps> = ({
  showHeader,
  showOutstandingCharges,
  showPlanPreview,
  postSubmitCallback,
  actionButtonText
}) => {
  const [error, setError] = useState<string>()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [directDebitToggle, setDirectDebitToggle] = useState<Boolean>(false)
  const [eftToggle, setEftToggle] = useState<Boolean>(false)
  const [creditToggle, setCreditToggle] = useState<Boolean>(false)
  const { isExternalMember } = useRole()
  const [, setDirectDebitSize] = useState<any>('4x')
  const [, setCreditSize] = useState<any>('4x')
  const auth = useAuth()
  const { platformTenant } = usePlatformTenant()
  const theme = useTheme()
  const { processingFeeEnabled, stripeConnectEnabled } = useFeatureFlags()
  const { orgId: routeOrgId } = useParams<{ orgId?: string }>()

  const orgId =
    (routeOrgId && parseInt(routeOrgId, 10)) || auth.currentOrganization?.id
  const totalUnpaidInvoices = useTotalUnpaidInvoices(orgId)

  const organization = auth.currentOrganization
  const orgUnit = auth.currentOrgUnit

  const pmSelected: React.CSSProperties = {
    background: 'white',
    borderWidth: '1px',
    borderColor: theme.colors.brandPrimary,
    color: theme.colors.brandPrimary
  }
  if (
    organization === undefined ||
    orgUnit === undefined ||
    platformTenant === undefined
  ) {
    return <CenteredSpinner />
  }

  let planName = ''
  let planDescription = ''
  if (!organization.is_team) {
    const seat = orgUnit.seats?.find(() => true)
    if (seat?.active_plan) {
      planName = seat.active_plan.name
      planDescription = seat.active_plan.description
    }
  }

  const handleSubmit = async (
    { token: sourceId }: { token: string | undefined },
    isEft: boolean
  ) => {
    setIsSubmitting(true)
    const key = window.localStorage.getItem('Token')
    try {
      await saveBillingDetails(sourceId, isEft, organization.id, { token: key })
      let paymentMethod = ''
      if (eftToggle) paymentMethod = 'eft'
      if (directDebitToggle) paymentMethod = 'direct_debit'
      if (creditToggle) paymentMethod = 'credit'
      analyticsTrack('Billing details changed', {
        paymentMethod
      })
      auth.getAndSetMe!(key).then(
        () => postSubmitCallback && postSubmitCallback()
      )
    } catch (error: any) {
      setIsSubmitting(false)
      if (error.response) {
        setError(error.response.data)
      }
    }
  }
  const handleStripeConnectSubmit = async (
    {
      paymentMethodId,
      setupIntent
    }: { paymentMethodId: string | undefined; setupIntent: string | undefined },
    isEft: boolean
  ) => {
    setIsSubmitting(true)
    const key = window.localStorage.getItem('Token')
    try {
      await saveBillingDetailsConnect(
        paymentMethodId,
        isEft,
        organization.id,
        platformTenant.id,
        setupIntent
      )
      let paymentMethod = ''
      if (eftToggle) paymentMethod = 'eft'
      if (directDebitToggle) paymentMethod = 'direct_debit'
      if (creditToggle) paymentMethod = 'credit'
      analyticsTrack('Billing details changed', {
        paymentMethod
      })
      auth.getAndSetMe!(key).then(
        () => postSubmitCallback && postSubmitCallback()
      )
    } catch (error: any) {
      setIsSubmitting(false)
      if (error.response) {
        setError(error.response.data)
      }
    }
  }

  const engageCredit = () => {
    setDirectDebitToggle(false)
    setEftToggle(false)
    setCreditToggle(!creditToggle)
    setCreditSize('2x')
    setDirectDebitSize('4x')
  }

  const engageDirectDebit = () => {
    setCreditToggle(false)
    setEftToggle(false)
    setDirectDebitToggle(!directDebitToggle)
    setCreditSize('4x')
    setDirectDebitSize('2x')
  }

  const engageEft = () => {
    setCreditToggle(false)
    setDirectDebitToggle(false)
    setEftToggle(!eftToggle)
    setCreditSize('4x')
    setDirectDebitSize('4x')
  }

  return (
    <Stack spacing="24px">
      {showHeader && <Text fontSize="20px">Payment Details</Text>}

      {showOutstandingCharges &&
        totalUnpaidInvoices !== undefined &&
        totalUnpaidInvoices > 0 && (
          <Box
            p={2}
            py={3}
            borderWidth={1}
            borderColor="gray.200"
            borderRadius="lg"
          >
            <Text fontWeight={'bold'} fontSize={'md'}>
              {'Outstanding Charges: $' +
                (totalUnpaidInvoices ? totalUnpaidInvoices : '-')}
            </Text>
          </Box>
        )}
      {showPlanPreview &&
        // for single members
        !organization.is_team &&
        planName && (
          <>
            <Card title={planName} arrow={false} onPress={() => {}}>
              <Text color="black">{planDescription}</Text>
            </Card>
            <br />
          </>
        )}

      <Text fontSize="sm">Please select your billing method to continue</Text>

      <Flex flexDir={['column', 'row', 'row']} justifyContent={'flex-start'}>
        <Button
          mr={[0, 2]}
          mb={[2, 0]}
          style={creditToggle ? pmSelected : undefined}
          small
          onClick={() => !creditToggle && engageCredit()}
          data-testid={'complete-credit-option'}
        >
          Credit/Debit
        </Button>
        {!isExternalMember && (
          <Button
            mr={[0, 2]}
            mb={[2, 0]}
            style={directDebitToggle ? pmSelected : undefined}
            small
            onClick={() => !directDebitToggle && engageDirectDebit()}
          >
            Direct Debit
          </Button>
        )}
        {organization.eft_permitted && (
          <Button
            mr={[0, 2]}
            mb={[2, 0]}
            style={eftToggle ? pmSelected : undefined}
            small
            onClick={() => !eftToggle && engageEft()}
          >
            Electronic Funds Transfer
          </Button>
        )}
      </Flex>
      <Divider my={4} />
      {creditToggle && (
        <Box>
          <Title>Credit Card</Title>
          <StripeConnectCardForm
            platformTenant={platformTenant}
            isSubmitting={isSubmitting}
            handleResult={handleStripeConnectSubmit}
            buttonText={
              actionButtonText ? actionButtonText : 'Update billing details'
            }
          />
        </Box>
      )}

      {directDebitToggle && (
        <Box>
          <Title>Direct Debit</Title>
          <StripeConnectDirectDebitForm
            platformTenant={platformTenant}
            isSubmitting={isSubmitting}
            handleResult={handleStripeConnectSubmit}
            buttonText={
              actionButtonText ? actionButtonText : 'Update billing details'
            }
          />
        </Box>
      )}

      {organization.eft_permitted
        ? eftToggle && (
            <Box>
              <Title>Electronic Funds Transfer (EFT)</Title>
              <Text color={'#888'}>
                An electronic funds transfer (EFT), or direct deposit, is a
                digital movement of money from one bank account to another.
              </Text>
              <Box mt={4}>
                <Button
                  onClick={() => handleSubmit({ token: undefined }, true)}
                  data-testid="eft-payment-option--submit"
                >
                  Confirm
                </Button>
              </Box>
            </Box>
          )
        : null}

      {processingFeeEnabled && (
        <Text as="i" mt={10}>
          Please note that a processing fee may apply to credit cards.
        </Text>
      )}
    </Stack>
  )
}
