import {
  Box,
  Checkbox,
  FormLabel,
  HStack,
  Input,
  Text,
  VStack
} from '@chakra-ui/react'
import React, { useState, useEffect } from 'react'
import { getSetupIntent } from '../api'
import { Button } from '../components/Button'
import { usePlatformTenant } from '../context/platform-tenant-context'
import { CenteredSpinner } from '../styled/CenteredSpinner'
import { IPlatformTenant } from '../types'

declare global {
  interface Window {
    Stripe: any
  }
}

// Note this component has been based off the strip docs found at:
// https://stripe.com/docs/stripe-js/elements/au-bank-account

const { REACT_APP_STRIPE_PUBLIC_KEY } = process.env

const stripeKey =
  REACT_APP_STRIPE_PUBLIC_KEY !== undefined ? REACT_APP_STRIPE_PUBLIC_KEY : ''

// Custom styling can be passed to options when creating an Element.
var style = {
  base: {
    color: '#32325d',
    fontFamily:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
    fontSmoothing: 'antialiased',
    fontSize: '18px',
    '::placeholder': {
      color: '#aab7c4'
    },
    ':-webkit-autofill': {
      color: '#32325d'
    }
  },
  invalid: {
    color: '#fa755a',
    iconColor: '#fa755a',
    ':-webkit-autofill': {
      color: '#fa755a'
    }
  }
}

interface Props {
  platformTenant?: IPlatformTenant
  handleResult: (
    {
      paymentMethodId,
      setupIntent
    }: { paymentMethodId: string; setupIntent: string },
    isEft: boolean
  ) => void
  isSubmitting: boolean
  buttonText?: string
}
export const StripeConnectDirectDebitForm: React.FC<Props> = ({
  platformTenant,
  handleResult,
  isSubmitting,
  buttonText
}) => {
  const [mandateIsAccepted, setMandateIsAccepted] = useState(false)
  const [billingName, setBillingName] = useState('')
  const [billingEmail, setBillingEmail] = useState('')
  const [clientSecret, setClientSecret] = useState<String>()
  const [stripe, setStripe] = useState<any | undefined>()
  const [elements, setElements] = useState<any | undefined>()
  const [errorMessage, setErrorMessage] = useState<string>()
  const [auBankAccount, setAuBankAccount] = useState<any | undefined>()
  var bankName = document.getElementById('bank-name')
  var agreeCheckbox = document.getElementById('agree-to-ddr')
  var confirmMandate = document.getElementById('confirm-mandate')
  var form = document.getElementById('payment-form')
  var errorElement = document.getElementById('error-message')
  const hiddenInput = document.createElement('input')
  // Add an instance of the auBankAccount Element into the `au-bank-account-element` <div>.
  useEffect(() => {
    if (auBankAccount) {
      //could cause a race condition here if teh html element hasn't loaded before this effect fires
      auBankAccount.mount('#au-bank-account-element')
    }
  }, [auBankAccount])

  useEffect(() => {
    platformTenant &&
      getSetupIntent(platformTenant.id).then((res) => {
        setClientSecret(res.data.client_secret)
        const strp = window.Stripe(stripeKey, {
          stripeAccount: res.data.tenant_id
        })
        setStripe(strp)
        const elem = strp.elements()
        setElements(elem)
        const aub = elem.create('auBankAccount', {
          style: style
        })
        setAuBankAccount(aub)
      })
  }, [platformTenant])

  if (!(clientSecret && stripe && elements && auBankAccount)) {
    return <CenteredSpinner />
  }
  const handleSubmit = async (evt: any) => {
    evt.preventDefault()

    if (!mandateIsAccepted) {
      return ''
    }

    const result = await stripe.confirmAuBecsDebitSetup(clientSecret, {
      payment_method: {
        au_becs_debit: auBankAccount,
        billing_details: {
          name: billingName,
          email: billingEmail
        }
      },

      mandate_data: {
        customer_acceptance: {
          type: 'online'
        }
      }
    })

    if (result && result.error) {
      // Inform the customer that there was an error.
      setErrorMessage(result.error.message)
    } else {
      // Redirect the customer to the authorization URL.
      handleResult(
        {
          paymentMethodId: result.setupIntent.payment_method,
          setupIntent: result.setupIntent.id
        },
        false
      )
    }
  }

  const handleChange = (e: any, type?: string) => {
    if (type === 'name') {
      setBillingName(e.target.value)
    }
    if (type === 'email') {
      setBillingEmail(e.target.value)
    }
    // Display bank name corresponding to auBankAccount, if available.
    if (e.bankName && e.branchName && bankName) {
      bankName.textContent = `${e.bankName}`
      bankName.classList.add('visible')
    } else if (e.bankName && bankName) {
      bankName.textContent = `${e.bankName}`
      bankName.classList.add('visible')
    } else {
      bankName && bankName.classList.remove('visible')
    }
  }

  return (
    <form
      onSubmit={handleSubmit}
      action="/charge"
      method="post"
      id="payment-form"
    >
      <VStack align="left">
        <Box>
          <FormLabel mb={0} htmlFor="name">
            Name
          </FormLabel>
          <Input
            variant="flushed"
            onChange={(e) => handleChange(e, 'name')}
            id="name"
            name="name"
            placeholder="Jenny Rosen"
            required
          />
        </Box>
        <Box>
          <FormLabel mb={0} htmlFor="email">
            Email Address
          </FormLabel>
          <Input
            variant="flushed"
            id="email"
            name="email"
            type="email"
            placeholder="jenny.rosen@example.com"
            onChange={(e) => handleChange(e, 'email')}
            required
          />
        </Box>
        <Box>
          <FormLabel mb={0} htmlFor="au-bank-account-element">
            Bank Account
          </FormLabel>
          <div id="au-bank-account-element">
            {/* <!-- A Stripe Element will be inserted here. --> */}
          </div>

          <div id="bank-name" onChange={(e) => handleChange(e)}></div>
        </Box>
        {/* <!-- Used to display form errors. --> */}
        <div id="error-message" role="alert"></div>
        {errorMessage && (
          <Text color={'red'} verticalSpacing={20}>
            {errorMessage} Please update your bank account details and submit
            them again.
          </Text>
        )}
        <div className="checkbox">
          <HStack>
            <Checkbox
              id="agree-to-ddr"
              isChecked={mandateIsAccepted}
              onChange={(e) => setMandateIsAccepted(e.target.checked)}
            />
            <FormLabel mb={0} htmlFor="agree-to-ddr">
              I agree to this Direct Debit Request
            </FormLabel>
          </HStack>
        </div>

        {/* <!-- Display mandate acceptance text. --> */}
        <div className="col" id="mandate-acceptance">
          By checking this box, you agree to this Direct Debit Request and
          the&nbsp;
          <a
            href="https://stripe.com/au-becs-dd-service-agreement/legal#service-agreement"
            target="_blank"
          >
            Direct Debit Request service agreement
          </a>
          , and authorise Stripe Payments Australia Pty Ltd ACN 160 180 343
          Direct Debit User ID number 507156 (“Stripe”) to debit your account
          through the Bulk Electronic Clearing System (BECS) on behalf of
          Spacecubed. (the "Merchant") for any amounts separately communicated
          to you by the Merchant. You certify that you are either an account
          holder or an authorised signatory on the account listed above.
        </div>
        <br />
        <Box>
          <Button
            disabled={!mandateIsAccepted || isSubmitting}
            id="confirm-mandate"
            type="submit"
          >
            {buttonText ? buttonText : 'Confirm'}
          </Button>
        </Box>
      </VStack>
    </form>
  )
}
