import React, { useEffect } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import './App.css'
import { AppProviders } from './AppProviders'
import { UserDetailsProvider } from './context/user-onboard-context'
import { Login } from './pages/Login'
import { Logout } from './pages/Logout'
import { PasswordReset } from './pages/PasswordReset'
import { PasswordResetConfirm } from './pages/PasswordResetConfirm'
import { TermsAndConditions } from './pages/TermsAndConditions'
import {
  adminRoutes,
  completeProfileRoutes,
  inviteRoutes,
  memberRoutes,
  startRoutes,
  teamAdminRoutes,
  mobileEmbedRoutes,
  tenantInviteRoutes,
  externalStartRoutes,
  publicBookingRoutes
} from './routes'
import { routeStrings } from './routeStrings'
import { analyticsLoad, analyticsPage } from './utils/analytics'
import {
  CompleteProfileGuard,
  MemberGuard,
  TeamAdminGuard,
  UnauthenticatedGuard,
  ProtectedRoute,
  CompleteTenantOnboardingGuard
} from './utils/Guards'
import { Flex } from '@chakra-ui/react'
import { AdminHeader } from './components/AdminHeader'
import { OnboardingWrapper } from './components/OnboardingWrapper'
import { KioskApp } from './pages/Kiosk/KioskApp'
import { LayoutNewUserOnboarding } from './pages/Onboarding/components/LayoutNewUserOnboarding'
import { Roles } from './utils/rbac'
import { CommunityProvider } from './context/community-context'
import { HubspotEmbed } from './components/HubspotEmbed'
import { usePlatformTenant } from './context/platform-tenant-context'
import { SPACECUBED_HUBSPOT_CODE } from './utils/constants'
import { useMobileEmbed } from './context/mobile-embed-context'
import { BookingProvider } from './context/BookingContext'
import { ContentExplorerProvider } from './context/content-explorer-context'
import { PublicMeetingRoomWrapper } from './pages/MemberDashboard/MeetingRooms/ExternalProfile/PublicMeetingRoomWrapper'
import { NotFoundComponent } from './NotFoundComponent'
import { MainAIChatComponent } from './components/MainAIChatComponent'
import { LeadForensicsEmbed } from './components/LeadForensicsEmbed'

const LoginRoute = () => {
  return (
    <UnauthenticatedGuard>
      <Switch>
        <Route path={routeStrings.login} render={() => <Login />} />
      </Switch>
    </UnauthenticatedGuard>
  )
}

const StartRoutes = () => {
  const { platformTheme } = usePlatformTenant()
  return (
    <UnauthenticatedGuard>
      <UserDetailsProvider>
        <HubspotEmbed
          hubSpotHelpCode={
            platformTheme && platformTheme.id === '1'
              ? SPACECUBED_HUBSPOT_CODE
              : ''
          }
        />
        <LeadForensicsEmbed
          leadForensicsCode={
            platformTheme && platformTheme.id == '1' ? 'GO' : ''
          }
        />
        <LayoutNewUserOnboarding>
          <Switch>
            {Object.entries(startRoutes).map(([key, route]) => (
              <Route
                path={route.path}
                key={key}
                render={() => <route.component />}
              />
            ))}
          </Switch>
        </LayoutNewUserOnboarding>
      </UserDetailsProvider>
    </UnauthenticatedGuard>
  )
}
const ExternalRoutes = () => {
  const { platformTheme } = usePlatformTenant()
  return (
    <UnauthenticatedGuard>
      <UserDetailsProvider>
        <HubspotEmbed
          hubSpotHelpCode={
            platformTheme && platformTheme.id === '1'
              ? SPACECUBED_HUBSPOT_CODE
              : ''
          }
        />
        <LeadForensicsEmbed
          leadForensicsCode={
            platformTheme && platformTheme.id == '1' ? 'GO' : ''
          }
        />
        <LayoutNewUserOnboarding isExternal>
          <Switch>
            {Object.entries(externalStartRoutes).map(([key, route]) => (
              <Route
                path={route.path}
                key={key}
                render={() => <route.component />}
              />
            ))}
          </Switch>
        </LayoutNewUserOnboarding>
      </UserDetailsProvider>
    </UnauthenticatedGuard>
  )
}

const PublicMeetingRoomsRoutes = () => {
  return (
    <Switch>
      <PublicMeetingRoomWrapper>
        {Object.entries(publicBookingRoutes).map(([key, route]) => (
          <Route
            path={route.path}
            key={key}
            render={() => <route.component />}
          />
        ))}
      </PublicMeetingRoomWrapper>
    </Switch>
  )
}

const CompleteProfileRoutes = () => {
  const { platformTenant } = usePlatformTenant()
  return (
    <CompleteProfileGuard>
      <UserDetailsProvider>
        {platformTenant?.hubspot_help_widget_code && (
          <HubspotEmbed
            hubSpotHelpCode={platformTenant.hubspot_help_widget_code}
          />
        )}
        <Switch>
          {Object.entries(completeProfileRoutes).map(([key, route]) => (
            <Route
              exact
              path={route.path}
              key={key}
              render={() => <route.component />}
            />
          ))}
        </Switch>
      </UserDetailsProvider>
    </CompleteProfileGuard>
  )
}

const AdminRoutes: React.FC = () => {
  return (
    <ProtectedRoute
      permittedRoles={[Roles.SpaceAdmin]}
      fallbackRoute={routeStrings.memberDashboardHome}
    >
      <CommunityProvider>
        <CompleteTenantOnboardingGuard>
          <Switch>
            {Object.entries(adminRoutes).map(([key, route]) => {
              return (
                <Route
                  exact
                  key={key}
                  path={route.path}
                  component={route.component}
                />
              )
            })}
            <Route
              path="*"
              render={() => <Redirect to={routeStrings.adminDashboard} />}
            />
          </Switch>
        </CompleteTenantOnboardingGuard>
      </CommunityProvider>
    </ProtectedRoute>
  )
}

const MemberRoutes = () => {
  const { platformTenant } = usePlatformTenant()
  return (
    <MemberGuard>
      <CommunityProvider>
        <ContentExplorerProvider>
          {/* do not display hubspot widget on mobile embed */}
          {platformTenant?.hubspot_help_widget_code && (
            <HubspotEmbed
              hubSpotHelpCode={platformTenant.hubspot_help_widget_code}
            />
          )}
          <Switch>
            {Object.entries(memberRoutes).map(([key, route]) => (
              <Route
                exact
                path={route.path}
                key={key}
                render={() => <route.component />}
              />
            ))}
            <Route
              path="*"
              render={() => <Redirect to={routeStrings.memberDashboardHome} />}
            />
          </Switch>
        </ContentExplorerProvider>
      </CommunityProvider>
    </MemberGuard>
  )
}
const MobileEmbedRoutes = () => {
  return (
    <MemberGuard>
      <CommunityProvider>
        <BookingProvider>
          <Switch>
            {Object.entries(mobileEmbedRoutes).map(([key, route]) => (
              <Route
                exact
                path={route.path}
                key={key}
                render={() => <route.component />}
              />
            ))}
            <Redirect to={routeStrings.memberDashboardBookings} />
          </Switch>
        </BookingProvider>
      </CommunityProvider>
    </MemberGuard>
  )
}
const InviteRoutes = () => {
  const { platformTenant } = usePlatformTenant()
  return (
    <UserDetailsProvider>
      <OnboardingWrapper>
        {platformTenant?.hubspot_help_widget_code && (
          <HubspotEmbed
            hubSpotHelpCode={platformTenant.hubspot_help_widget_code}
          />
        )}
        <UnauthenticatedGuard>
          <Switch>
            {Object.entries(inviteRoutes).map(([key, route]) => (
              <Route
                exact
                path={route.path}
                key={key}
                render={() => <route.component />}
              />
            ))}
          </Switch>
          <Switch>
            {Object.entries(tenantInviteRoutes).map(([key, route]) => (
              <Route
                exact
                path={route.path}
                key={key}
                render={() => <route.component />}
              />
            ))}
          </Switch>
        </UnauthenticatedGuard>
      </OnboardingWrapper>
    </UserDetailsProvider>
  )
}

const TeamAdminRoutes = () => {
  const { platformTenant } = usePlatformTenant()

  return (
    <TeamAdminGuard>
      {platformTenant?.hubspot_help_widget_code && (
        <HubspotEmbed
          hubSpotHelpCode={platformTenant.hubspot_help_widget_code}
        />
      )}
      <Switch>
        {Object.entries(teamAdminRoutes).map(([key, route]) => (
          <Route
            exact
            path={route.path}
            key={key}
            render={() => <route.component />}
          />
        ))}
        <Route
          path="*"
          render={() => <Redirect to={routeStrings.memberDashboardHome} />}
        />
      </Switch>
    </TeamAdminGuard>
  )
}

const App: React.FC = () => {
  useEffect(() => {
    analyticsLoad()
  }, [])

  // On window location change: tell analytics about page change
  useEffect(() => {
    analyticsPage()
  }, [window.location.href])
  return (
    <AppProviders>
      <AppWrapper>
        {/* Render admin header, if user has admin role in RBAC*/}
        <AdminHeader />
        <Switch>
          <Route path="/not-found" component={NotFoundComponent} />
          <Route path="/reset" component={PasswordReset} />
          <Route
            path="/accounts/reset/:uid?/:token?/:user?"
            component={PasswordResetConfirm}
          />
          <Route path={routeStrings.login}>
            <LoginRoute />
          </Route>
          <Route exact path={routeStrings.logout} component={Logout} />
          <Route
            exact
            path={routeStrings.termsAndConditions}
            component={TermsAndConditions}
          />
          <Route
            exact
            path="/"
            render={() => <Redirect to={routeStrings.login} />}
          />
          <Route
            exact
            path="/start"
            render={() => <Redirect to={routeStrings.location} />}
          />
          <Route path={'/start'}>
            <StartRoutes />
          </Route>
          <Route path={'/external/meeting-rooms'}>
            <PublicMeetingRoomsRoutes />
          </Route>
          <Route path={'/external'}>
            <ExternalRoutes />
          </Route>

          <Route path={'/complete'}>
            <CompleteProfileRoutes />
          </Route>

          <Route path={'/invite'}>
            {/* @TODO: add token validation to routes? */}
            <InviteRoutes />
          </Route>

          <Route path={'/management'}>
            <TeamAdminRoutes />
          </Route>

          {/* meeting room kiosk app */}
          <Route path={'/kiosk'}>
            <KioskApp />
          </Route>
          <BookingProvider>
            <Route path={'/admin'}>
              <AdminRoutes />
            </Route>
            <Route path={'/dashboard'}>
              <MemberRoutes />
            </Route>
            <MainAIChatComponent />
          </BookingProvider>
        </Switch>
      </AppWrapper>
    </AppProviders>
  )
}

export default App

const AppWrapper: React.FC = ({ children }) => {
  //we user this app wrapper to dynamically add margin when we need it to prevent the hubspit widget from blocking content
  //previously this was being done with a media query in the root
  const { isMobileEmbed } = useMobileEmbed()
  return (
    <Flex
      flexDir="column"
      flexGrow={1}
      minH="100vh"
      overflow="hidden"
      mb={[isMobileEmbed ? 0 : '100px', '100px', 0]}
    >
      {/* here we are using the mobile embed routes to lock the user in to a controlled subset of routes when in teh mobile app webview. this is a safeguard against future work exposing other nav options to the user */}
      {isMobileEmbed ? <MobileEmbedRoutes /> : children}
    </Flex>
  )
}
