import React from 'react'
import { Redirect } from 'react-router-dom'
import { redirectAfterLoginUrl, useAuth } from '../context/auth-context'
import { usePlatformTenant } from '../context/platform-tenant-context'
import {
  AdminRedirects,
  BaseRedirects,
  CompleteProfileRedirects,
  CompleteTenantOnboardingRedirects,
  MemberRedirects,
  TeamAdminRedirects
} from './redirects'
import { useRole, Roles } from './rbac'

export const UnauthenticatedGuard: React.FC = ({ children }) => {
  const auth = useAuth()

  if (auth.loading) {
    return null
  }
  if (auth.isAuthenticated && auth.me) {
    // This guard was conflicting with the auth-context login method, this would redirect the user before the correct "next" url was applied.
    // using the same method to pickup on next should fix this
    return <Redirect to={redirectAfterLoginUrl(auth.me)} />
  }
  return <>{children}</>
}

export const AdminGuard: React.FC = ({ children }) => {
  const auth = useAuth()

  // Base Redirects
  BaseRedirects(auth)

  const { me, loading } = auth
  if (me === undefined || loading === true) return null

  // Admin Redirects if not already redirected
  AdminRedirects(me)

  return <>{children}</>
}

export const TeamAdminGuard: React.FC = ({ children }) => {
  const auth = useAuth()
  const { role } = useRole()
  // Base Redirects
  BaseRedirects(auth)

  const { me, loading } = auth
  if (me === undefined || loading === true) return null

  // Team Admin Redirects if not already redirected
  TeamAdminRedirects(me, auth, role)

  return <>{children}</>
}

export const MemberGuard: React.FC = ({ children }) => {
  const auth = useAuth()
  const { role } = useRole()
  // Base Redirects
  BaseRedirects(auth)
  const { me, loading } = auth
  if (me === undefined || loading === true) return null

  // Member checks if not already redirected
  MemberRedirects(me, auth, role)

  return <>{children}</>
}

export const CompleteProfileGuard: React.FC = ({ children }) => {
  const auth = useAuth()

  // Base Redirects
  BaseRedirects(auth)

  const { me, loading } = auth
  if (me === undefined || loading === true) return null

  // additional checks if not already redirected
  CompleteProfileRedirects(me)

  return <>{children}</>
}

export const CompleteTenantOnboardingGuard: React.FC = ({ children }) => {
  const auth = useAuth()
  const { platformTenant } = usePlatformTenant()
  // Base Redirects
  BaseRedirects(auth)

  const { me, loading } = auth
  if (me === undefined || loading === true) return null

  // additional checks if not already redirected
  if (platformTenant) CompleteTenantOnboardingRedirects(me, platformTenant)

  return <>{children}</>
}

interface ProtectedRouteProps {
  permittedRoles: Roles[]

  /** the route to redirect the user to if they aren't in the allowed roles */
  fallbackRoute: string
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  permittedRoles,
  fallbackRoute,
  children
}) => {
  const { role, allowedRoles } = useRole()
  const { loading, me, currentOrgUnit, currentOrganization } = useAuth()

  //Wait until auth loading is finished before redirecting
  if (role === Roles.Default || loading) {
    return null
  }

  if (permittedRoles.filter((r) => allowedRoles.includes(r)).length === 0) {
    console.log('redirecting to', fallbackRoute)
    return <Redirect to={fallbackRoute} />
  }

  return <>{children}</>
}
