import React, { useState } from 'react'
import { AdminBodyWrapper } from '../../../../components/AdminBodyWrapper'
import { AuthDrawerProvider } from '../../../../context/auth-drawer-context'
import { BookingProvider } from '../../../../context/BookingContext'
import { SignInSignupDrawer } from '../../../SignInSignupDrawer'

import { LayoutMemberDashboard } from '../../LayoutMemberDashboard'
import { PublicBookingNavBar } from './PublicBookingNavBar'

export const PublicMeetingRoomWrapper: React.FC = ({ children }) => {
  return (
    <LayoutMemberDashboard showBack={false}>
      <BookingProvider>
        <AuthDrawerProvider>
          <PublicBookingNavBar />
          <AdminBodyWrapper>{children}</AdminBodyWrapper>
          <SignInSignupDrawer />
        </AuthDrawerProvider>
      </BookingProvider>
    </LayoutMemberDashboard>
  )
}
