import React from 'react'
import { IOfficeListDetail, IOrganizationalUnitBasic } from '../../../types'
import { TableDataProp, Table } from '../../../components/table/Table'
import { Box, AvatarGroup, Avatar, Link } from '@chakra-ui/react'
import { IAction } from '../../../components/table/ActionsDropdown'
import { TeamLink } from './TeamLink'

interface IInputParams {
  offices: IOfficeListDetail[]
  loading: boolean
  editOfficeAction: IAction
  assignOfficeAction: IAction
  unassignOfficeAction: IAction
}

export const Offices: React.FC<IInputParams> = (input) => {
  const {
    offices,
    loading,
    editOfficeAction,
    assignOfficeAction,
    unassignOfficeAction
  } = input

  function RenderOfficeLink(office: IOfficeListDetail) {
    return (
      <Link color="#255CE1" onClick={() => editOfficeAction.action(office.id)}>
        {office.name}
      </Link>
    )
  }

  const tableData =
    (offices &&
      offices.map((office) => {
        const actions: IAction[] = []

        const cleanedData = {
          id: TableDataProp(office.id),
          test_prefix: TableDataProp(`office-row-${office.id}`),
          name: TableDataProp(RenderOfficeLink(office), office.name),
          capacity: TableDataProp(`${office.number_of_desks} pax`),
          location: TableDataProp(office.location_name),
          level: TableDataProp(office.level),
          rate: TableDataProp(`$${office.rate}`),
          meeting_credit_rate: TableDataProp(office.meeting_credit_rate),
          total_meeting_credit_allowance: TableDataProp(
            office.meeting_credit_allowance
          ),
          team: TableDataProp(
            office.organization
              ? TeamLink({
                  organizationId: office.organization.id,
                  organizationName: office.organization.name
                })
              : ''
          ),
          xero_account_code: TableDataProp(
            office?.xero_account_code
              ? `${office.xero_account_code.title} (${office.xero_account_code.code})`
              : 'Not set'
          ),
          actions: TableDataProp([])
        }

        // Define actions for the row
        actions.push(editOfficeAction)
        if (office.organization) {
          actions.push(unassignOfficeAction)
        } else {
          actions.push(assignOfficeAction)
        }

        cleanedData.actions = TableDataProp(actions)

        return cleanedData
      })) ||
    []

  return (
    <Box my="3" w="100%">
      <Table
        pageSize={100}
        headers={[
          { header: 'id', accessor: 'id', show: false },
          { header: 'test_prefix', accessor: 'test_prefix', show: false },
          {
            header: 'Office',
            accessor: 'name',
            show: true,
            width: 2 / 12,
            enableFilter: true
          },
          {
            header: 'Capacity',
            accessor: 'capacity',
            show: true,
            width: 2 / 12
          },
          {
            header: 'Rate p/m',
            accessor: 'rate',
            show: true,
            width: 2 / 12
          },
          // {
          //   header: 'Credit Rate',
          //   accessor: 'meeting_credit_rate',
          //   show: true,
          //   width: 1 / 12
          // },
          {
            header: 'Meeting Credits',
            accessor: 'total_meeting_credit_allowance',
            show: true,
            width: 2 / 12
          },
          {
            header: 'Assigned Team',
            accessor: 'team',
            show: true,
            enableFilter: true,
            width: 2 / 12
          },
          {
            header: 'Xero Account Code',
            accessor: 'xero_account_code',
            show: true,
            width: 3 / 12
          },
          { header: '', accessor: 'actions', show: true, width: 1 / 12 }
        ]}
        data={tableData}
        loading={loading}
      />
    </Box>
  )
}
