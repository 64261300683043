import { AddIcon } from '@chakra-ui/icons'
import {
  Badge,
  Box,
  Divider,
  Flex,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Input,
  Link,
  List,
  ListIcon,
  ListItem,
  SimpleGrid,
  Spacer,
  Stack,
  StackDivider,
  Text,
  useToast,
  VStack
} from '@chakra-ui/react'
import { ErrorMessage, Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { stripeOnboardingLink, updateHumanitixAPI } from '../../../api'
import { AdminBodyWrapper } from '../../../components/AdminBodyWrapper'
import { Button } from '../../../components/Button'
import { PrimarySpinner } from '../../../components/chakra-v2/PrimarySpinner'
import { StyledInput } from '../../../components/StyledInputs'
import { useFeatureFlags } from '../../../context/feature-flag-context'
import { usePlatformTenant } from '../../../context/platform-tenant-context'
import { CenteredSpinner } from '../../../styled/CenteredSpinner'
import { InputWarning } from '../../../styled/InputWarning'
import {
  IPlatformTenant,
  IPlatformTenantUpdate,
  Location
} from '../../../types'
import { XeroConnectionSettings } from '../AdminDashboardXeroSettings'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMoneyBill } from '@fortawesome/free-solid-svg-icons'
import format from 'date-fns/format'

interface Props {}

export const AdminDashboardPlatformTenantEventsSettings: React.FC<Props> = (
  props
) => {
  const { platformTenant, tenantIntegration, revalidateSettings } =
    usePlatformTenant()
  const [apiKey, setApiKey] = useState<string>('')
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [showError, setShowError] = useState(false)
  const [isHidden, setIsHidden] = useState(true)
  const toast = useToast()

  useEffect(() => {
    if (tenantIntegration && tenantIntegration.humanitix_integration) {
      setIsHidden(
        tenantIntegration.humanitix_integration.api_key != undefined &&
          tenantIntegration.humanitix_integration.api_key != ''
      )
    }
  }, [tenantIntegration])

  useEffect(() => {
    if (platformTenant) {
      revalidateSettings()
    }
  }, [])

  if (!platformTenant) {
    return <CenteredSpinner />
  }

  const updateAPI = () => {
    if (!submitting && tenantIntegration && apiKey) {
      setSubmitting(true)
      updateHumanitixAPI(tenantIntegration.humanitix_integration.id, {
        api_key: apiKey.replaceAll(' ', '')
      })
        .then((res) => {
          toast({ title: 'API Key updated', status: 'success' })
          setSubmitting(false)
          setIsHidden(true)
          revalidateSettings()
        })
        .catch((error) => {
          setSubmitting(false)
          toast({ title: 'An error ocurred', status: 'error' })
        })
    }
  }

  return (
    <Stack spacing={3} textAlign="left">
      <Box>
        <Heading size="lg">Humanitix</Heading>
      </Box>
      <Text>
        Manage the configuration on how the platform will connect to{' '}
        <Link href="#" target="_blank">
          Humanitix
        </Link>{' '}
        to automatically fetch all the events you are hosting, and want your
        members to find out on their dashboard.
      </Text>
      <Divider my="3" />
      <Box w={['100%', '100%', '70%']}>
        <Flex>
          <Box p={1}>
            <Text fontWeight="800" fontSize="md">
              Humanitix API key
            </Text>
          </Box>
          <Spacer />
          {isHidden ? (
            <Text cursor={'pointer'} onClick={() => setIsHidden(false)}>
              {' '}
              Click here to update the API Key{' '}
            </Text>
          ) : (
            <Input
              w="70%"
              type="password"
              value={apiKey}
              onChange={(e) => {
                setApiKey(e.target.value)
              }}
            />
          )}
        </Flex>

        <Flex alignContent="right" w={['100%']} mt={3}>
          <Spacer />
          {!isHidden && (
            <Button disabled={submitting} onClick={updateAPI}>
              Update
            </Button>
          )}
        </Flex>
        {tenantIntegration && tenantIntegration.humanitix_integration && (
          <>
            <Divider my={3} />
            <Heading size="md">More Information</Heading>
            <Flex>
              <Box p={1}>
                <Text fontWeight="800" fontSize="md">
                  Last updated
                </Text>
              </Box>
              <Spacer />
              <Text fontSize="md" textAlign="right">
                {tenantIntegration.humanitix_integration.last_updated
                  ? format(
                      Date.parse(
                        tenantIntegration.humanitix_integration.last_updated
                      ),
                      'PPpp'
                    )
                  : 'Not recorded'}
              </Text>
            </Flex>
            <Flex>
              <Box p={1}>
                <Text fontWeight="800" fontSize="md">
                  Last synced
                </Text>
              </Box>
              <Spacer />
              <Text fontSize="md">
                {tenantIntegration.humanitix_integration.last_synced
                  ? format(
                      Date.parse(
                        tenantIntegration.humanitix_integration.last_synced
                      ),
                      'PPpp'
                    )
                  : 'Never'}
              </Text>
            </Flex>
            <Flex>
              <Box p={1}>
                <Text fontWeight="800" fontSize="md">
                  Status
                </Text>
              </Box>
              <Spacer />
              <Badge
                cursor="pointer"
                onDoubleClick={() => setShowError(!showError)}
                rounded="md"
                colorScheme={
                  tenantIntegration.humanitix_integration.status === 'ERROR'
                    ? 'red'
                    : tenantIntegration.humanitix_integration.status ==
                      'SUCCESS'
                    ? 'green'
                    : 'gray'
                }
                p={2}
              >
                {tenantIntegration.humanitix_integration.status}
              </Badge>
            </Flex>
            {showError &&
              tenantIntegration.humanitix_integration.status === 'ERROR' && (
                <Flex>
                  <Box p={1}>
                    <Text fontWeight="800" fontSize="md">
                      Error Message
                    </Text>
                  </Box>
                  <Spacer />
                  <Text colorScheme={'red'} p={2}>
                    {tenantIntegration.humanitix_integration.error_message}
                  </Text>
                </Flex>
              )}
          </>
        )}
      </Box>
    </Stack>
  )
}
