import {
  Box,
  BoxProps,
  Divider,
  Skeleton,
  Spacer,
  Text,
  useToast
} from '@chakra-ui/react'
import { format } from 'date-fns'
import { zonedTimeToUtc } from 'date-fns-tz'
import React, { useEffect, useState } from 'react'
import {
  getMeetingRoomTimeline,
  getWorkspaceRoomTimeline,
  previewRescheduleBookingCost,
  rescheduleMeetingRoom
} from '../api'
import { useBookingContext } from '../context/BookingContext'
import {
  IBooking,
  IInterval,
  ILocationOpeningHours,
  IMeetingRoom,
  IPreviewBookingCost,
  IWorkspace
} from '../types'
import { BookingCostsComponent } from './BookingCostsComponent'
import { BookingDurationComponent } from './BookingDurationComponent'
import { Button } from './Button'
import { CalendarFormInput } from './CalendarFormInput'
import { MeetingRoomTimeline } from './MeetingRoomTimeline'
import { WorkspaceTimeline } from './WorkspaceTimeline'

interface Props extends BoxProps {
  booking: IBooking
  handleBookingRescheduled: (bookingTimes: IInterval) => void
}

export const BookingInfoTimeline: React.FC<Props> = (props) => {
  const { booking, handleBookingRescheduled } = props

  const { locations: bookableLocations, makeRoomBooking } = useBookingContext()
  const toast = useToast()
  const [isBooking, setIsBooking] = useState(false)
  const [isError, setIsError] = useState<boolean>(false)

  const isExternalBooking = false

  const [chosenDate, setChosenDate] = useState(
    new Date(Date.parse(booking.start_time))
  )
  const [bookingTimes, setBookingTimes] = useState<IInterval | undefined>()
  const [isCalculating, setIsCalculating] = useState<boolean>(false)

  const [bookingCost, setBookingCost] = useState<IPreviewBookingCost>()
  const [roomTimeline, setRoomTimeline] = useState<IMeetingRoom>()
  const [workspaceTimeline, setWorkspaceTimeline] = useState<IWorkspace>()
  const [isGettingTimeline, setIsGettingTimeline] = useState(false)
  const [openingHours, setOpeningHours] = useState<ILocationOpeningHours>()

  const resourceId = booking.meeting_room ?? booking.workspace
  const selectedLocation = bookableLocations.find(
    (l) => l.id === booking.location
  )

  const dayBusinessHours =
    selectedLocation?.business_hours &&
    selectedLocation.business_hours.find(
      (hours) => hours.day === chosenDate.getDay() - 1
    )
  const isMeetingRoomBooking = booking.meeting_room != undefined

  useEffect(() => {
    if (chosenDate) {
      handleChangeDate(chosenDate)
    }
  }, [chosenDate])

  useEffect(() => {
    if (workspaceTimeline) {
      const location = bookableLocations.find(
        (l) => l.id === workspaceTimeline?.location
      )
      setOpeningHours(location ? location.opening_hours : undefined)
    }
  }, [workspaceTimeline, bookableLocations])

  const handleChangeDate = (newDate) => {
    if (!resourceId || !chosenDate || isGettingTimeline) return
    setIsGettingTimeline(true)
    if (isMeetingRoomBooking) {
      getMeetingRoomTimeline(resourceId, newDate)
        .then((res) => {
          setIsGettingTimeline(false)
          setRoomTimeline(res.data)
        })
        .catch((err) => {
          setIsGettingTimeline(false)
        })
    } else {
      getWorkspaceRoomTimeline(resourceId, newDate)
        .then((res) => {
          setIsGettingTimeline(false)
          setWorkspaceTimeline(res.data)
        })
        .catch((err) => {
          setIsGettingTimeline(false)
        })
    }
  }

  const selectTimes = (timeSlot: IInterval | undefined) => {
    if (isCalculating || !selectedLocation) return
    setBookingTimes(timeSlot)
    if (!timeSlot) return
    if (booking.is_space_admin) return

    setIsCalculating(true)
    previewRescheduleBookingCost(booking.id, {
      meeting_room: isMeetingRoomBooking ? resourceId : undefined,
      workspace: !isMeetingRoomBooking ? resourceId : undefined,
      organizational_unit: booking.organizational_unit,
      start_time: zonedTimeToUtc(
        timeSlot.start,
        selectedLocation.city.timezone
      ).toISOString(),
      end_time: zonedTimeToUtc(
        timeSlot.end,
        selectedLocation.city.timezone
      ).toISOString()
    })
      .then(({ data }) => {
        setBookingCost(data)
        setIsCalculating(false)
      })
      .catch((err) => {
        setIsCalculating(false)
      })
  }

  const confirmReschedule = () => {
    if (!bookingTimes || isBooking) return null
    setIsBooking(true)
    rescheduleMeetingRoom(booking.id, {
      meeting_room: isMeetingRoomBooking ? resourceId : undefined,
      workspace: !isMeetingRoomBooking ? resourceId : undefined,
      organizational_unit: booking.organizational_unit,
      start_time: zonedTimeToUtc(
        bookingTimes.start,
        booking.timezone
      ).toISOString(),
      end_time: zonedTimeToUtc(bookingTimes.end, booking.timezone).toISOString()
    })
      .then((res) => {
        setIsBooking(false)
        toast({
          description: `Booking rescheduled to ${format(
            bookingTimes.start,
            'PP, pp'
          )}`,
          duration: 4000,
          status: 'success'
        })
        handleBookingRescheduled(bookingTimes)
      })
      .catch((error) => {
        setIsBooking(false)
        let description = 'Something went wrong, failed to make booking'
        try {
          description = error.response.data
        } catch (error) {}
        toast({
          description,
          status: 'error'
        })
      })
  }

  return (
    <Box>
      <Box w={['100%', '80%', '50%']}>
        <CalendarFormInput
          dateProps={{
            chosenDate,
            handleChangeDate: setChosenDate
          }}
          closeOnSelect={true}
        />
      </Box>
      <Divider my={2} />
      <Box pt={1}>
        <Text fontWeight={'bold'}>Select the times</Text>
      </Box>

      {/* {getTimeLine()} */}
      {isGettingTimeline && <Skeleton h={'16'} />}
      {!isGettingTimeline && (
        <Box py={8}>
          {roomTimeline && (
            <MeetingRoomTimeline
              chosenDate={chosenDate}
              setTimeSlot={selectTimes}
              meetingRoom={roomTimeline}
              setError={setIsError}
              existingBooking={booking}
              businessHours={dayBusinessHours}
              isExternal={isExternalBooking}
            />
          )}
          {workspaceTimeline && openingHours && (
            <WorkspaceTimeline
              workspace={workspaceTimeline}
              openingHours={openingHours}
              chosenDate={chosenDate}
              setTimeSlot={(timeSlot: IInterval, session: string) => {
                selectTimes(timeSlot)
              }}
              setError={setIsError}
              existingBooking={booking}
              otherBookings={workspaceTimeline.other_bookings}
            />
          )}
        </Box>
      )}
      <Box h={8}></Box>
      <Box>
        <BookingDurationComponent timeSlot={bookingTimes} />
        {bookingCost && <Divider my={2} />}
        <BookingCostsComponent
          costs={bookingCost}
          isCalculating={isCalculating}
        />
      </Box>
      <Divider my={2} />
      {!booking.is_external && bookingTimes && (
        <Box mt="5px" display={{ md: 'flex' }}>
          <Spacer />

          <Button
            isLoading={isBooking}
            loadingText={'Rescheduling'}
            disabled={isError || isBooking}
            size="sm"
            onClick={confirmReschedule}
          >
            Confirm new date
          </Button>
        </Box>
      )}
    </Box>
  )
}
