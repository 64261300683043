import { Box, useDisclosure } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { AdminBodyWrapper } from '../../components/AdminBodyWrapper'
import {
  GroupingHeaderLayout,
  IActionButton
} from '../../components/GroupingHeaderLayout'
import { LocationAdminSelector } from '../../components/LocationAdminSelector'
import { useAppTourContextProvider } from '../../context/app-tour-context'
import { usePlatformTenant } from '../../context/platform-tenant-context'
import { tourTargetStrings } from '../../tourStepsArrays'
import { IOfficeListDetail, Location } from '../../types'
import { useOfficesDetailed } from '../../utils/apiHooks'
import { Actions, useRBAC } from '../../utils/rbac'
import { LayoutAdminDashboard } from './LayoutAdminDashboard'
import { AssignOfficeModal } from './Modals/AssignOfficeModal'
import { CreateLocationModal } from './Modals/CreateLocationModal'
import { CreateOfficeModal } from './Modals/CreateOfficeModal'
import { EditOfficeModal } from './Modals/EditOfficeModal'
import { UnassignOfficeModal } from './Modals/UnassignOfficeModal'
import { Offices } from './Tables/Offices'

export const AdminDashboardLocations: React.FC = () => {
  const { adminTourActive, setRun } = useAppTourContextProvider()
  useEffect(() => {
    if (adminTourActive) {
      setRun(true)
    }
  }, [])
  const { platformTenant, revalidate } = usePlatformTenant()
  const [locationFilter, setLocationFilter] = useState<number>()
  const { data: offices, revalidate: revalidateOffices } = useOfficesDetailed(
    platformTenant?.id,
    locationFilter,
    {
      refreshInterval: 0,
      revalidateOnFocus: false
    }
  )

  const loadingOffices = offices === undefined
  const [filteredOffices, setFilteredOffices] = useState<IOfficeListDetail[]>(
    []
  )

  const [assignOffice, setAssignOffice] = useState<IOfficeListDetail>()
  const [unassignOffice, setUnassignOffice] = useState<IOfficeListDetail>()
  const [editOffice, setEditOffice] = useState<IOfficeListDetail>()
  const canAdministerLocations = useRBAC(Actions.AdministerLocation)
  const canAdministerOffices = useRBAC(Actions.AdministerOffice)
  const [editingLocation, setEditingLocation] = useState<Location>()
  const { isOpen, onOpen, onClose } = useDisclosure()

  useEffect(() => {
    if (platformTenant) {
      setLocationFilter(
        platformTenant?.locations && platformTenant.locations.length > 0
          ? platformTenant.locations[0].id
          : 0
      )
      revalidateOffices()
    }
  }, [platformTenant])

  const {
    isOpen: isLocationOpen,
    onOpen: onLocationOpen,
    onClose: onLocationClose
  } = useDisclosure()

  const handleCloseLocationModal = () => {
    onLocationClose()
    revalidate()
  }
  const selectedLocation = platformTenant?.locations.find(
    (l) => l.id === locationFilter
  )
  const rowActions = {
    editOffice: {
      text: 'Edit Office',
      testIdPrefix: 'edit-office',
      action: (rowId: number) =>
        setEditOffice(filteredOffices.find((office) => office.id === rowId))
    },
    assignOffice: {
      text: 'Assign Team',
      testIdPrefix: 'assign-team',
      action: (rowId: number) =>
        setAssignOffice(filteredOffices.find((office) => office.id === rowId))
    },
    unassignOffice: {
      text: 'Unassign Team',
      testIdPrefix: 'unassign-team',
      action: (rowId: number) =>
        setUnassignOffice(filteredOffices.find((office) => office.id === rowId))
    }
  }

  useEffect(() => {
    if (offices) {
      if (locationFilter) {
        setFilteredOffices(
          offices.filter((office) => office.location_id === locationFilter)
        )
      }
    }
  }, [locationFilter, offices])

  function closeModal() {
    setAssignOffice(undefined)
    setEditOffice(undefined)
    setUnassignOffice(undefined)
    revalidateOffices()
  }
  const actions: IActionButton[] = []
  canAdministerLocations &&
    actions.push({
      text: 'Create Location',
      className: tourTargetStrings.adminheaderLocationsCreate,
      variant: 'primary',
      onClick: () => {
        setEditingLocation(undefined)
        onLocationOpen()
      }
    })

  selectedLocation &&
    actions.push({
      text: 'Edit Location',
      variant: 'primary',
      testIdPrefix: 'action-button-edit-location',
      onClick: () => {
        setEditingLocation(selectedLocation)
        onLocationOpen()
      }
    })

  canAdministerOffices &&
    actions.push({
      text: 'Create office',
      className: tourTargetStrings.adminheaderLocationsOffice,
      variant: 'primary',
      onClick: onOpen
    })
  return (
    <LayoutAdminDashboard>
      <GroupingHeaderLayout
        basicTitle="Locations"
        totalValue={platformTenant?.locations.length}
        action={actions}
      />

      {/* Tables */}
      <AdminBodyWrapper>
        <Box
          p={4}
          display={{ base: 'flex' }}
          flexWrap="wrap"
          data-testid="locations-box"
        >
          {platformTenant?.locations.map((location) => (
            <LocationAdminSelector
              key={location.id.toString()}
              location={location}
              selected={locationFilter === location.id}
              onClick={() => setLocationFilter(location.id)}
              cursor={'pointer'}
              data-testid={`location-selector-${location.id}`}
            />
          ))}
        </Box>
        <Offices
          offices={filteredOffices}
          loading={loadingOffices}
          editOfficeAction={rowActions.editOffice}
          assignOfficeAction={rowActions.assignOffice}
          unassignOfficeAction={rowActions.unassignOffice}
        />
      </AdminBodyWrapper>

      {/* Modals */}
      <EditOfficeModal
        isOpen={editOffice !== undefined}
        office={editOffice}
        closeModal={closeModal}
      />
      <AssignOfficeModal
        isOpen={assignOffice !== undefined}
        office={assignOffice}
        closeModal={closeModal}
      />
      <UnassignOfficeModal
        isOpen={unassignOffice !== undefined}
        office={unassignOffice}
        closeModal={closeModal}
      />
      <CreateOfficeModal
        isOpen={isOpen}
        onClose={() => {
          onClose()
          revalidateOffices()
        }}
      />
      <CreateLocationModal
        locationToEdit={editingLocation}
        isOpen={isLocationOpen}
        onClose={handleCloseLocationModal}
        children={undefined}
      />
    </LayoutAdminDashboard>
  )
}
