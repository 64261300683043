import {
  Flex,
  Box,
  Text,
  Spacer,
  Divider,
  Link,
  Input,
  Textarea,
  Button as ChackraButton,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Spinner,
  Alert,
  AlertIcon
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { IBookingAdmin, IInterval } from '../../../types'
import { ChakraModal } from '../../../components/ChakraModal'
import { Button } from '../../../components/Button'
import { isFuture } from 'date-fns'
import { ExternalLinkIcon } from '@chakra-ui/icons'
import { IS_VALID_HTTP_URL } from '../../../utils/formUtils'

import { utcToZonedTime } from 'date-fns-tz'
import { BookingInfoTimeline } from '../../../components/BookingInfoTimeline'
import { CopyURLComponent } from '../../../components/CopyURLComponent'
import { BookingTimeInfoCard } from '../../../components/BookingTimeInfoCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUsers } from '@fortawesome/free-solid-svg-icons'
import { RouterLink } from '../../../components/Link'
import { getTeamMemberProfileUrl } from '../../../routeStrings'

interface IModalInput {
  isOpen: boolean
  booking: IBookingAdmin | undefined
  onCancel: () => void
  onUpdate: (notes: string, url: string, newTimes?: IInterval) => void
  handleBookingRescheduled: () => void
  closeModal: () => void
}

export const AdminCancelBookingModal: React.FC<IModalInput> = ({
  isOpen,
  booking,
  handleBookingRescheduled,
  onCancel,
  onUpdate,
  closeModal
}) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [notes, setNotes] = useState<string>('')
  const [linkURL, setLinkURL] = useState<string>('')
  const [validURL, setValidURL] = useState<boolean>(true)

  const [isRescheduling, setIsRescheduling] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)

  const is_meeting_room_booking = booking?.meeting_room != undefined

  useEffect(() => {
    if (booking) {
      setLinkURL(booking.event_sheet_url || '')
      setNotes(booking.notes || '')
    }
  }, [booking])

  useEffect(() => {
    setIsSubmitting(false)
    setIsRescheduling(false)
    setIsUpdating(false)
  }, [isOpen])

  if (!booking) return null

  const startTime = utcToZonedTime(booking.start_time, booking.timezone)
  const endTime = utcToZonedTime(booking.end_time, booking.timezone)
  const isCancelled = booking.cancelled_at != null

  const isFutureBooking = isFuture(new Date(Date.parse(booking.start_time)))
  // can cancel the booking if it is in the future and not already cancelled
  const isCancellableBooking = !isCancelled && !booking.was_billed
  const isEditablebleBooking =
    isFutureBooking &&
    !booking.is_deleted_org &&
    !isCancelled &&
    !booking.is_external &&
    !booking.was_billed
  const confirmUpdateBooking = () => {
    setIsSubmitting(true)
    onUpdate(notes, linkURL)
  }

  const setNewBookingTimes = (times: IInterval) => {
    handleBookingRescheduled()
  }

  if (!booking) return null

  return (
    <ChakraModal
      title={
        'Booking of ' + (booking?.meeting_room_name ?? booking?.workspace_name)
      }
      isOpen={isOpen}
      onClose={closeModal}
      size={isRescheduling || isUpdating ? '6xl' : 'lg'}
    >
      {booking?.is_external && (
        <Box my={2}>
          <Alert status="info">
            <AlertIcon />
            This is an{' '}
            <Text ml={1} as="span" fontWeight={'bold'}>
              External Booking
            </Text>
          </Alert>
        </Box>
      )}
      <Flex flexDir={['column', 'row']}>
        <Box width={isRescheduling || isUpdating ? ['100%', '50%'] : '100%'}>
          {booking.is_deleted_org && (
            <Box my={2}>
              <Alert status="error">
                <AlertIcon />
                This company has been deactivated
              </Alert>
            </Box>
          )}
          <Text fontSize="14px" fontWeight="bold" letterSpacing="0px">
            Booking for
          </Text>
          <RouterLink
            color="#255CE1"
            mb={0}
            to={getTeamMemberProfileUrl(0, booking.organizational_unit, true)}
            target="_blank"
            fontSize="md"
          >
            {booking?.booked_by_first_name + ' ' + booking?.booked_by_last_name}{' '}
            <ExternalLinkIcon />
          </RouterLink>
          <Text fontSize="10px" letterSpacing="0px" fontWeight="bold">
            {booking?.booked_by_email}
          </Text>
          <Box bg="brandPrimary" color="white" my={3} rounded="md" p={4}>
            <Flex>
              <Text fontSize="18px" fontWeight="bold" letterSpacing="0.25px">
                Booking Info:
              </Text>
              <Spacer />
              {isEditablebleBooking && (
                <Button
                  disabled={isUpdating || booking.was_billed || isSubmitting}
                  variant="secondary"
                  size={'xs'}
                  onClick={() => {
                    if (!booking.was_billed) {
                      setIsRescheduling(!isRescheduling)
                    }
                  }}
                >
                  {isRescheduling ? 'Cancel' : 'Reschedule Booking'}
                </Button>
              )}
            </Flex>
            <BookingTimeInfoCard
              startTime={startTime}
              endTime={endTime}
              booking={booking}
            />
          </Box>
          {is_meeting_room_booking && (
            <Box>
              {' '}
              <Flex>
                <Text fontWeight={'bold'} fontSize={'md'}>
                  Additional Info
                </Text>
                <Spacer />
                {!booking.is_deleted_org && (
                  <ChackraButton
                    variant={'ghost'}
                    disabled={isRescheduling || isSubmitting}
                    color={'brandPrimary'}
                    size={'sm'}
                    onClick={() => {
                      if (isRescheduling) return
                      setIsUpdating(!isUpdating)
                    }}
                    mt="10px"
                  >
                    {isUpdating ? 'Cancel' : 'Change details'}
                  </ChackraButton>
                )}
              </Flex>
              <Divider my={1} />
              <Box my={2}>
                {booking.number_attendees && booking.number_attendees > 0 ? (
                  <Flex alignContent="center">
                    <Text color={'gray.600'} fontWeight={'bold'}>
                      People attending
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      {booking.number_attendees}
                    </Text>
                    <FontAwesomeIcon
                      style={{ margin: 3, color: '#444' }}
                      icon={faUsers}
                    />
                  </Flex>
                ) : (
                  <Text color={'gray.600'} fontWeight={'bold'}>
                    No attendees recorded
                  </Text>
                )}
              </Box>
              <Box my={2}>
                <Text color={'gray.600'} fontWeight={'bold'}>
                  Notes
                </Text>
                <Text>{booking.notes}</Text>
              </Box>
              <Box my={2}>
                <Text color={'gray.600'} fontSize={'sm'} fontWeight="bold">
                  Event Description URL
                </Text>
                {booking.event_sheet_url && (
                  <CopyURLComponent
                    label={booking.event_sheet_url}
                    url={booking.event_sheet_url}
                  />
                )}
              </Box>
            </Box>
          )}

          <Box mt={5}>
            <Accordion allowToggle>
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">
                      More actions
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel py={1}>
                  <ChackraButton
                    variant={'ghost'}
                    isDisabled={!isCancellableBooking}
                    loadingText={'Processing cancellation'}
                    isLoading={isSubmitting}
                    colorScheme={'red'}
                    size={'sm'}
                    onClick={() => {
                      if (isSubmitting) return
                      setIsSubmitting(true)
                      onCancel()
                    }}
                  >
                    Cancel Booking&nbsp;
                    {booking.is_external ? ' (May involve a refund)' : ''}
                    {isSubmitting && <Spinner size={'xs'} />}
                  </ChackraButton>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </Box>
        </Box>
        {isRescheduling && (
          <Box
            ml={isRescheduling ? 10 : 0}
            width={isRescheduling ? ['100%', '50%'] : '100%'}
          >
            <Text fontWeight={'bold'} fontSize={['md', 'lg']}>
              Reschedule booking
            </Text>
            <Divider my={2} />
            {isRescheduling && (
              <BookingInfoTimeline
                booking={booking}
                handleBookingRescheduled={setNewBookingTimes}
              />
            )}
          </Box>
        )}
        {isUpdating && (
          <Box
            ml={isUpdating ? 10 : 0}
            width={isUpdating ? ['100%', '50%'] : '100%'}
          >
            {is_meeting_room_booking && (
              <Box>
                <Text fontWeight={'bold'} fontSize={['md', 'lg']}>
                  Edit Aditional Information
                  <Divider my={2} />
                </Text>

                {booking.event_sheet_url && (
                  <Link href={booking.event_sheet_url} isExternal my={10}>
                    Open Event sheet <ExternalLinkIcon mx="2px" />
                  </Link>
                )}
                <Box w={['100%', '100%', '80%']}>
                  <Text mr="4px" fontWeight="bold">
                    Link to event sheet:
                  </Text>
                  <Input
                    isInvalid={!validURL}
                    placeholder="Event sheet URL"
                    value={linkURL}
                    onChange={(e) => {
                      let url = e.target.value
                      setValidURL(url ? IS_VALID_HTTP_URL(url) : true)
                      setLinkURL(url)
                    }}
                  />
                </Box>
                <Text fontSize="14px" fontWeight="bold" letterSpacing="0.25px">
                  Notes
                </Text>
                <Textarea
                  placeholder="Add some notes to this booking..."
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                  resize={'vertical'}
                  size="md"
                  maxLength={2000}
                />
              </Box>
            )}
            <Flex mt={5}>
              <Spacer />
              {is_meeting_room_booking && (
                <Button
                  disabled={isSubmitting}
                  key="submit"
                  data-testid="assign"
                  onClick={confirmUpdateBooking}
                  mr="8px"
                >
                  Update Details
                </Button>
              )}
            </Flex>
          </Box>
        )}
      </Flex>

      <Box rounded="md">
        <Flex flexDirection="row" mt={10}>
          <Spacer />
          <Button
            data-testid="cancel"
            variant="secondary"
            onClick={closeModal}
            mr="10px"
          >
            Close
          </Button>
        </Flex>
      </Box>
    </ChakraModal>
  )
}
