import { Box, Button, Text, useToast } from '@chakra-ui/react'
import React, { useState } from 'react'
import { setOrgUnitAdmin, setReceivesMembershipEmails } from '../../../api'
import { IAction } from '../../../components/table/ActionsDropdown'
import { Table, TableDataProp } from '../../../components/table/Table'
import { TableRow } from '../../../components/table/TableRow'
import { useAuth } from '../../../context/auth-context'
import { IOrganizationalUnitBasic } from '../../../types'
import { useOrganization } from '../../../utils/apiHooks'
import { UserDisplay } from '../../../components/UserDisplay'
import { RouterLink } from '../../../components/Link'
import { getTeamMemberProfileUrl, routeStrings } from '../../../routeStrings'
import { useRole, Roles, useRBAC, Actions } from '../../../utils/rbac'
import { AddIcon } from '@chakra-ui/icons'
import { usePlatformTenant } from '../../../context/platform-tenant-context'
import { useFeatureFlags } from '../../../context/feature-flag-context'
import { StripeConnectRequiredModal } from '../../AdminDashboard/Modals/StripeConnectRequiredModal'
function RenderCheck(check: boolean) {
  if (check === true) {
    return (
      <svg
        width="16"
        height="14"
        viewBox="0 0 10 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="eft-permitted"
      >
        <path
          d="M9.4001 1.99961L8.0001 0.599609L4.0001 4.59961L2.0001 2.59961L0.600098 3.99961L4.0001 7.39961L9.4001 1.99961Z"
          fill="#757575"
        />
      </svg>
    )
  } else return ''
}

function RenderInviteMemberRow(handleInviteMember: () => void) {
  return (
    <TableRow padding={0} key={-1} minHeight={0}>
      <Button
        width="100%"
        padding="8px"
        minHeight={25}
        colorScheme="blue"
        variant="outline"
        leftIcon={<AddIcon />}
        opacity={0.8}
        onClick={handleInviteMember}
        data-testid="invite-member"
      >
        Invite new team member
      </Button>
    </TableRow>
  )
}

interface Props {
  title: string
  members: IOrganizationalUnitBasic[]
  loading: boolean
  isSpaceAdmin: boolean
  handleActionInvite?: () => void
  triggerRemoveMember: (orgUnit: IOrganizationalUnitBasic) => void
  orgId?: number
}
const TEXT_REMOVE_FROM_TEAM = 'Remove from team'

export const TeamMembers: React.FC<Props> = (props) => {
  const { members, loading, orgId, isSpaceAdmin } = props

  const auth = useAuth()
  const toast = useToast()
  const { revalidate, data: organization } = useOrganization(orgId)
  const { isInAdminRole, isSpaceSuperAdminRole } = useRole()
  const canInviteNewTeamMember = useRBAC(Actions.InviteTeamMember)
  const { tenantIntegration } = usePlatformTenant()
  const [setUpModalIsVisible, setSetUpModalIsVisible] = useState(false)
  const { stripeConnectOnboardingEnabled } = useFeatureFlags()
  const [isProcessing, setIsProcessing] = useState(false)

  const revokeAdminAction: IAction = {
    text: 'Revoke admin access',
    testIdPrefix: '',
    action: async (rowId) => {
      const orgUnit = members
        ? members.find((obj) => obj.id === rowId)
        : undefined
      if (orgUnit) {
        await setOrgUnitAdmin(orgUnit.id, false)
        await revalidate()
      }
    }
  }

  const grantAdminAction: IAction = {
    text: 'Grant admin access',
    testIdPrefix: '',
    action: async (rowId) => {
      const orgUnit = members
        ? members.find((obj) => obj.id === rowId)
        : undefined
      if (orgUnit) {
        await setOrgUnitAdmin(orgUnit.id, true)
        await revalidate()
      }
    }
  }

  const grantReceivesAdminEmailsAction: IAction = {
    text: 'Turn on receiving Admin Emails',
    testIdPrefix: '',
    action: async (rowId) => {
      if (isProcessing) return
      const orgUnit = members
        ? members.find((obj) => obj.id === rowId)
        : undefined
      if (orgUnit) {
        setIsProcessing(true)
        setReceivesMembershipEmails(orgUnit.user.user_profile.id, true)
          .then((res) => {
            toast({
              description: 'Settings saved successfully',
              duration: 1500
            })
            revalidate().finally(() => {
              setIsProcessing(false)
            })
          })
          .catch((err) => {
            toast({
              description: 'An error occurred',
              duration: 1500,
              status: 'error'
            })
            revalidate().finally(() => {
              setIsProcessing(false)
            })
          })
      }
    }
  }

  const cancelReceivesAdminEmailsAction: IAction = {
    text: 'Turn off receiving Admin Emails',
    testIdPrefix: '',
    action: async (rowId) => {
      if (isProcessing) return
      const orgUnit = members
        ? members.find((obj) => obj.id === rowId)
        : undefined
      if (orgUnit) {
        setReceivesMembershipEmails(orgUnit.user.user_profile.id, false)
          .then((res) => {
            toast({
              description: 'Settings saved successfully',
              duration: 1500
            })
            revalidate().finally(() => {
              setIsProcessing(false)
            })
          })
          .catch((err) => {
            toast({
              description: 'An error occurred',
              duration: 1500,
              status: 'error'
            })
            revalidate().finally(() => {
              setIsProcessing(false)
            })
          })
      }
    }
  }

  const removeMemberAction: IAction = {
    text: TEXT_REMOVE_FROM_TEAM,
    testIdPrefix: '',
    action: async (rowId) => {
      const orgUnit = members
        ? members.find((obj) => obj.id === rowId)
        : undefined
      if (orgUnit) {
        props.triggerRemoveMember(orgUnit)
      }
    }
  }

  const tableHeaders = [
    { header: 'id', accessor: 'id', show: false },
    {
      header: '',
      accessor: 'displayImage',
      show: true,
      width: 0.125
    },

    { header: 'Name', accessor: 'name', show: true, width: 0.51 },
    { header: 'Title', accessor: 'title', show: true },
    { header: 'Phone', accessor: 'phone', show: true },
    { header: 'Is Admin', accessor: 'teamAdmin', show: true },
    { header: '', accessor: 'actions', show: true, width: 0.125 }
  ]

  function MemberLink(member: IOrganizationalUnitBasic) {
    return (
      <RouterLink
        color="#255CE1"
        mb={0}
        to={
          orgId ? getTeamMemberProfileUrl(orgId, member.id, isInAdminRole) : '#'
        }
      >
        {member.user.name}
      </RouterLink>
    )
  }

  const tableData: any[] = members
    ? members.map((member) => {
        const user = member.user
        const myId = auth.me?.user.id

        const actions: IAction[] = []
        if (!organization?.is_deleted) {
          if (user.id !== myId && auth.isTeamAdmin) {
            // action to grant admin access
            if (!!!member.is_team_admin) {
              actions.push(grantAdminAction)
            }
            // actions only available when there are more than 1 members
            if (members.length > 1) {
              if (member.is_team_admin) {
                actions.push(revokeAdminAction)
              }
              actions.push(removeMemberAction)
            }
          }
          if (
            isSpaceSuperAdminRole &&
            !organization?.is_space_admin &&
            !actions.find((a) => a.text === TEXT_REMOVE_FROM_TEAM)
          ) {
            actions.push(removeMemberAction)
          }
          if (auth.isTeamAdmin && isSpaceAdmin) {
            if (isSpaceAdmin) {
              if (member.user.user_profile.receives_space_admin_emails) {
                actions.push(cancelReceivesAdminEmailsAction)
              } else {
                actions.push(grantReceivesAdminEmailsAction)
              }
            }
          }
        }

        const cleanedData = {
          id: TableDataProp(member.id),
          displayImage: TableDataProp(
            <UserDisplay
              name={member.user.name}
              src={member.user.user_profile.profile_picture?.image}
            />
          ),
          actions: TableDataProp(actions),
          teamAdmin: TableDataProp(RenderCheck(member.is_team_admin)),
          name: [TableDataProp(MemberLink(member)), TableDataProp(user.email)],
          title: TableDataProp(member.job_title ?? ''),
          phone: TableDataProp(user.user_profile.mobile_number)
        }

        return cleanedData
      })
    : []

  let completeTenantSetUp =
    stripeConnectOnboardingEnabled &&
    tenantIntegration &&
    !tenantIntegration.is_stripe_connected

  if (
    !organization?.is_deleted &&
    canInviteNewTeamMember &&
    props.handleActionInvite
  ) {
    tableData.push(
      RenderInviteMemberRow(
        completeTenantSetUp
          ? () => setSetUpModalIsVisible(true)
          : props.handleActionInvite
      )
    )
  }

  return (
    <div style={{ width: '100%', padding: '16px' }}>
      <Box my="3" w="100%">
        <Box d="flex" mb={3} alignItems="baseline">
          <Text
            mb={0}
            mr={3}
            color="headingPrimary"
            fontSize="lg"
            textTransform="capitalize"
          >
            {props.title}
          </Text>
          <Text fontSize="xs" color="grey">
            {members ? members.length : 0} Total
          </Text>
        </Box>
        <Table headers={tableHeaders} data={tableData} loading={loading} />
      </Box>

      <StripeConnectRequiredModal
        isOpen={setUpModalIsVisible}
        closeModal={() => setSetUpModalIsVisible(false)}
      />
    </div>
  )
}
