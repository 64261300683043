import { useDisclosure } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { StringParam, useQueryParam } from 'use-query-params'
import {
  GroupingHeaderLayout,
  IActionButton
} from '../../components/GroupingHeaderLayout'
import { useAppTourContextProvider } from '../../context/app-tour-context'
import { tourTargetStrings } from '../../tourStepsArrays'
import { IMeetingRoomSuperAdmin } from '../../types'
import { Actions, useRBAC } from '../../utils/rbac'
import { AdminDashboardMeetingRoomsAdmin } from './AdminDashboardMeetingRoomsAdmin'
import { AdminDashboardMeetingRoomsBookings } from './AdminDashboardMeetingRoomsBookings'
import { AdminDashboardMeetingRoomsSettings } from './AdminDashboardMeetingRoomsSettings'
import { LayoutAdminDashboard } from './LayoutAdminDashboard'
import { CreateMeetingRoomModal } from './Modals/CreateMeetingRoomModal'

const meetingRoomPages = [
  {
    text: 'Meeting room bookings',
    id: 'meeting-room-bookings',
    tourClassName: ''
  },
  {
    text: 'Meeting rooms',
    id: 'meeting-rooms',
    tourClassName: tourTargetStrings.adminheaderMeetingroomsMeetingRoom
  },
  {
    text: 'Booking Settings',
    id: 'bookings-settings',
    tourClassName: ''
  }
]

export const AdminDashboardMeetingRooms: React.FC = () => {
  const [selectedRoom, setSelectedRoom] = useState<IMeetingRoomSuperAdmin>()

  const { isOpen, onClose, onOpen } = useDisclosure()
  const canAdministerMeetingRooms = useRBAC(Actions.AdministerMeetingRooms)
  const [meetingRoomPage, setMeetingRoomPage] = useQueryParam(
    'type',
    StringParam
  )
  const { adminTourActive, setRun } = useAppTourContextProvider()
  useEffect(() => {
    if (adminTourActive) {
      setRun(true)
    }
  }, [])
  useEffect(() => {
    if (!meetingRoomPage) {
      setMeetingRoomPage(meetingRoomPages[0].id)
    }
  }, [meetingRoomPage])

  const headerActions: IActionButton[] = []
  meetingRoomPage == 'meeting-rooms' &&
    canAdministerMeetingRooms &&
    headerActions.push({
      text: 'New meeting room',
      variant: 'primary',
      onClick: () => {
        setSelectedRoom(undefined)
        onOpen()
      }
    })

  return (
    <LayoutAdminDashboard>
      <GroupingHeaderLayout
        basicTitle="Meeting rooms"
        tabs={meetingRoomPages.map((page) => {
          return {
            text: page.text,
            className: page.tourClassName,
            active: meetingRoomPage == page.id,
            selectAction: () => {
              setMeetingRoomPage(page.id)
            }
          }
        })}
        action={headerActions}
      />
      {meetingRoomPage == 'meeting-rooms' && (
        <AdminDashboardMeetingRoomsAdmin />
      )}
      {meetingRoomPage == 'meeting-room-bookings' && (
        <AdminDashboardMeetingRoomsBookings />
      )}
      {meetingRoomPage == 'bookings-settings' && (
        <AdminDashboardMeetingRoomsSettings />
      )}
      {isOpen && (
        <CreateMeetingRoomModal
          onClose={onClose}
          isOpen={isOpen}
          meetingRoomToEdit={undefined}
        />
      )}
    </LayoutAdminDashboard>
  )
}
