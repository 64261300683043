import { Box, Text, useDisclosure, Flex } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { getOrgBonds } from '../api'
import { useAuth } from '../context/auth-context'
import { IBond } from '../types'
import { useOrganization } from '../utils/apiHooks'
import { format } from 'date-fns'
import { CreateBondModal } from '../pages/AdminDashboard/Modals/CreateBondModal'
import { TableDataProp, Table } from './table/Table'
import { Button } from './Button'
import { ValueComponent } from './billing/ValueComponent'

export const Bonds: React.FC<any> = () => {
  const { onOpen, isOpen, onClose } = useDisclosure()
  const auth = useAuth()
  const { orgId: routeOrgId } = useParams<{ orgId?: string }>()

  const orgId =
    (routeOrgId && parseInt(routeOrgId, 10)) || auth.currentOrganization?.id
  const { data: organization } = useOrganization(orgId)
  const [members, setMembers] = useState<{ id: number; name: string }[]>()
  const [bonds, setBonds] = useState<IBond[]>([])
  const [editBond, setEditBond] = useState<IBond>()

  useEffect(() => {
    if (orgId) {
      getOrgBonds(orgId)
        .then((res) =>
          setBonds(res.data.filter((bond) => bond.organization === orgId))
        )
        .catch((reason) => {
          console.error(reason)
        })
    }
  }, [orgId, organization, isOpen])

  useEffect(() => {
    setMembers(
      organization && organization.members
        ? organization?.members.map((member) => ({
            name: member.user.name,
            id: member.user.id
          }))
        : []
    )
  }, [organization])

  const bondActions = [
    {
      text: 'Cancel Bond',
      testIdPrefix: 'cancel-bond',
      action: (rowId) => {
        const bond = bonds.find((bond) => bond.id === rowId)
        if (bond) {
          setEditBond(bond)
          onOpen()
        }
      }
    }
  ]

  const tableData =
    bonds.map((bond) => {
      const cleanedData = {
        id: TableDataProp(bond.id),
        category: TableDataProp(bond.category),
        location: TableDataProp(bond.location),
        date_paid: TableDataProp(
          bond.date_paid && typeof bond.date_paid === 'string'
            ? format(Date.parse(bond.date_paid), 'dd/MM/yyyy')
            : undefined
        ),
        date_returned: TableDataProp(
          bond.date_returned && typeof bond.date_returned === 'string'
            ? format(Date.parse(bond.date_returned), 'dd/MM/yyyy')
            : undefined
        ),
        amount_paid: TableDataProp(
          <ValueComponent value={`${bond.amount_paid}`} />
        ),
        amount_returned: TableDataProp(
          <ValueComponent value={`${bond.amount_returned}`} />
        ),
        notes_given: TableDataProp(bond.notes_given),
        actions: TableDataProp(bondActions),
        member: TableDataProp(
          bond.user ? members?.find((m) => m.id === bond.user)?.name : ''
        )
      }

      return cleanedData
    }) || []

  return (
    <Box my="3" minW="900px">
      <Flex mb={1} alignItems="center" justify="space-between">
        <Text mb={0} fontWeight="semibold" color="headingPrimary" fontSize="lg">
          Bonds History{' '}
        </Text>
        {!organization?.is_deleted && (
          <Button
            variant="secondary"
            mb={3}
            mr={4}
            onClick={() => {
              setEditBond(undefined)
              onOpen()
            }}
          >
            Create new bond
          </Button>
        )}
      </Flex>
      {/* Billing history */}
      <Table
        headers={[
          { header: 'id', accessor: 'id', show: false },
          { header: 'test_prefix', accessor: 'test_prefix', show: false },
          {
            header: 'Category',
            accessor: 'category',
            show: true,
            width: 1.5 / 12
          },
          {
            header: 'Location',
            accessor: 'location',
            show: true,
            width: 1.5 / 12
          },
          {
            header: 'Date paid',
            accessor: 'date_paid',
            show: true,
            width: 1.5 / 12
          },
          {
            header: 'Member Associated',
            accessor: 'member',
            show: true,
            width: 1.5 / 12
          },
          {
            header: 'Date returned',
            accessor: 'date_returned',
            show: true,
            width: 1.5 / 12
          },
          {
            header: 'Amount paid',
            accessor: 'amount_paid',
            show: true,
            width: 2 / 12
          },
          {
            header: 'Amount returned',
            accessor: 'amount_returned',
            show: true,
            width: 2 / 12
          },
          {
            header: 'Notes_given',
            accessor: 'notes_given',
            show: true,
            width: 2 / 12
          },
          { header: '', accessor: 'actions', show: true, width: 1 / 12 }
        ]}
        data={tableData}
        loading={!bonds}
      />

      <CreateBondModal
        isOpen={isOpen}
        onClose={onClose}
        bondToCancel={editBond}
        organization={organization}
      />
    </Box>
  )
}
