import { AddIcon, CalendarIcon, TimeIcon } from '@chakra-ui/icons'
import {
  Box,
  Center,
  Flex,
  FormControl,
  FormLabel,
  GridItem,
  Heading,
  HStack,
  SimpleGrid,
  Spacer,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
  useTheme
} from '@chakra-ui/react'
import { format } from 'date-fns'
import { Field, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { Button } from '../../../components/Button'
import { FormErrorMessage } from '../../../components/FormErrorMessage'
import { StyledSelect } from '../../../components/StyledSelect'
import { useBookingContext } from '../../../context/BookingContext'
import { usePlatformTenant } from '../../../context/platform-tenant-context'
import { ILocationOpeningHours, Location } from '../../../types'
import { LocationOpeningHoursModal } from '../Modals/LocationOpeningHoursModal'

interface Props {
  location: Location
  onUpdate: () => void
}

export const LocationOpeningHours: React.FC<Props> = ({
  location,
  onUpdate,
  ...rest
}) => {
  const theme = useTheme()
  const { platformTenant } = usePlatformTenant()
  const { tenantWorkspaces } = useBookingContext()
  const { isOpen, onClose, onOpen } = useDisclosure()

  const openingHours = location.opening_hours

  const timeFrameComponent = (name: string, start?: string, end?: string) => {
    return (
      <Box pl={5} py={3} textAlign="center">
        {name && (
          <Text color="gray.500" fontSize="lg">
            {name}
          </Text>
        )}
        <Box>{openingHoursComponent(start, end)}</Box>
      </Box>
    )
  }

  const openingHoursComponent = (start?: string, end?: string) => {
    return (
      <Text fontSize="lg">
        {start && end ? parseHours(start) + ' to ' + parseHours(end) : '--:--'}
      </Text>
    )
  }

  const parseHours = (hours: string) => {
    // hours is in format hh:mm:ss
    try {
      let hoursArray = hours.split(':')
      let d = new Date()
      d.setHours(parseInt(hoursArray[0]))
      d.setMinutes(parseInt(hoursArray[1]))
      return format(d, 'h:mm aaa')
    } catch (error) {
      console.error(error)
      return 'Invalid Format'
    }
  }

  return (
    <Box
      bg="white"
      {...rest}
      w={['100%', '70%']}
      position="relative"
      background="#fff"
      boxShadow="0px 8px 16px rgba(0, 0, 0, 0.1)"
      borderRadius="4px"
      padding="15px"
      margin="5px"
      cursor="pointer"
      mb={3}
    >
      <Flex py={3} textAlign="center">
        <Heading as="h4" size="md">
          <TimeIcon color={theme.colors.brandPrimary} fontSize="xl" mr={2} />
          Opening hours settings
        </Heading>
        <Spacer />
        <Button variant="secondary" onClick={onOpen}>
          Update
        </Button>
      </Flex>
      <SimpleGrid columns={2} spacing={5}>
        <GridItem align={'right'}>
          <Text color="gray.500" fontSize="lg">
            Opening hours
          </Text>
        </GridItem>
        <GridItem>
          {openingHoursComponent(
            openingHours?.full_day_from,
            openingHours?.full_day_to
          )}
        </GridItem>
        <GridItem align={'right'}>
          <Text fontSize="lg" color="gray.500">
            Half a day is{' '}
          </Text>
        </GridItem>
        <GridItem>
          <Text fontSize="lg">
            {openingHours
              ? openingHours.hours_half_day +
                ' ' +
                (openingHours.hours_half_day === 1 ? 'hour' : 'hours')
              : 'Value is not set'}
          </Text>
        </GridItem>
      </SimpleGrid>
      <HStack w={['100%', '100%', '100%']}>
        {/* Morning */}
        {/* <Box>
          {timeFrameComponent(
            'Morning',
            openingHours?.first_half_from,
            openingHours?.first_half_to
          )}
        </Box> */}
        {/* Evening */}
        {/*  <Box>
          {timeFrameComponent(
            'Evening',
            openingHours?.second_half_from,
            openingHours?.second_half_to
          )}
        </Box> */}
      </HStack>
      <LocationOpeningHoursModal
        onClose={(status?: boolean) => {
          onClose()
          if (status) onUpdate()
        }}
        isOpen={isOpen}
        location={location}
      />
    </Box>
  )
}
