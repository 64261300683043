import { ChatIcon } from '@chakra-ui/icons'
import { Box, Text, Tooltip, useDisclosure, useTheme } from '@chakra-ui/react'

import React, { useEffect, useState } from 'react'

import { useFeatureFlags } from '../context/feature-flag-context'
import { usePlatformTenant } from '../context/platform-tenant-context'
import { useRole } from '../utils/rbac'
import { MainAIChatBotDrawer } from './MainAIChatBotDrawer'

interface Props {}

export const MainAIChatComponent: React.FC<Props> = ({ children }) => {
  const theme = useTheme()
  const { AIAdminChatbotEnabled, AIMembersChatbotEnabled } = useFeatureFlags()
  const { isOpen, onClose, onOpen, onToggle } = useDisclosure()
  const { platformTenant } = usePlatformTenant()
  const [showBubble, setShowBubble] = useState(false)
  const { isInAdminRole } = useRole()

  useEffect(() => {
    if (!AIAdminChatbotEnabled && !AIMembersChatbotEnabled) {
      setShowBubble(false)
      onClose()
      return
    }
    if (platformTenant) {
      if (
        isInAdminRole &&
        AIAdminChatbotEnabled &&
        platformTenant.enable_admin_chatbot &&
        platformTenant.admin_chatbot_iframe_html
      ) {
        setShowBubble(true)
        return
      } else if (
        !isInAdminRole &&
        AIMembersChatbotEnabled &&
        platformTenant.enable_user_chatbot &&
        platformTenant.user_chatbot_iframe_html
      ) {
        setShowBubble(true)
        return
      }
      setShowBubble(false)
    }
  }, [
    isInAdminRole,
    platformTenant,
    AIAdminChatbotEnabled,
    AIMembersChatbotEnabled
  ])

  if (!showBubble) return null

  return (
    <Box>
      <Tooltip label="AI Chatbot Assistant" placement="auto-end">
        <Box
          display={isOpen ? 'none' : 'flex'}
          height="50px"
          w={'50px'}
          cursor={'pointer'}
          background={theme.colors.brandPrimary}
          borderRadius={'full'}
          zIndex={9999}
          position={'absolute'}
          bottom={platformTenant?.hubspot_help_widget_code ? '100px' : 10}
          right={10}
          onClick={onOpen}
          _hover={{
            boxShadow: 'dark-lg'
          }}
          alignItems="center"
          justifyContent="center"
        >
          <Text
            style={{
              background: '#2c3e50',
              lineHeight: '11px',
              textAlign: 'right',
              borderRadius: '3px',
              width: '30px',
              color: 'white',
              padding: '2px',
              fontSize: '11px',
              position: 'absolute',
              top: '-5px',
              right: '-13px'
            }}
          >
            Beta
          </Text>
          <Text
            style={{
              lineHeight: '11px',
              textAlign: 'center',
              borderRadius: '3px',
              width: '30px',
              color: 'white',
              padding: '2px',
              fontSize: '11px',
              position: 'absolute',
              top: '15px',
              fontWeight: 'bold'
            }}
          >
            AI
          </Text>

          <ChatIcon color="#fff" boxSize={6} />
        </Box>
      </Tooltip>
      <MainAIChatBotDrawer
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        onToggle={onToggle}
      />
    </Box>
  )
}
