import {
  BoxProps,
  Button,
  Icon,
  Stack,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure
} from '@chakra-ui/react'
import { differenceInCalendarDays, format } from 'date-fns'
import React from 'react'
import Calendar from 'react-calendar'
import { CalendarIcon, ChevronDownIcon } from "@chakra-ui/icons"
interface DatePickerProps extends BoxProps {
  dateOptions: Date[]
  selected: Date
  handleSelected: (date: Date) => void
}

export const DatePicker: React.FC<DatePickerProps> = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <Stack>
      <Stack
        isInline
        spacing="8px"
        align="center"
        cursor="pointer"
        onClick={onOpen}
      >
        <CalendarIcon />
        <Text>{format(props.selected, 'MMM yyyy')}</Text>
        <ChevronDownIcon color="brandPrimary" />
      </Stack>
      <Stack spacing="1px" isInline w="100%" overflow="scroll">
        {props.dateOptions.map((date, index) => (
          <Button
            flexShrink={0}
            padding="0px"
            w="47px"
            h="80px"
            key={index}
            borderRadius="0px"
            backgroundColor={
              differenceInCalendarDays(props.selected, date) === 0
                ? 'brandPrimary'
                : 'white'
            }
            color={
              differenceInCalendarDays(props.selected, date) === 0
                ? 'white'
                : '#303B40'
            }
            onClick={() => {
              props.handleSelected(date)
            }}
          >
            {format(date, 'dd')} <br />
            {format(date, 'E')}
          </Button>
        ))}
      </Stack>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader color="#212121">Pick date</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Calendar
              selectRange={false}
              view="month"
              minDetail="month"
              onChange={(date) => {
                //Needs to handle date range, even with date range disabled
                let selectedDate = date[0] || date
                props.handleSelected(selectedDate)
              }}
              value={props.selected}
              minDate={new Date()}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Stack>
  )
}
