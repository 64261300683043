import {
  Flex,
  InputProps,
  Stack,
  Text,
  Textarea,
  Image,
  TextareaProps
} from '@chakra-ui/react'
import { format } from 'date-fns'
import React, { useState } from 'react'
import { addComment } from '../../api'
import { Button } from '../../components/Button'
import { UserDisplay } from '../../components/UserDisplay'
import { useAuth } from '../../context/auth-context'
import { IDailyFiveContent } from '../../types'
import { analyticsTrack } from '../../utils/analytics'
import { useComments } from '../../utils/apiHooks'

interface Props extends TextareaProps {
  content: IDailyFiveContent | undefined
}
export const ContentComments: React.FC<Props> = ({ content, ...rest }) => {
  const [newComment, setNewComment] = useState<string>('')
  const { data: comments, revalidate } = useComments(content?.id)
  const { me } = useAuth()
  const [submitting, setSubmitting] = useState<boolean>(false)
  const handlePost = () => {
    setSubmitting(true)
    if (content && content.id && newComment && newComment?.length > 0) {
      const data = { body: newComment }

      try {
        addComment(content.id, data).then((response) => {
          setSubmitting(false)
          analyticsTrack('Comment Added To Post', {
            contentId: content.id,
            userId: me?.user.id
          })
          revalidate()
          clearState()
        })
      } catch (e: any) {
        if (e.response && e.response.data) {
          setSubmitting(false)
          console.log(e.response.data)
        }
      }
    }
  }
  const clearState = () => {
    setNewComment('')
  }

  const getResponsesText = () => {
    if (comments === undefined) return null
    if (comments.length > 0) return `${comments.length} comments`
    else return 'No comments yet. Be the first to write a response'
  }

  return (
    <Flex
      height="100vh"
      width={['100%', '530px']}
      p={5}
      justifyContent="left"
      flexShrink={0}
    >
      <Stack w="100%">
        <Text fontSize="s" color="#000000" fontWeight="bold">
          {getResponsesText()}
        </Text>
        {comments?.map((comment, i) => (
          <Stack key={i}>
            <Stack>
              <Flex alignContent="center">
                <UserDisplay
                  mr={3}
                  name={`${comment.firstname[0]} ${comment.lastname[0]}`}
                  size="xs"
                  src={comment.user_profile_image}
                />

                <Stack isInline spacing={2} align="center">
                  <Text
                    alignItems="center"
                    as="span"
                    fontSize="xs"
                    color="#4F4F4F"
                    fontWeight="bold"
                  >
                    {`${comment.firstname} ${comment.lastname}`}
                  </Text>
                  <Text as="span" fontSize="sm" alignItems="center">
                    •
                  </Text>
                  <Text fontSize="sm" alignItems="center">
                    {comment.created_at &&
                      format(
                        new Date(Date.parse(comment.created_at)),
                        'h:mm aa, d MMM '
                      )}
                  </Text>
                </Stack>
              </Flex>
            </Stack>
            <Flex w="100%" pb="24px">
              <Text>{comment.body}</Text>
            </Flex>
          </Stack>
        ))}

        <Stack>
          <Text>Add a new comment</Text>
          <Textarea
            id="comments"
            {...rest}
            value={newComment}
            background="#FAFAFA"
            borderBottom="1px"
            borderBottomColor="#757575"
            onChange={(e) => setNewComment(e.target.value)}
          />
          <Button disabled={submitting} small onClick={() => handlePost()}>
            Post
          </Button>
        </Stack>
      </Stack>
    </Flex>
  )
}
