import {
  Box,
  Flex,
  Image,
  Stack,
  Text,
  HStack,
  Tooltip,
  Divider,
  Tag,
  TagLabel,
  Heading
} from '@chakra-ui/react'
import { faTv, faUsers, faVideo } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { differenceInSeconds, format } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import React from 'react'
import {
  IBookingDisplay,
  IMeetingRoomSuperAdmin,
  IWorkspace
} from '../../../types'
import { secondsToTimeString } from '../Workspaces/meetingRoomUtils'

interface Props {
  booking: IBookingDisplay
  meetingRoom?: IMeetingRoomSuperAdmin
  workspace?: IWorkspace
  memberPricePerHour?: number
  creditCostPerHour?: number
  cardWidth?: string[]
}

export const BookingDisplay: React.FC<Props> = ({
  booking,
  meetingRoom,
  workspace,
  memberPricePerHour,
  creditCostPerHour,
  children
}) => {
  const placeholder = require('../../../assets/placeholder.svg')

  const {
    startTime,
    endTime,
    meetingRoomImage,
    meetingRoomName,
    workspaceName,
    has_videoconference,
    has_screen_mirroring,
    capacity,
    location_address,
    location_name,
    timezone
  } = booking

  const isMeetingRoomBooking = meetingRoomName != undefined
  const cardWidth = isMeetingRoomBooking
    ? ['100%', '80%', '40%']
    : ['100%', '80%', '60%']

  const ResourceBasicInfo = () => {
    return (
      <Stack>
        <Heading size="md">
          {meetingRoomName} {workspaceName}
        </Heading>
        <Heading size="xs" color="gray.400">
          {location_name},&nbsp;{location_address}
        </Heading>
        <HStack justifyContent={'left'} mt={2}>
          {capacity && (
            <Tooltip label="Capacity">
              <Tag
                size={'sm'}
                borderRadius="full"
                variant="subtle"
                colorScheme="blackAlpha"
              >
                <FontAwesomeIcon icon={faUsers} />
                <TagLabel ml={2}>{capacity}</TagLabel>
              </Tag>
            </Tooltip>
          )}
          {has_videoconference && (
            <Tooltip label="Video Conference">
              <Tag
                size={'sm'}
                borderRadius="full"
                variant="subtle"
                colorScheme="blackAlpha"
              >
                <FontAwesomeIcon icon={faVideo} />
                <TagLabel ml={2}>VC</TagLabel>
              </Tag>
            </Tooltip>
          )}
          {has_screen_mirroring && (
            <Tooltip label="Screen Mirroring">
              <Tag
                size={'sm'}
                borderRadius="full"
                variant="subtle"
                colorScheme="blackAlpha"
              >
                <FontAwesomeIcon icon={faTv} />
                <TagLabel ml={2}>SM</TagLabel>
              </Tag>
            </Tooltip>
          )}
        </HStack>
      </Stack>
    )
  }

  return (
    <Box margin="auto">
      <ResourceBasicInfo />
      <Stack
        align={'flex-start'}
        spacing={{ base: 8, md: 10 }}
        py={{ base: 2 }}
        direction={{ base: 'column', md: 'row' }}
      >
        {meetingRoomName && (
          <Flex bg="#fff" mb={4} align="center">
            <Image
              src={meetingRoomImage}
              fallbackSrc={placeholder.default}
              objectFit="cover"
              maxHeight="300px"
            />
          </Flex>
        )}
        <Flex
          // flexGrow={1}
          width={cardWidth}
          height="100%"
          justifyContent={isMeetingRoomBooking ? 'space-between' : 'center'}
          flexDirection={'column'}
        >
          <Box bg="brandPrimary" color="white" rounded="md" p={4}>
            <Text fontSize="18px" fontWeight="bold" letterSpacing="0.25px">
              Booking Info:
            </Text>
            <Stack spacing={2} justifyContent="space-between" color={'white'}>
              <Box>
                <Divider mt={1} />
              </Box>
              <Text fontSize="22px" fontWeight="bold" letterSpacing="0.25px">
                {format(utcToZonedTime(startTime, timezone), 'PP')}
              </Text>

              <Text fontSize="14px" fontWeight="bold" letterSpacing="0.25px">
                {format(utcToZonedTime(startTime, timezone), 'h:mm b')} -{' '}
                {format(utcToZonedTime(endTime, timezone), 'h:mm b')}
              </Text>
              <Text fontSize="xs">{timezone}</Text>
              <Text fontSize="12px" letterSpacing="0.25px">
                Booking for{' '}
                {secondsToTimeString(differenceInSeconds(endTime, startTime))}
              </Text>
            </Stack>
            <Divider my={2} />
            <Stack spacing={0} justifyContent="space-between" color={'white'}>
              <Text fontSize="14px" fontWeight="bold" letterSpacing="0.25px">
                {location_name}
              </Text>
              <Text>{location_address}</Text>
            </Stack>
          </Box>
          {meetingRoom && (
            <Stack>
              <Text fontSize="16px" fontWeight="bold" lineHeight="26px" pb={2}>
                {meetingRoomName && 'Meeting Room Description:'}{' '}
              </Text>
              <Text fontSize="14px" fontWeight="bold" letterSpacing="0.25px">
                {meetingRoomName} {workspaceName}
              </Text>
              {meetingRoom && (
                <>
                  <Text
                    fontSize="14px"
                    fontWeight="bold"
                    letterSpacing="0.25px"
                  >
                    Cost:
                  </Text>
                  <Stack mb={3} isInline>
                    <Stack pr={3} borderRight="1px" borderColor="eastBay06">
                      <Text fontSize="14px" letterSpacing="0.25px">
                        Credits per hour:
                      </Text>
                      <Text>
                        {creditCostPerHour ?? meetingRoom.credits_per_hour}
                      </Text>
                    </Stack>

                    <Stack pl={3}>
                      <Text fontSize="14px" letterSpacing="0.25px">
                        Cost per hour (ex gst):
                      </Text>
                      <Text>
                        $
                        {memberPricePerHour ??
                          meetingRoom.member_price_per_hour}
                      </Text>
                    </Stack>
                  </Stack>
                </>
              )}
            </Stack>
          )}

          <Box mt={3} pb={3}>
            {children}
          </Box>
        </Flex>
      </Stack>
    </Box>
  )
}
