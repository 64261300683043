import {
  Divider,
  Flex,
  Icon,
  Stack,
  Text,
  Box,
  Image,
  useToast
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { getContent, updateContent } from '../../api'
import comment from '../../assets/comment.svg'
import { UserDisplay } from '../../components/UserDisplay'
import { IDailyFiveContent, INewContent } from '../../types'
import { LayoutMemberDashboard } from '../MemberDashboard/LayoutMemberDashboard'
import { ContentComments } from './ContentComments'
import { ContentDetailBar } from './ContentDetailBar'
import { SaveButton } from './SaveButton'
import { ChevronLeftIcon } from '@chakra-ui/icons'
import { ContentDisplay } from './ContentDisplay'
import { CenteredSpinner } from '../../styled/CenteredSpinner'
import { MemberDashboardCreatePost } from './MemberDashboardCreatePost'
import { useAuth } from '../../context/auth-context'
import { routeStrings } from '../../routeStrings'
export const ContentEdition: React.FC = () => {
  //const [saveIcon, setSaveIcon] = useState<string>()

  const { me } = useAuth()
  const { contentId: contentId } = useParams<{ contentId?: string }>()
  const [content, setContent] = useState<IDailyFiveContent>()
  const history = useHistory()
  const toast = useToast()
  useEffect(() => {
    //get the content

    contentId &&
      getContent(parseInt(contentId))
        .then(({ data }) => {
          let content = data as IDailyFiveContent
          if (content.user === me?.user.id) {
            setContent(content)
          } else {
            history.push(routeStrings.memberDashboardHome)
          }
        })
        .catch((err) => {
          console.log(err)
          history.push(routeStrings.memberDashboardHome)
        })
  }, [])
  return (
    <>
      {contentId && content ? (
        <MemberDashboardCreatePost
          content={content}
          handleSave={(data: INewContent) => {
            return new Promise((resolve, reject) => {
              updateContent(data, parseInt(contentId)).then((res) => {
                let message = 'Post updated'
                let success = false
                if (res.data) {
                  if (content) {
                    history.push(
                      routeStrings.memberDashboardViewPost(content?.id)
                    )
                  }
                  toast({
                    position: 'top',
                    description: message,
                    status: 'success'
                  })
                  resolve({
                    message: 'Post Updated',
                    success: true,
                    content: content
                  })
                  return
                } else {
                  message = 'An error occurred'
                }
                reject({ reason: message })
              })
            })
          }}
        />
      ) : (
        <CenteredSpinner />
      )}
    </>
  )
}
