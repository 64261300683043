import { format } from 'date-fns'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { Box, Image, Text, useTheme } from '@chakra-ui/react'
import { createNewUser, getTermsAndConditions } from '../../api'

import { BackButton } from '../../components/BackButton'
import { Button } from '../../components/Button'
import { FormInput } from '../../components/FormInput'
import { useUserDetails } from '../../context/user-onboard-context'
import { routeStrings } from '../../routeStrings'
import { InputWarning } from '../../styled/InputWarning'
import { analyticsIdentify, analyticsTrack } from '../../utils/analytics'
import { useAuth } from '../../context/auth-context'
import { usePlatformTenant } from '../../context/platform-tenant-context'

export const OnboardingTenant6Password: React.FC = () => {
  const { revalidate } = usePlatformTenant()
  const { userDetails } = useUserDetails()
  const auth = useAuth()
  const history = useHistory()
  const [formErrorMessage, setFormErrorMessage] = useState({})
  const [, setPasswordErrorMessage] = useState('')
  const [tcId, setTcId] = useState(0)
  const theme: any = useTheme()
  useEffect(() => {
    getTermsAndConditions().then(({ data }: any) => setTcId(data[0].id))
  }, [])

  return (
    <Formik
      initialValues={{ password: '' }}
      validate={(values) => {
        const errors: any = {}
        if (!values.password) {
          errors.password = 'Required'
          setPasswordErrorMessage(errors.password)
        }
        if (/^\d+$/.test(values.password)) {
          errors.password = "Your password can't be entirely numeric."
          setPasswordErrorMessage(errors.password)
        }
        if (values.password.length < 8) {
          errors.password =
            'This password is too short. It must contain at least 8 characters.'
          setPasswordErrorMessage(errors.password)
        } else {
          setPasswordErrorMessage('')
        }
        return errors
      }}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true)

        // use org unit to tie a new wuser to
        let plan
        let startDate
        plan = userDetails!.plan
        startDate = format(new Date(), 'yyyy-MM-dd')

        // gather all required details
        // note - for skills and interests we just send an array of the selected IDs through
        createNewUser({
          email: userDetails.email,
          username: userDetails.email,
          password1: values.password,
          password2: values.password,
          first_name: userDetails!.firstName,
          last_name: userDetails!.lastName,
          postcode: userDetails!.postcode,
          mobile_number: userDetails!.mobileNumber,
          invite_token: userDetails!.inviteToken,
          job_title: userDetails!.jobTitle,
          company: userDetails!.company,
          interests: userDetails!.interests.map((i) => i.id),
          skills: userDetails!.skills.map((s) => s.id),
          about_you: userDetails!.aboutYou,
          start_date: startDate,
          agreed_tc: tcId
          // plan
        })
          .then(({ data: { key, userId } }) => {
            window.localStorage.setItem('Token', key)

            analyticsIdentify(String(userId), {
              email: userDetails!.email,
              name: userDetails!.firstName + userDetails!.lastName
            })
          })
          .then((data) => {
            setSubmitting(false)
            auth.getAndSetMe!(window.localStorage.getItem('Token')).then(() =>
              history.push(routeStrings.onboardingTenant1BusinessSettings)
            )
          })
          .catch((e) => {
            setSubmitting(false)
            if (e.response.data.password1) {
              setFormErrorMessage(e.response.data)
            } else setFormErrorMessage(e.response.data)
          })
      }}
    >
      {({ isSubmitting }) => (
        <Box p={3}>
          <Image
            src={theme.logos.logo_long}
            width="128px"
            className="location-logo"
          />
          <Box mt={6}>
            <h3>Create a password</h3>
            <ul
              style={{
                color: 'darkslategrey',
                fontFamily: 'Open Sans'
              }}
            >
              <li>
                Your password can't be too similar to your other personal
                information.
              </li>
              <li>Your password must contain at least 8 characters.</li>
              <li>Your password can't be a commonly used password.</li>
              <li>Your password can't be entirely numeric.</li>
            </ul>
            <br />
            <Form>
              <Field
                type="password"
                name="password"
                autoFocus={true}
                data-testid="onboarding-password-field"
                placeholder="Type your password here"
                component={FormInput}
              />
              {/* Form error message */}
              {Object.keys(formErrorMessage).length ? (
                <Text color="black">
                  Please go back and check the following fields
                </Text>
              ) : null}

              {Object.entries(formErrorMessage).map((error) => {
                // const errorNameString = error[0].replace(/_/g, ' ')
                let errorFields = ''

                if (error[0].includes('password1'))
                  return (
                    <InputWarning
                      color="#ffdd57"
                      style={{ color: 'rgba(0,0,0,.7)', margin: '5px' }}
                    >
                      Password not strong enough
                    </InputWarning>
                  )

                error &&
                  error.map((unit) => {
                    errorFields += ' ' + unit + ','
                  })
                return (
                  <InputWarning
                    color="#ffdd57"
                    style={{ color: 'rgba(0,0,0,.7)', margin: '5px' }}
                  >
                    {errorFields.slice(0, -1)}
                  </InputWarning>
                )
              })}
              {/* Password error message */}
              <ErrorMessage name="password" component={InputWarning} />
              <br />
              <Text>
                By pressing finish, you agree to the
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={routeStrings.termsAndConditions}
                >
                  {' Terms and Conditions'}
                </a>
              </Text>
              <br />
              <BackButton>Back</BackButton>
              <Button
                ml="auto"
                type="submit"
                data-testid="onboarding-next"
                style={{ float: 'right', display: 'inline-block' }}
                disabled={isSubmitting}
              >
                Finish
              </Button>
            </Form>
          </Box>
        </Box>
      )}
    </Formik>
  )
}
