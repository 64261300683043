import styled from '@emotion/styled'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import React from 'react'
import { useHistory } from 'react-router'
import ReactTags, { Tag } from 'react-tag-autocomplete'
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Image,
  Spacer,
  Stack,
  Text,
  useTheme
} from '@chakra-ui/react'

import { BackButton } from '../../components/BackButton'
import { Button } from '../../components/Button'
import { FormInput } from '../../components/FormInput'
import { OnboardingWrapper } from '../../components/OnboardingWrapper'
import { useUserDetails } from '../../context/user-onboard-context'
import { routeStrings } from '../../routeStrings'
import { useSkillsAndInterests } from '../../utils/apiHooks'
import { InputWarning } from '../../styled/InputWarning'

export const CompleteProfile3: React.FC = () => {
  const { setUserDetails, userDetails } = useUserDetails()
  const history = useHistory()
  const theme: any = useTheme()
  const blurActiveEl = () => {
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur()
    }
    return
  }

  const { skills, interests } = useSkillsAndInterests()

  return (
    <OnboardingWrapper>
      <Box my={6}>
        <Image
          src={theme.logos.logo_long}
          width="128px"
          className="location-logo"
        />
      </Box>
      <StyledTagsWrapper>
        <Formik
          initialValues={{
            skills: userDetails!.skills || [],
            interests: userDetails!.interests || [],
            aboutYou: userDetails!.aboutYou
          }}
          validate={(values): any => {
            const errors: any = {}

            if (
              values.aboutYou === undefined ||
              values.aboutYou?.length === 0
            ) {
              errors.aboutYou = 'Required'
            }
            // Check if empty
            if (values.skills.length === 0 || values.interests.length === 0) {
              // errors.skills = 'Required'
            }
            // Check if max length exceeds 1000 chars (database restriction)
            if (values.interests && values.skills) {
              let interestCharLen = 0
              let skillCharLen = 0

              values.interests.map((i) => (interestCharLen += i.name.length))
              values.interests.map((i) => (skillCharLen += i.name.length))

              if (interestCharLen > 999) {
                errors.interests =
                  'Please select fewer options (total length must not exceed 1000 characters'
              }

              if (skillCharLen > 999) {
                errors.skills =
                  'Please select fewer options (total length must not exceed 1000 characters'
              }
            }
            return errors
          }}
          onSubmit={(values) => {
            setUserDetails((currentValues: any) => ({
              ...currentValues,
              aboutYou: values!.aboutYou,
              interests: values.interests,
              skills: values.skills
            }))
            history.push(routeStrings.completeBookOnboarding)
          }}
        >
          {({ values, setFieldValue, isSubmitting, errors, touched }) => (
            <>
              <Form>
                <Box bg={'white'} boxShadow={'lg'} p={8} rounded={'xl'}>
                  <Box data-testid="complete-skills-field" mt={6}>
                    <Heading size="xs" color="gray.400" my={6}>
                      Step 2 of 2
                    </Heading>
                    <Heading size="md" my={6}>
                      Business Profile
                    </Heading>
                    <Heading size="sm" my={2}>
                      Skills and Interests
                    </Heading>

                    <ReactTags
                      // allowNew={true}
                      // label="skills"
                      tags={values.skills}
                      suggestions={skills}
                      placeholder="Select some of your skills"
                      minQueryLength={0}
                      autofocus={false}
                      maxSuggestionsLength={999}
                      handleDelete={(interest: any) => {
                        const tags = values.skills.slice(0)
                        tags.splice(interest, 1)
                        setFieldValue('skills', tags)
                      }}
                      handleAddition={(skill: Tag) => {
                        setFieldValue('skills', [...values.skills, skill])
                        blurActiveEl()
                      }}
                    />
                    <ErrorMessage name="skills" component={FormErrorMessage} />
                  </Box>
                  <Box my={2} data-testid="complete-interests-field">
                    <Label fontWeight="700" color="#4F4F4F" fontSize="sm">
                      Interests
                    </Label>
                    <ReactTags
                      tags={values.interests || []}
                      suggestions={interests}
                      placeholder="Select some interests"
                      minQueryLength={0}
                      maxSuggestionsLength={999}
                      autofocus={false}
                      // allowNew={true}
                      handleDelete={(interest: any) => {
                        const tags = values.interests.slice(0)
                        tags.splice(interest, 1)
                        setFieldValue('interests', tags)
                      }}
                      handleAddition={(interest: Tag) => {
                        setFieldValue('interests', [
                          ...values.interests,
                          interest
                        ])
                        blurActiveEl()
                      }}
                    />
                    <ErrorMessage
                      name="interests"
                      component={FormErrorMessage}
                    />
                  </Box>
                  <Box my={2}>
                    <FormControl
                      isInvalid={
                        touched.aboutYou && errors.aboutYou != undefined
                      }
                    >
                      <FormLabel fontWeight="bold" fontSize="sm" mb={1}>
                        About you
                      </FormLabel>
                      <Field
                        textarea
                        name="aboutYou"
                        data-testid="complete-aboutYou-field"
                        component={FormInput}
                        rows="3"
                        placeholder="Enter a short bio about yourself"
                      />
                      <ErrorMessage
                        name="aboutYou"
                        component={FormErrorMessage}
                      />
                    </FormControl>
                  </Box>

                  <Flex>
                    <BackButton>Back</BackButton>
                    <Spacer />
                    <Button
                      my={2}
                      ml="auto"
                      type="submit"
                      data-testid="complete-next"
                      disabled={isSubmitting}
                    >
                      Last
                    </Button>
                  </Flex>
                </Box>
              </Form>
            </>
          )}
        </Formik>
      </StyledTagsWrapper>
    </OnboardingWrapper>
  )
}

const Label = styled(Text)`
  font-family: Open Sans;
  color: black;
  font-weight: bold;
`

const StyledTagsWrapper = styled.div`
  .react-tags__suggestions {
    z-index: 999 !important;
    left: unset;
    width: 100%;
    max-height: 260px;
    overflow-y: scroll;
    background-color: white;

    mark {
      padding: 0;
    }
  }

  .react-tags__search-input {
    z-index: 0 !important;
  }
`
