import { Box, BoxProps } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import {
  IWorkspace,
  IInterval,
  IBooking,
  ISimpleBooking,
  ILocationOpeningHours
} from '../types'
import {
  set,
  areIntervalsOverlapping,
  roundToNearestMinutes,
  format,
  isEqual,
  isToday,
  isBefore,
  differenceInMinutes,
  isAfter,
  addSeconds,
  subSeconds,
  isWithinInterval
} from 'date-fns'

import TimeRange from 'react-timeline-range-slider'
import { castHoursToDate } from '../pages/MemberDashboard/MeetingRooms/meetingRoomUtils'
import { utcToZonedTime } from 'date-fns-tz'

interface Props extends BoxProps {
  workspace: IWorkspace
  chosenDate: Date
  openingHours: ILocationOpeningHours
  setTimeSlot: (timeSlot: IInterval, session: string) => void
  setError: (error: boolean) => void
  existingBooking: IBooking | undefined
  otherBookings?: ISimpleBooking[] | undefined
  unavailable?: boolean
}

export const WorkspaceTimeline: React.FC<Props> = ({
  workspace,
  chosenDate,
  unavailable,
  existingBooking,
  openingHours,
  otherBookings,
  setTimeSlot,
  setError,
  ...rest
}) => {
  const [isError, setIsError] = useState<boolean>(false)

  const [selectedInterval, setSelectedInterval] = useState<[Date, Date]>([
    chosenDate,
    chosenDate
  ])

  const startTime = castHoursToDate(openingHours.full_day_from, chosenDate)
  const endTime = castHoursToDate(openingHours.full_day_to, chosenDate)
  const [disabledIntervals, setDisabledIntervals] = useState<IInterval[]>()

  // Calculates Disabled intervals
  useEffect(() => {
    let interval: IInterval[] = []
    if (isToday(chosenDate)) {
      let earlyToday = set(chosenDate, { hours: 0, minutes: 0 })
      interval.push({
        start: earlyToday,
        end: roundToNearestMinutes(
          utcToZonedTime(new Date(), workspace.timezone),
          { nearestTo: 15 }
        )
      })
    }
    // If it is a rescheduling, ignores booking from the invalid intervals
    // Displays originally selected booking times
    if (existingBooking) {
      workspace.bookings = workspace.bookings.filter(
        (b) => b.id != existingBooking.id
      )
      setSelectedInterval([
        utcToZonedTime(
          new Date(Date.parse(existingBooking.start_time)),
          workspace.timezone
        ),
        utcToZonedTime(
          new Date(Date.parse(existingBooking.end_time)),
          workspace.timezone
        )
      ])
    }
    let bookings: IInterval[] = workspace.bookings
      .concat(otherBookings != undefined ? otherBookings : [])
      .map((b) => {
        return {
          start: utcToZonedTime(new Date(b.start_time), workspace.timezone),
          end: utcToZonedTime(new Date(b.end_time), workspace.timezone)
        } as IInterval
      })

    try {
      bookings.sort(
        (a: IInterval, b: IInterval) => a.start.getTime() - b.start.getTime()
      )
      bookings = mergeIntervals(0, bookings, interval)
    } catch (error) {
      console.log(error)
    }
    bookings = bookings.map((b) => {
      b.start = addSeconds(b.start, 1)
      b.end = subSeconds(b.end, 1)
      return b
    })
    if (isToday(chosenDate)) {
      bookings = bookings.filter((b) => isAfter(b.start, chosenDate))
    }
    interval = interval.concat(bookings)
    setDisabledIntervals(interval)
  }, [chosenDate, workspace])

  const mergeIntervals = (
    index: number,
    intervals: IInterval[],
    result: IInterval[]
  ) => {
    if (intervals.length === index) {
      return result
    }
    if (result.length === 0) {
      result.push(intervals[index])
      index += 1
      return mergeIntervals(index, intervals, result)
    }
    let i1 = result[result.length - 1]
    let i2 = intervals[index]

    if (isEqual(i1.end, i2.start)) {
      result[result.length - 1] = getInterval(i1.start, i2.end)
    } else if (isAfter(i1.end, i2.start) && isBefore(i1.end, i2.end)) {
      result[result.length - 1] = getInterval(i1.start, i2.end)
    } else if (isWithinInterval(i2.end, i1)) {
      // Skip
    } else {
      result.push(i2)
    }
    index += 1
    return mergeIntervals(index, intervals, result)
  }

  const getInterval = (start: Date, end: Date) => {
    try {
      return { start, end } as IInterval
    } catch (error) {}
    return { start: chosenDate, end: chosenDate } as IInterval
  }

  const onChangeCallback = (selectedInterval) => {
    if (isError) return
    if (isEqual(selectedInterval[0], selectedInterval[1])) {
      return
    }
    let interval = getInterval(
      selectedInterval[0],
      roundToNearestMinutes(selectedInterval[1], { nearestTo: 15 })
    )
    if (disabledIntervals) {
      disabledIntervals.forEach((i) => {
        try {
          if (areIntervalsOverlapping(getInterval(i.start, i.end), interval)) {
            return
          }
        } catch (e) {
          console.log(e)
          return
        }
      })
    }
    let booking_duration_in_minutes = differenceInMinutes(
      interval.end,
      interval.start
    )
    let session =
      openingHours.hours_half_day < booking_duration_in_minutes / 60
        ? 'FULL_DAY'
        : 'MORNING'
    setTimeSlot(interval, session)
    setSelectedInterval(selectedInterval)
  }
  const onUpdateCallback = (value) => {
    // console.log(value)
    setIsError(value.error)
    setError(value.error)
  }

  return (
    <Box {...rest}>
      <TimeRange
        error={isError}
        containerClassName={'TimeRange'}
        ticksNumber={15}
        formatTick={(ms) => format(ms, 'h aaa')}
        selectedInterval={selectedInterval}
        timelineInterval={[startTime, endTime]}
        onUpdateCallback={onUpdateCallback}
        onChangeCallback={onChangeCallback}
        disabledIntervals={disabledIntervals}
        mode={1}
      />
    </Box>
  )
}
