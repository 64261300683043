import {
  Box,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Spacer,
  Stack,
  Tag,
  Text,
  useTheme,
  useToast
} from '@chakra-ui/react'
import { Field, Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { width } from 'styled-system'
import { updateOrganizationProfile } from '../../../api'
import { AdminBodyWrapper } from '../../../components/AdminBodyWrapper'
import { Button } from '../../../components/Button'
import { EditableInputText } from '../../../components/EditableInputText'
import { ProfilePictureUpdateModal } from '../../../components/modals/ProfilePictureUpdateModal'
import { SettingsItem } from '../../../components/SettingsItems'
import { TextAreaInput } from '../../../components/TextAreaInput'
import { UserDisplay } from '../../../components/UserDisplay'
import { useAuth } from '../../../context/auth-context'
import { useMembersDirectoryContext } from '../../../context/members-directory-context'
import { usePlatformTenant } from '../../../context/platform-tenant-context'
import { CenteredSpinner } from '../../../styled/CenteredSpinner'
import { IUpdateOrganizationProfile } from '../../../types'
import { useCurrentOrganizationProfile } from '../../../utils/apiHooks'
import { IS_VALID_HTTP_URL } from '../../../utils/formUtils'
import { FilterableIndustries } from '../../DailyFive/FilterableIndustries'

export const DirectoryMyTeam: React.FC = () => {
  const { platformTenant } = usePlatformTenant()
  const { currentOrgUnit } = useAuth()
  const theme = useTheme()
  const [debouncedIndustries, setDebouncedIndustries] = useState<number[]>()
  const { industries } = useMembersDirectoryContext()
  const toast = useToast()
  const { data: profile, revalidate: revalidateOrganizationProfile } =
    useCurrentOrganizationProfile(
      currentOrgUnit?.organization.organization_profile?.id
    )
  const [chosenIndustries, setChosenIndustries] = useState<number[]>()
  const [updatingOrganizationName, setUpdatingOrganizationProfile] =
    useState(false)

  const isTeamAdmin = currentOrgUnit?.is_team_admin === true

  const [
    updateProfilePictureModalIsVisible,
    setUpdateProfilePictureModalIsVisible
  ] = useState(false)

  useEffect(() => {
    if (profile) {
      setChosenIndustries(profile?.industries?.map((i) => i.id))
    }
  }, [profile])

  if (!platformTenant || !profile) {
    return <CenteredSpinner />
  }
  return (
    <AdminBodyWrapper>
      <Heading mr={3} fontSize="xl" mb={2}>
        Team Profile
      </Heading>

      <Box
        bg="white"
        w={['100%', '95%', '100%', '90%']}
        boxShadow="0px 1px 0px rgba(0, 0, 0, 0.05)"
        rounded="md"
        px={8}
        py={5}
        position="relative"
        color="eastBay09"
        justifyContent="space-between"
      >
        {isTeamAdmin ? (
          <Text fontSize={{ base: 'sm', lg: 'md' }} color={'gray.500'}>
            Edit your organisation's profile in the Directory. Other members
            will be able to reach out to your organisation based on this
            information.
          </Text>
        ) : (
          <Text fontSize={{ base: 'sm', lg: 'md' }} color={'gray.500'}>
            Your team's profile in the Members Directory.
          </Text>
        )}

        <Box mt={2}>
          <Formik
            initialValues={{
              logo_url: profile?.logo_url ?? '',
              logo: undefined,
              linkedin_url: profile?.linkedin_url ?? '',
              mobile_number: profile?.mobile_number ?? '',
              website: profile?.website ?? '',
              industries: [],
              overview: profile?.overview ?? ''
            }}
            enableReinitialize={true}
            validate={(values) => {
              const errors: any = {}
              return errors
            }}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              if (!profile || updatingOrganizationName) return
              setUpdatingOrganizationProfile(true)
              const profileUpdate: IUpdateOrganizationProfile = {
                ...profile,
                ...values,
                industries: chosenIndustries
                  ? chosenIndustries.map((i) => ({ id: i }))
                  : [],
                overview: undefined,
                empty_industries: chosenIndustries?.length === 0
              }
              updateOrganizationProfile(profile.id, profileUpdate)
                .then((res) => {
                  setUpdatingOrganizationProfile(false)
                  setSubmitting(false)
                })
                .catch((error) => {
                  setSubmitting(false)
                  setUpdatingOrganizationProfile(false)
                  toast({ description: 'Unsuccessful update', status: 'error' })
                  toast({
                    description: 'There was an error. Try again later',
                    status: 'error',
                    duration: 5000
                  })
                })
            }}
          >
            {({
              values,
              setFieldValue,
              isSubmitting,
              setSubmitting,
              submitForm,

              errors
            }) => (
              <Form>
                <Stack spacing={4} px={2}>
                  {/* TOP PART */}
                  <Flex flexDir={['column', 'column', 'column', 'row']}>
                    <Box>
                      <UserDisplay
                        mb={3}
                        cursor="pointer"
                        onClick={() => {
                          isTeamAdmin &&
                            setUpdateProfilePictureModalIsVisible(true)
                        }}
                        size="2xl"
                        src={profile.logo_url}
                        name={currentOrgUnit?.organization.name}
                      />
                      {isTeamAdmin && (
                        <Text mb={3}>Update profile picture</Text>
                      )}
                    </Box>
                    <Spacer />
                    <Stack
                      spacing={1}
                      divider={<Divider maxW={['100%', '100%', '80%']} />}
                      maxW={['100%', '100%', '70%']}
                    >
                      <SettingsItem title="Website" description={''}>
                        <EditableInputText
                          isDisabled={!isTeamAdmin}
                          isLink={true}
                          maxLength={200}
                          defaultValue={profile?.website ?? ''}
                          onSubmitValue={(newValue: string) => {
                            if (newValue != undefined) {
                              if (
                                newValue === '' ||
                                IS_VALID_HTTP_URL(newValue)
                              ) {
                                setFieldValue('website', newValue.trim())
                                setTimeout(submitForm, 0)
                              } else {
                                toast({
                                  description: 'Invalid LinkedIn URL',
                                  status: 'error'
                                })
                                return
                              }
                            } else {
                              setFieldValue('website', newValue)
                              setTimeout(submitForm, 0)
                            }
                          }}
                          isUpdating={updatingOrganizationName}
                        />
                      </SettingsItem>
                      <SettingsItem title="Mobile Number" description={''}>
                        <EditableInputText
                          isDisabled={!isTeamAdmin}
                          defaultValue={profile?.mobile_number ?? ''}
                          maxLength={20}
                          onSubmitValue={(newValue: string) => {
                            if (newValue != '' || newValue != undefined) {
                              var phonePattern = /^\+?\d{8,15}$/g
                              if (phonePattern.test(newValue)) {
                                setFieldValue('mobile_number', newValue)
                                setTimeout(submitForm, 0)
                              } else {
                                toast({
                                  description: 'Invalid number',
                                  status: 'error'
                                })
                                return
                              }
                            } else {
                              setFieldValue('mobile_number', newValue)
                              setTimeout(submitForm, 0)
                            }
                          }}
                          isUpdating={updatingOrganizationName}
                        />
                      </SettingsItem>

                      <SettingsItem title="LinkedIn profile" description={''}>
                        <EditableInputText
                          isDisabled={!isTeamAdmin}
                          isLink={true}
                          defaultValue={profile?.linkedin_url ?? ''}
                          maxLength={200}
                          onSubmitValue={(newValue: string) => {
                            if (newValue != '' || newValue != undefined) {
                              if (IS_VALID_HTTP_URL(newValue)) {
                                setFieldValue('linkedin_url', newValue.trim())
                                setTimeout(submitForm, 0)
                              } else {
                                toast({
                                  description: 'Invalid Linked In URL',
                                  status: 'error'
                                })
                                return
                              }
                            } else {
                              setFieldValue('linkedin_url', newValue)
                              setTimeout(submitForm, 0)
                            }
                          }}
                          isUpdating={updatingOrganizationName}
                        />
                      </SettingsItem>
                    </Stack>
                  </Flex>
                  {/* END TOP PART */}

                  <Box>
                    <Box w={['100%', '80%']}>
                      <FormLabel color="#4F4F4F" fontSize="sm">
                        Organization Industries
                      </FormLabel>

                      {isTeamAdmin && (
                        <FilterableIndustries
                          value={chosenIndustries}
                          industriesList={industries}
                          initialValues={profile?.industries?.map((i) => i.id)}
                          onSelectionChanges={(industries) => {
                            setChosenIndustries(industries)
                            setTimeout(submitForm, 0)
                          }}
                        />
                      )}
                      {!isTeamAdmin &&
                        profile.industries?.map((i) => {
                          return (
                            <Tag
                              background={theme.colors.brandPrimary}
                              color={'white'}
                              key={i.id}
                            >
                              {i.name}
                            </Tag>
                          )
                        })}
                      {/* {console.log(errors)} */}
                      {errors.industries && (
                        <FormErrorMessage>{errors.industries}</FormErrorMessage>
                      )}
                    </Box>

                    <Box mt={5} mb={2} />
                    {isTeamAdmin && (
                      <FormControl>
                        <FormLabel color="#4F4F4F" fontSize="md" mb={1}>
                          Write an Overview
                        </FormLabel>

                        <Field
                          name="overview"
                          label=""
                          maxLength={'300'}
                          component={TextAreaInput}
                        />
                        <Text fontSize={'xx-small'} color="#ccc" my={1}>
                          Max 300 characters
                        </Text>
                      </FormControl>
                    )}
                    {!isTeamAdmin && (
                      <Text>
                        <Text fontWeight={'bold'} as="span">
                          Overview
                          <br />
                        </Text>
                        {profile.overview}
                      </Text>
                    )}
                  </Box>

                  {isTeamAdmin && (
                    <Flex mt={4}>
                      <Spacer />
                      <Button
                        variant="primary"
                        disabled={isSubmitting}
                        mr="8px"
                        onClick={() => {
                          let profileData = {
                            id: profile.id,
                            overview: values.overview ?? ''
                          }
                          return updateOrganizationProfile(
                            profile.id,
                            profileData
                          )
                            .then((res) => {
                              toast({
                                description: 'Overview updated',
                                status: 'success'
                              })
                              setSubmitting(false)
                              revalidateOrganizationProfile()
                              return 'Image updated'
                            })
                            .catch((err) => {
                              setSubmitting(false)
                              return 'error'
                            })
                        }}
                      >
                        Update Overview
                      </Button>
                    </Flex>
                  )}
                </Stack>
              </Form>
            )}
          </Formik>
        </Box>
      </Box>
      {/* TODO add user profile revalidate to modal props */}
      {updateProfilePictureModalIsVisible && (
        <ProfilePictureUpdateModal
          handleSave={(file) => {
            let profileData = {
              id: profile.id,
              logo_image_file: file
            }
            return updateOrganizationProfile(profile.id, profileData)
              .then((res) => {
                toast({
                  description: 'Logo updated successfully',
                  status: 'success'
                })
                setUpdateProfilePictureModalIsVisible(false)
                revalidateOrganizationProfile()
                return 'Image updated'
              })
              .catch((err) => {
                return 'error'
              })
          }}
          revalidate={revalidateOrganizationProfile}
          visibility={{
            modalIsVisible: updateProfilePictureModalIsVisible,
            setModalIsVisible: setUpdateProfilePictureModalIsVisible
          }}
        />
      )}
    </AdminBodyWrapper>
  )
}
