import React from 'react'
import { Box, Heading, Stack, Text, Button, Flex, Link } from '@chakra-ui/react'
import { format } from 'date-fns'
import { css } from '@emotion/core'
import { IArticle } from './types'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { analyticsTrack } from '../../../../utils/analytics'

interface Props {
  article?: IArticle
}

export const SmallNewsArticle: React.FC<Props> = ({ article }) => {
  return (
    <Link
      href={article?.link}
      onClick={() =>
        analyticsTrack('View Blog Post', { featureArcticle: false })
      }
      target="_blank"
      rel="noopener noreferrer"
      _hover={{ textDecor: 'none' }}
    >
      <Flex h="100%" w="100%" alignItems="space-between">
        <Stack spacing={1} p={1} flexGrow={1}>
          <Heading size="xs">
            {article ? article.title : <Skeleton width={150} />}
          </Heading>
          {article ? (
            <Stack isInline spacing={2}>
              <Text as="span" fontSize="xs" color="#616161">
                {format(article.date, 'd MMM').toUpperCase()}
              </Text>
              <Text as="span" fontSize="xs" color="#E0E0E0">
                •
              </Text>
              <Text as="span" fontSize="xs" color="#255CE1" fontWeight="bold">
                {article.category}
              </Text>
            </Stack>
          ) : (
            <Skeleton width={50} />
          )}
        </Stack>
        <Box
          flexShrink={0}
          background={article ? `url("${article.image}")` : '#dadada'}
          w="80px"
          h="80px"
          backgroundSize="cover"
        />
      </Flex>
    </Link>
  )
}
