import {
  Alert,
  AlertIcon,
  Box,
  Divider,
  Flex,
  Input,
  InputGroup,
  InputRightAddon,
  Link,
  Stack,
  Text,
  Textarea,
  useToast
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { ChakraModal } from '../../../components/ChakraModal'
import {
  ICreateBookingRequest,
  IInterval,
  ILocationBusinessHours,
  ILocationOpeningHours,
  IMeetingRoom,
  IOrganizationalUnit,
  IOrganizationalUnitBasic,
  IOrganizationalUnitFilterableSelect,
  IPreviewBookingCost,
  IWorkspace
} from '../../../types'
import {
  useMeetingRoomTimeline,
  useTenantLocations,
  useWorkspaceTimeline
} from '../../../utils/apiHooks'
import { Button } from '../../../components/Button'
import { useAuth } from '../../../context/auth-context'
import { FilterableSelect } from '../../../components/FilterableSelect'
import 'react-datepicker/dist/react-datepicker.css'
import { IS_VALID_HTTP_URL } from '../../../utils/formUtils'
import { ExternalLinkIcon } from '@chakra-ui/icons'
import { MeetingRoomTimeline } from '../../../components/MeetingRoomTimeline'
import { useEffect } from 'react'
import { CalendarFormInput } from '../../../components/CalendarFormInput'
import { usePlatformTenant } from '../../../context/platform-tenant-context'
import { WorkspaceTimeline } from '../../../components/WorkspaceTimeline'
import { FilterableOrganizationalUnitsSelect } from '../../../components/FilterableOrganizationalUnitsSelect'
import { previewBookingCost } from '../../../api'
import { zonedTimeToUtc } from 'date-fns-tz'
import { BookingCostsComponent } from '../../../components/BookingCostsComponent'
import { BookingDurationComponent } from '../../../components/BookingDurationComponent'
import { NumberInputComponent } from '../../../components/NumberInputComponent'

interface IModalInput {
  isOpen: boolean
  resource?: IMeetingRoom | IWorkspace
  resourceList?: IMeetingRoom[] | IWorkspace[]
  resourceType: 'meeting-room' | 'workspace'
  makeBooking: (newBooking: ICreateBookingRequest) => void
  closeModal: () => void
  isBooking: boolean
}

export const AdminRoomBookingModal: React.FC<IModalInput> = (input) => {
  const {
    isOpen,
    resource,
    closeModal,
    resourceType,
    resourceList,
    isBooking,
    makeBooking
  } = input

  useEffect(() => {
    if (isOpen) clearValues()
  }, [isOpen])

  const { platformTenant } = usePlatformTenant()

  const toast = useToast()
  const [isError, setIsError] = useState<boolean>(false)

  const [roomId, setRoomId] = useState<number | undefined>()
  const [workspaceId, setWorkspaceId] = useState<number | undefined>()
  const [linkURL, setLinkURL] = useState<string>()
  const [validURL, setValidURL] = useState<boolean>(true)
  const [notes, setNotes] = useState<string>()
  const [selectedOrgUnit, setSelectedOrgUnit] =
    useState<IOrganizationalUnitFilterableSelect>()
  const [bookingTimes, setBookingTimes] = useState<IInterval | undefined>()
  const [chosenDate, setChosenDate] = useState<Date>(new Date())
  const [isCalculating, setIsCalculating] = useState<boolean>(false)
  const [isExternalMember, setIsExternalMember] = useState<boolean>(false)
  const [chosenElementTimezone, setChosenElementTimezone] = useState('')
  const [numberAttendees, setNumberAttendees] = useState(0)

  const [bookingCost, setBookingCost] = useState<IPreviewBookingCost>()

  const swrOptions = {
    refreshInterval: 0,
    revalidateOnFocus: false
  }

  const { data: meetingRoomTimeline } = useMeetingRoomTimeline(
    roomId,
    chosenDate,
    swrOptions
  )
  const { data: workspaceTimeline } = useWorkspaceTimeline(
    workspaceId,
    chosenDate,
    swrOptions
  )
  const { data: locations, revalidate: revalidateLocations } =
    useTenantLocations(platformTenant?.id)

  const isMeetingRoom = resourceType === 'meeting-room'
  const isWorkspace = resourceType === 'workspace'

  const workspaces = isWorkspace ? (resourceList as IWorkspace[]) : undefined
  const meetingRooms = isMeetingRoom
    ? (resourceList as IMeetingRoom[])
    : undefined

  const submit = () => {
    let error_message = ''
    if (!bookingTimes) error_message = 'Select a valid start and end times'
    if (!roomId && !workspaceId)
      error_message = 'Select a valid ' + resourceName
    if (!selectedOrgUnit) error_message = 'Select a member to book the room'

    if (workspaceId && openingHours === undefined) {
      error_message = 'Opening hours have not been set'
      setBookingTimes(undefined)
    }

    if (error_message) {
      toast({ description: error_message, status: 'error' })
      return
    }
    if (!bookingTimes) return

    if (isError) return

    let timezone = 'Australia/Perth'
    let resourceToBook = isMeetingRoom
      ? meetingRooms?.find((r) => r.id === roomId)
      : isWorkspace
      ? workspaces?.find((r) => r.id === workspaceId)
      : undefined
    if (resourceToBook === undefined) {
      toast({
        description: 'The resource you are trying to books is invalid',
        status: 'error'
      })
      return
    }
    timezone = resourceToBook.timezone
    let start_time = zonedTimeToUtc(
      bookingTimes?.start.toISOString(),
      timezone
    ).toISOString()
    let end_time = zonedTimeToUtc(
      bookingTimes?.end.toISOString(),
      timezone
    ).toISOString()
    const bookingRequest = {
      start_time,
      end_time,
      organizational_unit: selectedOrgUnit?.id,
      meeting_room: isMeetingRoom ? roomId : undefined,
      workspace: isWorkspace ? workspaceId : undefined,
      event_sheet_url: linkURL,
      notes: notes,
      number_attendees: numberAttendees
    } as ICreateBookingRequest
    makeBooking(bookingRequest)
  }

  const selectTimes = (timeSlot: IInterval | undefined) => {
    if (isCalculating) return
    let resourceId = roomId ?? workspaceId
    setBookingTimes(timeSlot)
    if (
      !timeSlot ||
      !selectedOrgUnit ||
      !resourceId ||
      selectedOrgUnit.organization.is_space_admin
    )
      return

    setIsCalculating(true)
    previewBookingCost({
      meeting_room: roomId,
      workspace: workspaceId,
      organizational_unit: selectedOrgUnit.id,
      start_time: zonedTimeToUtc(
        timeSlot.start,
        chosenElementTimezone
      ).toISOString(),
      end_time: zonedTimeToUtc(
        timeSlot.end,
        chosenElementTimezone
      ).toISOString()
    })
      .then(({ data }) => {
        setBookingCost(data)
        setIsCalculating(false)
      })
      .catch((err) => {
        setIsCalculating(false)
      })
  }

  const getLocationName = (locationId) => {
    let loc = platformTenant?.locations.find((l) => l.id === locationId)
    return loc ? loc.name : ''
  }

  const resourceName =
    resourceType === 'meeting-room' ? 'Meeting Room' : 'Workspace'

  const clearValues = () => {
    setChosenDate(new Date())
    setValidURL(true)
    setRoomId(undefined)
    setWorkspaceId(undefined)
    setOpeningHours(undefined)
    setNotes('')
    setLinkURL('')
    setBookingTimes(undefined)
    setSelectedOrgUnit(undefined)
    setIsExternalMember(false)
    setBookingCost(undefined)
    setNumberAttendees(0)
  }

  const onSelectResource = (value: number | undefined) => {
    if (resourceType === 'meeting-room') {
      setRoomId(value)
    } else {
      setWorkspaceId(value)
    }
  }

  const getResourceFilterableSelect = () => {
    const values = resourceList
      ? meetingRooms != undefined
        ? meetingRooms.map((room: IMeetingRoom) => ({
            label:
              (room.private_room ? '(Private) ' : '') +
              room.name +
              ' - ' +
              getLocationName(room.location),
            value: room.id,
            disabled: false,
            timezone: room.timezone,
            public_booking: room.public_booking
          }))
        : workspaces != undefined
        ? workspaces.map((room: IWorkspace) => ({
            label: room.name + ' - ' + getLocationName(room.location),
            value: room.id,
            disabled: !room.is_bookable,
            timezone: room.timezone,
            public_booking: true
          }))
        : []
      : []

    const selectedIndex = resourceType === 'meeting-room' ? roomId : workspaceId

    if (!selectedOrgUnit) return null

    return (
      <FilterableSelect
        dataTestId="booking-select-room-id"
        isClearable={true}
        items={values.filter((val) => {
          return selectedOrgUnit?.organization.is_active_organization
            ? true
            : val.public_booking
        })}
        isOptionDisabled={(value) => value.disabled}
        name={'Select a ' + resourceName}
        initialValue={selectedIndex}
        onSelect={(n) => {
          let tz = values.find((val) => val.value === n)
          if (tz) {
            setChosenElementTimezone(tz.timezone)
          }
          onSelectResource(n)
          if (bookingTimes) selectTimes(bookingTimes)
        }}
      />
    )
  }

  const [openingHours, setOpeningHours] = useState<ILocationOpeningHours>()
  const [businessHours, setBusinessHours] = useState<ILocationBusinessHours>()

  useEffect(() => {
    if (chosenDate && meetingRoomTimeline) {
      const location = locations.find(
        (l) => l.id === meetingRoomTimeline?.location
      )

      const dayBusinessHours =
        location?.business_hours &&
        location?.business_hours.find(
          (hours) => hours.day === chosenDate.getDay()
        )

      setBusinessHours(dayBusinessHours)
    }
  }, [chosenDate, meetingRoomTimeline])

  useEffect(() => {
    if (selectedOrgUnit) {
      if (
        selectedOrgUnit.organization.is_active_organization != undefined &&
        !selectedOrgUnit.organization.is_active_organization
      ) {
        setIsExternalMember(true)
      } else {
        setIsExternalMember(false)
      }
      onSelectResource(undefined)
      setBookingTimes(undefined)
    }
  }, [selectedOrgUnit])

  useEffect(() => {
    setBookingTimes(undefined)
  }, [roomId, workspaceId])

  useEffect(() => {
    if (workspaceTimeline) {
      const location = locations.find(
        (l) => l.id === workspaceTimeline?.location
      )
      setOpeningHours(location ? location.opening_hours : undefined)
    }
  }, [workspaceTimeline])

  return (
    <ChakraModal
      title={'Book a ' + resourceName}
      isOpen={isOpen}
      onClose={() => {
        if (!isBooking) {
          clearValues()
          closeModal()
        }
      }}
      closeOnOverlayClick={!isBooking}
      size="xl"
    >
      <FilterableOrganizationalUnitsSelect
        name="Select a member"
        dataTestId="orgunit-searchbox"
        onSelect={(n, value) => setSelectedOrgUnit(value)}
      />
      {resourceList && getResourceFilterableSelect()}

      <Box my={2} id="admin-booking-calendar-div">
        <CalendarFormInput
          dateProps={{
            chosenDate,
            handleChangeDate: setChosenDate
          }}
          minDate={new Date()}
          closeOnSelect={true}
          placement="bottom"
          dataTestId="booking-calendar"
        />
      </Box>
      {chosenDate && roomId && meetingRoomTimeline && (
        <>
          <Text>Select the times</Text>
          <Box
            p={[2, 4]}
            flex={2}
            align={'center'}
            justify={'center'}
            mt={[5]}
            mb={[14]}
          >
            <MeetingRoomTimeline
              meetingRoom={meetingRoomTimeline}
              chosenDate={chosenDate}
              setTimeSlot={(timeSlot: IInterval) => {
                selectTimes(timeSlot)
              }}
              setError={setIsError}
              existingBooking={undefined}
              businessHours={businessHours}
              isExternal={isExternalMember}
            />
          </Box>
        </>
      )}
      {locations && workspaceId && workspaceTimeline && (
        <>
          <Text>Select the times</Text>
          <Box
            p={[2, 4]}
            flex={2}
            align={'center'}
            justify={'center'}
            mt={[5]}
            mb={[14]}
          >
            {openingHours ? (
              <WorkspaceTimeline
                workspace={workspaceTimeline}
                openingHours={openingHours}
                chosenDate={chosenDate}
                setTimeSlot={(timeSlot: IInterval, session: string) => {
                  selectTimes(timeSlot)
                }}
                setError={setIsError}
                existingBooking={undefined}
                otherBookings={workspaceTimeline.other_bookings}
              />
            ) : (
              <Text>
                Please, configure this location's Opening hours. <br />
                Find those settings on the <i>Workspaces tab</i>
              </Text>
            )}
          </Box>
        </>
      )}
      <Divider my={2} />
      <BookingDurationComponent timeSlot={bookingTimes} />
      <Divider my={2} />
      {selectedOrgUnit && bookingTimes && (
        <BookingCostsComponent
          costs={bookingCost}
          isCalculating={isCalculating}
          isWorkspace={workspaceId != undefined}
        />
      )}
      {isExternalMember && (
        <Box my={2}>
          <Alert status="warning">
            <AlertIcon />
            The selected member does not have an active membership
          </Alert>

          <Stack
            spacing={2}
            borderWidth={2}
            borderColor={'yellow.300'}
            borderTop={'none'}
            borderBottomRadius={'lg'}
            p={2}
          >
            <Text>- The booking is considered as external.</Text>
            <Text>
              - The platform will attempt to charge this booking upon
              confirmation.
            </Text>
            <Text>- Non-member rates are applied.</Text>
          </Stack>
        </Box>
      )}
      {resourceType === 'meeting-room' && (
        <>
          <Text fontSize="md" fontWeight="bold" mt={2}>
            Extra details:
          </Text>
          <Divider my={3} />
          <Stack spacing={0}>
            <Text fontSize="md">Number of Attendees</Text>
            <Flex justifyContent={'space-between'}>
              <Text fontSize={'xs'} color={'#8c8c8c'}>
                Please confirm the number of people attending your booking.
              </Text>
              <Box>
                <NumberInputComponent
                  maxW={'100px'}
                  max={800}
                  min={0}
                  value={numberAttendees}
                  onNumberChange={(val: number) => {
                    setNumberAttendees(val)
                  }}
                />
              </Box>
            </Flex>
          </Stack>
          <Box>
            <Text mr="4px" fontSize="md">
              Notes:
            </Text>
            <Textarea
              data-testid="booking-notes"
              placeholder="Add more information to this booking..."
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              maxLength={2000}
              disabled={isBooking}
            />
          </Box>
          <Box>
            <Text mr="4px" fontSize="md">
              Link to event sheet:
            </Text>
            <InputGroup size="lg">
              <Input
                data-testid="booking-url-sheet"
                isInvalid={!validURL}
                disabled={isBooking}
                placeholder="Event sheet URL"
                size="lg"
                value={linkURL}
                onChange={(e) => {
                  let url = e.target.value
                  setValidURL(url ? IS_VALID_HTTP_URL(url) : true)
                  setLinkURL(url)
                }}
              />
              <InputRightAddon
                children={
                  <Link href={linkURL} isExternal>
                    <ExternalLinkIcon mx="2px" />
                  </Link>
                }
              />
            </InputGroup>
          </Box>
        </>
      )}

      <Flex flexDirection="row-reverse" mt={10}>
        <Button
          key="submit"
          data-testid="make-booking-button"
          disabled={isError || isBooking}
          onClick={submit}
          ml="8px"
        >
          Confirm booking
        </Button>
        <Button
          disabled={isBooking}
          data-testid="cancel"
          variant="secondary"
          onClick={closeModal}
        >
          Cancel
        </Button>
      </Flex>
    </ChakraModal>
  )
}
