import React from 'react'
import {
  IAction,
  ActionsDropdown
} from '../../../components/table/ActionsDropdown'
import { Table, TableDataProp } from '../../../components/table/Table'
import { TableRow } from '../../../components/table/TableRow'
import { Box, Text, Avatar } from '@chakra-ui/react'
import { IOffice, IOrganizationSeatBasic, User } from '../../../types'
import { Cell } from '../../../components/table/Cell'
import { CheckCustomIcon } from '../../../components/icons/icons'
import { useRole } from '../../../utils/rbac'
import { MemberTableLink } from '../../../components/MemberTableLink'

function RenderProfilePicture(user: User) {
  return <Avatar size="sm" name={user.name} />
}

function RenderInviteSent() {
  return (
    <Text mr={5}>
      <CheckCustomIcon color="alertSuccess" mr={2} />
      Invite sent
    </Text>
  )
}

function RenderEmptySeatRow(seat: IOrganizationSeatBasic, actions: IAction[]) {
  return (
    <TableRow key={seat.id} minHeight="48px" opacity={0.6}>
      <Cell
        key="1"
        width={0.9}
        value={{ value: <strong>Unassigned desk</strong> }}
      />
      {seat.active_invitation ? (
        <Cell key="2" value={{ value: RenderInviteSent() }} />
      ) : null}
      {
        <ActionsDropdown
          width="4%"
          actions={actions}
          rowId={seat.id}
          key={3}
          dataTestid={'expand-' + seat.id}
        />
      }
    </TableRow>
  )
}

interface IInputParams {
  title: string
  office: IOffice
  loading: boolean
  handleActionInvite: (seatId: number) => void
  clearSeatAssignment: (seatId: number) => void
}

export const Office: React.FC<IInputParams> = (input) => {
  const { isInAdminRole } = useRole()
  const office = input.office
  const organizationId = office.organization?.id ?? 0
  const desks = office.desks
  const assignedDesks = desks.filter(
    (desk) => desk.organization_seat?.organizational_unit
  )
  const loading = input.loading

  const inviteAction: IAction = {
    text: 'Assign Member',
    testIdPrefix: 'invite',
    action: input.handleActionInvite
  }
  const viewInviteAction: IAction = {
    text: 'View Invite',
    testIdPrefix: 'view-invite',
    action: input.handleActionInvite
  }
  const clearSeatAssignmentAction: IAction = {
    text: 'Clear Seat Assignment',
    testIdPrefix: 'clear-seat',
    action: input.clearSeatAssignment
  }

  const tableHeaders = [
    { header: 'id', accessor: 'id', show: false },
    {
      header: '',
      accessor: 'displayImage',
      show: true,
      width: 0.125
    },
    { header: 'Name', accessor: 'name', show: true, width: 0.51 },
    { header: 'Title', accessor: 'title', show: true },
    { header: 'Phone', accessor: 'phone', show: true },
    { header: '', accessor: 'actions', show: true, width: 0.125 }
  ]

  const tableData = desks
    ? desks.map((desk) => {
        const orgUnit = desk.organization_seat?.organizational_unit
        const user = desk.organization_seat?.organizational_unit?.user
        let cleanedData

        if (orgUnit && user && office) {
          // Has a user
          cleanedData = {
            // id associates to the seat for actions
            id: TableDataProp(desk.organization_seat!.id),
            displayImage: TableDataProp(RenderProfilePicture(user)),
            actions: TableDataProp([clearSeatAssignmentAction]),
            name: [
              TableDataProp(
                <MemberTableLink
                  orgId={organizationId}
                  member={orgUnit}
                  isInAdminRole={isInAdminRole}
                />
              ),
              TableDataProp(user.email)
            ],
            title: TableDataProp(
              desk.organization_seat?.organizational_unit?.job_title
            ),
            phone: TableDataProp(user.user_profile.mobile_number)
          }
        } else {
          // Empty seat
          const emptySeatActions: IAction[] = []
          // has an active invite
          if (desk.organization_seat?.active_invitation) {
            emptySeatActions.push(viewInviteAction)
          }
          //  no active invite
          else {
            emptySeatActions.push(inviteAction)
          }
          cleanedData = RenderEmptySeatRow(
            desk.organization_seat!,
            emptySeatActions
          )
        }

        return cleanedData
      })
    : []

  return (
    <div style={{ width: '100%', padding: '16px' }}>
      <Box my="3" w="100%">
        <Box d="flex" mb={3} alignItems="baseline">
          <Text
            mb={0}
            mr={3}
            color="headingPrimary"
            fontSize="lg"
            textTransform="capitalize"
          >
            {input.title}
          </Text>
          <Text fontSize="xs" color="grey">
            {assignedDesks.length} / {desks.length}
          </Text>
        </Box>
        <Table headers={tableHeaders} data={tableData} loading={loading} />
      </Box>
    </div>
  )
}
