// import { InputWarning } from '../styled/InputWarning'
// import css from '@styled-system/css'
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import styled from '@emotion/styled'
import PopperJS from 'popper.js'
import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { fetchPaginatedOrganizationsByName } from '../api'
import { usePlatformTenant } from '../context/platform-tenant-context'
import { SelectOption } from '../types'
import { FilterableSelect } from './FilterableSelect'
export const FilterableOrganizationsSelect = ({
  name,
  onSelect,
  dataTestId,
  initialValue
}: {
  name: string
  initialValue?: number
  onSelect: (n: number) => any
  additionalActions?:
    | Array<{ label: string | JSX.Element; itemAction: (arg: any) => void }>
    | undefined
  popOverPlacement?: PopperJS.Placement // Based off PopperJS.Placement from popper-js
  dataTestId?: string
}) => {
  const { platformTenant } = usePlatformTenant()

  const [value, setValue] = useState<string | undefined>()
  const [debouncedTerm, setDebouncedTerm] = useState('')

  const page_size = 10

  const [items, setItems] = useState<Array<SelectOption>>([])

  const [page, setPage] = useState(1)
  const [hasMoreResults, setHasMoreResults] = useState(true)
  const [isFetching, setIsFeching] = useState(false)
  const fetchOrgs = (input_page: number, value: string) => {
    if (isFetching) return
    if (platformTenant) {
      setIsFeching(true)
      return fetchPaginatedOrganizationsByName(
        platformTenant.id,
        value,
        undefined,
        undefined,
        input_page,
        page_size
      )
        .then((res) => {
          if (res && res.data) {
            let results = res.data.results
              ? res.data.results.map((org) => {
                  return {
                    label: `${org.name} - ${org.primary_contact_email ?? ''}`,
                    value: org.id
                  }
                })
              : []
            results = input_page > 1 ? items.concat(results) : results

            setHasMoreResults(res.data.next != null)
            setPage(input_page + 1)
            setIsFeching(false)
            setItems(results)
          }
        })
        .catch((error) => {
          setIsFeching(false)
        })
    }
  }

  useEffect(() => {
    if (platformTenant && value != undefined) {
      fetchOrgs(1, value)
    }
  }, [value])

  useEffect(() => {
    const timer = setTimeout(() => setValue(debouncedTerm), 600)
    return () => clearTimeout(timer)
  }, [debouncedTerm])

  return (
    <FilterableSelect
      onInputChange={(newValue: string) => {
        setDebouncedTerm(newValue)
      }}
      dataTestId={dataTestId}
      isClearable={true}
      items={items || []}
      name={name ?? 'Select a value'}
      initialValue={initialValue}
      onSelect={onSelect}
      onMenuScrollToBottom={() => {
        if (hasMoreResults) fetchOrgs(page, value ?? '')
      }}
    />
  )
}
