import { Stack, Box, Link, Text, Heading, VStack } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import { routeStrings } from '../../routeStrings'
import { useUserDetails } from '../../context/user-onboard-context'
import { usePlansByLocation } from '../../utils/apiHooks'
import { PlanCard } from './components/PlanCard'
import { OnboardingHeading } from './OnboardingComponents'
import queryString from 'query-string'
import { usePlatformTenant } from '../../context/platform-tenant-context'
import { useState } from 'react'
import { CenteredSpinner } from '../../styled/CenteredSpinner'

export const OnboardingChoosePlan: React.FC = () => {
  const [locationId, setLocationId] = useState<number>()
  const { data: plans } = usePlansByLocation(locationId)
  const { setThemeTenantLocation, platformTheme } = usePlatformTenant()
  const history = useHistory()
  const { setUserDetails, userDetails } = useUserDetails()
  const { loc } = queryString.parse(window.location.search) // Takes the ?loc=MakePlace out of the url. Useful when you directly link the location.

  useEffect(() => {
    let location_id = 0
    if (!loc) {
      history.push(routeStrings.location)
    } else {
      location_id = parseInt(loc?.toString() || '0')
    }
    setLocationId(location_id)

    setThemeTenantLocation(undefined, parseInt(loc?.toString() || ''))
  }, [loc])

  useEffect(() => {
    if (plans) {
      if (!userDetails.locationName) {
        let location = plans.find((p) => p.location?.id === loc)
        setUserDetails((currentValues: any) => ({
          ...currentValues,
          locationName: location ? location.name : '-',
          location_id: loc
        }))
      }
      fillPlansToDisplay()
    }
  }, [plans])

  const [plansToDisplay, setPlansToDisplay] =
    useState<{ title: string; description: string; planOptions: any[] }[]>()

  const fillPlansToDisplay = () => {
    let categories: string[] = []

    plans
      ?.filter((p) => p.category)
      .forEach((p) => {
        if (categories.indexOf(p.category.name) < 0) {
          categories.push(p.category.name)
        }
      })

    categories.sort()
    let plans2Display: {
      title: string
      description: string
      planOptions: any[]
    }[] = []
    categories.forEach((category) => {
      plans2Display.push({
        title: category,
        description: category,
        planOptions: plans
          ? plans
              .filter((p) => p.category && p.category.name === category)
              .map((p) => ({
                uuid: p.uuid,
                name: p.name,
                rate: p.rate_string,
                inclusions_url: p.inclusions_document_url
              }))
          : []
      })
    })
    setPlansToDisplay(plans2Display)
  }

  const handleSelectPlan = (uuid: string) => {
    let plan = plans?.find((p) => p.uuid === uuid)
    if (!plan) return
    let locationName = plan.location
      ? plan.location.name
      : userDetails?.locationName
    setUserDetails((currentValues: any) => ({
      ...currentValues,
      ...{
        plan: uuid,
        plan_name: plan?.name,
        plan_rate: plan?.rate_string,
        plan_inclusions_url: plan?.inclusions_document_url,
        locationName: locationName
      }
    }))
    history.push(routeStrings.singlePageSignup)
  }

  return (
    <Stack spacing={8} flexGrow={1}>
      <VStack spacing={2} textAlign="center">
        <OnboardingHeading>Choose your plan</OnboardingHeading>
        <Text fontSize="lg" color={'gray.500'} px={[4, 0, 0]}>
          Discover the membership that seamlessly adjusts to your needs,
          offering the flexibility you seek.
        </Text>
      </VStack>
      <Box
        px={8}
        // spacing={8}
        overflowX="auto"
        flexWrap="nowrap"
        flexShrink={0}
        flexGrow={1}
        maxW="100vw"
        mx="auto"
      >
        {plansToDisplay ? (
          plansToDisplay.map((plan) => (
            <Box display="inline-block" flex="0 0 auto" mr={8} key={plan.title}>
              <PlanCard
                key={plan.title}
                {...plan}
                w={'350px'}
                minH={'209px'}
                flex="0 0 280px"
                onSelectPlan={handleSelectPlan}
                maxHeight="90%"
                overflow="auto"
                mt={'2'}
              />
            </Box>
          ))
        ) : (
          <CenteredSpinner />
        )}
        {locationId !== undefined && plansToDisplay?.length === 0 && (
          <Box textAlign="center" marginBottom="1rem" mt={10} px={[4, 0, 0]}>
            <Heading color="#ccc">No plans to show at the moment </Heading>
          </Box>
        )}
      </Box>
      <Box textAlign="center" marginBottom="1rem" mt={10} px={[4, 0, 0]}>
        <Text fontSize="lg">
          If you are looking for team workspace, private offices or need a
          custom plan please{' '}
          <Link
            color="brandPrimary"
            isExternal
            href={
              platformTheme?.contact_form_url
                ? platformTheme.contact_form_url
                : 'https://share.hsforms.com/1yhz9rKIZT86xlG5SBq9urw3hdcq'
            }
          >
            click here
          </Link>{' '}
          to request a custom quote. <br />
          Discounts apply to 6 and 12 month contracts.
        </Text>
      </Box>
    </Stack>
  )
}
