import {
  Box,
  Checkbox,
  Container,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Link,
  Select,
  Spacer,
  Spinner,
  Stack,
  Text,
  useMediaQuery,
  useToast
} from '@chakra-ui/react'
import {
  addMinutes,
  format,
  getDay,
  roundToNearestMinutes,
  subMinutes,
  set as datefnsSet
} from 'date-fns'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'

import { useHistory } from 'react-router'
import { CreatePostFormInput } from '../../components/CreatePostFormInput'
import { CreatePostTextAreaInput } from '../../components/CreatePostTextAreaInput'
import { FormErrorMessage } from '../../components/FormErrorMessage'
import { useAuth } from '../../context/auth-context'
import { OnboardingUser } from '../../interfaces'
import { routeStrings } from '../../routeStrings'
import {
  ApprovalStatusChoices,
  IDailyFiveContent,
  INewContent
} from '../../types'
import { analyticsTrack } from '../../utils/analytics'
import { useCommunityProfile } from '../../utils/apiHooks'
import { Button as StyledButton } from '../../components/Button'
import { usePlatformTenant } from '../../context/platform-tenant-context'
import { useCommunity } from '../../context/community-context'
import { useBookingContext } from '../../context/BookingContext'
import { ContentDisplay } from './ContentDisplay'
import ReactDatePicker from 'react-datepicker'
import { FilterableInterestTags } from './FilterableInterestTags'
import { postContent } from '../../api'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCalendarDay,
  faImage,
  faMapMarked
} from '@fortawesome/free-solid-svg-icons'
import { Steps } from 'antd'
import { CalendarFormInput } from '../../components/CalendarFormInput'
import { useMedia } from '../../hooks/useMedia'
const { REACT_APP_ZOOM_CLIENT_ID, REACT_APP_ZOOM_REDIRECT_URI } = process.env
const zoomKey =
  REACT_APP_ZOOM_CLIENT_ID !== undefined ? REACT_APP_ZOOM_CLIENT_ID : ''
const redirectURI =
  REACT_APP_ZOOM_REDIRECT_URI !== undefined ? REACT_APP_ZOOM_REDIRECT_URI : ''

const Categories = {
  ASK: 'Ask',
  EVENT: 'Event',
  LEARNING: 'Learning',
  OFFER: 'Offer',
  COMMUNITY: 'Community'
}

const STEP_BASICS = 0
const STEP_DETAILS = 1
const STEP_EXTRA = 2
const steps = [
  {
    title: 'Basics'
  },
  {
    title: 'Details'
  },
  {
    title: 'Extra Info'
  }
]

function roundUpQuaterHour(time: Date) {
  return roundToNearestMinutes(addMinutes(time, 15), { nearestTo: 15 })
}

interface Props {
  content?: IDailyFiveContent
  handleSave?: (content: INewContent) => Promise<{
    success: boolean
    message: string
    content: IDailyFiveContent
  }>
  handleCancel?: () => void
}

export const MemberDashboardCreatePostForm: React.FC<Props> = ({
  content,
  handleSave,
  handleCancel
}) => {
  const [isEdition, setIsEdition] = useState<boolean>(false)
  const [currentStep, setCurrentStep] = useState(0)
  const stepStyle = {
    marginBottom: 20,
    width: '60%'
  }
  const { me, currentOrgUnit } = useAuth()
  const { platformTenant } = usePlatformTenant()
  const toast = useToast()
  const { data: communityProfile } = useCommunityProfile()
  const history = useHistory()
  const { previewContent, setPreviewContent } = useCommunity()

  const [isLargeEnough] = useMediaQuery('(min-width: 1080px)')
  const [chosenStartDateTime, setChosenStartDateTime] = useState<Date>()
  const [chosenEndDateTime, setChosenEndDateTime] = useState<Date>()

  const [chosenInterests, setChosenInterests] = useState<number[]>([])
  const [initialValues, setInitialValues] = useState<any>()
  const [debouncedContent, setDebouncedContent] = useState<any>()
  const [hasDate, setHasDate] = useState(true)
  const { locations } = useBookingContext()

  const next = () => {
    setCurrentStep(currentStep + 1)
  }

  const prev = () => {
    setCurrentStep(currentStep - 1)
  }
  useEffect(() => {
    if (!debouncedContent) return
    const timer = setTimeout(() => {
      updatePreview(debouncedContent)
    }, 600)
    return () => clearTimeout(timer)
  }, [debouncedContent])

  useEffect(() => {
    setPreviewContent({} as IDailyFiveContent)
  }, [])

  useEffect(() => {
    if (!chosenStartDateTime || !chosenEndDateTime) return
    if (
      chosenStartDateTime > subMinutes(chosenEndDateTime, 15) ||
      getDay(chosenStartDateTime) !== getDay(chosenEndDateTime)
    ) {
      setChosenEndDateTime(roundUpQuaterHour(chosenStartDateTime))
    }
  }, [])

  useEffect(() => {
    if (!chosenStartDateTime || !chosenEndDateTime) return
    if (addMinutes(chosenStartDateTime, 15) > chosenEndDateTime) {
      setChosenStartDateTime(
        roundToNearestMinutes(subMinutes(chosenEndDateTime, 15), {
          nearestTo: 15
        })
      )
    }
  }, [chosenEndDateTime])
  function validURL(str: string) {
    var pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
      'i'
    ) // fragment locator
    return !!pattern.test(str)
  }

  const updatePreview = (values: any) => {
    const content: IDailyFiveContent = Object.assign({}, previewContent)
    content.author = me?.user.name
    content.user_profile_image = me?.user.user_profile.profile_picture?.image
    content.title = values.title
    content.content_type = values.category
    content.date = values.date
    content.url = values.url
    content.location = values.location
    content.location_venue = values.location_venue
    content.summary = values.summary
    content.zoom_meeting_url = values.zoom
    content.author = me?.user.name
    content.user_profile_image = me?.user.user_profile.profile_picture?.image
    content.start_time = chosenStartDateTime
      ? format(chosenStartDateTime!, 'HH:mm:ss')
      : values['startDate']
      ? format(values['startDate']!, 'HH:mm:ss')
      : undefined
    content.finish_time = chosenEndDateTime
      ? format(chosenEndDateTime!, 'HH:mm:ss')
      : values['endDate']
      ? format(values['endDate']!, 'HH:mm:ss')
      : undefined

    // Compare previous value
    if (values.image && typeof values.image === 'string') {
      content.image = values.image
    } else {
      content.image = values.image
        ? URL.createObjectURL(values.image)
        : undefined
    }
    setPreviewContent(content)
  }

  // Fills up Form with Post info to edit it
  useEffect(() => {
    if (content != undefined) {
      setIsEdition(true)
      const initValues = {
        title: content.title,
        category: Categories[content.content_type],
        date: content.date ? new Date(content.date) : undefined,
        from: content.start_time != null,
        to: content.finish_time != null,
        location: content.location,
        location_venue: content.location_venue,
        url: content.url,
        image: content.image,
        summary: content.summary ? content.summary : '',
        zoom: content.zoom_meeting_url != null,
        private: content.platform_tenant != null,
        tags: '',
        start: content.start_time != null
      }
      let today = content.date ? new Date(content.date) : new Date()
      if (content.date && content.start_time != null) {
        let startDate = setHoursToDate(today, content.start_time)
        setChosenStartDateTime(startDate)
        initValues['startDate'] = startDate
      }
      if (content.date && content.finish_time != null) {
        let endDate = setHoursToDate(today, content.finish_time)
        setChosenEndDateTime(endDate)
        initValues['endDate'] = endDate
      }
      if (content && content.interests) {
        setChosenInterests(content.interests.map((i) => i.id))
      }
      setInitialValues(initValues)
      updatePreview(initValues)
    } else {
      setInitialValues({
        title: '',
        category: '',
        date: undefined,
        from: false,
        to: false,
        location: '',
        location_venue: undefined,
        url: '',
        image: undefined,
        summary: '',
        zoom: false,
        private: false,
        tags: '',
        start: ''
      })
    }
  }, [])

  const setHoursToDate = (inputDate: Date, time: string) => {
    try {
      let a = time.split(':')
      return datefnsSet(inputDate, {
        hours: parseInt(a[0]),
        minutes: parseInt(a[1])
      })
    } catch (error) {}
    return inputDate
  }

  const handleCreatePost = (data: INewContent) => {
    return new Promise<{
      message: string
      success: boolean
      content: IDailyFiveContent
    }>((resolve, reject) => {
      return postContent(data)
        .then((res) => {
          let message = 'Post submitted'
          let success = false
          if (res.status === 201) {
            success = true
            const respData = res.data as IDailyFiveContent
            message =
              respData.approval_status === ApprovalStatusChoices.APPROVED
                ? message
                : 'Post submitted for admin approval'
            const trackingData = {
              userId: me?.user.id,
              contentType: data.content_type
            }
            analyticsTrack('Submit New Daily Five Content', trackingData)

            toast({
              position: 'top',
              description: message,
              status: success ? 'success' : 'error'
            })

            if (respData) {
              history.push(routeStrings.memberDashboardViewPost(respData?.id))
            }
            resolve({ message, success, content: respData })
            return
          } else {
            message = 'An error occurred'
          }
          reject({ reason: message })
        })
        .catch((error) => {
          console.log(error)
          reject({ reason: 'An error occurred' })
        })
    })
  }

  return (
    <Container
      className="scrollable-list"
      maxW="container.xl"
      h={'100vh'}
      overflowY="scroll"
      py={3}
    >
      <Grid
        h="200px"
        templateRows="repeat(2, 1fr)"
        templateColumns="repeat(2, 1fr)"
        gap={4}
      >
        <GridItem colSpan={isLargeEnough ? 1 : 2}>
          <Box
            bg={'white'}
            boxShadow={'lg'}
            p={8}
            rounded={'xl'}
            minHeight={'70vh'}
          >
            <Flex justifyContent="start" w="100%">
              <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                validate={(values): OnboardingUser => {
                  const errors: any = {}
                  if (!values.title) {
                    errors.title = 'Title Required'
                  }
                  if (!values.category) {
                    errors.category = 'Post type required'
                  }
                  if (values.url && !validURL(values.url)) {
                    errors.url =
                      'please enter a valid url in the format https://www.spacecubed.com'
                  }
                  if (chosenInterests.length === 0) {
                    errors.tags = 'Please add some interest tags to your post'
                  }
                  setDebouncedContent(values)

                  return errors
                }}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                  if (values.category == Categories.EVENT) {
                    if (!values.date) {
                      toast({
                        position: 'top',
                        description: 'Set a date for your event',
                        status: 'error'
                      })
                      setSubmitting(false)
                      return
                    }
                  }
                  // Adding this check here to ensure we dont publish content as public due to this being undefined
                  if (currentOrgUnit) {
                    setSubmitting(true)
                    const catIndex = Object.values(Categories).findIndex(
                      (key) => {
                        return key === values.category
                      }
                    )
                    const data: INewContent = {
                      title: values.title,
                      summary: values.summary,
                      url: values.url,
                      content_type: Object.keys(Categories)[catIndex],
                      image:
                        typeof values.image === 'object'
                          ? values.image
                          : undefined,
                      // HH: must be capitalised to ensure 24h time is passed to BE

                      location: values.location,
                      location_venue: values.location_venue,
                      add_zoom_meeting: values.zoom,
                      // tenant undefined makes teh post go to the public pool
                      platform_tenant: values.private
                        ? currentOrgUnit?.organization.parent_platform_tenant
                        : undefined,

                      interests: chosenInterests.map((i) => ({
                        id: i,
                        name: ''
                      }))
                    }
                    if (hasDate) {
                      data.start_time = values.from
                        ? format(chosenStartDateTime!, 'HH:mm:ss')
                        : undefined
                      data.finish_time = values.to
                        ? format(chosenEndDateTime!, 'HH:mm:ss')
                        : undefined
                      data.date =
                        values.date &&
                        `${format(values.date!, 'yyyy-MM-dd')}T${format(
                          values.date!,
                          'hh:mm:ss'
                        )}`
                    }
                    let action =
                      handleSave != undefined
                        ? handleSave(data)
                        : handleCreatePost(data)
                    action
                      .then((res) => {
                        setSubmitting(false)
                      })
                      .catch((error) => {
                        setSubmitting(false)
                        let description =
                          'Something went wrong, failed to submit post'
                        if (error.response) {
                          description = error.response.data
                        }
                        toast({ position: 'top', description, status: 'error' })
                      })
                  }
                }}
              >
                {({
                  values,
                  setFieldValue,
                  isSubmitting,
                  touched,
                  errors,
                  submitCount,
                  submitForm,
                  handleSubmit,
                  validateForm,
                  validateField
                }) => (
                  <Form>
                    {/* <Flex justifyContent="flex-end">
                      <StyledButton
                        ml={2}
                        onClick={() => {
                          if (
                            errors != undefined &&
                            Object.keys(errors).length > 0
                          ) {
                            toast({
                              position: 'bottom',
                              title: 'Your post has errors',
                              description: 'Please check the required fields',
                              status: 'error'
                            })
                          }
                          handleSubmit()
                        }}
                        disabled={
                          isSubmitting ||
                          (!isEdition && currentStep != STEP_EXTRA)
                        }
                      >
                        {isEdition ? 'Update' : 'Post'}
                      </StyledButton>
                    </Flex> */}
                    <Flex justifyContent="center">
                      <Steps
                        current={currentStep}
                        style={stepStyle}
                        size="small"
                      >
                        {steps.map((s, index) => {
                          return (
                            <Steps.Step
                              key={index}
                              title={s.title}
                              description={
                                <Text fontWeight="bold" color="gray.600"></Text>
                              }
                              onClick={() => {
                                if (index > STEP_BASICS) {
                                  // if(Object.keys(errors).includes)
                                }
                                setCurrentStep(index)
                              }}
                            />
                          )
                        })}
                      </Steps>
                      <Spacer />
                      <StyledButton
                        ml={2}
                        mt={-2}
                        onClick={() => {
                          if (
                            errors != undefined &&
                            Object.keys(errors).length > 0
                          ) {
                            toast({
                              position: 'bottom',
                              title: 'Your post has errors',
                              description: 'Please check the required fields',
                              status: 'error'
                            })
                          }
                          handleSubmit()
                        }}
                        disabled={
                          isSubmitting ||
                          (!isEdition && currentStep != STEP_EXTRA)
                        }
                      >
                        {isEdition ? 'Update' : 'Post'}
                      </StyledButton>
                    </Flex>
                    <Stack spacing={4} px={4}>
                      {currentStep == STEP_BASICS && (
                        <Stack spacing={3}>
                          {/* <Heading size={'sm'}>
                            <FontAwesomeIcon
                              icon={faNewspaper}
                              style={{ marginRight: '5px' }}
                            />
                            Post Basics
                          </Heading> */}
                          <Text color="gray.500">Post basics</Text>

                          <FormControl w={['100%', '50%', '50%']} isRequired>
                            <FormLabel color="gray.800" fontSize="sm">
                              Post type
                            </FormLabel>
                            <Select
                              placeholder="Select a post type"
                              value={values.category}
                              variant="outline"
                              name="category"
                              onChange={(e) => {
                                setFieldValue('category', e.target.value)
                                setDebouncedContent(values)
                              }}
                            >
                              <option value={Categories.ASK}>
                                {Categories.ASK}
                              </option>
                              <option value={Categories.EVENT}>
                                {Categories.EVENT}
                              </option>
                              <option value={Categories.LEARNING}>
                                {Categories.LEARNING}
                              </option>
                              <option value={Categories.OFFER}>
                                {Categories.OFFER}
                              </option>
                              <option value={Categories.COMMUNITY}>
                                {Categories.COMMUNITY}
                              </option>
                            </Select>

                            <ErrorMessage
                              name="category"
                              component={FormErrorMessage}
                            />
                          </FormControl>
                          <Divider />
                          <FormControl isRequired>
                            <FormLabel
                              color="gray.800"
                              fontWeight="bold"
                              fontSize="sm"
                            >
                              Title
                            </FormLabel>
                            <Field
                              name="title"
                              component={CreatePostFormInput}
                            />
                            <ErrorMessage
                              name="title"
                              component={FormErrorMessage}
                            />
                          </FormControl>
                          <FormControl isRequired>
                            <FormLabel color="gray.800" fontSize="sm">
                              Add some tags to your Post
                            </FormLabel>
                            <FilterableInterestTags
                              value={chosenInterests}
                              initialValues={
                                content
                                  ? content.interests.map((i) => i.id)
                                  : undefined
                              }
                              onSelectionChanges={(interests) => {
                                setChosenInterests(interests)
                                // This forces the validation to execute again
                                setFieldValue('title', values.title)
                              }}
                            />

                            {errors.tags && touched.tags && (
                              <FormErrorMessage>{errors.tags}</FormErrorMessage>
                            )}
                          </FormControl>
                          <FormControl isRequired>
                            <FormLabel color="gray.800" fontSize="sm">
                              Description
                            </FormLabel>
                            <Field
                              name="summary"
                              component={CreatePostTextAreaInput}
                            />
                          </FormControl>
                        </Stack>
                      )}

                      {currentStep === STEP_DETAILS && (
                        <Stack spacing={3}>
                          <Text color="gray.500">Post Details</Text>
                          <Text fontSize={'md'}>
                            <FontAwesomeIcon
                              icon={faImage}
                              style={{ marginRight: '5px' }}
                            />
                            Cover Photo
                          </Text>
                          <Flex>
                            <Stack>
                              <input
                                type="file"
                                id="image"
                                accept="image/png, image/jpeg"
                                onChange={(e) => {
                                  setFieldValue('image', e.target.files![0])
                                  setDebouncedContent(values)
                                }}
                              />
                              <ErrorMessage
                                name="photo"
                                component={FormErrorMessage}
                              />
                            </Stack>
                          </Flex>
                          <Divider />
                          <Box>
                            <Text fontSize="md">
                              <FontAwesomeIcon
                                icon={faMapMarked}
                                style={{ marginRight: '5px' }}
                              />
                              Location
                            </Text>

                            <Text color="gray.500">
                              Choose a location in your space
                            </Text>
                            <Select
                              bg="#e5e5e5"
                              variant="filled"
                              width={['100%', '60%']}
                              value={values.location_venue?.id}
                              onChange={(e) => {
                                setFieldValue(
                                  'location_venue',
                                  locations.find(
                                    (l) => `${l.id}` === e.target.value
                                  )
                                )
                              }}
                              fontSize={'sm'}
                            >
                              <option value={''}>
                                -- Select a Location --
                              </option>
                              {locations &&
                                locations.map((location, i) => (
                                  <option key={i} value={location.id}>
                                    {location.name} {' - '} {location.address}
                                  </option>
                                ))}
                            </Select>
                            <Text color="gray.500" mt={2}>
                              Or type a custom location
                            </Text>
                            <Field
                              width={['100%', '60%']}
                              name="location"
                              component={CreatePostFormInput}
                              placeholder={'Custom Location'}
                              fontSize="sm"
                              px={3}
                            />
                            <ErrorMessage
                              name="url"
                              component={FormErrorMessage}
                            />
                          </Box>
                          <Divider />
                          <Box>
                            <Flex>
                              <Text fontSize={'md'} mb={3}>
                                <FontAwesomeIcon
                                  icon={faCalendarDay}
                                  style={{ marginRight: '5px' }}
                                />
                                Date and Time
                              </Text>
                              <Spacer />
                              {values.date && (
                                <StyledButton
                                  variant="secondary"
                                  onClick={() => {
                                    setFieldValue('date', undefined)
                                    setFieldValue('from', false)
                                    setChosenStartDateTime(undefined)
                                    setChosenEndDateTime(undefined)
                                    setFieldValue('to', false)
                                  }}
                                  size="xs"
                                >
                                  Clear Date
                                </StyledButton>
                              )}
                            </Flex>

                            <Flex flexDir={['column', 'row', 'row']}>
                              <Box w={['100%', '100%', '60%']}>
                                <FormLabel color="#4F4F4F" fontSize="sm" mb={1}>
                                  Date
                                </FormLabel>

                                <CalendarFormInput
                                  label={''}
                                  minDate={new Date()}
                                  dateProps={{
                                    chosenDate: values.date,
                                    handleChangeDate: (date: Date) => {
                                      setFieldValue(
                                        'date',
                                        new Date(date.setHours(0))
                                      )
                                    }
                                  }}
                                  closeOnSelect={true}
                                />
                              </Box>
                            </Flex>

                            <Flex flexDir={['column', 'row', 'row']}>
                              <Box m={1}>
                                <FormLabel color="#4F4F4F" fontSize="sm" mb={1}>
                                  Start Time
                                </FormLabel>
                                <ReactDatePicker
                                  disabled={values.date === undefined}
                                  className="create-post"
                                  selected={chosenStartDateTime}
                                  onChange={(date) => {
                                    if (date === null) return
                                    setChosenStartDateTime(date)
                                    setFieldValue('from', true)
                                  }}
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeIntervals={15}
                                  timeCaption="From"
                                  dateFormat="h:mm aa"
                                />
                              </Box>
                              <Box m={1}>
                                <FormLabel color="#4F4F4F" fontSize="sm" mb={1}>
                                  End Time
                                </FormLabel>
                                <ReactDatePicker
                                  disabled={values.date === undefined}
                                  selected={chosenEndDateTime}
                                  onChange={(date) => {
                                    setChosenEndDateTime(date ?? undefined)
                                    setFieldValue('to', true)
                                  }}
                                  isClearable
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeIntervals={15}
                                  timeCaption="To"
                                  dateFormat="h:mm aa"
                                />
                              </Box>
                            </Flex>
                          </Box>
                        </Stack>
                      )}

                      {currentStep === STEP_EXTRA && (
                        <Stack spacing={3}>
                          <Text color="gray.500">Additional Information</Text>

                          <Stack spacing={3}>
                            <Box>
                              <Field
                                name="url"
                                label="Add a Link to your post"
                                component={CreatePostFormInput}
                                placeholder={'https://...'}
                                px={2}
                              />
                              <ErrorMessage
                                name="url"
                                component={FormErrorMessage}
                              />
                            </Box>

                            <Stack w={['100%', '30%', '30%']}>
                              <FormLabel color="#4F4F4F" fontSize="sm" mb={1}>
                                Zoom Link
                              </FormLabel>
                              {communityProfile ? (
                                communityProfile[0].is_zoom_connected ? (
                                  <Checkbox
                                    onChange={(e) => {
                                      setFieldValue('zoom', e.target.checked)
                                    }}
                                    color="#255CE1"
                                  >
                                    Add Zoom link
                                  </Checkbox>
                                ) : (
                                  <Link
                                    color="#255CE1"
                                    href={`https://zoom.us/oauth/authorize?response_type=code&client_id=${zoomKey}&redirect_uri=${redirectURI}`}
                                    isExternal
                                  >
                                    Connect Zoom
                                  </Link>
                                )
                              ) : (
                                <Spinner></Spinner>
                              )}
                            </Stack>
                            <Divider />
                            <Box w={'100%'}>
                              <Heading size={'sm'} mb={3}>
                                Privacy
                              </Heading>

                              <Checkbox
                                onChange={(e) => {
                                  setFieldValue('private', e.target.checked)
                                }}
                                color="#255CE1"
                              >
                                Make this post available to{' '}
                                {platformTenant?.business_name} members only
                              </Checkbox>
                              <Text fontSize="xs">
                                (This will hide this post from other Spacecubed
                                Platform users and will require{' '}
                                {platformTenant?.business_name} admin approval)
                              </Text>
                            </Box>
                          </Stack>
                        </Stack>
                      )}

                      <Flex mt={4}>
                        <Spacer />
                        {currentStep > 0 && (
                          <StyledButton
                            size="sm"
                            variant="secondary"
                            style={{ margin: '0 8px' }}
                            onClick={() => prev()}
                          >
                            Previous
                          </StyledButton>
                        )}

                        {currentStep < steps.length - 1 && (
                          <StyledButton size="sm" onClick={() => next()}>
                            Next
                          </StyledButton>
                        )}
                      </Flex>
                    </Stack>
                  </Form>
                )}
              </Formik>
            </Flex>
          </Box>
        </GridItem>
        <GridItem colSpan={1}>
          {isLargeEnough && (
            <ContentDisplay content={previewContent} isPreview={true} />
          )}
        </GridItem>
      </Grid>
    </Container>
  )
}
