import { Box, Heading, Image, Text, useTheme } from '@chakra-ui/react'
import styled from '@emotion/styled'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import React from 'react'
import { useHistory } from 'react-router'
import ReactTags, { Tag } from 'react-tag-autocomplete'

import { BackButton } from '../../components/BackButton'
import { Button } from '../../components/Button'
import { FormInput } from '../../components/FormInput'
import { routeStrings } from '../../routeStrings'
import { useUserDetails } from '../../context/user-onboard-context'
import { useSkillsAndInterests } from '../../utils/apiHooks'

export const Onboarding5SkillsAndBio: React.FC = () => {
  const { setUserDetails, userDetails } = useUserDetails()
  const history = useHistory()
  const theme: any = useTheme()
  const blurActiveEl = () => {
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur()
    }
    return
  }

  const { skills, interests } = useSkillsAndInterests()

  return (
    <StyledTagsWrapper>
      <Formik
        initialValues={{
          skills: userDetails!.skills || [],
          interests: userDetails!.interests || [],
          aboutYou: userDetails!.aboutYou
        }}
        validate={(values): any => {
          const errors: any = {}
          // Check if empty
          if (values.skills.length === 0 || values.interests.length === 0) {
            // errors.skills = 'Required'
          }
          // Check if max length exceeds 1000 chars (database restriction)
          if (values.interests && values.skills) {
            let interestCharLen = 0
            let skillCharLen = 0

            values.interests.map((i) => (interestCharLen += i.name.length))
            values.interests.map((i) => (skillCharLen += i.name.length))

            if (interestCharLen > 999) {
              errors.interests =
                'Please select fewer options (total length must not exceed 1000 characters'
            }

            if (skillCharLen > 999) {
              errors.skills =
                'Please select fewer options (total length must not exceed 1000 characters'
            }
          }
          return errors
        }}
        onSubmit={(values) => {
          setUserDetails((currentValues: any) => ({
            ...currentValues,
            aboutYou: values!.aboutYou,
            interests: values.interests,
            skills: values.skills
          }))
          userDetails.is_new_tenant_invite
            ? history.push(routeStrings.onboardingTenant6Password)
            : history.push(routeStrings.onboarding6Password)
        }}
      >
        {({
          values,
          setFieldValue,
          isSubmitting
        }: {
          values: {
            interests
            skills
          }
          setFieldValue: any
          isSubmitting: boolean
        }) => (
          <>
            <Box bg={'white'} boxShadow={'lg'} p={8} rounded={'xl'}>
              <Image
                src={theme.logos.logo_long}
                width="128px"
                className="location-logo"
              />
              <Form>
                <Box data-testid="complete-skills-field" mt={6}>
                  <Heading size="xs" color="gray.400" my={6}>
                    Step 5 of 6
                  </Heading>
                  <Box py={3}>
                    <Heading size="sm" mb={2}>
                      Tell us about yourself
                    </Heading>
                  </Box>

                  <Label>Skills</Label>
                  <ReactTags
                    // allowNew={true}
                    // label="skills"
                    tags={values.skills}
                    suggestions={skills}
                    placeholder="Choose some skills"
                    minQueryLength={0}
                    autofocus={false}
                    maxSuggestionsLength={999}
                    handleDelete={(interest: any) => {
                      const tags = values.skills.slice(0)
                      tags.splice(interest, 1)
                      setFieldValue('skills', tags)
                    }}
                    handleAddition={(skill: Tag) => {
                      setFieldValue('skills', [...values.skills, skill])
                      blurActiveEl()
                    }}
                  />
                  <ErrorMessage name="skills" component="div" />
                </Box>
                <Box data-testid="complete-interests-field">
                  <Label>Interests</Label>
                  <ReactTags
                    tags={values.interests || []}
                    suggestions={interests}
                    placeholder="Choose some interests"
                    minQueryLength={0}
                    maxSuggestionsLength={999}
                    autofocus={false}
                    // allowNew={true}
                    handleDelete={(interest: any) => {
                      const tags = values.interests.slice(0)
                      tags.splice(interest, 1)
                      setFieldValue('interests', tags)
                    }}
                    handleAddition={(interest: Tag) => {
                      setFieldValue('interests', [
                        ...values.interests,
                        interest
                      ])
                      blurActiveEl()
                    }}
                  />
                  <ErrorMessage name="interests" component="div" />
                </Box>
                <Box>
                  <Field
                    textarea
                    name="aboutYou"
                    data-testid="complete-aboutYou-field"
                    component={FormInput}
                    label="About you"
                    rows="3"
                    placeholder="Enter a short bio about yourself (optional)"
                  />
                  <ErrorMessage name="aboutYou" component="div" />
                </Box>

                <br />
                <Button
                  ml="auto"
                  type="submit"
                  data-testid="onboarding-next"
                  style={{ float: 'right', display: 'inline-block' }}
                  disabled={isSubmitting}
                >
                  Next
                </Button>
                <BackButton>Back</BackButton>
              </Form>
            </Box>
          </>
        )}
      </Formik>
    </StyledTagsWrapper>
  )
}

const Label = styled(Text)`
  font-family: Open Sans;
  color: black;
  font-weight: bold;
`

const StyledTagsWrapper = styled.div`
  .react-tags__suggestions {
    z-index: 999 !important;
    left: unset;
    width: 100%;
    max-height: 260px;
    overflow-y: scroll;
    background-color: white;

    mark {
      padding: 0;
    }
  }

  .react-tags__search-input {
    z-index: 0 !important;
  }
`
