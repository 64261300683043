import {
  Box,
  Flex,
  Icon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Text,
  useDisclosure,
  useTheme
} from '@chakra-ui/react'
import { format } from 'date-fns'
import { jsx, css } from '@emotion/core'
import React, { useState } from 'react'
// import { InputWarning } from '../styled/InputWarning'
// import css from '@styled-system/css'
import Calendar from 'react-calendar'
import { CalendarCustomIcon } from './icons/icons'
import { Button } from './Button'

interface Props {
  label?: string
  hideLabel?: boolean
  dateProps?: {
    chosenDate?: Date
    handleChangeDate: (newDate: Date) => void
  }
  rangeProps?: {
    chosenDates: Date[]
    handleChangeDateRange: (newDates: Date[]) => void
  }
  selectRange?: boolean
  shouldConfirmChange?: boolean
  customMessage?: string
  minDate?: Date
  maxDate?: Date
  closeOnSelect?: boolean
  placement?: 'bottom' | 'left' | 'right' | undefined
  dataTestId?: string
}

export const CalendarFormInput: React.FC<Props> = ({
  label,
  hideLabel,
  dateProps,
  rangeProps,
  shouldConfirmChange,
  customMessage,
  minDate,
  maxDate,
  closeOnSelect,
  placement,
  selectRange,
  dataTestId,
  children
}) => {
  const theme: any = useTheme()
  const { isOpen, onClose, onOpen } = useDisclosure()
  const [selectedRange, setSelectedRange] = useState<Date[]>(
    rangeProps ? rangeProps.chosenDates : [new Date(), new Date()]
  )

  const inputStyles = css`
    transition: all ease 0.2s;
    border-radius: 0;
    border: 0px none;
    background: ${theme.colors.eastBay01 || '#FAFAFA'};
    font-family: Open Sans;
    padding: 11px;
    align-items: center;
    color: black;
    &:hover {
      cursor: pointer;
      p {
        text-decoration: underline;
      }
    }
    p {
      margin-bottom: 0px;
    }
  `
  const labelStyles = css`
    font-family: Open Sans;
    color: black;
    font-weight: bold;
    margin-bottom: 4px;
  `

  const handleChangeDate = (date: Date) => {
    if (dateProps) {
      dateProps.handleChangeDate(date as Date)
      if (closeOnSelect) {
        onClose()
      }
    }
  }

  const handleChangeDateRange = (dates: Date[]) => {
    if (rangeProps) {
      rangeProps.handleChangeDateRange(dates)
      if (closeOnSelect) {
        onClose()
      }
    }
  }

  if (selectRange && !rangeProps)
    return <Text>You need to specify the rangeProps object</Text>

  return (
    <Box mb={3}>
      {!hideLabel && <Text css={labelStyles}>{label ?? 'Date'} </Text>}
      <Popover
        placement={placement ?? 'right'}
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
      >
        <PopoverTrigger>
          {children ? (
            children
          ) : (
            <Flex
              css={inputStyles}
              data-testid={dataTestId ?? 'calendar-form-input'}
            >
              {dateProps && dateProps.chosenDate && (
                <Text>{format(dateProps.chosenDate, 'dd/MM/yyyy')}</Text>
              )}
              {rangeProps && rangeProps.chosenDates.length > 0 && (
                <Text>
                  {format(rangeProps.chosenDates[0], 'dd/MM/yyyy')} -{' '}
                  {format(rangeProps.chosenDates[1], 'dd/MM/yyyy')}
                </Text>
              )}
              <CalendarCustomIcon
                color="brandPrimary"
                cursor="pointer"
                float="right"
                margin="0 8px 0 auto"
              />
            </Flex>
          )}
        </PopoverTrigger>
        <PopoverContent
          width="390px"
          bg="white"
          boxShadow="0px 8px 16px rgba(0, 0, 0, 0.1)"
        >
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverBody mt={4}>
            <Calendar
              onChange={(date) => {
                if (date) {
                  if (selectRange) {
                    !shouldConfirmChange &&
                      handleChangeDateRange(date as Date[])
                    setSelectedRange(date as Date[])
                  } else {
                    handleChangeDate(date as Date)
                  }
                }
              }}
              selectRange={selectRange}
              value={
                dateProps
                  ? dateProps.chosenDate
                  : rangeProps
                  ? rangeProps.chosenDates
                  : new Date()
              }
              minDate={minDate || new Date()}
              maxDate={maxDate || undefined}
              css={
                !selectRange
                  ? css`
                      &.react-calendar {
                        border: none;
                      }
                    `
                  : ''
              }
            />
            <Text color="grey" mt={2}>
              {customMessage}
            </Text>
            {shouldConfirmChange && (
              <Button
                variant="secondary"
                size={'xs'}
                onClick={() => {
                  if (selectedRange) {
                    handleChangeDateRange(selectedRange)
                  }
                }}
              >
                Confirm
              </Button>
            )}
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  )
}
