import { Box, Flex, HStack, Text } from '@chakra-ui/react'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import React from 'react'
import { useHistory } from 'react-router'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { PrimaryButton } from '../../components/chakra-v2/PrimaryButton'
import { FormErrorMessage } from '../../components/FormErrorMessage'
import { FormInput } from '../../components/FormInput'
import { OnboardingUser } from '../../interfaces'
import { routeStrings } from '../../routeStrings'
import {
  SinglePageSignupComponent,
  useUserDetails
} from '../../context/user-onboard-context'
import {
  EMAIL_REGEX,
  FERN_ALLENDALE_PLAN_UUID,
  ILUMA_PLAN_UUID
} from '../../utils/constants'
import { OnboardingHeading } from './OnboardingComponents'
import { Phone } from '../../styled/Phone'

export const OnboardingEmail: React.FC<SinglePageSignupComponent> = (props) => {
  const { onCompleteCallback, setComplete, isComplete, isActive, setIsActive } =
    props
  const { setUserDetails, userDetails } = useUserDetails()
  function lowerEmail(input) {
    input.email = input.email.toLowerCase()
    return input
  }
  return (
    <Box ml="auto" mr="auto" w="450px" my={5} onClick={setIsActive}>
      <OnboardingHeading
        size={'md'}
        color={isActive ? 'gray.800' : 'gray.400'}
        mb={4}
        textAlign="center"
      >
        Let's start with your details
      </OnboardingHeading>

      <Formik
        initialValues={{
          email: userDetails.email,
          firstName: userDetails.firstName,
          lastName: userDetails.lastName,
          mobileNumber: userDetails.mobileNumber
        }}
        validate={(values): OnboardingUser => {
          const errors: any = {}

          if (!values.email) {
            errors.email = 'Required'
          } else if (!EMAIL_REGEX.test(values.email)) {
            errors.email = 'Invalid email address'
          }
          if (!values.firstName) {
            errors.firstName = 'Required'
          }
          if (!values.lastName) {
            errors.lastName = 'Required'
          }
          if (!values.mobileNumber) {
            errors.mobileNumber = 'Required'
          } else {
            if (!isValidPhoneNumber(values.mobileNumber)) {
              errors.mobileNumber = 'Invalid phone number'
            }
          }
          if (setComplete != undefined) {
            setComplete(false)
          }
          return errors
        }}
        onSubmit={(values, { setSubmitting }) => {
          setUserDetails((currentValues: any) => ({
            ...currentValues,
            ...lowerEmail(values)
          }))
          setSubmitting(false)
          onCompleteCallback()
          // Deprecated
          /* if (isAllendalePlan || isIlumaPlan) {
            history.push(routeStrings.acceptTermsAndConditions)
          } else {
            history.push(routeStrings.startDate)
          } */
        }}
      >
        {({ isSubmitting, touched, errors }) => (
          <Form>
            <Flex direction="column">
              <Box mb={8}>
                <Field
                  type="email"
                  name="email"
                  autoFocus={true}
                  isInvalid={
                    isActive && touched.email && errors.email !== undefined
                  }
                  placeholder="Email"
                  component={FormInput}
                  data-testid="onboarding-email-field"
                />
                <ErrorMessage name="email" component={FormErrorMessage} />
                <Text>
                  {userDetails.plan === FERN_ALLENDALE_PLAN_UUID &&
                    'Please use your work email address to ensure a quick approval.'}
                </Text>
              </Box>
              <HStack mb={5} justifyContent={'space-between'}>
                <Box w="48%">
                  <Field
                    type="text"
                    name="firstName"
                    isInvalid={
                      isActive &&
                      touched.firstName &&
                      errors.firstName !== undefined
                    }
                    component={FormInput}
                    data-testid="onboarding-firstName-field"
                    placeholder="First Name"
                  />
                  <Box h={5}>
                    <ErrorMessage
                      name="firstName"
                      component={FormErrorMessage}
                    />
                  </Box>
                </Box>
                <Box w="48%">
                  <Field
                    isInvalid={
                      isActive &&
                      touched.lastName &&
                      errors.lastName !== undefined
                    }
                    type="text"
                    name="lastName"
                    component={FormInput}
                    data-testid="onboarding-lastName-field"
                    placeholder="Last Name"
                  />
                  <Box h={5}>
                    <ErrorMessage
                      name="lastName"
                      component={FormErrorMessage}
                    />
                  </Box>
                </Box>
              </HStack>
              <Box mb={5}>
                <Field
                  isInvalid={
                    isActive &&
                    touched.mobileNumber &&
                    errors.mobileNumber !== undefined
                  }
                  data-testid="onboarding-mobileNumber-field"
                  name="mobileNumber"
                  placeholder="Mobile Number"
                  component={Phone}
                />
                <ErrorMessage
                  name="mobileNumber"
                  component={FormErrorMessage}
                />
              </Box>
              <PrimaryButton
                type="submit"
                px={6}
                data-testid="onboarding-email-next"
                isDisabled={isSubmitting || !isActive}
                flexGrow={0}
                mr="auto"
                ml="auto"
              >
                {isActive
                  ? 'Confirm Details'
                  : isComplete
                  ? 'Details Confirmed'
                  : 'Unconfirmed Details'}
              </PrimaryButton>
            </Flex>
          </Form>
        )}
      </Formik>
    </Box>
  )
}
