import { Box, Flex, useDisclosure, useToast, VStack } from '@chakra-ui/react'
import { addDays, subDays, subMonths } from 'date-fns'
import React, { useState } from 'react'
import { useEffect } from 'react'
import {
  cancelTeamInvite,
  declineChangeRequest,
  reSendInvitationEmail
} from '../../api'
import { AdminBodyWrapper } from '../../components/AdminBodyWrapper'
import { CalendarFormInput } from '../../components/CalendarFormInput'
import { FilterableOrganizationsSelect } from '../../components/FilterableOrganizationsSelect'
import {
  GroupingHeaderLayout,
  ITab
} from '../../components/GroupingHeaderLayout'
import { AdminDashboardChangeRequestModal } from '../../components/modals/AdminDashboardChangeRequestModal'
import { useAppTourContextProvider } from '../../context/app-tour-context'
import { useAuth } from '../../context/auth-context'
import { usePlatformTenant } from '../../context/platform-tenant-context'
import {
  IAddonPlanChangeRequest,
  IInvitationRecord,
  IOrganizationalUnitRequest,
  IPlanChangeRequest
} from '../../types'
import {
  useAddonPlanChangeRequests,
  useMemberInvitations,
  useNewOrgUnitRequests,
  useNewTeamMemberInvitations,
  usePaginatedFilteredAddonPlanChangeRequests,
  usePaginatedFilteredOrgUnitChangeRequests,
  usePaginatedFilteredPlanChangeRequests,
  usePlanChangeRequests
} from '../../utils/apiHooks'
import { GenericAlertDialog } from './Components/GenericAlertDialog'
import { LayoutAdminDashboard } from './LayoutAdminDashboard'
import { AddonPlanChangeRequests } from './Tables/AddonPlanChangeRequests'
import { MemberInvitations } from './Tables/MemberInvitations'
import { OrganizationalUnitRequests } from './Tables/OrganizationalUnitRequests'
import { PlanChangeRequests } from './Tables/PlanChangeRequests'
import { TeamMemberInvitations } from './Tables/TeamMemberInvitations'

export interface TableDropdownOption {
  text: string
  testIdPrefix?: string
  variant: string
  onClick: (row: any) => void
}

export const AdminDashboardRequests: React.FC = () => {
  const { adminTourActive, setRun } = useAppTourContextProvider()
  useEffect(() => {
    if (adminTourActive) {
      setRun(true)
    }
  }, [])
  const [selectedPendingRequest, setSelectedPendingRequest] =
    useState<IOrganizationalUnitRequest | null>(null)

  const [invitationsDateFilter, setInvitationsDateFilter] = useState(
    subDays(new Date(), 7)
  )
  const [selectedOrganizationId, setSelectedOrganizationId] = useState(0)

  const [filterChangeRequests, setFilterChangeRequest] = useState({
    page: 1,
    organizationId: 0
  })

  const [filterAddonChangeRequests, setFilterAddonChangeRequest] = useState({
    page: 1,
    organizationId: 0
  })

  const [filterOrgUnitRequests, setFilterOrgUnitRequest] = useState({
    page: 1,
    organizationId: 0
  })

  // Only show results from 6 months back
  const today = new Date()
  const minDate = subMonths(today, 3)
  const tomorrow = addDays(today, 1)
  // Processing Invitation Actions
  const [isProcessing, setIsProcessing] = useState(false)
  const {
    isOpen: isInvitationDialogOpen,
    onOpen: onInvitationDialogOpen,
    onClose: onInvitationDialogClose
  } = useDisclosure()
  const [selectedInvitation, setSelectedInvitation] =
    useState<IInvitationRecord>()

  const [requestsFilter, setRequestsFilter] =
    useState<string>('pending_requests')

  const toast = useToast()
  const { currentOrgUnit } = useAuth()
  const { platformTenant } = usePlatformTenant()

  const headerGroups: ITab[] = [
    {
      active: requestsFilter === 'pending_requests',
      text: 'Pending Requests',
      selectAction: () => {
        setRequestsFilter('pending_requests')
      }
    },
    {
      active: requestsFilter === 'plan_requests',
      text: 'Plan Change Requests',
      selectAction: () => {
        setRequestsFilter('plan_requests')
      }
    },
    {
      active: requestsFilter === 'addon_plan_requests',
      text: 'Add-on Plan Requests',
      selectAction: () => {
        setRequestsFilter('addon_plan_requests')
      }
    },
    {
      active: requestsFilter === 'member_requests',
      text: 'Member Requests',
      selectAction: () => {
        setRequestsFilter('member_requests')
      }
    },
    {
      active: requestsFilter === 'member_invitations',
      text: 'Member Invitations',
      selectAction: () => {
        setRequestsFilter('member_invitations')
      }
    }
  ]

  // State to hide/show approval modal
  const [approvalModalIsVisible, setApprovalModalIsVisible] = useState(false)

  // Revalidation hooks
  const { revalidate: revalidatePendingOus, data: pendingOUReq } =
    useNewOrgUnitRequests(
      'PENDING',
      currentOrgUnit?.organization.parent_platform_tenant,
      { revalidateOnFocus: false }
    )
  const { revalidate: revalidatePendingPlanReqs, data: pendingPlanChange } =
    usePlanChangeRequests(
      'PENDING',
      currentOrgUnit?.organization.parent_platform_tenant,
      { revalidateOnFocus: false }
    )
  const {
    revalidate: revalidatePendingAddonPlanReqs,
    data: pendingAddonPlanChange
  } = useAddonPlanChangeRequests(
    'PENDING',
    currentOrgUnit?.organization.parent_platform_tenant,
    { revalidateOnFocus: false }
  )
  const {
    revalidate: revalidateOus,
    data: paginatedOrgUnitRequests,
    isValidating: isValidatingOrgUnitReqs
  } = usePaginatedFilteredOrgUnitChangeRequests(
    currentOrgUnit?.organization.parent_platform_tenant,
    filterOrgUnitRequests,
    { revalidateOnFocus: false }
  )

  const {
    data: paginatedAllPlanChange,
    isValidating: isValidatingPlanChangeReq
  } = usePaginatedFilteredPlanChangeRequests(
    currentOrgUnit?.organization.parent_platform_tenant,
    filterChangeRequests,
    { revalidateOnFocus: false }
  )
  const {
    revalidate: revalidateAddonPlanReqs,
    data: paginatedAlladdonPlanChange,
    isValidating: isValidatingAddonPlanReq
  } = usePaginatedFilteredAddonPlanChangeRequests(
    currentOrgUnit?.organization.parent_platform_tenant,
    filterAddonChangeRequests,
    { revalidateOnFocus: false }
  )
  const {
    revalidate: revalidateMemberInvitations,
    data: allMemberInvitations,
    isValidating: isLoadingInvitations
  } = useMemberInvitations(platformTenant?.id, invitationsDateFilter, {
    revalidateOnFocus: false
  })
  const {
    revalidate: revalidateTeamMemberInvitations,
    data: allTeamMemberInvitations,
    isValidating: isLoadingTeamInvitations
  } = useNewTeamMemberInvitations(platformTenant?.id, invitationsDateFilter, {
    revalidateOnFocus: false
  })

  function handleApproveOrgUnitRequest(request: IOrganizationalUnitRequest) {
    setApprovalModalIsVisible(true)
    setSelectedPendingRequest(request)
    revalidatePendingOus()
  }

  const [allOUReq, setAllOUReq] = useState<IOrganizationalUnitRequest[]>([])
  const [allplanChange, setAllPlanChange] = useState<IPlanChangeRequest[]>([])
  const [alladdonPlanChange, setAllAddonPlanChange] = useState<
    IAddonPlanChangeRequest[]
  >([])
  useEffect(() => {
    if (paginatedOrgUnitRequests) {
      if (filterOrgUnitRequests.page === 1) {
        setAllOUReq(paginatedOrgUnitRequests?.results)
      } else {
        setAllOUReq(allOUReq.concat(paginatedOrgUnitRequests?.results))
      }
    }
  }, [paginatedOrgUnitRequests])

  useEffect(() => {
    if (paginatedAllPlanChange) {
      if (filterChangeRequests.page === 1) {
        setAllPlanChange(paginatedAllPlanChange?.results)
      } else {
        setAllPlanChange(allplanChange.concat(paginatedAllPlanChange?.results))
      }
    }
  }, [paginatedAllPlanChange])

  useEffect(() => {
    if (paginatedAlladdonPlanChange) {
      if (filterAddonChangeRequests.page === 1) {
        setAllAddonPlanChange(paginatedAlladdonPlanChange?.results)
      } else {
        setAllAddonPlanChange(
          alladdonPlanChange.concat(paginatedAlladdonPlanChange?.results)
        )
      }
    }
  }, [paginatedAlladdonPlanChange])

  useEffect(() => {
    // everytime the modal is closed
    if (!approvalModalIsVisible) {
      revalidatePendingOus()
    }
  }, [approvalModalIsVisible])

  function handleDeclineOrgUnitRequest(request: IOrganizationalUnitRequest) {
    declineChangeRequest(request.id)
      .then((result) => {
        revalidatePendingOus()
        revalidateOus()
        if (typeof result.data === 'string') {
          toast({ description: result.data, status: 'info' })
        }
      })
      .catch(() => {
        toast({
          description: 'Something went wrong, request cancellation failed',
          status: 'error'
        })

        revalidatePendingOus()
        revalidateOus()
      })
  }

  const handleCancelInvite = (invitation: IInvitationRecord) => {
    if (isInvitationDialogOpen) return
    setSelectedInvitation(invitation)
    onInvitationDialogOpen()
  }

  const handleResendInvite = (invitation: IInvitationRecord) => {
    if (isProcessing) return
    setIsProcessing(true)
    reSendInvitationEmail(invitation.token)
      .then((res) => {
        setIsProcessing(false)
        toast({ description: 'Invitation email sent', status: 'info' })
      })
      .catch((err) => {
        setIsProcessing(false)
        toast({
          description: 'There was an error with this invitation',
          status: 'error'
        })
      })
  }

  const handleSelectOrganization = (orgId: number) => {
    const filter = {
      organizationId: orgId,
      page: 1
    }
    setFilterChangeRequest(filter)
    setFilterAddonChangeRequest(filter)
    setFilterOrgUnitRequest(filter)
  }

  return (
    <LayoutAdminDashboard>
      <GroupingHeaderLayout basicTitle="Requests" tabs={headerGroups} />

      <AdminBodyWrapper>
        {['pending_requests', 'member_requests'].includes(requestsFilter) && (
          <OrganizationalUnitRequests
            pendingRequestData={pendingOUReq}
            status={'PENDING'}
            approveRequest={handleApproveOrgUnitRequest}
            declineRequest={handleDeclineOrgUnitRequest}
          />
        )}
        {['pending_requests', 'plan_requests'].includes(requestsFilter) && (
          <PlanChangeRequests
            pendingRequests={pendingPlanChange}
            status={'PENDING'}
            revalidateRequests={() => {
              revalidatePendingPlanReqs()
            }}
          />
        )}
        {['pending_requests', 'addon_plan_requests'].includes(
          requestsFilter
        ) && (
          <AddonPlanChangeRequests
            pendingRequests={pendingAddonPlanChange}
            status={'PENDING'}
            revalidateRequests={() => {
              revalidatePendingAddonPlanReqs()
            }}
          />
        )}
        {!['pending_requests', 'member_invitations'].includes(
          requestsFilter
        ) && (
          <Flex mb={1} flexDir="column">
            <VStack
              bg="white"
              w="100%"
              boxShadow="0px 1px 0px rgba(0, 0, 0, 0.05)"
              rounded="md"
              p={1}
              position="relative"
              color="eastBay09"
              align="center"
              justifyContent="space-between"
            >
              <Box w={['full']} my={2}>
                <Box p="4" bg={'white'} maxW={'md'}>
                  <FilterableOrganizationsSelect
                    name="Filter by team"
                    initialValue={selectedOrganizationId}
                    onSelect={(n) => {
                      handleSelectOrganization(n)
                    }}
                  />
                </Box>
              </Box>
            </VStack>
          </Flex>
        )}
        {['member_requests'].includes(requestsFilter) && (
          <OrganizationalUnitRequests
            pendingRequestData={allOUReq}
            approveRequest={handleApproveOrgUnitRequest}
            declineRequest={handleDeclineOrgUnitRequest}
            pagination={{
              hasMoreResults: paginatedOrgUnitRequests?.next != undefined,
              isLoading: isValidatingOrgUnitReqs,
              fetchMoreResults: () => {
                setFilterOrgUnitRequest((prev_state) => ({
                  ...prev_state,
                  page: prev_state.page + 1
                }))
              },
              count: paginatedOrgUnitRequests?.count ?? 0
            }}
          />
        )}
        {['plan_requests'].includes(requestsFilter) && (
          <PlanChangeRequests
            pendingRequests={allplanChange}
            revalidateRequests={() => {
              revalidatePendingPlanReqs()
            }}
            pagination={{
              hasMoreResults: paginatedAllPlanChange?.next != undefined,
              isLoading: isValidatingPlanChangeReq,
              fetchMoreResults: () => {
                // setPlanChangesPage(planChangesPage + 1)
                setFilterChangeRequest((prev_state) => ({
                  ...prev_state,
                  page: prev_state.page + 1
                }))
              },
              count: paginatedAllPlanChange?.count ?? 0
            }}
          />
        )}
        {['addon_plan_requests'].includes(requestsFilter) && (
          <AddonPlanChangeRequests
            pendingRequests={alladdonPlanChange}
            revalidateRequests={() => {
              revalidatePendingAddonPlanReqs()
            }}
            pagination={{
              hasMoreResults: paginatedAlladdonPlanChange?.next != undefined,
              isLoading: isValidatingAddonPlanReq,
              fetchMoreResults: () => {
                setFilterAddonChangeRequest((prev_state) => ({
                  ...prev_state,
                  page: prev_state.page + 1
                }))
              },
              count: paginatedAlladdonPlanChange?.count ?? 0
            }}
          />
        )}
        {requestsFilter === 'member_invitations' && (
          <Box>
            <Box w={['100%', '20%']}>
              <CalendarFormInput
                minDate={minDate}
                maxDate={tomorrow}
                dateProps={{
                  chosenDate: invitationsDateFilter,
                  handleChangeDate: (date: Date) => {
                    setInvitationsDateFilter(new Date(date.setHours(0)))
                  }
                }}
                closeOnSelect={true}
              />
            </Box>
            <MemberInvitations
              invitations={allMemberInvitations}
              isLoading={isLoadingInvitations}
              revalidateInvitations={revalidateMemberInvitations}
              resendInvitation={handleResendInvite}
              cancelInvitation={handleCancelInvite}
            />
            <TeamMemberInvitations
              invitations={allTeamMemberInvitations}
              isLoading={isLoadingInvitations}
              revalidateInvitations={revalidateMemberInvitations}
              resendInvitation={handleResendInvite}
              cancelInvitation={handleCancelInvite}
            />
          </Box>
        )}
        <GenericAlertDialog
          onClose={onInvitationDialogClose}
          isConfirming={isProcessing}
          isOpen={isInvitationDialogOpen}
          title={'Cancel Invitation'}
          description={'Are you sure you want to cancel this invitation?'}
          confirmButtonText={'Confirm'}
          onConfirm={() => {
            if (selectedInvitation) {
              if (isProcessing) return
              setIsProcessing(true)
              cancelTeamInvite(selectedInvitation.token)
                .then((res) => {
                  setIsProcessing(false)
                  onInvitationDialogClose()
                  setSelectedInvitation(undefined)
                  revalidateMemberInvitations()
                  revalidateTeamMemberInvitations()
                  toast({
                    description: 'The invitation was cancelled',
                    status: 'info'
                  })
                })
                .catch((err) => {
                  setIsProcessing(false)
                  toast({
                    description: 'There was an error with this invitation',
                    status: 'error'
                  })
                })
            }
          }}
        />
      </AdminBodyWrapper>

      {/* Modals */}
      <AdminDashboardChangeRequestModal
        visibility={{
          modalIsVisible: approvalModalIsVisible,
          setModalIsVisible: setApprovalModalIsVisible
        }}
        selectedPendingRequest={selectedPendingRequest}
      />
    </LayoutAdminDashboard>
  )
}
