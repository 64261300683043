import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { isProduction } from './isProduction'
import { History } from 'history'

export function initErrorTracking(history: History) {
  if (isProduction) {
    Sentry.init({
      integrations: [
        new Integrations.BrowserTracing({
          routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
          tracingOrigins: ['api.spacecubed.com']
        })
      ],
      tracesSampleRate: 0.25,
      dsn: 'https://3e4fa46249984e179b090dc5c620e218@sentry.io/1809253'
    })
  }
}
