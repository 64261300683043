import { Box, Link, useDisclosure } from '@chakra-ui/react'
import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { routeStrings } from '../../../routeStrings'

interface Props {
  organizationId: number
  organizationName: string
}

export const TeamLink: React.FC<Props> = ({
  organizationId,
  organizationName
}) => {
  const history = useHistory()
  return (
    <Link
      color="#255CE1"
      mb={0}
      onClick={() => {
        history.push(routeStrings.adminTeamDetailView(organizationId))
      }}
    >
      {organizationName}
    </Link>
  )
}
