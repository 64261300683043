import React, { useEffect, useState } from 'react'

import { useAuth } from './auth-context'
import { createCtx } from './createCtx'
import { usePlatformTenant } from './platform-tenant-context'
import { analyticsTrack } from '../utils/analytics'
import { Step } from 'react-joyride'

import { AdminTourJoyride } from '../components/tours/AdminTourJoyride'
import { MemberTourJoyride } from '../components/tours/MemberTourJoyride'
import { useRole } from '../utils/rbac'
import { differenceInCalendarDays } from 'date-fns'

interface AppTourContext {
  run: boolean

  tourActive: boolean
  stepIndex?: number
  adminTourActive: boolean
  shouldShowMemberTour: boolean
  setTourActive: (val: boolean) => void
  setShouldShowMemberTour: (val: boolean) => void
  setStepIndex: (val: number | undefined) => void
  setAdminTourActive: (val: boolean) => void
  setRun: (val: boolean) => void
}

const [useAppTourContextProvider, AppTourContextProvider] =
  createCtx<AppTourContext>()

export interface TourProps {
  steps: Step[]
  continuous?: boolean
  showProgress?: boolean
  run: boolean

  stepIndex?: number
  isTourActive: boolean
}

interface ProviderProps {}

const AppTourProvider: React.FC<ProviderProps> = ({ children }) => {
  const { platformTenant } = usePlatformTenant()
  const { currentOrgUnit, me } = useAuth()
  const { isInAdminRole } = useRole()
  // const smallScreen = useMedia(['(min-width: 575px)'], [false], true)
  // const showMobileVersion = isMobileEmbed || smallScreen

  const [tourActive, setTourActive] = useState(false)
  const [shouldShowMemberTour, setShouldShowMemberTour] = useState(false)
  const [adminTourActive, setAdminTourActive] = useState(false)
  const [stepIndex, setStepIndex] = useState<number>()

  const [run, setRun] = useState(false)

  useEffect(() => {
    if (me && currentOrgUnit && currentOrgUnit.created_at) {
      try {
        if (
          differenceInCalendarDays(
            new Date(),
            new Date(currentOrgUnit.created_at)
          ) < 30
        ) {
          if (
            window.localStorage.getItem(`member-tour-${me.user.id}`) == null
          ) {
            setShouldShowMemberTour(true)
          }
        }
      } catch (error) {}
    }
  }, [me, currentOrgUnit])

  return (
    <AppTourContextProvider
      value={{
        run,
        adminTourActive,
        tourActive,
        stepIndex,
        shouldShowMemberTour,
        setShouldShowMemberTour,
        setStepIndex,
        setTourActive,
        setAdminTourActive,
        setRun
      }}
    >
      {isInAdminRole ? <AdminTourJoyride /> : <MemberTourJoyride />}
      {children}
    </AppTourContextProvider>
  )
}

export { useAppTourContextProvider, AppTourProvider }
