import {
  Box,
  Container,
  Flex,
  HStack,
  Spacer,
  Spinner,
  Text
} from '@chakra-ui/react'
import React from 'react'
import { useEffect } from 'react'
import { useHistory } from 'react-router'
import { Button } from '../../../components/Button'
import { CalendarFormInput } from '../../../components/CalendarFormInput'
import { FilterableBookableLocationsSelect } from '../../../components/FilterableBookableLocationsSelect'
import { MeetingRoomTimeLineCard } from '../../../components/MeetingRoomTimeLineCard'
import { useBookingContext } from '../../../context/BookingContext'
import { useCustomToast } from '../../../context/toast-context'
import { routeStrings } from '../../../routeStrings'
import { CenteredSpinner } from '../../../styled/CenteredSpinner'
import { useRole } from '../../../utils/rbac'

export const MeetingRooms: React.FC = () => {
  const {
    loading,
    handleChangeDate,
    meetingRooms,
    chosenLocation,
    chosenDate,
    revalidateMeetingRoomTimelines,
    setChosenLocation,
    revalOrgUnitBookings
  } = useBookingContext()

  const { newToast: toast } = useCustomToast()
  const history = useHistory()

  const { isExternalMember } = useRole()

  const { locations: bookableLocations } = useBookingContext()

  const bookingConfirmed = (status: boolean, data: any) => {
    if (status) {
      toast({ description: 'Booking created', status: 'success' })
      revalOrgUnitBookings()
    }
  }

  useEffect(() => {
    if (chosenLocation) revalidateMeetingRoomTimelines()
  }, [])

  return (
    <>
      <Box pb={3} px={8} bg="#FFFFFF" width="100%">
        <Text py={8} fontWeight="bold" fontSize="18px" color="black">
          Search for rooms
        </Text>
        <Flex flexDir={['column', 'column', 'row']}>
          <Box w={['100%', '80%', '30%']}>
            <CalendarFormInput
              dateProps={{
                chosenDate,
                handleChangeDate
              }}
              closeOnSelect={true}
            />
          </Box>
          <Box w={['100%', '80%', '30%']} ml={[0, 0, 3]}>
            {chosenLocation && (
              <FilterableBookableLocationsSelect
                name="Location"
                dataTestId="location-searchbox"
                initialValue={chosenLocation.id}
                onSelect={(loc) => {
                  setChosenLocation(loc)
                }}
              />
            )}
          </Box>
          <Spacer />
          <Box d="flex" alignItems="center" w={'20%'}>
            <HStack py={4}>
              <Button
                key={'action'}
                variant="secondary"
                onClick={() => {
                  history.push(routeStrings.memberDashboardBookings)
                }}
                data-testid=""
              >
                My Bookings
              </Button>
            </HStack>
          </Box>
        </Flex>
      </Box>

      <Flex
        bg="#F4F5F5"
        width="100%"
        height="100%"
        pos="relative"
        overflow="scroll"
      >
        {loading && (
          <Flex
            pos="absolute"
            align="center"
            justify="center"
            h="100%"
            w="100%"
            overflow="hidden"
            opacity={0.5}
            bg="#f4f5f5"
            cursor=""
          >
            <Spinner size="xl" />
          </Flex>
        )}
        <Container maxW="container.lg">
          {/* {loading && <Spinner />} */}
          <Box mt="10px">
            {meetingRooms ? (
              meetingRooms.map((meetingRoom, i) => (
                <MeetingRoomTimeLineCard
                  key={i}
                  meetingRoom={meetingRoom}
                  chosenDate={chosenDate}
                  bookingConfirmed={bookingConfirmed}
                  isExternal={
                    isExternalMember ||
                    !bookableLocations.find(
                      (l) => l.id === meetingRoom.location
                    )
                  }
                  businessHours={chosenLocation?.business_hours}
                />
              ))
            ) : (
              <CenteredSpinner />
            )}
          </Box>
        </Container>
      </Flex>
    </>
  )
}
