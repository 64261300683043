import React, { useContext, useEffect, useState } from 'react'

//7803625 core hubspot code
//5849306 spacecubed hubspot code
//7536017  hubspot prod
interface Props {
  hubSpotHelpCode: string
}

export const HubspotEmbed: React.FC<Props> = ({
  children,
  hubSpotHelpCode
}) => {
  //   const [hubSpotHelpCode, setHubSpotHelpCode] = useState(undefined)
  //   const [displayHelpWidget, setDisplayHelpWidget] = useState(false)

  useEffect(() => {
    if (hubSpotHelpCode !== undefined && hubSpotHelpCode != '') {
      renderWidget(hubSpotHelpCode)
    }
    return () => cleanUpHelpWidget(hubSpotHelpCode)
  }, [hubSpotHelpCode])

  const renderWidget = (widgetCode: string) => {
    var tagString = `<script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/${widgetCode}.js"></script>`
    var range = document.createRange()
    range.selectNode(document.getElementsByTagName('BODY')[0])
    var df = range.createContextualFragment(tagString)
    document.body.appendChild(df)
  }

  const cleanUpHelpWidget = (widgetCode: string) => {
    document.getElementById('hubspot-messages-iframe-container')?.remove()
    document.getElementById('hs-script-loader')?.remove()
    document.getElementById('hubspot-messages-loader')?.remove()
    document.getElementById(`cookieBanner-${widgetCode}`)?.remove()
    document.getElementById(`CollectedForms-${widgetCode}`)?.remove()
    document.getElementById('hs-analytics')?.remove()

    // currently, deleting these doesn't affect the widget functionality.
    // however we are still deleting them in case Hubspot shifts the duplicate detection functionality into these objects in future.
    delete (window as any).HubSpotConversations
    delete (window as any).hubspot

    // this one is super important - hubspot will complain about "duplicate chats" existing if it isn't deleted.
    delete (window as any).hubspot_live_messages_running

    if ((window as any).HubSpotConversations) {
      ;(window as any).HubSpotConversations.clear()
    }
  }
  return null
}
