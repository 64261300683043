import {
  Box,
  Checkbox,
  Flex,
  FormLabel,
  Stack,
  Text
} from '@chakra-ui/react'
import { Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { updateCommunityProfile } from '../../../api'
import { PrimarySpinner } from '../../../components/chakra-v2/PrimarySpinner'
import { ICommunityProfile } from '../../../types'
import { useCommunityProfile } from '../../../utils/apiHooks'
import { Button } from '../../../components/Button'

export const EditSettings: React.FC = () => {
  const { data: communityProfile } = useCommunityProfile()
  const [status, setStatus] = useState<boolean>()
  useEffect(() => { }, [])

  if (!communityProfile) {
    return <PrimarySpinner />
  }

  return (
    <Box my="3" maxWidth="650px">
      <Flex mb={3} flexDir="column">
        <Text mb={3} fontWeight="semibold" color="headingPrimary" fontSize="lg">
          Notification Settings
        </Text>
        <Flex
          bg="white"
          w="100%"
          boxShadow="0px 1px 0px rgba(0, 0, 0, 0.05)"
          rounded="md"
          px={8}
          py={5}
          position="relative"
          color="eastBay09"
          align="center"
          justifyContent="space-between"
        >
          <Formik
            initialValues={{
              new_comment_added_email_notifications:
                communityProfile[0].new_comment_added_email_notifications,
              new_comment_added_push_notifications:
                communityProfile[0].new_comment_added_push_notifications,
              new_daily_five_ready_push_notifications:
                communityProfile[0].new_daily_five_ready_push_notifications,
              new_daily_five_ready_email_notifications:
                communityProfile[0].new_daily_five_ready_email_notifications,
              is_zoom_connected: communityProfile[0].is_zoom_connected
            }}
            validate={(values): ICommunityProfile => {
              const errors: any = {}

              return errors
            }}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true)

              const data: ICommunityProfile = {
                new_comment_added_email_notifications:
                  values.new_comment_added_email_notifications,
                new_comment_added_push_notifications:
                  values.new_comment_added_push_notifications,
                new_daily_five_ready_push_notifications:
                  values.new_daily_five_ready_push_notifications,
                new_daily_five_ready_email_notifications:
                  values.new_daily_five_ready_email_notifications,
                is_zoom_connected: values.is_zoom_connected
              }

              if (communityProfile[0].id) {
                updateCommunityProfile(data, communityProfile[0].id).then(
                  (res) => {
                    if (res.status === 200) {
                      setSubmitting(false)
                      setStatus(true)
                    } else {
                      setSubmitting(false)
                      setStatus(false)
                    }
                  }
                )
              }
            }}
          >
            {({ values, setFieldValue, isSubmitting, touched, errors }) => (
              <Form>
                <Stack spacing={8}>
                  <Stack>
                    <FormLabel color="#4F4F4F" fontSize="sm" mb={1}>
                      New Comment
                    </FormLabel>

                    <Checkbox
                      isChecked={values.new_comment_added_email_notifications}
                      onChange={(e) => {
                        setFieldValue(
                          'new_comment_added_email_notifications',
                          e.target.checked
                        )
                      }}
                      color="#255CE1"
                    >
                      Email notification
                    </Checkbox>
                    <Checkbox
                      isChecked={values.new_comment_added_push_notifications}
                      onChange={(e) => {
                        setFieldValue(
                          'new_comment_added_push_notifications',
                          e.target.checked
                        )
                      }}
                      color="#255CE1"
                    >
                      Mobile push notification
                    </Checkbox>
                    <FormLabel color="#4F4F4F" fontSize="sm" mb={1}>
                      New Daily Five
                    </FormLabel>

                    <Checkbox
                      isChecked={
                        values.new_daily_five_ready_email_notifications
                      }
                      onChange={(e) => {
                        setFieldValue(
                          'new_daily_five_ready_email_notifications',
                          e.target.checked
                        )
                      }}
                      color="#255CE1"
                    >
                      Email notification
                    </Checkbox>
                    <Checkbox
                      isChecked={values.new_daily_five_ready_push_notifications}
                      onChange={(e) => {
                        setFieldValue(
                          'new_daily_five_ready_push_notifications',
                          e.target.checked
                        )
                      }}
                      color="#255CE1"
                    >
                      Mobile push notification
                    </Checkbox>
                    <FormLabel color="#4F4F4F" fontSize="sm" mb={1}>
                      Zoom connection
                    </FormLabel>

                    <Checkbox
                      isChecked={values.is_zoom_connected}
                      // onChange={(e) => {
                      //   setFieldValue('is_zoom_connected', e.target.checked)
                      // }}
                      color="#255CE1"
                    >
                      Zoom connected to platform
                    </Checkbox>
                  </Stack>
                  {status === true && (
                    <Text color="green.500">Profile Updated</Text>
                  )}
                  {status === false && (
                    <Text color="red.500">Failed to update profile</Text>
                  )}
                  <Box>
                    <Button
                      type="submit"

                      disabled={isSubmitting}
                    >
                      Update settings
                    </Button>
                  </Box>
                </Stack>
              </Form>
            )}
          </Formik>
        </Flex>
      </Flex>
    </Box>
  )
}
