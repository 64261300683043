import React, { useState } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  Stack,
  Flex
} from '@chakra-ui/react'
import { regenerateWifiPassword } from '../../../api'
import { PrimarySpinner } from '../../../components/chakra-v2/PrimarySpinner'
import { PrimaryButton } from '../../../components/chakra-v2/PrimaryButton'
import { WifiPasswordDisplay } from '../../../components/WifiPasswordDisplay'

interface Props {
  isOpen: boolean
  onClose: () => void
  userProfileId: number
}

/**
 * Note - you will need to manually update any SWR caches after `onClose()` is called if
 * you are implementing this component
 */
export const RegenerateWifiPasswordModal: React.FC<Props> = ({
  isOpen,
  onClose,
  userProfileId
}) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [newPassword, setNewPassword] = useState<string>()

  const handleRegenPassword = async () => {
    setLoading(true)
    const { data } = await regenerateWifiPassword(userProfileId)
    setNewPassword(data.wifi_password)
    setLoading(false)
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {newPassword ? 'Success' : 'Regenerate WiFi password'}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {loading ? (
            <Flex p={5} align="center" justify="center">
              <PrimarySpinner />
            </Flex>
          ) : newPassword ? (
            <Stack spacing={3}>
              <Text>The new WiFi password is:</Text>
              <WifiPasswordDisplay
                password={newPassword}
                showPasswordByDefault
              />
            </Stack>
          ) : (
            <Text>
              Are you sure you want to regenerate this WiFi password? The
              password will need to be reset on all currently connected devices.
            </Text>
          )}
        </ModalBody>

        <ModalFooter>
          {newPassword ? (
            <PrimaryButton onClick={onClose}>Close</PrimaryButton>
          ) : (
            <Stack isInline spacing={1}>
              <Button
                variant="ghost"
                borderRadius="100px"
                onClick={onClose}
                isDisabled={loading}
              >
                Cancel
              </Button>
              <PrimaryButton onClick={handleRegenPassword} isDisabled={loading}>
                Regenerate
              </PrimaryButton>
            </Stack>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
