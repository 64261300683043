import {
  Box,
  Divider,
  Flex,
  Grid,
  Heading,
  Select,
  Spacer,
  Text,
  useDisclosure,
  useTheme,
  useToast
} from '@chakra-ui/react'
import { addDays } from 'date-fns'
import React, { useEffect, useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import {
  bookMeetingRoom,
  cancelBooking,
  updateBookingExtraDetails
} from '../../api'
import { AdminBodyWrapper } from '../../components/AdminBodyWrapper'
import { Button } from '../../components/Button'
import { useBookingContext } from '../../context/BookingContext'
import { MeetingRoomSchedule } from '../../styled/MeetingRoomSchedule'
import {
  IBookingAdmin,
  ICreateBookingRequest,
  IOrganizationalUnitBasic,
  Location
} from '../../types'
import { useBookings } from '../../utils/apiHooks'
import { AdminCancelBookingModal } from './Modals/AdminCancelBookingModal'
import { AdminRoomBookingModal } from './Modals/AdminRoomBookingModal'
import { CalendarFormInput } from '../../components/CalendarFormInput'
import { TimelineGroup, TimelineItem } from 'react-calendar-timeline'
import { tourTargetStrings } from '../../tourStepsArrays'
import { useAppTourContextProvider } from '../../context/app-tour-context'

export const AdminDashboardMeetingRoomsBookings: React.FC = () => {
  const { adminTourActive, setRun } = useAppTourContextProvider()
  useEffect(() => {
    if (adminTourActive) {
      setRun(true)
    }
  }, [])
  const theme = useTheme()
  const [isBooking, setIsBooking] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const toast = useToast()

  const [isCancelModalOpen, setIsCancelModalOpen] = useState<boolean>(false)
  const [selectedBooking, setSelectedBooking] = useState<
    IBookingAdmin | undefined
  >()

  const [selectedOrgUnit, setSelectedOrgUnit] =
    useState<IOrganizationalUnitBasic>()

  const { tenantRooms, locations } = useBookingContext()
  const [chosenDate, setChosenDate] = useState(new Date(new Date().setHours(0)))
  const [chosenLocation, setChosenLocation] = useState<Location>()
  const { data: roomBookings, revalidate } = useBookings<IBookingAdmin[]>(
    chosenDate,
    addDays(chosenDate, 1),
    true,
    true,
    false,
    { refreshInterval: 0 }
  )

  const { isOpen, onClose, onOpen } = useDisclosure()

  const makeBooking = (newBooking: ICreateBookingRequest) => {
    setIsBooking(true)

    bookMeetingRoom(newBooking)
      .then((res) => {
        clearValues()
        toast({
          description: 'Booking successfully made',
          status: 'success'
        })
        revalidate()
        onClose()
      })
      .catch((error) => {
        let description = 'Something went wrong, failed to make booking'
        try {
          description = error.response.data
        } catch (error) {}
        try {
          description = error.response.data.non_field_errors[0]
        } catch (error) {}
        toast({
          description,
          status: 'error'
        })
        setIsBooking(false)
      })
  }

  const [mapItems, setMapItems] = useState<any[]>()
  const [mapGroup, setMapGroup] = useState<TimelineGroup[]>()

  useEffect(() => {
    setIsFetching(true)
    if (roomBookings) {
      const items: TimelineItem[] = roomBookings.map((b) => ({
        title: b.booked_by_first_name + ' ' + b.booked_by_last_name,
        group: b.meeting_room ?? 0,
        id: b.id,
        start_time: Date.parse(b.start_time),
        end_time: Date.parse(b.end_time),

        itemProps: {
          'data-custom-attribute': 'Random content',
          'aria-hidden': true,
          onDoubleClick: () => {
            setSelectedBooking(b)
            setIsCancelModalOpen(true)
          },
          className: 'weekend',
          style: {
            background: b.is_external
              ? '#2c3e50'
              : b.is_deleted_org
              ? '#bbb'
              : theme.colors.brandPrimary ?? '#2ac'
          }
        }
      }))

      setMapItems(items)
      setIsFetching(false)
    } else {
      setMapItems([])
      setIsFetching(false)
    }
  }, [roomBookings, chosenDate])
  useEffect(() => {
    const groups: TimelineGroup[] = tenantRooms
      .filter((r) => {
        return chosenLocation ? r.location === chosenLocation?.id : true
      })
      .map((x) => ({
        id: x.id,
        title: `${x.name} (${x.capacity}p)`,
        height: 40
      }))
    setMapGroup(groups)
  }, [tenantRooms, chosenLocation])

  const handleCancelBooking = () => {
    if (selectedBooking != undefined) {
      cancelBooking(selectedBooking.id)
        .then((data) => {
          toast({
            description: 'Booking cancelled',
            status: 'success'
          })
          setIsCancelModalOpen(false)
          setSelectedBooking(undefined)
          clearValues()
          revalidate()
        })
        .catch((error) => {
          let description = 'Something went wrong, failed to make booking'
          try {
            description = error.response.data
          } catch (error) {}
          toast({
            description,
            status: 'error'
          })
          setIsCancelModalOpen(false)
          setSelectedBooking(undefined)
          clearValues()
          revalidate()
        })
    }
  }

  const clearValues = () => {
    setIsBooking(false)
    setSelectedOrgUnit(undefined)
  }

  const handleBookingRescheduled = () => {
    setIsCancelModalOpen(false)
    setSelectedBooking(undefined)
    clearValues()
    revalidate()
  }

  const handleUpdateBooking = (notes, url) => {
    if (selectedBooking != undefined) {
      updateBookingExtraDetails(selectedBooking.id, notes, url)
        .then((data) => {
          toast({
            description: 'Booking updated',
            status: 'success'
          })
          setIsCancelModalOpen(false)
          setSelectedBooking(undefined)
          clearValues()
          revalidate()
        })
        .catch((error) => {
          let description = 'Something went wrong, failed to make booking'
          try {
            description = error.response.data
          } catch (error) {}
          toast({
            description,
            status: 'error'
          })
          setIsCancelModalOpen(false)
          setSelectedBooking(undefined)
          clearValues()
          revalidate()
        })
    }
  }

  return (
    <>
      <AdminBodyWrapper
        bg="white"
        w={['100%', '100%', '100%', '80%']}
        boxShadow="0px 1px 0px rgba(0, 0, 0, 0.05)"
        rounded="md"
        px={8}
        py={5}
        position="relative"
        color="eastBay09"
        align="center"
        justifyContent="space-between"
      >
        <Box>
          <Flex>
            <Heading mr={3} fontSize="xl" mb={2}>
              Bookings
            </Heading>
            <Spacer />
            <Button
              data-testid="btn-booking-open-modal"
              className={tourTargetStrings.adminheaderMeetingroomsBooking}
              onClick={onOpen}
            >
              Book a Meeting Room
            </Button>
          </Flex>
          <Text fontSize={{ base: 'sm', lg: 'md' }} color={'gray.500'}>
            Book meeting rooms for you and other members. <br />
            Members will see the booking on their{' '}
            <strong>Booking's Dashboard</strong> and will see the correspondent
            charge for each booking made on their behalf on their next invoice.
          </Text>
        </Box>
        <Divider py={3} />
        <Box w={['100%', '80%', '60%']} py={3}>
          <Heading mr={3} fontSize="xl" mb={2}>
            Timeline
          </Heading>
          <Text fontSize={{ base: 'sm', lg: 'md' }} color={'gray.500'}>
            Here is an overview of the bookings for a specific date and the days
            before and after that date.
            <br />
            <strong>Tip: </strong> On the timeline,{' '}
            <strong>double click </strong> on a booking element to see more
            details.
          </Text>
        </Box>

        <Grid
          templateColumns={['1fr', 'repeat(4, minmax(150px, 45%))']}
          gap={[5, 10]}
        >
          <Box>
            <CalendarFormInput
              minDate={new Date(Date.parse('01-01-2000'))}
              dateProps={{
                chosenDate,
                handleChangeDate: (date: Date) => {
                  setChosenDate(new Date(date.setHours(0)))
                }
              }}
              closeOnSelect={true}
            />
          </Box>
          <Box>
            <Text fontWeight="bold" mb="4px">
              Location
            </Text>
            <Select
              bg="#e5e5e5"
              variant="filled"
              value={chosenLocation?.id}
              onChange={(e) => {
                const loc = locations?.find((l) => l.id + '' === e.target.value)
                setChosenLocation(loc)
              }}
            >
              <option key={'all'} value={''}>
                {' '}
                All locations
              </option>
              {locations &&
                locations.map((location, i) => (
                  <option key={i} value={location.id}>
                    {location.name} {' - '} {location.address}
                  </option>
                ))}
            </Select>
          </Box>
        </Grid>

        <Box
          style={{
            overflow: 'hidden',
            width: 'calc(100%)',
            border: '1px solid #000000'
          }}
        >
          {!isFetching && mapItems && mapGroup && (
            <MeetingRoomSchedule
              mapItems={mapItems}
              mapGroup={mapGroup}
              date={chosenDate}
            />
          )}
        </Box>
      </AdminBodyWrapper>
      <AdminCancelBookingModal
        isOpen={isCancelModalOpen}
        booking={selectedBooking}
        onCancel={handleCancelBooking}
        onUpdate={handleUpdateBooking}
        handleBookingRescheduled={handleBookingRescheduled}
        closeModal={() => {
          setIsCancelModalOpen(false)
        }}
      />
      <AdminRoomBookingModal
        isOpen={isOpen}
        resourceType="meeting-room"
        resourceList={tenantRooms}
        makeBooking={makeBooking}
        closeModal={onClose}
        isBooking={isBooking}
      />
    </>
  )
}
