import {
  Box,
  BoxProps,
  Stack,
  Text,
  Spacer,
  useDisclosure,
  Flex
} from '@chakra-ui/react'
import { differenceInSeconds, format, formatDistance, getDay } from 'date-fns'
import { stringify } from 'query-string'
import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { encodeQueryParams } from 'use-query-params'
import {
  bookMeetingRoom,
  previewBookingCost,
  previewRescheduleBookingCost,
  rescheduleMeetingRoom
} from '../api'
import { useAuth } from '../context/auth-context'
import { useBookingContext } from '../context/BookingContext'
import { usePlatformTenant } from '../context/platform-tenant-context'
import { useCustomToast } from '../context/toast-context'
import { BookingConfirmModal } from './modals/BookingConfirmModal'
import { secondsToTimeString } from '../pages/MemberDashboard/Workspaces/meetingRoomUtils'
import { CenteredSpinner } from '../styled/CenteredSpinner'
import {
  IMeetingRoom,
  IInterval,
  IPreviewBookingCost,
  IBooking,
  ILocationBusinessHours
} from '../types'
import { viewBookingQueryParams } from '../utils/queryParams'
import { Roles, useRole } from '../utils/rbac'
import { Button } from './Button'
import { MeetingRoomSmallCard } from './MeetingRoomSmallCard'
import { MeetingRoomTimeline } from './MeetingRoomTimeline'
import { zonedTimeToUtc } from 'date-fns-tz'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCalendar,
  faClock,
  faMoneyCheck
} from '@fortawesome/free-solid-svg-icons'

interface Props extends BoxProps {
  meetingRoom: IMeetingRoom
  chosenDate: Date
  booking?: IBooking
  unavailable?: boolean
  businessHours?: ILocationBusinessHours[]
  isExternal?: boolean
  bookingConfirmed: (success: boolean, data: any) => void
}

export const MeetingRoomTimeLineMobileCard: React.FC<Props> = ({
  meetingRoom,
  chosenDate,
  unavailable,
  booking,
  businessHours,
  isExternal,
  bookingConfirmed,
  ...rest
}) => {
  const { currentOrgUnit, me } = useAuth()
  const { platformTenant } = usePlatformTenant()
  const history = useHistory()
  const { newToast: toast } = useCustomToast()
  const {
    isBooking,
    locations,
    chosenLocation,
    setIsBooking,
    revalOrgUnitBookings
  } = useBookingContext()
  const placeholder = require('../assets/placeholder.svg')

  const [isCalculating, setIsCalculating] = useState<boolean>(false)
  const { role } = useRole()
  const [isError, setIsError] = useState<boolean>(false)

  const { isOpen, onOpen, onClose } = useDisclosure()

  const isEdition = booking != undefined

  const [bookingCost, setBookingCost] = useState<IPreviewBookingCost>()

  const [bookingTimes, setBookingTimes] = useState<IInterval | undefined>()

  const dayBusinessHours =
    businessHours &&
    businessHours.find((hours) => hours.day === chosenDate.getDay() - 1)

  const selectTimes = (timeSlot: IInterval | undefined) => {
    if (isCalculating) return
    setBookingTimes(timeSlot)
    if (!timeSlot || !currentOrgUnit) return
    if (me && me.user.is_space_admin) return

    setIsCalculating(true)
    let promise =
      booking != undefined
        ? previewRescheduleBookingCost(booking.id, {
            meeting_room: meetingRoom.id,
            organizational_unit: currentOrgUnit.id,
            start_time: zonedTimeToUtc(
              timeSlot.start,
              meetingRoom.timezone
            ).toISOString(),
            end_time: zonedTimeToUtc(
              timeSlot.end,
              meetingRoom.timezone
            ).toISOString()
          })
        : previewBookingCost({
            meeting_room: meetingRoom.id,
            organizational_unit: currentOrgUnit.id,
            start_time: zonedTimeToUtc(
              timeSlot.start,
              meetingRoom.timezone
            ).toISOString(),
            end_time: zonedTimeToUtc(
              timeSlot.end,
              meetingRoom.timezone
            ).toISOString()
          })
    promise
      .then(({ data }) => {
        setBookingCost(data)
        setIsCalculating(false)
        revalOrgUnitBookings()
      })
      .catch((err) => {
        setIsCalculating(false)
      })
  }

  const makeBooking = async () => {
    if (!bookingTimes || !me || !currentOrgUnit) return

    setIsBooking(true)
    try {
      let promise =
        booking != undefined
          ? await rescheduleMeetingRoom(booking.id, {
              meeting_room: meetingRoom.id,
              organizational_unit: currentOrgUnit.id,
              start_time: zonedTimeToUtc(
                bookingTimes.start,
                meetingRoom.timezone
              ).toISOString(),
              end_time: zonedTimeToUtc(
                bookingTimes.end,
                meetingRoom.timezone
              ).toISOString()
            })
          : await bookMeetingRoom({
              meeting_room: meetingRoom.id,
              organizational_unit: currentOrgUnit.id,
              start_time: zonedTimeToUtc(
                bookingTimes.start,
                meetingRoom.timezone
              ).toISOString(),
              end_time: zonedTimeToUtc(
                bookingTimes.end,
                meetingRoom.timezone
              ).toISOString()
            })
      let bookingResponse = promise.data as IBooking
      if (!isEdition) {
        const params = encodeQueryParams(viewBookingQueryParams, {
          newBooking: true
        })

        const url = `/dashboard/booking/${bookingResponse.id}?${stringify(
          params
        )}`
        history.push(url)
      } else {
        bookingConfirmed(true, bookingResponse)
        toast({
          description: 'Booking rescheduled',
          status: 'success'
        })
      }
    } catch (error) {
      let description = 'Something went wrong, failed to make booking'
      toast({
        description,
        status: 'error'
      })
      onClose()
    }
    setIsBooking(false)
  }

  const makeExternalBooking = async () => {
    if (!bookingTimes || !me || !currentOrgUnit) return

    setIsBooking(true)
    onOpen()
  }
  const getBookingCost = (
    chosenDate,
    bookingTimes,
    meetingRoom,
    bookingCost
  ) => {
    if (!bookingTimes) return null
    return (
      <Box>
        <Text
          mb={2}
          textAlign="center"
          fontWeight={'bold'}
          fontSize="md"
          color={'#666'}
        >
          Booking on {chosenDate ? format(chosenDate, 'EEE, PP') : ''}
        </Text>
        {bookingTimes && (
          <Text fontSize={'md'} py={2}>
            <FontAwesomeIcon icon={faCalendar} color="#7e7e7e" />
            &nbsp; &nbsp;
            {`${format(bookingTimes.start, 'h:mm a')} - ${format(
              bookingTimes.end,
              'h:mm a'
            )}`}
          </Text>
        )}
        <Text fontSize={'md'} py={2}>
          <FontAwesomeIcon icon={faClock} color="#7e7e7e" />
          &nbsp; &nbsp;
          {bookingTimes &&
            secondsToTimeString(
              differenceInSeconds(bookingTimes.end, bookingTimes.start)
            )}{' '}
        </Text>
        {bookingCost && (
          <Text fontSize={'md'} py={2}>
            <FontAwesomeIcon icon={faMoneyCheck} color="#7e7e7e" />
            &nbsp; &nbsp;
            {'$' +
              Number.parseFloat(bookingCost?.charges).toFixed(2) +
              ' (' +
              Number.parseFloat(bookingCost?.credits).toFixed(2) +
              ' credits used)'}
          </Text>
        )}
      </Box>
    )
  }

  return (
    <Box
      w="100%"
      bg="#fff"
      boxShadow="0px 8px 16px rgba(0, 0, 0, 0.1)"
      onClick={() => {
        // handleSelectMeetingRoom()
      }}
      mb="15px"
      borderRadius={'3xl'}
    >
      {isEdition && <Text>Reschedule your booking</Text>}
      <Box display={{ md: 'flex' }} mb="10px">
        {!isEdition && (
          <MeetingRoomSmallCard
            isMobile={true}
            w={['100%']}
            meetingRoom={meetingRoom}
          />
        )}

        <Box
          p={[2, 4, 6, 8]}
          flex={2}
          align={'center'}
          justify={'center'}
          mt={[5]}
          mb={[14]}
        >
          <MeetingRoomTimeline
            meetingRoom={meetingRoom}
            chosenDate={chosenDate}
            setTimeSlot={selectTimes}
            setError={setIsError}
            existingBooking={booking}
            isExternal={isExternal}
            businessHours={dayBusinessHours}
          />
        </Box>
      </Box>

      {(!isExternal || (isExternal && dayBusinessHours?.open)) && (
        <Box mt="5px" display={{ md: 'flex' }} pr={[0, 5]}>
          <Box pl={[3, 0]} display={{ md: 'flex' }}>
            {getBookingCost(chosenDate, bookingTimes, meetingRoom, bookingCost)}
          </Box>
          <Spacer />
          <Stack direction={{ base: 'column', md: 'row' }} spacing={4}>
            <Button
              disabled={isError || isBooking}
              size="sm"
              onClick={() => {
                if (isExternal) {
                  makeExternalBooking()
                } else {
                  makeBooking()
                }
              }}
            >
              {isBooking ? (
                <CenteredSpinner />
              ) : isEdition ? (
                'Confirm Reschedule'
              ) : (
                'Make Booking'
              )}
            </Button>
          </Stack>
        </Box>
      )}
      {chosenLocation && bookingTimes && bookingCost && (
        <BookingConfirmModal
          isOpen={isOpen}
          onClose={() => {
            setIsBooking(false)
            onClose()
          }}
          confirmBooking={makeBooking}
          resource={'MeetingRoom'}
          meeting_room={meetingRoom}
          workspace={undefined}
          location={chosenLocation}
          start_time={bookingTimes?.start}
          end_time={bookingTimes?.end}
          bookingCost={bookingCost}
        />
      )}
    </Box>
  )
}
