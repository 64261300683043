import { AxiosResponse } from 'axios'
import React, { useEffect, useState } from 'react'
import { updatePlatformTenant, sendWelcomeEmailTest } from '../api'
import {
  IBlogPost,
  ICity,
  IPlatformIntegrationSettings,
  IPlatformTenant,
  IPlatformTenantTheme,
  IPlatformTenantUpdate
} from '../types'
import {
  useApiAdminPlatformTenantSettings,
  useApiPlatformTenant,
  useCitiesList,
  usePlatformTenantTheme
} from '../utils/apiHooks'
import { useAuth } from './auth-context'
import { createCtx } from './createCtx'

interface PlatformTenantContextProps {
  revalidate: () => void
  supportUrl: string
  platformTenant: IPlatformTenant | undefined
  tenantIntegration: IPlatformIntegrationSettings | undefined
  revalidateSettings: () => void
  adminUpdatePlatformTenant: (
    data: IPlatformTenantUpdate
  ) => Promise<AxiosResponse>
  blogPosts: IBlogPost[] | undefined
  testWelcomeEmail: () => void
  setThemeTenantLocation: (tenantId?: number, locationId?: number) => void
  platformTheme: IPlatformTenantTheme | undefined
  cities: ICity[]
}

const [usePlatformTenant, PlatformTenantContextProvider] =
  createCtx<PlatformTenantContextProps>()

const PlatformTenantProvider = ({ children }) => {
  // get location profile details, render the theme
  const auth = useAuth()

  //To remove
  const [blogPosts, setBlogPosts] = useState<IBlogPost[]>()
  const { data: platformTenant, revalidate } = useApiPlatformTenant(
    auth.currentOrgUnit?.organization.parent_platform_tenant
  )
  const { data: tenantIntegration, revalidate: revalidateSettings } =
    useApiAdminPlatformTenantSettings(
      auth.currentOrgUnit?.is_space_super_admin
        ? auth.currentOrgUnit?.organization.parent_platform_tenant
        : undefined
    )
  const [supportUrl, setSupportUrl] = useState<string>(
    'https://www.spacecubed.com/contact-us'
  )

  useEffect(() => {
    platformTenant?.support_link && setSupportUrl(platformTenant.support_link)
  }, [platformTenant])

  const adminUpdatePlatformTenant = async (data: IPlatformTenantUpdate) => {
    let response: any
    if (platformTenant?.id) {
      response = await updatePlatformTenant(data, platformTenant?.id)
      revalidate()
    }
    return response
  }
  const testWelcomeEmail = async () => {
    let response: any
    if (platformTenant?.id) {
      response = await sendWelcomeEmailTest(platformTenant?.id)
    }
    return response
  }
  const { data: cities } = useCitiesList()

  const [themeLocation, setThemeLocation] = useState<number | undefined>()
  const [themeTenant, setThemeTenant] = useState<number | undefined>()
  const setThemeTenantLocation = (tenantId?: number, locationId?: number) => {
    setThemeLocation(locationId)
    setThemeTenant(tenantId)
  }
  const { data: platformTheme } = usePlatformTenantTheme(
    themeTenant,
    themeLocation
  )

  return (
    <PlatformTenantContextProvider
      value={{
        blogPosts,
        revalidate,
        supportUrl,
        platformTenant,
        tenantIntegration,
        platformTheme,
        cities: cities ?? [],
        revalidateSettings,
        adminUpdatePlatformTenant,
        testWelcomeEmail,
        setThemeTenantLocation
      }}
    >
      {children}
    </PlatformTenantContextProvider>
  )
}

export { usePlatformTenant, PlatformTenantProvider }
