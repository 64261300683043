import { Box, Grid } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { adminUpdateContent, IContentUpdate } from '../../api'
import { AdminBodyWrapper } from '../../components/AdminBodyWrapper'
import {
  GroupingHeaderLayout,
  ITab
} from '../../components/GroupingHeaderLayout'
import { useAppTourContextProvider } from '../../context/app-tour-context'
import { useAuth } from '../../context/auth-context'

import { ApprovalStatusChoices, IDailyFiveContent, Interest } from '../../types'
import { analyticsTrack } from '../../utils/analytics'
import {
  useDailyFiveContentContent,
  useInterests,
  useScheduledTenantContent
} from '../../utils/apiHooks'
import { AdminDashboardContentCard } from '../DailyFive/AdminDashboardContentCard'
import { LayoutAdminDashboard } from './LayoutAdminDashboard'

export interface TableDropdownOption {
  text: string
  testIdPrefix?: string
  variant: string
  onClick: (row: any) => void
}

export const AdminDashboardCommunity: React.FC = () => {
  const { adminTourActive, setRun } = useAppTourContextProvider()
  useEffect(() => {
    if (adminTourActive) {
      setRun(true)
    }
  }, [])
  const { currentOrgUnit } = useAuth()

  const {
    data: approvedActiveTenantContent,
    revalidate: revalidateActiveTenantApproved
  } = useDailyFiveContentContent(
    currentOrgUnit?.organization.parent_platform_tenant,
    true,
    'APPROVED'
  )
  // const { data: approvedActivePublicContent, revalidate: revalidateActivePublicApproved } = useDailyFiveContentContent(undefined, true, "APPROVED")
  const {
    data: approvedInactiveTenantContent,
    revalidate: revalidateInactiveTenantApproved
  } = useDailyFiveContentContent(
    currentOrgUnit?.organization.parent_platform_tenant,
    false,
    'APPROVED'
  )
  const {
    data: unapprovedTenantContent,
    revalidate: revalidateUnapprovedTenantApproved
  } = useDailyFiveContentContent(
    currentOrgUnit?.organization.parent_platform_tenant,
    true,
    'NONE'
  )
  const {
    data: scheduledTenantContent,
    revalidate: revalidateScheduledTenantContent
  } = useScheduledTenantContent(
    currentOrgUnit?.organization.parent_platform_tenant
  )
  const { data: interestsRes } = useInterests()

  const interests = interestsRes ?? []

  const revalidateTenantHooks = () => {
    // Could use mutations here for swr hooks to improve percieved performance
    revalidateActiveTenantApproved()
    revalidateInactiveTenantApproved()
    revalidateUnapprovedTenantApproved()
    revalidateScheduledTenantContent()
  }

  const [contentFilter, setContentFilter] = useState<string>('pending_content')

  const handleApprove = (content: IDailyFiveContent | undefined) => {
    if (content !== undefined) {
      const data = {
        approval_status: ApprovalStatusChoices.APPROVED,
        interests: content.interests
      }
      adminUpdateContent(data, content.id).then((res) => {
        revalidateTenantHooks()
        analyticsTrack('Daily Five Post Approved', {
          contentId: content.id,
          title: content.title,
          contentType: content.content_type
        })
      })
    }
  }

  // Toggles whether the posts are in circulation (active_to_show_in_future_daily_five)
  const handleActivation = (content: IDailyFiveContent | undefined) => {
    if (content !== undefined) {
      const data: IContentUpdate = {
        // Preserve its current approval status and interests
        approval_status: content.approval_status,
        interests: content.interests,
        // Toggle its activation status
        active_to_show_in_future_daily_five:
          !content.active_to_show_in_future_daily_five
      }
      adminUpdateContent(data, content.id).then((res) => {
        revalidateTenantHooks()
        analyticsTrack('Daily Five Post removed/added to circulation', {
          contentId: content.id
        })
      })
    }
  }
  const handleDecline = (content: IDailyFiveContent | undefined) => {
    if (content !== undefined) {
      const data = { approval_status: ApprovalStatusChoices.DECLINED }
      adminUpdateContent(data, content.id).then((res) => {
        revalidateTenantHooks()
        analyticsTrack('Daily Five Post Declined', { contentId: content.id })
      })
    }
  }

  const headerGroups: ITab[] = [
    {
      active: contentFilter === 'pending_content',
      text: 'Pending',
      selectAction: () => {
        setContentFilter('pending_content')
      }
    },
    {
      active: contentFilter === 'approved_content',
      text: `Approved for next business day (${
        scheduledTenantContent?.length ?? 0
      })`,
      selectAction: () => {
        setContentFilter('approved_content')
      }
    },
    {
      active: contentFilter === 'active_tenant_content',
      text: `Active Tenant Content (${
        approvedActiveTenantContent?.length ?? 0
      })`,
      selectAction: () => {
        setContentFilter('active_tenant_content')
      }
    },
    // {
    //   active: contentFilter === 'active_public_content',
    //   text: `Active Public Content (${approvedActivePublicContent?.length ?? 0})`,
    //   selectAction: () => {
    //     setContentFilter('active_public_content')
    //   }
    // },
    {
      active: contentFilter === 'inactive_content',
      text: `Inactive (${approvedInactiveTenantContent?.length ?? 0})`,
      selectAction: () => {
        setContentFilter('inactive_content')
      }
    }
  ]

  return (
    <LayoutAdminDashboard>
      <GroupingHeaderLayout basicTitle="Daily Five" tabs={headerGroups} />

      <AdminBodyWrapper>
        {contentFilter === 'approved_content' && (
          <Box w="100%">
            <Grid gap="8px">
              {scheduledTenantContent?.map((content: IDailyFiveContent) => (
                <AdminDashboardContentCard
                  interests={interests}
                  key={content.id}
                  content={content}
                  handleActivation={handleActivation}
                  handleApprove={handleApprove}
                  handleDecline={handleDecline}
                />
              ))}
            </Grid>
          </Box>
        )}

        {contentFilter === 'pending_content' && (
          <Box w="100%">
            <Grid gap="8px">
              {unapprovedTenantContent?.map((content: IDailyFiveContent) => (
                <AdminDashboardContentCard
                  interests={interests}
                  key={content.id}
                  content={content}
                  handleActivation={handleActivation}
                  handleApprove={handleApprove}
                  handleDecline={handleDecline}
                />
              ))}
            </Grid>
          </Box>
        )}
        {contentFilter === 'active_tenant_content' && (
          <Box w="100%">
            <Grid gap="8px">
              {approvedActiveTenantContent?.map(
                (content: IDailyFiveContent) => (
                  <AdminDashboardContentCard
                    interests={interests}
                    key={content.id}
                    content={content}
                    removeFromCirculation
                    handleActivation={handleActivation}
                    handleApprove={handleApprove}
                    handleDecline={handleDecline}
                  />
                )
              )}
            </Grid>
          </Box>
        )}
        {/* {contentFilter === 'active_public_content' && (
          <Box w="100%">
            <Grid gap="8px">
              {
                approvedActivePublicContent?.map((content: IDailyFiveContent) => (
                  <AdminDashboardContentCard
                    interests={interests}
                    key={content.id}
                    content={content}
                    removeFromCirculation
                    handleActivation={handleActivation}
                    handleApprove={handleApprove}
                    handleDecline={handleDecline}
                  />
                ))}
            </Grid>
          </Box>
        )} */}
        {contentFilter === 'inactive_content' && (
          <Box w="100%">
            <Grid gap="8px">
              {approvedInactiveTenantContent?.map(
                (content: IDailyFiveContent) => (
                  <AdminDashboardContentCard
                    interests={interests}
                    key={content.id}
                    content={content}
                    removeFromCirculation
                    handleActivation={handleActivation}
                    handleApprove={handleApprove}
                    handleDecline={handleDecline}
                  />
                )
              )}
            </Grid>
          </Box>
        )}
      </AdminBodyWrapper>
    </LayoutAdminDashboard>
  )
}
