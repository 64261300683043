import { Box, Flex, IconButton, Spinner, Text } from '@chakra-ui/react'
import styled from '@emotion/styled'
import React from 'react'
import { useParams } from 'react-router'
import { InvoiceStatus, updateBillingAccount } from '../api'
import { useAuth } from '../context/auth-context'
import { useOrganization } from '../utils/apiHooks'
import { Tag } from './Tag'
import { EditableInputText } from './EditableInputText'
import { CardPanel } from './CardPanel'

export const BillingInfo: React.FC<any> = () => {
  const auth = useAuth()
  const { orgId: routeOrgId } = useParams<{ orgId?: string }>()

  const orgId =
    (routeOrgId && parseInt(routeOrgId, 10)) || auth.currentOrganization?.id

  const {
    data: organization,
    isValidating,
    revalidate
  } = useOrganization(orgId)

  return (
    <Box my="3" maxWidth="650px">
      <Box d="flex" mb={3} alignItems="center">
        <Text mb={0} fontWeight="semibold" color="headingPrimary" fontSize="lg">
          Billing Information
        </Text>
      </Box>
      {!organization && isValidating ? (
        <Spinner />
      ) : (
        <>
          <CardPanel spacing={5} align="initial" p={5} mb={3}>
            <Flex justifyContent="space-between">
              <Text
                color="textPrimary"
                fontWeight="bold"
                fontSize="sm"
                mr="auto"
              >
                Billing Name
              </Text>

              <EditableInputText
                maxLength={100}
                defaultValue={organization?.billing_account?.billing_name ?? ''}
                onSubmitValue={(newValue) => {
                  if (organization) {
                    updateBillingAccount(organization?.billing_account.id, {
                      billing_name: newValue
                    }).then(() => revalidate())
                  }
                }}
                isUpdating={undefined}
              />
            </Flex>
            <Flex justifyContent="space-between">
              <Box>
                <Text
                  color="textPrimary"
                  fontWeight="bold"
                  fontSize="sm"
                  mr="auto"
                >
                  Billing Email
                </Text>
                <Text color="textPrimary" fontSize="xx-small" mr="auto">
                  Monthly invoices will be sent to this address
                </Text>
              </Box>
              <EditableInputText
                maxLength={200}
                defaultValue={
                  organization?.billing_account?.billing_email ?? ''
                }
                onSubmitValue={(newValue) => {
                  if (organization) {
                    updateBillingAccount(organization?.billing_account.id, {
                      billing_email: newValue
                    }).then(() => revalidate())
                  }
                }}
                isUpdating={undefined}
              />
            </Flex>
            <Flex justifyContent="space-between">
              <Box>
                <Text
                  color="textPrimary"
                  fontWeight="bold"
                  fontSize="sm"
                  mr="auto"
                >
                  Address
                </Text>
                <Text
                  color="textPrimary"
                  fontSize="xx-small"
                  mr="auto"
                  maxW="60%"
                >
                  This address appears on your monthly invoice and should be the
                  legal address of your home or business
                </Text>
              </Box>
              <Box>
                <EditableInputText
                  maxLength={200}
                  defaultValue={
                    organization?.billing_account?.billing_address ?? ''
                  }
                  onSubmitValue={(newValue) => {
                    if (organization) {
                      updateBillingAccount(organization?.billing_account.id, {
                        billing_address: newValue
                      }).then(() => revalidate())
                    }
                  }}
                  isUpdating={undefined}
                />
              </Box>
            </Flex>
          </CardPanel>
        </>
      )}
    </Box>
  )
}

interface statusProps {
  text: string | InvoiceStatus
}

export const InvoiceStatusTag: React.FC<statusProps> = (props) => {
  const { text, ...rest } = props

  return (
    <Tag
      positive={text === InvoiceStatus.Paid}
      negative={text === InvoiceStatus.Declined}
      yellow={text === InvoiceStatus.Pending}
      blue={text === InvoiceStatus.Preview}
    >
      {text}
    </Tag>
  )
}

const StyledIconButton = styled(IconButton)`
  height: 23px;
  width: 23px;
  border-radius: 18px;
  border: none;
  color: grey;
  justify-content: center;
  margin-left: 0.75rem;
`
