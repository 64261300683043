import { Box, useDisclosure } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { AdminBodyWrapper } from '../../components/AdminBodyWrapper'
import { LocationAdminSelector } from '../../components/LocationAdminSelector'
import { IAction } from '../../components/table/ActionsDropdown'
import { useBookingContext } from '../../context/BookingContext'
import { usePlatformTenant } from '../../context/platform-tenant-context'
import { IWorkspaceSuperAdmin } from '../../types'
import { useTenantLocations } from '../../utils/apiHooks'
import { Actions, useRBAC } from '../../utils/rbac'
import { LocationOpeningHours } from './Components/LocationOpeningHours'
import { CreateWorkspaceModal } from './Modals/CreateWorkspaceModal'
import { WorkspacesTable } from './Tables/Workspaces'

export const AdminDashboardWorkspacesAdmin: React.FC = () => {
  const { isOpen, onClose, onOpen } = useDisclosure()
  const [selectedWorkspace, setSelectedWorkspace] =
    useState<IWorkspaceSuperAdmin>()

  const { platformTenant } = usePlatformTenant()
  const [locationId, setLocationId] = useState<number>(
    platformTenant?.locations && platformTenant.locations.length > 0
      ? platformTenant.locations[0].id
      : 0
  )

  const { data: locations, revalidate: revalidateLocations } =
    useTenantLocations(platformTenant?.id)
  const { tenantWorkspaces, revalidateAdminWorkspaces } = useBookingContext()
  const [filteredWorkspaces, setFilteredWorkspaces] = useState<
    IWorkspaceSuperAdmin[]
  >([])

  const canAdministerMeetingRooms = useRBAC(Actions.AdministerMeetingRooms)
  const rowActions: IAction[] = []
  canAdministerMeetingRooms &&
    rowActions.push({
      text: 'Edit workspace',
      testIdPrefix: 'edit-workspace',
      action: (rowId: number) => {
        setSelectedWorkspace(
          tenantWorkspaces.find((meetingRoom) => meetingRoom.id === rowId)
        )
        onOpen()
      }
    })
  useEffect(() => {
    if (locations && locations.length > 0) {
      setLocationId(locations[0].id)
    }
  }, [locations])

  useEffect(() => {
    if (tenantWorkspaces.length > 0 && locationId) {
      setFilteredWorkspaces(
        tenantWorkspaces.filter(
          (room) => room.location === locationId || !room.location
        )
      )
    }
  }, [locationId, tenantWorkspaces])

  let chosenLocation = locations.find((l) => l.id === locationId)

  return (
    <AdminBodyWrapper>
      <Box
        p={4}
        display={{ md: 'flex' }}
        flexWrap="wrap"
        data-testid="locations-box"
      >
        {platformTenant?.locations.map((location) => (
          <LocationAdminSelector
            key={location.id.toString()}
            location={location}
            selected={locationId === location.id}
            onClick={() => setLocationId(location.id)}
            data-testid={`location-selector-${location.id}`}
          />
        ))}
      </Box>
      {chosenLocation && (
        <LocationOpeningHours
          location={chosenLocation}
          onUpdate={() => {
            revalidateLocations()
          }}
        />
      )}
      <WorkspacesTable
        workspaces={filteredWorkspaces}
        loading={false}
        editAction={rowActions}
      />

      <CreateWorkspaceModal
        onClose={(status?: boolean) => {
          onClose()
          if (status) revalidateAdminWorkspaces()
        }}
        isOpen={isOpen}
        workspaceToEdit={selectedWorkspace}
      />
    </AdminBodyWrapper>
  )
}
