import {
  Flex,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tab,
  useTheme
} from '@chakra-ui/react'
import styled from '@emotion/styled'
import React from 'react'
import { useRouteMatch } from 'react-router'
import { AdminBodyWrapper } from '../../components/AdminBodyWrapper'
import { AdminHeaderLayout } from '../../components/AdminHeaderLayout'
import { MemberProfileBar } from '../../components/MemberProfileBar'

import { UpcomingMeetingRoomBookings } from '../../components/UpcomingMeetingRoomBookings'
import { CenteredSpinner } from '../../styled/CenteredSpinner'
import { LayoutMemberDashboard } from '../MemberDashboard/LayoutMemberDashboard'
import { AdminEditMemberWifi } from './teamAdminMemberProfileView/AdminEditMemberWifi'
import { useRBAC, Actions } from '../../utils/rbac'
import { useAuth } from '../../context/auth-context'

export const TeamAdminMemberProfileView: React.FC = () => {
  const match = useRouteMatch<{ ouId: string }>()
  const canEditWifiPassword = useRBAC(Actions.EditAllWifiPasswords)
  const { currentOrgUnit } = useAuth()
  // HARDCODED: Only Spacecubed and CORE
  const canViewWifi =
    useRBAC(Actions.EditAllWifiPasswords) &&
    currentOrgUnit &&
    currentOrgUnit.organization &&
    [1, 2].includes(currentOrgUnit.organization?.parent_platform_tenant)
  const theme: any = useTheme()
  const ouId = match?.params.ouId
  const teamMemberouId = ouId
  const tabData = [
    {
      title: 'Bookings',
      bodyComponent: <UpcomingMeetingRoomBookings ouId={teamMemberouId} />
    }
  ]

  if (canEditWifiPassword && canViewWifi) {
    tabData.push({
      title: 'WiFi',
      bodyComponent: <AdminEditMemberWifi />
    })
  }
  const StyledTab = styled(Tab)`
    /* tab / default */
    font-weight: 600;
    font-size: 14px;
    color: ${theme.colors.brandPrimary};
    padding-bottom: 16px;
    border: unset;

    /* tab / active */
    &[aria-selected='true'] {
      border: unset;
      border-bottom: 2px solid ${theme.colors.brandPrimary};
      color: ${theme.colors.brandPrimary};
    }
  `
  if (ouId) {
    return (
      <LayoutMemberDashboard>
        <Tabs flexGrow={1} display="flex" overflow="hidden">
          <Flex
            className="user-profile"
            direction="column"
            w="320px"
            flexShrink={0}
            h="100%"
          >
            <MemberProfileBar ouId={ouId} />
          </Flex>
          <Flex flexDir="column" flexGrow={1} overflow="hidden">
            <AdminHeaderLayout>
              <TabList h="45px">
                {tabData.map(({ title }) => (
                  <StyledTab key={title}>{title}</StyledTab>
                ))}
              </TabList>
            </AdminHeaderLayout>

            <AdminBodyWrapper>
              <TabPanels>
                {tabData.map((tab) => (
                  <TabPanel key={tab.title}>{tab.bodyComponent}</TabPanel>
                ))}
              </TabPanels>
            </AdminBodyWrapper>
          </Flex>
        </Tabs>
      </LayoutMemberDashboard>
    )
  } else return <CenteredSpinner />
}

const AdminHeaderText = styled(Text)``
