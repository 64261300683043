import {
  BoxProps,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useToast
} from '@chakra-ui/react'
import React from 'react'
import { IDailyFiveContent } from '../../types'
import { HamburgerIcon, EditIcon, DeleteIcon } from '@chakra-ui/icons'
import { useAuth } from '../../context/auth-context'
import { useHistory } from 'react-router'
import { routeStrings } from '../../routeStrings'
import { removeContent } from '../../api'
import { useContentExplorer } from '../../context/content-explorer-context'
interface DetailProps extends BoxProps {
  content: IDailyFiveContent | undefined
  size?: 'xs' | 'sm' | 'md' | string
}
export const ContentMenu: React.FC<DetailProps> = ({ content, size }) => {
  const { me } = useAuth()
  const toast = useToast()
  const { removePost } = useContentExplorer()
  const history = useHistory()
  size = size ? size : 'sm'
  if (me?.user.id != content?.user || !content) return null
  return (
    <Flex m={0} textAlign="right" className={'daily5-menu'}>
      <Menu>
        <MenuButton
          size={size}
          as={IconButton}
          aria-label="Options"
          icon={<HamburgerIcon />}
        />
        <MenuList>
          <MenuItem
            icon={<EditIcon />}
            onClick={() => {
              history.push(routeStrings.memberDashboardEditPost(content?.id))
            }}
          >
            Edit post
          </MenuItem>
          <MenuItem
            icon={<DeleteIcon />}
            onClick={() => {
              removeContent(content.id).then((res) => {
                removePost(content.id)
                toast({ description: 'Post removed', status: 'info' })
                history.push(routeStrings.memberDashboardHome)
              })
            }}
          >
            Remove post
          </MenuItem>
        </MenuList>
      </Menu>
    </Flex>
  )
}
